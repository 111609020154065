import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import { manageCustomer, manageDistributor, manageInstrument, manageSite } from 'redux/slices/admin.slice';
import { store } from 'redux/store';

export async function manageExistingDistributor(
  newDistributor: DistributorFormInput,
  onManagedDistributor: () => void
) {
  const headersState = store.getState().headers;
  store
    .dispatch(manageDistributor({ session: headersState, distributor: newDistributor }))
    .then(() => onManagedDistributor());
}

export async function manageExistingCustomer(newCustomer: CustomerFormInput, onManagedCustomer: () => void) {
  const headersState = store.getState().headers;
  store.dispatch(manageCustomer({ session: headersState, customer: newCustomer })).then(() => onManagedCustomer());
}

export async function manageExistingSite(newSite: SiteFormInput, onManagedSite: () => void) {
  const headersState = store.getState().headers;
  store.dispatch(manageSite({ session: headersState, site: newSite })).then(() => onManagedSite());
}

export async function manageExistingInstrument(newInstrument: InstrumentFormInput, onManagedInstrument: () => void) {
  const headersState = store.getState().headers;
  store
    .dispatch(manageInstrument({ session: headersState, instrument: newInstrument }))
    .then(() => onManagedInstrument());
}
