import { DropdownOption } from 'components/styled-components/selection/Dropdown';
import getTranslation from 'helpers/translation/get-translation';

import CheckboxInput from '../input/CheckboxInput';

interface FilterOptionProps {
  text: string;
  isChecked: boolean;
  selectCallback: () => void;
}

function FilterOption({ text, isChecked, selectCallback }: FilterOptionProps) {
  return (
    <DropdownOption onClick={() => selectCallback()}>
      <CheckboxInput checked={isChecked} />
      {getTranslation(text)}
    </DropdownOption>
  );
}

export default FilterOption;
