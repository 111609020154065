import FooterWrapper from 'components/footer/FooterWrapper';
import Header from 'components/header/Header';
import SnackbarCard from 'components/snackbar/SnackbarCard';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import Router from 'Router';
import styled from 'styled-components';

const AppContainer = styled.div`
  min-height: 100vh;
  margin: auto;
`;
const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.background.light};
  min-height: 90vh;
  padding: 0 10em;
  display: flow-root;
  ${() => bpTheme.breakpoints.down('lg')} {
    padding: 0 5em;
  }
  ${() => bpTheme.breakpoints.down('md')} {
    padding: 0 0;
  }
`;
const HeaderWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1001;
`;

function AppContent() {
  return (
    <AppContainer>
      <SnackbarCard />
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <ContentContainer id="application-content-ref">
        <Router />
      </ContentContainer>
      <FooterWrapper />
    </AppContainer>
  );
}

export default AppContent;
