import { ReagentConnectionResponse } from '@dewire/models/definitions/api-response/reagent-connection-response';
import { connectReagent } from 'redux/slices/reagent.slice';
import { store } from 'redux/store';

import { ApiResponse } from '../../api/response';

export default async function connectReagentToInstrument(
  encryptedPayload: string,
  jwt: string,
  onConnectedReagent: (connectionCode: ReagentConnectionResponse) => void,
  onError: () => void
) {
  const response = await store.dispatch(connectReagent({ encryptedPayload, jwt }));
  const payload = response.payload as ApiResponse<ReagentConnectionResponse>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onConnectedReagent(payload.data.result);
}
