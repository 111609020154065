import { ReagentFormInput } from '@dewire/models/definitions/form-input/reagent-form-input';
import handleResponse from 'helpers/error-handling/handle-response';
import { addReagent } from 'redux/slices/reagent.slice';
import { store } from 'redux/store';

export default async function addNewReagent(
  newReagent: ReagentFormInput,
  onAddedReagent: () => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(addReagent({ session: headersState, reagent: newReagent }));

  handleResponse(response.meta.requestStatus, onAddedReagent, onError);
}
