import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import QrCodeIcon from 'assets/icons/QrCodeIcon';
import ScanButtonIcon from 'assets/icons/ScanButtonIcon';
import ReagentModal from 'components/modals/reagent/ReagentModal';
import NavContainer from 'components/nav/NavContainer';
import ReagentScanner from 'components/reagent/connect/ReagentScanner';
import NavigationLinkButton from 'components/styled-components/buttons/NavigationLinkButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import QrCodeContainer from 'components/styled-components/containers/QrCodeContainer';
import QrReaderContainer from 'components/styled-components/containers/QrReaderContainer';
import ReagentContentContainer from 'components/styled-components/containers/ReagentContentContainer';
import QrCodeWrapper from 'components/styled-components/wrappers/QrCodeWrapper';
import QrReaderWrapper from 'components/styled-components/wrappers/QrReaderWrapper';
import getTranslation from 'helpers/translation/get-translation';
import { Paths } from 'interfaces/common';
import { useState } from 'react';

interface ReagentRegistrationProps {
  hasReagentDashboardAccess: boolean;
}

function ReagentRegistrationView({ hasReagentDashboardAccess }: ReagentRegistrationProps) {
  const [scanReagentActive, setScanReagentActive] = useState(false);
  const [reagentModalToggled, setReagentModalToggled] = useState(false);

  const onScanReagent = () => {
    setScanReagentActive(true);
  };

  return (
    <>
      {hasReagentDashboardAccess && (
        <NavContainer>
          <NavigationLinkButton to={Paths.ReagentDashboardUrl}>
            <ArrowBackIcon size="small" />
            <p>Reagent dashboard</p>
          </NavigationLinkButton>
        </NavContainer>
      )}
      <ReagentContentContainer margin={!hasReagentDashboardAccess}>
        <QrReaderContainer>
          {reagentModalToggled && (
            <ReagentModal
              message="Unrecognized QR"
              success={false}
              onFailedCallback={() => setReagentModalToggled(false)}
            />
          )}
          {scanReagentActive ? (
            <QrReaderWrapper>
              <ReagentScanner
                onScanReultFaultyCallback={() => {
                  setScanReagentActive(false);
                  setReagentModalToggled(true);
                }}
              />
            </QrReaderWrapper>
          ) : (
            <QrCodeWrapper>
              <QrCodeContainer onClick={() => onScanReagent()}>
                <QrCodeIcon />
              </QrCodeContainer>
              <PrimaryButton width={24} height={4} margin={false} gap={1} onClick={() => onScanReagent()}>
                <ScanButtonIcon />
                {getTranslation('Scan reagents')}
              </PrimaryButton>
            </QrCodeWrapper>
          )}
        </QrReaderContainer>
      </ReagentContentContainer>
    </>
  );
}
export default ReagentRegistrationView;
