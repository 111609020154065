import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

interface NumericInfoCardProps {
  category: string;
  amount: number;
}

const NumericInfoCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 1.8em;
`;
const AmountText = styled.div`
  font-weight: 900;
  font-size: 1.1em;
  color: ${() => useTheme().font.color.black};
`;
const CategoryText = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  color: ${() => useTheme().font.color.secondary};
`;

function NumericInfoCard({ category, amount }: NumericInfoCardProps) {
  return (
    <NumericInfoCardContainer>
      <AmountText>{amount}</AmountText>
      <CategoryText>{category}</CategoryText>
    </NumericInfoCardContainer>
  );
}

export default NumericInfoCard;
