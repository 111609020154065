import styled from 'styled-components';

const ScrollToTopButton = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background: #084372;
  right: 35px;
  bottom: 15px;
`;

export default ScrollToTopButton;
