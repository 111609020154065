import { OriginTypes } from '@dewire/models/definitions/origin-types';
import { useTheme } from 'app/AppStyling';
import PageNextArrowIcon from 'assets/icons/PageNextArrowIcon';
import styled from 'styled-components';

const AccessCardContainer = styled.div<{ isInstrument?: boolean }>`
  display: flex;
  width: 20em;
  height: 4.5em;
  background: ${() => useTheme().font.color.white};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  cursor: ${({ isInstrument }) => (isInstrument ? 'auto' : 'pointer')};
`;
const LeftContainer = styled.div`
  display: flex;
`;
const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1em;
`;
const UserNameTitle = styled.div`
  font-weight: bold;
`;
const AccessInformationText = styled.div`
  font-size: 0.8em;
  color: ${() => useTheme().font.color.secondary};
`;

interface UserAccessCardProps {
  name: string;
  level: OriginTypes;
  toggleModalCallback: () => void;
}

// TODO: use in other places like totalCycles
function UserAccessCard({ name, level, toggleModalCallback }: UserAccessCardProps) {
  return (
    <AccessCardContainer
      onClick={() => {
        if (level !== 'INSTRUMENT') toggleModalCallback();
      }}
      isInstrument={level === 'INSTRUMENT'}
    >
      <LeftContainer>
        <InformationWrapper>
          <UserNameTitle>{name}</UserNameTitle>
          <AccessInformationText>{level}</AccessInformationText>
        </InformationWrapper>
      </LeftContainer>
      {level !== 'INSTRUMENT' && <PageNextArrowIcon />}
    </AccessCardContainer>
  );
}

export default UserAccessCard;
