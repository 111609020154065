import { ErrorSourceType } from '@dewire/models/definitions/error-source-type';
import { StatusType } from '@dewire/models/definitions/status-type';
import { Status } from 'interfaces/common';
import IconProps from 'interfaces/icon-props';
import { IconSize } from 'interfaces/icon-size';
import styled, { DefaultTheme, useTheme } from 'styled-components';

const StyledSvg = styled.svg<{ size?: IconSize }>`
  ${({ size }) => {
    if (size === 'small') return { width: '0.5em', height: '1em' };
    if (size === 'medium') return { width: '1em', height: '1.5em' };
    if (size === 'x-large') return { width: '2em', height: '2.5em' };
    return { width: '1.5em', height: '2em' };
  }};
`;

interface ErrorSourceIconProps extends IconProps {
  type: ErrorSourceType;
}

function getPath(type: ErrorSourceType): string {
  switch (type) {
    case 'QC':
      return 'M7.83585 14.192L6.25385 11.63C6.04852 11.3127 5.69385 11.154 5.18985 11.154C4.19119 11.154 3.31385 10.944 2.55785 10.524C1.81118 10.0947 1.22785 9.49733 0.807852 8.732C0.397185 7.96667 0.191852 7.07533 0.191852 6.058C0.191852 5.03133 0.397185 4.14 0.807852 3.384C1.22785 2.61867 1.81585 2.026 2.57185 1.606C3.32785 1.186 4.20519 0.976 5.20385 0.976C6.20252 0.976 7.07519 1.186 7.82185 1.606C8.57785 2.026 9.16585 2.61867 9.58585 3.384C10.0059 4.14 10.2159 5.03133 10.2159 6.058C10.2159 7.08467 10.0012 7.98533 9.57185 8.76C9.15185 9.52533 8.56385 10.1133 7.80785 10.524C8.26519 10.6547 8.63385 10.9533 8.91385 11.42L10.0339 13.198L7.83585 14.192ZM5.20385 8.998C5.93185 8.998 6.50119 8.74133 6.91185 8.228C7.33185 7.70533 7.54185 6.982 7.54185 6.058C7.54185 5.134 7.33652 4.41533 6.92585 3.902C6.51519 3.38867 5.94119 3.132 5.20385 3.132C4.46652 3.132 3.89252 3.38867 3.48185 3.902C3.07119 4.41533 2.86585 5.134 2.86585 6.058C2.86585 6.982 3.07119 7.70533 3.48185 8.228C3.89252 8.74133 4.46652 8.998 5.20385 8.998ZM16.7208 11.154C15.6288 11.154 14.6908 10.944 13.9068 10.524C13.1228 10.104 12.5208 9.51133 12.1008 8.746C11.6902 7.98067 11.4848 7.08467 11.4848 6.058C11.4848 5.03133 11.6902 4.14 12.1008 3.384C12.5208 2.61867 13.1228 2.026 13.9068 1.606C14.6908 1.186 15.6288 0.976 16.7208 0.976C17.3742 0.976 18.0042 1.07867 18.6108 1.284C19.2175 1.48 19.7075 1.74133 20.0808 2.068L19.3108 4.098C18.9002 3.79933 18.4895 3.58 18.0788 3.44C17.6682 3.29067 17.2482 3.216 16.8188 3.216C15.9508 3.216 15.2975 3.46333 14.8588 3.958C14.4202 4.44333 14.2008 5.14333 14.2008 6.058C14.2008 6.982 14.4202 7.69133 14.8588 8.186C15.2975 8.67133 15.9508 8.914 16.8188 8.914C17.2482 8.914 17.6682 8.844 18.0788 8.704C18.4895 8.55467 18.9002 8.33067 19.3108 8.032L20.0808 10.062C19.7075 10.3887 19.2175 10.6547 18.6108 10.86C18.0042 11.056 17.3742 11.154 16.7208 11.154Z';
    case 'Background':
      return 'M0.251469 11V1.13H5.01147C6.07547 1.13 6.8968 1.36333 7.47547 1.83C8.06347 2.28733 8.35747 2.90333 8.35747 3.678C8.35747 4.19133 8.2268 4.63933 7.96547 5.022C7.71347 5.40467 7.3588 5.68933 6.90147 5.876C7.45214 6.044 7.8768 6.32867 8.17547 6.73C8.47414 7.122 8.62347 7.612 8.62347 8.2C8.62347 9.068 8.3108 9.754 7.68547 10.258C7.06947 10.7527 6.2248 11 5.15147 11H0.251469ZM2.71547 5.05H4.54947C5.01614 5.05 5.3568 4.966 5.57147 4.798C5.79547 4.63 5.90747 4.37333 5.90747 4.028C5.90747 3.68267 5.79547 3.43067 5.57147 3.272C5.3568 3.104 5.01614 3.02 4.54947 3.02H2.71547V5.05ZM2.71547 9.11H4.80147C5.27747 9.11 5.6228 9.02133 5.83747 8.844C6.06147 8.65733 6.17347 8.382 6.17347 8.018C6.17347 7.654 6.06147 7.38333 5.83747 7.206C5.6228 7.02867 5.27747 6.94 4.80147 6.94H2.71547V9.11ZM15.1215 11.154C13.9922 11.154 13.0309 10.944 12.2375 10.524C11.4535 10.104 10.8562 9.516 10.4455 8.76C10.0349 8.004 9.82955 7.122 9.82955 6.114C9.82955 5.06867 10.0442 4.16333 10.4735 3.398C10.9122 2.62333 11.5375 2.026 12.3495 1.606C13.1615 1.186 14.1415 0.976 15.2895 0.976C15.9802 0.976 16.6382 1.06933 17.2635 1.256C17.8889 1.43333 18.3975 1.676 18.7895 1.984L18.0055 3.972C17.5762 3.692 17.1422 3.49133 16.7035 3.37C16.2649 3.23933 15.7982 3.174 15.3035 3.174C14.3609 3.174 13.6562 3.426 13.1895 3.93C12.7322 4.434 12.5035 5.162 12.5035 6.114C12.5035 7.066 12.7369 7.78467 13.2035 8.27C13.6702 8.75533 14.3609 8.998 15.2755 8.998C15.7049 8.998 16.1855 8.93267 16.7175 8.802V7.248H14.7435V5.442H18.8175V10.398C18.2949 10.6407 17.7115 10.8273 17.0675 10.958C16.4235 11.0887 15.7749 11.154 15.1215 11.154Z';
    default:
      return '';
  }
}

function getColor(status: StatusType, theme: DefaultTheme): string {
  switch (status) {
    case Status.Success:
      return theme.colors.success.iconDetails;
    case Status.Warning:
      return theme.colors.warning.iconDetails;
    case Status.Failure:
      return theme.colors.danger.iconDetails;
    case Status.Unknown:
      return theme.colors.unknown.iconDetails;
    default:
      return '';
  }
}

function ErrorSourceIcon({ size, status, type }: ErrorSourceIconProps) {
  const theme = useTheme();
  const path = getPath(type);
  const color = getColor(status, theme);
  return (
    <StyledSvg size={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d={path} fill={color} />
    </StyledSvg>
  );
}

export default ErrorSourceIcon;
