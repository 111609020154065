/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReagentConnectionResponse } from '@dewire/models/definitions/api-response/reagent-connection-response';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import QrCodeWrapper from 'components/styled-components/wrappers/QrCodeWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import connectReagentToInstrument from 'helpers/reagent/connect';
import getTranslation from 'helpers/translation/get-translation';
import { TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSearchParams } from 'react-router-dom';

function ReagentPairInstrument() {
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get('jwt');
  const payload = searchParams.get('payload');

  const [qrCodeResponse, setQrCodeResponse] = useState<string>('');

  useEffect(() => {
    if (jwt && payload) {
      connectReagentToInstrument(
        payload,
        jwt,
        (res: ReagentConnectionResponse) => {
          setQrCodeResponse(JSON.stringify(res));
        },
        () => {
          // Add error handling here from new error implementation in Api.ts (spike)
          throw new Error('Something went wrong');
        }
      );
    } else {
      throw new Error('Missing data for sending pairing request');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QrCodeWrapper>
      <TextWrapper color={TextColor.Blue} bold height={3} textAlignCenter justifyContentCenter width={20}>
        Finalize pairing by scanning this QR code with your instrument!
      </TextWrapper>
      <QRCode value={qrCodeResponse} />
      <PrimaryButton width={24} height={4} margin={false} onClick={() => window.location.reload()}>
        {getTranslation('Pair new instrument')}
      </PrimaryButton>
    </QrCodeWrapper>
  );
}

export default ReagentPairInstrument;
