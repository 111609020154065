function QrFrameIcon() {
  return (
    <svg width="237" height="228" viewBox="0 0 237 228" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 25.25V75.75H26.25V25.25H78.75V0H26.25C11.8125 0 0 11.3625 0 25.25ZM26.25 151.5H0V202C0 215.887 11.8125 227.25 26.25 227.25H78.75V202H26.25V151.5ZM210 202H157.5V227.25H210C224.438 227.25 236.25 215.887 236.25 202V151.5H210V202ZM210 0H157.5V25.25H210V75.75H236.25V25.25C236.25 11.3625 224.438 0 210 0Z"
        fill="#C2CDD6"
      />
    </svg>
  );
}

export default QrFrameIcon;
