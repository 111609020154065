import { postSignOut, renewAccessTokens } from 'api/Api';
import BouleIcon from 'assets/icons/BouleIcon';
import HeaderMenu from 'components/header/HeaderMenu';
import { KEYCLOAK_ROOT_URL, LOGOUT_HOSTED_UI_URL } from 'config/config';
import dayjs from 'dayjs';
import clearUserSession from 'helpers/resets/clear-user-session';
import { TextColor } from 'interfaces/common';
import { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { logActivity } from 'redux/slices/activity.slice';
import { store } from 'redux/store';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.font.color.primary};
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 1em;
`;

function Header() {
  const [headersState, activityState] = useAppSelector((state) => [state.headers, state.activity]);

  const clearSession = useCallback(
    (expired: boolean) => {
      const headersStateCopy = JSON.parse(JSON.stringify(headersState));
      const logoutCallback = () => {
        clearUserSession();

        if (!expired) {
          window
            .open(`https://${KEYCLOAK_ROOT_URL}/realms/boule-realm/protocol/openid-connect/logout`, '_blank')!
            .focus();
          window.location.href = LOGOUT_HOSTED_UI_URL;
        }
      };

      if (expired) logoutCallback();
      else postSignOut(headersStateCopy).then(logoutCallback);

      logoutCallback();
    },
    [headersState]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs().unix();
      const limit = headersState.timestamp;

      if (now >= limit && limit !== 0) {
        if (activityState.lastActive + 30 * 60 > dayjs().unix()) {
          renewAccessTokens(headersState);
        } else {
          clearSession(true);
        }
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [headersState, clearSession, activityState.lastActive]);

  useEffect(() => {
    const clickActionHandler = () => {
      store.dispatch(logActivity());
    };
    document.addEventListener('mousedown', clickActionHandler);
    return () => {
      document.removeEventListener('mousedown', clickActionHandler);
    };
  }, []);

  return (
    <Container>
      <IconContainer to="/test">
        <BouleIcon width={94} height={25} color={TextColor.White} />
      </IconContainer>
      <HeaderMenu clearSessionCallback={() => clearSession(true)} />
    </Container>
  );
}

export default Header;
