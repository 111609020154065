import { ReagentLotDetails } from '@dewire/models/definitions/api-response/reagent-lot-with-reagents-response';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { formatDisplayDate } from 'helpers/formatters/date-formatter';
import styled, { useTheme } from 'styled-components';

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${() => useTheme().font.color.white};
  padding: 2em;
  gap: 1em;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
`;
const DetailTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;

interface ReagentDetailsHeaderProps {
  lot: ReagentLotDetails;
}

function ReagentDetailsHeader({ lot }: ReagentDetailsHeaderProps) {
  return (
    <DetailsHeader>
      <h4>Lot overview</h4>
      <DetailsContainer>
        <DetailTextWrapper>
          <TextWrapper bold>Lot:</TextWrapper>
          <TextWrapper>{lot?.lotNumber ?? ''}</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Brand:</TextWrapper>
          <TextWrapper>{lot?.brand ?? ''}</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Type:</TextWrapper>
          <TextWrapper>{lot?.type ?? ''}</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Volume:</TextWrapper>
          <TextWrapper>{lot?.volume ?? ''} ml</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Expiry date:</TextWrapper>
          <TextWrapper>{lot?.expiryDate ? formatDisplayDate(lot.expiryDate) : ''}</TextWrapper>
        </DetailTextWrapper>
      </DetailsContainer>
    </DetailsHeader>
  );
}

export default ReagentDetailsHeader;
