import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import handleResponse from 'helpers/error-handling/handle-response';
import { addCustomer, addDistributor, addInstrument, addSite } from 'redux/slices/admin.slice';
import { store } from 'redux/store';

export async function addNewDistributor(
  newDistributor: DistributorFormInput,
  onAddedDistributor: () => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(addDistributor({ session: headersState, distributor: newDistributor }));

  handleResponse(response.meta.requestStatus, onAddedDistributor, onError);
}

export async function addNewCustomer(newCustomer: CustomerFormInput, onAddedCustomer: () => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(addCustomer({ session: headersState, customer: newCustomer }));

  handleResponse(response.meta.requestStatus, onAddedCustomer, onError);
}

export async function addNewSite(newSite: SiteFormInput, onAddedSite: () => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(addSite({ session: headersState, site: newSite }));

  handleResponse(response.meta.requestStatus, onAddedSite, onError);
}

export async function addNewInstrument(
  newInstrument: InstrumentFormInput,
  onAddedInstrument: () => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(addInstrument({ session: headersState, instrument: newInstrument }));

  handleResponse(response.meta.requestStatus, onAddedInstrument, onError);
}
