import { ReagentLot } from '@dewire/models/definitions/api-response/reagentlot';
import { useTheme } from 'app/AppStyling';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import InfiniteScrollList from 'components/lists/InfiniteScrollList';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import FilterModal from 'components/modals/FilterModal';
import NavContainer from 'components/nav/NavContainer';
import FilterGroup from 'components/selection/filter/FilterGroup';
import DateInputField from 'components/selection/input/DateInputField';
import NavigationLinkButton from 'components/styled-components/buttons/NavigationLinkButton';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import DateRangePicker from 'components/styled-components/selection/DateRangePicker';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllReagentLots } from 'helpers/reagent/getters';
import getTranslation from 'helpers/translation/get-translation';
import getDate from 'helpers/utils/date-utils';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Paths, ReagentBrands, ReagentTypes, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnifyingGlass from '../../../assets/icons/magnifying-glas.svg';
import ReagentLotRow from '../../rows/reagent/ReagentLotRow';

const Table = styled.div`
  padding: 1.5em;
  text-align: start;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ToolbarContainerWithWrap = styled(ToolbarContainer)`
  justify-content: flex-start;
  ${() => bpTheme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const FlexNavContainer = styled(NavContainer)`
  flex: 1;
  display: flex;
  justify-content: right;
  ${() => bpTheme.breakpoints.down('sm')} {
    justify-content: left;
  }
`;

const tableHeaders = ['Lot number', 'Brand', 'Volume', 'Type', 'Manufacturing date', 'Expiry date', 'Reagent status'];
const filterBrandOptions = Object.values(ReagentBrands);
const filterTypeOptions = Object.values(ReagentTypes);

interface ReagentLotOverviewFilters {
  brandFilter: string[];
  typeFilter: string[];
  statusFilter: string[];
  search: string;
  startDate: Date;
  endDate: Date;
}

function ReagentLotsOverview() {
  const reagentLotState = useAppSelector((state) => state.reagent.reagentLots.content);
  const [reagentLots, setReagentLots] = useState<ReagentLot[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredReagentLots, setFilteredReagentLots] = useState<ReagentLot[]>([]);

  const [filter, setFilter] = useState<ReagentLotOverviewFilters>({
    brandFilter: [],
    typeFilter: [],
    statusFilter: [],
    search: '',
    startDate: getDate(-60),
    endDate: new Date(),
  });
  const hasReagentUploadAccess = useAppSelector((state) => state.headers).accesses.reagentLotRegistration;

  const blueColor = useTheme().colors.blue;

  const onChangeDate = (dates: [any, any]) => {
    const [start, end] = dates;
    setFilter({ ...filter, startDate: start, endDate: end });
  };

  const isFilterActive = () =>
    filter.brandFilter.length > 0 || filter.typeFilter.length > 0 || filter.statusFilter.length > 0;

  const addOrRemoveFilter = (filters: string[], option: string): string[] => {
    if (filters.includes(option)) return filters.filter((element) => element !== option);
    filters.push(option);
    return filters;
  };

  const clearFilters = () => {
    if (filter) setFilter({ ...filter, brandFilter: [], typeFilter: [], statusFilter: [] });
  };

  const setFilterWrapper = (option: string, filterType: 'brandFilter' | 'typeFilter' | 'statusFilter') => {
    const filters = addOrRemoveFilter(filter?.[filterType] || [], option);
    setFilter({ ...filter, [filterType]: filters });
  };

  useEffect(() => {
    if (loading) {
      getAllReagentLots(
        (result: ReagentLot[]) => {
          setReagentLots(result);
          setFilteredReagentLots(result);
          setLoading(false);
        },
        () => {
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch reagent lots.');
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (reagentLotState.length !== 0) setReagentLots(reagentLotState);
  }, [reagentLotState]);

  const applyBrandFilter = (lot: ReagentLot) => {
    if (filter?.brandFilter?.length === 0) return true;
    return filter.brandFilter.includes(lot.brand);
  };

  const applyTypeFilter = (reagentLot: ReagentLot) => {
    if (filter?.typeFilter?.length === 0) return true;
    return filter?.typeFilter?.includes(reagentLot.type);
  };

  const applyStatusFilter = (reagentLot: ReagentLot) => {
    if (filter?.statusFilter?.includes('Not expired')) return new Date(reagentLot.expiryDate) > new Date();
    return true;
  };

  const applyDateFilter = (reagentLot: ReagentLot) => {
    const { manufacturingDate } = reagentLot;
    return new Date(manufacturingDate) >= filter?.startDate && new Date(manufacturingDate) <= filter?.endDate;
  };

  const applySearchFilter = (reagentLot: ReagentLot) => {
    const { search } = filter;
    return !search || reagentLot.lotNumber.toLowerCase().includes(search.toLowerCase());
  };

  const applyFilters = () => {
    const filteredLots = reagentLots
      .filter(applyBrandFilter)
      .filter(applyTypeFilter)
      .filter(applyStatusFilter)
      .filter(applyDateFilter)
      .filter(applySearchFilter);

    setFilteredReagentLots(filteredLots);
  };

  const mapReagentLots = () => {
    if (!filteredReagentLots) return [];
    return filteredReagentLots?.map((lot) => <ReagentLotRow key={lot.id} reagentLot={lot} />);
  };

  useMemo(() => {
    if (!loading) applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reagentLots, filter]);

  return (
    <>
      <ToolbarContainerWithWrap>
        <FlexContainer>
          <SearchBarContainer>
            <SearchField
              type="text"
              placeholder="Search"
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
              iconUrl={magnifyingGlass}
            />
          </SearchBarContainer>
          <FilterModal isFiltering={isFilterActive()} clearCallback={() => clearFilters()}>
            <FilterGroup
              groupTitle="Brand"
              groupWidth={13.7}
              filterOptions={filterBrandOptions}
              filterArray={filter?.brandFilter || []}
              onFilterCallback={(filterValue: string) => setFilterWrapper(filterValue, 'brandFilter')}
            />
            <FilterGroup
              groupTitle="Type"
              groupWidth={11}
              filterOptions={filterTypeOptions}
              filterArray={filter?.typeFilter || []}
              onFilterCallback={(filterValue: string) => setFilterWrapper(filterValue, 'typeFilter')}
            />
            <FilterGroup
              groupTitle="Is not expired"
              groupWidth={11}
              filterOptions={['Not expired']}
              filterArray={filter?.statusFilter || []}
              onFilterCallback={(filterValue: string) => setFilterWrapper(filterValue, 'statusFilter')}
            />
          </FilterModal>
        </FlexContainer>
        <FlexContainer>
          <DateRangePicker
            selected={filter.startDate}
            onChange={onChangeDate}
            startDate={filter.startDate}
            endDate={filter.endDate}
            selectsRange
            dateFormat="yyyy-MM-dd"
            monthsShown={2}
            filterDate={(date) => date < new Date()}
            customInput={<DateInputField value={`${filter.startDate} ${filter.endDate}`} onClick={undefined} />}
            className="react-datepicker"
          />
        </FlexContainer>
        <FlexNavContainer>
          {hasReagentUploadAccess && (
            <NavigationLinkButton to={Paths.ReagentRegisterUrl} $inContainer={false} $buttonColor={blueColor}>
              <p>{getTranslation('Upload reagent')}</p>
              <ArrowBackIcon size="small" reversed arrowColor={blueColor} />
            </NavigationLinkButton>
          )}
        </FlexNavContainer>
      </ToolbarContainerWithWrap>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="14% 12% 12% 10% 20% 17% 13%" textAlign="start">
            {tableHeaders.map((tableHeader: string) => (
              <p key={tableHeader}>{getTranslation(tableHeader)}</p>
            ))}
          </TableHeader>
          {loading ? <LoadingSpinner containerBased /> : <InfiniteScrollList items={mapReagentLots()} />}
        </Table>
      </HistoryTable>
    </>
  );
}

export default ReagentLotsOverview;
