import CycleInfo from '@dewire/models/definitions/api-response/cycle-info';
import type InstrumentCycleDayDetails from '@dewire/models/definitions/api-response/instrument-cycle-day-details';
import type InstrumentCycleDaySummary from '@dewire/models/definitions/api-response/instrument-cycle-day-summary';
import { cycleTypes } from '@dewire/models/definitions/cycle-types';
import { getInstrumentCycleHistoryDay } from 'api/Api';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import CycleGroupCard from 'components/cards/instrument-dashboard/CycleGroupCard';
import CycleItemCard from 'components/cards/instrument-dashboard/CycleItemCard';
import SummaryItemCard from 'components/cards/instrument-dashboard/SummaryItemCard';
import Flex from 'components/flex/Flex';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import ExpandableTableRow from 'components/styled-components/table/ExpandableTableRow';
import { Divider, InfoTableContainer, InfoTableRow } from 'components/styled-components/table/info-table-style';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import { Status, TextColor } from 'interfaces/common';
import { CycleGroup } from 'interfaces/cycle-group';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const margin = '5em';
const Box = styled.div`
  margin-left: ${margin};
`;
const CycleContainer = styled.ul`
  list-style: none;
  margin: 1em;
`;
const SpinnerContainer = styled.div`
  margin-bottom: 1em;
`;

const StyledTableRow = styled.div<{ open: boolean }>`
  ${({ open, theme }) => {
    if (!open) return { borderBottom: theme.breakLine.soft };
    return {};
  }};
`;

interface CycleRowProps {
  cycleLog: InstrumentCycleDaySummary;
  expanded: boolean;
  expandRowCallback: (isExpanded: boolean) => void;
}

function CycleRow({ cycleLog, expanded, expandRowCallback }: CycleRowProps) {
  const { id } = useParams();
  const headersState = useAppSelector((state) => state.headers);

  const [dayDetails, setDayDetails] = useState<InstrumentCycleDayDetails>({} as InstrumentCycleDayDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [listRenderItems, setRenderListItems] = useState<(CycleGroup | CycleInfo)[]>([]);

  const renderListItems = (item: CycleGroup | CycleInfo) => {
    if ('startTime' in item) {
      return <CycleItemCard cycleInfo={item} key={item.startTime.toLocaleString()} />;
    }
    return CycleGroupCard(item as CycleGroup);
  };

  const isNormalCycle = (_id: BigInt): boolean =>
    _id === cycleTypes.openTube ||
    _id === cycleTypes.prediluted ||
    _id === cycleTypes.capPiercer ||
    _id === cycleTypes.autosampler ||
    _id === cycleTypes.microCapillary;

  const getCycleHistory = () => {
    setIsLoading(true);
    getInstrumentCycleHistoryDay(id ?? '', new Date(cycleLog.date), headersState).then((res) => {
      if (res.status === Status.Ok && res.data.result) {
        setDayDetails(res.data.result);
      } else {
        setApiError(res.status);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!Object.keys(dayDetails).length) {
      getCycleHistory();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    setIsLoading(true);
    const result = dayDetails.history?.reduce<(CycleGroup | CycleInfo)[]>((acc, currentItem, index) => {
      if (!isNormalCycle(BigInt(currentItem.cycleNameId)) || currentItem.error) acc.push(currentItem);
      else if (
        currentItem.cycleNameId === dayDetails.history[index - 1]?.cycleNameId &&
        !dayDetails.history[index - 1].error
      ) {
        const accumulator = acc.pop() as CycleGroup;
        accumulator.number += 1;
        accumulator.lastStart = currentItem.startTime;
        acc.push(accumulator);
      } else if (currentItem.cycleNameId === dayDetails.history[index + 1]?.cycleNameId) {
        acc.push({
          cycleName: currentItem.cycleName,
          number: 1,
          firstStart: currentItem.startTime,
          lastStart: currentItem.startTime,
        });
      } else acc.push(currentItem);
      return acc;
    }, []);
    setRenderListItems(result);
    setIsLoading(false);
  }, [dayDetails.history]);

  return (
    <>
      <StyledTableRow open={expanded}>
        <ExpandableTableRow
          textAlign="center"
          gridExpression="15% 25% 55% 7%"
          open={expanded}
          onClick={() => {
            expandRowCallback(expanded);
          }}
        >
          <p>{dayjs(cycleLog.date).format('YYYY-MM-DD')}</p>
          <p>{cycleLog.numberOfSamples}</p>
          <p>{cycleLog.numberOfErrors}</p>
          <Flex gap="5px" justifyContent="center" alignItems="center">
            <ExpandArrowIcon open={expanded} />
          </Flex>
        </ExpandableTableRow>
      </StyledTableRow>
      {expanded &&
        (isLoading ? (
          <SpinnerContainer>
            <LoadingSpinner containerBased />
          </SpinnerContainer>
        ) : (
          <>
            <Box>
              {apiError ? (
                <InfoTableContainer>
                  <InfoTableRow>
                    <SummaryItemCard titleTranslationKey="Total samples" value={dayDetails.summary.totalSamples} />
                    <SummaryItemCard titleTranslationKey="Open tube" value={dayDetails.summary.openTube} />
                    <SummaryItemCard titleTranslationKey="Prediluted" value={dayDetails.summary.prediluted} />
                  </InfoTableRow>
                  <Divider />
                  <InfoTableRow>
                    <SummaryItemCard titleTranslationKey="Cap piercer" value={dayDetails.summary.capPiercer} />
                    <SummaryItemCard titleTranslationKey="Autosampler" value={dayDetails.summary.autosampler} />
                    <SummaryItemCard titleTranslationKey="Micro-capillary" value={dayDetails.summary.microCapillary} />
                  </InfoTableRow>
                </InfoTableContainer>
              ) : (
                <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>
              )}
            </Box>
            <CycleContainer>{listRenderItems?.map(renderListItems)}</CycleContainer>
          </>
        ))}
    </>
  );
}

export default CycleRow;
