import styled from 'styled-components';

import CycleInfoBox from './CycleInfoBox';

const CycleItemBox = styled.li`
  position: relative;
  display: flex;
  padding-left: 5em;

  &:before {
    position: absolute;
    left: 0;
    top: calc(50% - 0.75em);
    content: '●';
    margin-right: 10px;
    font-size: 2em;
    color: ${({ theme }) => theme.background.medium};
  }

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    border-left: 2px solid ${({ theme }) => theme.background.medium};
    margin-left: calc(0.5em + 1px);
    height: 100%;
  }

  &:first-of-type:after {
    top: 50%;
  }
  &:last-of-type:after {
    top: -50%;
  }
  &:only-of-type:after {
    display: none;
  }

  &:not(:last-of-type) {
    ${CycleInfoBox} {
      border-bottom: ${({ theme }) => theme.breakLine.soft};
    }
  }
`;

export default CycleItemBox;
