import { useTheme } from 'app/AppStyling';

function LinkedInIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3418_10238)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C17.0711 0 22 4.92895 22 11C22 17.0711 17.0711 22 11 22C4.92895 22 0 17.0711 0 11C0 4.92895 4.92895 0 11 0ZM7.56091 17.182V8.5916H4.70504V17.182H7.56091ZM17.8571 17.182V12.2558C17.8571 9.61709 16.4483 8.38961 14.5696 8.38961C13.0547 8.38961 12.3762 9.22273 11.9962 9.80787V8.5916H9.14109C9.17894 9.39757 9.14109 17.182 9.14109 17.182H11.9962V12.3845C11.9962 12.1277 12.0147 11.871 12.0903 11.6875C12.2964 11.1747 12.7665 10.6434 13.5554 10.6434C14.5881 10.6434 15.0018 11.4314 15.0018 12.5858V17.182H17.8571ZM6.15227 4.44941C5.17516 4.44941 4.53677 5.0918 4.53677 5.93377C4.53677 6.758 5.15573 7.41808 6.11445 7.41808H6.13289C7.12869 7.41808 7.74851 6.758 7.74851 5.93377C7.73003 5.0918 7.12873 4.44941 6.15227 4.44941Z"
          fill={useTheme().icon.linkedIn}
        />
      </g>
      <defs>
        <clipPath id="clip0_3418_10238">
          <rect width="22" height="22" fill={useTheme().font.color.white} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedInIcon;
