import { useTheme } from 'app/AppStyling';
import DeleteButton from 'components/styled-components/buttons/DeleteButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import styled from 'styled-components';

const DeleteDistributorCard = styled.div`
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  margin-bottom: 100vh;
`;
const DeleteTitle = styled(TitleWrapper)`
  font-weight: bold;
  margin: 1em 0 0.2em 0;
`;
const WarningTitle = styled(TitleWrapper)`
  font-weight: bold;
  margin: 1em;
`;
const DeleteModalWarning = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${() => useTheme().colors.error.lighter};
  border-left: 10px solid ${() => useTheme().colors.error.dark};
  min-width: 30em;
  margin: 1em;
  border-radius: 0.3em;
`;
const DeleteModalButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0em 1em;
`;
const WarningText = styled.div`
  margin: 0em 1em 2em 1em;
  color: ${() => useTheme().colors.error.dark};
`;

interface DeleteModalProps {
  onCloseCallback: () => void;
  onDelete: () => void;
  titleObject: string;
  element: string;
  warning: string;
}

function DeleteModal({ onCloseCallback, onDelete, titleObject, element, warning }: DeleteModalProps) {
  return (
    <DimmedContainer>
      <DeleteDistributorCard>
        <DeleteTitle color={useTheme().font.color.black}>Delete {titleObject}?</DeleteTitle>
        <p>{`Are you sure you want to delete ${titleObject} "${element}"?`}</p>
        <DeleteModalWarning>
          <WarningTitle color={useTheme().colors.error.dark}>Warning</WarningTitle>
          <WarningText>{`By deleting this ${titleObject} ${warning}.`}</WarningText>
        </DeleteModalWarning>
        <DeleteModalButtons>
          <SecondaryButton onClick={onCloseCallback}>Cancel</SecondaryButton>
          <DeleteButton onClick={onDelete} filled>
            Delete
          </DeleteButton>
        </DeleteModalButtons>
      </DeleteDistributorCard>
    </DimmedContainer>
  );
}

export default DeleteModal;
