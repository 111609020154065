import { ManageOriginLists } from '@dewire/models/definitions/api-response/user-admin/manage-origin-list';
import { ApiResponse } from 'api/response';
import { getOriginsOverview, getUsers } from 'redux/slices/users.slice';
import { store } from 'redux/store';

export async function getAllUsers() {
  const headersState = store.getState().headers;
  store.dispatch(getUsers(headersState));
}

export async function getAllOrigins(onFetchedOrigins: (origins: ManageOriginLists) => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getOriginsOverview(headersState));
  const payload = response.payload as ApiResponse<ManageOriginLists | undefined>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedOrigins(payload.data.result);
}
