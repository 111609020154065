import { ScaleState } from 'interfaces/scale-state';
import styled from 'styled-components';

const WarningSvg = styled.svg`
  stroke: ${({ theme }) => theme.colors.danger.iconDetails};
  stroke-width: 3px;
  fill: none;
  width: 1em;
  height: 1.5em;
  xmlns: 'http://www.w3.org/2000/svg';
`;

interface QcOutOfRangeIndicatorIconProps {
  valueState: ScaleState;
}

function QcOutOfRangeIndicatorIcon({ valueState }: QcOutOfRangeIndicatorIconProps) {
  switch (valueState) {
    case 'inRange':
    case 'valueMissing':
      return null;
    case 'lowRange':
      return (
        <WarningSvg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L7 6L1 1" />
        </WarningSvg>
      );
    case 'highRange':
      return (
        <WarningSvg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 6L7 1L1 6" />
        </WarningSvg>
      );
    case 'lowOutOfRange':
      return (
        <WarningSvg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L7 6L1 1M13 8L7 13L1 8" />
        </WarningSvg>
      );
    case 'highOutOfRange':
      return (
        <WarningSvg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 6L7 1L1 6M13 13L7 8L1 13" />
        </WarningSvg>
      );
    default:
      return null;
  }
}

export default QcOutOfRangeIndicatorIcon;
