import RootContainer from 'components/styled-components/containers/RootContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { TextColor } from 'interfaces/common';

interface IdentityProviderProps {
  name: string;
  url: string;
}

function IdentityProvider({ name, url }: IdentityProviderProps) {
  return (
    <RootContainer
      margin
      onClick={() => {
        document.location.href = url;
      }}
    >
      <TextWrapper size="small" color={TextColor.White}>
        {name}
      </TextWrapper>
    </RootContainer>
  );
}

export default IdentityProvider;
