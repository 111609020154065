import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const IconContainer = styled.div`
  :hover {
    transform: scale(1.15);
  }
`;

function DownloadIcon() {
  return (
    <IconContainer>
      <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Interface / Download">
          <path
            id="Vector"
            d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
            stroke={useTheme().colors.primary}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </IconContainer>
  );
}

export default DownloadIcon;
