function ErrorSnackbarIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.07 8.94C8.23667 8.94 8.37667 8.8825 8.49 8.7675C8.60333 8.6525 8.66 8.51 8.66 8.34V4.46C8.66 4.3 8.6 4.16333 8.48 4.05C8.36 3.93667 8.21667 3.88 8.05 3.88C7.88333 3.88 7.74333 3.9375 7.63 4.0525C7.51667 4.1675 7.46 4.31 7.46 4.48V8.36C7.46 8.52 7.52 8.65667 7.64 8.77C7.76 8.88333 7.90333 8.94 8.07 8.94ZM7.99964 12C8.18655 12 8.34333 11.9368 8.47 11.8104C8.59667 11.6839 8.66 11.5273 8.66 11.3404C8.66 11.1535 8.59679 10.9967 8.47036 10.87C8.34392 10.7433 8.18725 10.68 8.00036 10.68C7.81345 10.68 7.65667 10.7432 7.53 10.8696C7.40333 10.9961 7.34 11.1527 7.34 11.3396C7.34 11.5265 7.40321 11.6833 7.52964 11.81C7.65608 11.9367 7.81275 12 7.99964 12ZM7.99 16C6.86333 16 5.81333 15.7967 4.84 15.39C3.86667 14.9833 3.02 14.42 2.3 13.7C1.58 12.98 1.01667 12.1333 0.61 11.16C0.203333 10.1867 0 9.13667 0 8.01C0 6.88333 0.203333 5.83333 0.61 4.86C1.01667 3.88667 1.58 3.04 2.3 2.32C3.02 1.6 3.86667 1.03333 4.84 0.62C5.81333 0.206667 6.86333 0 7.99 0C9.11667 0 10.1667 0.206667 11.14 0.62C12.1133 1.03333 12.96 1.6 13.68 2.32C14.4 3.04 14.9667 3.88667 15.38 4.86C15.7933 5.83333 16 6.88333 16 8.01C16 9.13667 15.7933 10.1867 15.38 11.16C14.9667 12.1333 14.4 12.98 13.68 13.7C12.96 14.42 12.1133 14.9833 11.14 15.39C10.1667 15.7967 9.11667 16 7.99 16ZM8 14.8C9.86667 14.8 11.4667 14.1333 12.8 12.8C14.1333 11.4667 14.8 9.86667 14.8 8C14.8 6.13333 14.1333 4.53333 12.8 3.2C11.4667 1.86667 9.86667 1.2 8 1.2C6.13333 1.2 4.53333 1.86667 3.2 3.2C1.86667 4.53333 1.2 6.13333 1.2 8C1.2 9.86667 1.86667 11.4667 3.2 12.8C4.53333 14.1333 6.13333 14.8 8 14.8Z"
        fill="#C8102E"
      />
    </svg>
  );
}

export default ErrorSnackbarIcon;
