import FilterIcon from 'assets/icons/FilterIcon';
import ClearButton from 'components/styled-components/buttons/ClearButton';
import { Dropdown, DropdownBoxTernary, DropdownTitle } from 'components/styled-components/selection/Dropdown';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import getTranslation from 'helpers/translation/get-translation';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
  gap: 1em;
  position: relative;
  z-index: 100;
`;
const FilterButtonContainer = styled.div`
  cursor: pointer;
  :hover {
    transform: scale(1.15);
  }
`;
const FilterDropdownContainer = styled.div<{ left: number }>`
  align-items: center;
  position: absolute;
  left: ${({ left }) => `${left}em`};
  top: 2em;
  width: fit-content;
`;
const FilterDropdown = styled(Dropdown)`
  margin: 0;
`;

interface FilterModalProps {
  children: React.ReactNode;
  isFiltering: boolean;
  leftAlign?: number;
  clearCallback: () => void;
}

function FilterModal({ children, isFiltering, leftAlign = 0, clearCallback }: FilterModalProps) {
  const dropdownRef = useRef<any>(null);
  const [filterToggled, setFilterToggled] = useState(false);

  function handleClear() {
    setFilterToggled((t) => !t);
    clearCallback();
  }

  useEffect(() => {
    const clickOutsideDropdownHandler = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && filterToggled) {
        setFilterToggled(false);
      }
    };
    document.addEventListener('mousedown', clickOutsideDropdownHandler);
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropdownHandler);
    };
  }, [dropdownRef, filterToggled]);

  return (
    <FilterContainer ref={dropdownRef}>
      <FilterButtonContainer onClick={() => setFilterToggled((toggled) => !toggled)}>
        <FilterIcon />
      </FilterButtonContainer>
      <FilterDropdownContainer left={leftAlign}>
        <FilterDropdown toggled={filterToggled}>
          <DropdownTitle>
            <TextWrapper bold>{getTranslation('Filters')}</TextWrapper>
            {isFiltering && <ClearButton onClearCallback={() => handleClear()} />}
          </DropdownTitle>
          <DropdownBoxTernary>{children}</DropdownBoxTernary>
        </FilterDropdown>
      </FilterDropdownContainer>
    </FilterContainer>
  );
}

export default FilterModal;
