import { useTheme } from 'app/AppStyling';

function PenIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 20.247V22.6112C9 22.8289 9.1774 23 9.40318 23H11.8545C11.9594 23 12.0642 22.9611 12.1368 22.8833L20.9422 14.399L17.9184 11.4827L9.12095 19.9671C9.04032 20.0449 9 20.1382 9 20.247ZM23.2807 12.1437C23.5952 11.8404 23.5952 11.3505 23.2807 11.0472L21.3938 9.22747C21.0793 8.92418 20.5713 8.92418 20.2568 9.22747L18.7812 10.6506L21.8051 13.5669L23.2807 12.1437V12.1437Z"
        fill={useTheme().colors.blue}
      />
    </svg>
  );
}

export default PenIcon;
