import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Market } from '@dewire/models/definitions/api-response/market';
import { Site } from '@dewire/models/definitions/api-response/site';
import { FieldInput } from 'interfaces/field-input';

export function getMarketFieldInput(marketsState: Market[], id: number | undefined): FieldInput<number> {
  const market = marketsState.find((val) => id === val.id);
  return { label: market?.name ?? '', id };
}

export function getDistributorFieldInput(distributorsState: Distributor[], id: number | undefined): FieldInput<number> {
  const distributor = distributorsState.find((val) => id === val.formData.id);
  return { label: distributor?.name ?? '', id };
}

export function getDistributorFieldInputByCustomer(
  distributorsCustomers: { label: string; id: number }[],
  id: number | undefined
): FieldInput<number> {
  const distributor = distributorsCustomers.find((val) => id === val.id);
  return { label: distributor?.label ?? '', id };
}

export function getDistributorFieldInputWithoutDistributorAccess(
  customersState: Customer[],
  id: number | undefined
): FieldInput<number> {
  const distributor = customersState.find((val) => id === val.distributorId);
  return { label: distributor?.distributorName ?? '', id };
}

export function getCustomerFieldInputWithoutCustomerAccess(
  sitesState: Site[],
  id: number | undefined
): FieldInput<number> {
  const customer = sitesState.find((val) => id === val.customerId);
  return { label: customer?.customerName ?? '', id };
}

export function getCustomerFieldInput(availableCustomers: Customer[], id: number | undefined): FieldInput<number> {
  const customer = availableCustomers.find((val) => id === val.formData.id);
  return { label: customer?.name ?? '', id };
}

export function getSiteFieldInput(availableSites: Site[], id: number | undefined): FieldInput<number> {
  const site = availableSites.find((val) => id === val.formData.id);
  return { label: site?.name ?? '', id };
}

export function getInstrumentFieldInput(
  availableInstruments: { label: string; id: string }[],
  id: string | undefined
): FieldInput<string> {
  const instrument = availableInstruments.find((val) => id === val.id);
  return { label: instrument?.label ?? '', id };
}
