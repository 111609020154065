import { getAccessTypes } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import DropdownInput from 'components/selection/input/DropdownInput';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import getTranslation from 'helpers/translation/get-translation';
import { Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';

interface DropdownRolesSelectProps {
  onChangeCallback: (roleId: string) => void;
  currentRole: FieldInput<number>;
  setCurrentRole: React.Dispatch<React.SetStateAction<FieldInput<number>>>;
  error?: boolean;
  helper?: string;
}

function RoleSelection({
  currentRole,
  setCurrentRole,
  onChangeCallback,
  error = false,
  helper = '',
}: DropdownRolesSelectProps) {
  const theme = useTheme();
  const [headersState] = useAppSelector((state) => [state.headers, state.users]);
  const [roles, setRoles] = useState<FieldInput<number>[]>([]);

  const handleOnChange = (id: number | undefined) => {
    const selectedRole = roles.find((role) => role.id === id);
    if (!selectedRole) return;
    setCurrentRole(selectedRole);

    if (!selectedRole.id || selectedRole.id === -1) return; // invalid selection, dont update userinstance in parent
    const roleId = selectedRole.id.toString();
    onChangeCallback(roleId);
  };

  useEffect(() => {
    const fetchAccessTypes = async () => {
      const res = await getAccessTypes(headersState);
      if (res.status === Status.Ok) {
        const fetchedTypes = res.data.result;
        const fetchedRoles = fetchedTypes.map((role) => ({ label: role.name, id: role.id }));
        setRoles(fetchedRoles);
      } else {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch roles.');
      }
    };

    fetchAccessTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DropdownInput
      label={getTranslation('Role *')}
      value={currentRole}
      valueArray={roles}
      checkbox
      backgroundColor={theme.font.color.white}
      onChangeCallback={(id) => handleOnChange(id)}
      error={error}
      helper={helper}
    />
  );
}

export default RoleSelection;
