import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 15em;
  height: 23em;
  min-height: 20em;
  background-color: ${() => useTheme().colors.tertiary};
  box-shadow: 2px 5px 5px ${() => useTheme().shadow.secondary};
`;
const CardCircle = styled.div`
  padding: 1em;
  justify-content: center;
  align-items: center;
  background-color: ${() => useTheme().font.color.white};
  height: 10em;
  display: flex;
  width: 10em;
  border-radius: 10em;
`;
const CardContent = styled.div`
  padding: 1em;
  font-size: 3em;
  color: ${() => useTheme().font.color.secondary};
`;

interface ExtraInstrumentsCardProps {
  extra: number;
  onClickCallback: () => void;
}

function ExtraInstrumentsCard({ extra, onClickCallback: onClick }: ExtraInstrumentsCardProps) {
  return (
    <CardContainer className="instrumentCard" onClick={() => onClick()}>
      <CardCircle>
        <CardContent>+{extra}</CardContent>
      </CardCircle>
    </CardContainer>
  );
}

export default ExtraInstrumentsCard;
