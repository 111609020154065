import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Site } from '@dewire/models/definitions/api-response/site';
import RefreshIcon from 'assets/icons/RefreshIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import AddSiteModal from 'components/modals/add/AddSiteModal';
import ManageSiteModal from 'components/modals/manage/ManageSiteModal';
import Paginator from 'components/paginator/Paginator';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import RefreshContainer from 'components/styled-components/containers/RefreshContainer';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllSites } from 'helpers/organization/getters';
import { manageExistingSite } from 'helpers/organization/manage';
import getTranslation from 'helpers/translation/get-translation';
import { Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';
import SiteRow from '../../components/rows/organization/SiteRow';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;

interface SiteAdminViewProps {
  sitesParent: Customer | undefined;
  navigateCallback: (site: Site) => void;
}

function SiteAdminView({ sitesParent, navigateCallback }: SiteAdminViewProps) {
  const [headersState, sitesState] = useAppSelector((state) => [state.headers, state.admin.sites.content]);

  const [sortedSites, setSortedSites] = useState<Site[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [addSiteToggled, setAddSiteToggled] = useState(false);
  const [currentSite, setCurrentSite] = useState<Site>();
  const [currentSiteToExpand] = useState<number>();
  const [page, setPage] = useState(1);
  const pageLimit = 8;

  const refreshSites = () => {
    setLoading(true);
  };

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleAddSiteClose = () => {
    setAddSiteToggled(false);
  };

  const filterSitesByParent = () => {
    if (sitesParent) {
      return sitesState.filter((_site) => _site.customerName === sitesParent.name);
    }
    return sitesState;
  };

  const saveManagedSite = (managedSite: Site) => {
    manageExistingSite(managedSite.formData, () => {
      provideSnackbar(
        Status.Success,
        lodash.capitalize(Status.Success),
        `Site "${currentSite?.name}" was successfully changed.`
      );
    });
    setCurrentSite(undefined);
  };

  useEffect(() => {
    if (loading) {
      getAllSites(() => setLoading(false));
      if (sitesState.length === 0) {
        provideSnackbar(Status.Info, 'Status', 'No sites currently exist for this customer.');
      }
    }
  }, [loading, sitesState]);

  useEffect(() => {
    setSortedSites(lodash.sortBy(filterSitesByParent(), (site) => site.name.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesState, sitesParent]);

  const availableSites = sortedSites
    .filter(
      (site) =>
        !search.length ||
        (search.length && site.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && site.city.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && site.address.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    )
    .map((site) => (
      <SiteRow
        key={site.formData.id}
        site={site}
        expanded={site.formData.id === currentSiteToExpand}
        manageSiteCallback={() => setCurrentSite(site)}
        instrumentsCallback={() => navigateCallback(site)}
      />
    ));

  return (
    <div>
      {currentSite && (
        <ManageSiteModal
          site={currentSite}
          onSaveCallback={(site: Site) => saveManagedSite(site)}
          onCloseCallback={() => setCurrentSite(undefined)}
        />
      )}
      {(headersState.accesses.organization.sites.edit || headersState.accesses.organization.subsites.edit) &&
        addSiteToggled && (
          <AddSiteModal
            customer={{ label: sitesParent?.name ?? '', id: sitesParent?.formData.id }}
            country={{ label: sitesParent?.country ?? '', id: sitesParent?.formData.countryCode }}
            onCloseCallback={() => handleAddSiteClose()}
          />
        )}
      <ToolbarContainer>
        <SearchBarContainer>
          <SearchField
            type="text"
            className="searchSite"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            iconUrl={magnyfyingGlass}
          />
        </SearchBarContainer>
        <ToolbarContainer hasFiltering={false}>
          {(headersState.accesses.organization.sites.edit || headersState.accesses.organization.subsites.edit) && (
            <PrimaryButton className="addSite" rounded type="button" onClick={() => setAddSiteToggled(true)}>
              Add site
            </PrimaryButton>
          )}
          <RefreshContainer onClick={() => refreshSites()}>
            <RefreshIcon />
          </RefreshContainer>
        </ToolbarContainer>
      </ToolbarContainer>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="27% 20% 32% 19% 6%" textAlign="start">
            <p>{getTranslation('Site')}</p>
            <p>{getTranslation('City')}</p>
            <p>{getTranslation('Address')}</p>
            <p>{getTranslation('Instruments')}</p>
            <div />
          </TableHeader>
          {loading ? (
            <LoadingSpinner containerBased />
          ) : (
            <div>{availableSites.slice(page * pageLimit - pageLimit, page * pageLimit)}</div>
          )}
        </Table>
      </HistoryTable>
      {availableSites.length > pageLimit && !loading && (
        <Paginator
          currentPage={page}
          maxPages={Math.ceil(availableSites.length / pageLimit)}
          paginationCallback={(newPage: number) => handlePagination(newPage)}
        />
      )}
    </div>
  );
}

export default SiteAdminView;
