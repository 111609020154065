import styled from 'styled-components';

import CardContainer from './CardContainer';

const AddCardContainer = styled(CardContainer)`
  padding: 1em;
  max-width: 80em;
  border-radius: 4px;
`;

export default AddCardContainer;
