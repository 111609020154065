import { useTheme } from 'app/AppStyling';

function OrganizationAdminIcon() {
  return (
    <svg
      fill={useTheme().font.color.secondary}
      stroke={useTheme().font.color.secondary}
      strokeWidth="10"
      width="30"
      height="30"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.001 512.001"
    >
      <g>
        <g>
          <path d="M208.001,0v104h39.546v8.289h15.851V104H304V0H208.001z M288,88.001h-64V88V16h64V88.001z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M143.999,199.995V160h-40.165v-7.557H87.983V160H48.001v96.001h96v-40.155h8.442v-15.851H143.999z M63.999,240.001
            v-64.001h64v64.001H63.999z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M304,199.995V160h-40.603v-7.557h-15.851V160h-39.546v39.995h-8.006v15.851h8.006v40.155h96v-40.155h8.006v-15.851H304z
            M288,240.001h-64v-64.001h64V240.001z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M424.017,160v-7.557h-15.851V160h-40.165v39.995h-8.443v15.851h8.443v40.155h96V160H424.017z M448.001,240.001h-64
            v-64.001h64V240.001z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            points="87.984,119.686 87.984,143.99 103.835,143.99 103.835,135.536 247.547,135.536 247.547,143.99 263.397,143.99
            263.397,135.536 408.166,135.536 408.166,143.99 424.017,143.99 424.017,119.686 		"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            points="159.84,199.995 159.84,215.846 168.294,215.846 168.294,271.851 184.145,271.851 184.145,215.846
            191.541,215.846 191.541,199.995 		"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M184.145,288.001v-7.696h-15.851v7.696h-40.293v39.856h-8.315v15.851h8.315V384h40.293v14.655h15.851V384H224v-96H184.145
            z M208.001,368.001h-64v-64h64V368.001z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="40.433,327.857 40.433,398.656 56.283,398.656 56.283,343.707 112.289,343.707 112.289,327.857 		" />
        </g>
      </g>
      <g>
        <g>
          <path d="M56.283,416.002v-7.835H40.433v7.835H0v96h96v-96H56.283z M80,496H16v-64h64V496z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M184.145,416.002v-7.835h-15.851v7.835h-40.293v96h96v-96H184.145z M208.001,496h-64v-64h64V496z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M471.568,416.002v-7.835h-15.851v7.835h-39.716v96h96v-96H471.568z M496.002,496.002h-64v-64h64V496.002z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M384,327.856v-39.856h-40.293v-7.696h-15.851v7.696h-39.856v96h39.856v14.655h15.851V384H384v-40.293h8.315v-15.851H384z
            M368.001,368.001h-64v-64h64V368.001z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            points="320.46,199.995 320.46,215.846 327.857,215.846 327.857,271.851 343.707,271.851 343.707,215.846
            352.161,215.846 352.161,199.995 		"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon points="399.712,327.857 399.712,343.707 455.718,343.707 455.718,398.656 471.568,398.656 471.568,327.857 		" />
        </g>
      </g>
      <g>
        <g>
          <path d="M343.707,416.002v-7.835h-15.851v7.835h-39.856v96h96v-96H343.707z M368.001,496h-64v-64h64V496z" />
        </g>
      </g>
    </svg>
  );
}

export default OrganizationAdminIcon;
