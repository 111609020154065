import styled from 'styled-components';

interface FlexProps {
  justifyContent?: 'space-between' | 'baseline' | 'center' | 'space-around' | 'flex-start' | 'flex-end';
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  gap?: string;
  minHeight?: string;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  gap: ${({ gap }) => gap ?? '0'};
  ${({ minHeight }) => (minHeight ? { 'min-height': `${minHeight}em` } : undefined)};
`;

export default Flex;
