import { Customer } from '@dewire/models/definitions/api-response/customer';
import { CustomerDeleteResponse } from '@dewire/models/definitions/api-response/customer-delete-respons';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';

export const successfulCustomerAddCase = (state: any, action: PayloadAction<FullGetResponse<Customer>>) => {
  const newCustomer = action.payload.data.result;
  const updatedDistributors = [...state.distributors.content];
  const distributorIndex = updatedDistributors.findIndex(
    (val: Distributor) => val.formData.id === newCustomer.formData.distributorId
  );
  if (distributorIndex !== -1) {
    const newDistributor = updatedDistributors[distributorIndex];
    updatedDistributors[distributorIndex] = {
      ...newDistributor,
      numOfCustomers: newDistributor.numOfCustomers + 1,
    };
    return {
      ...state,
      customers: {
        ...state.customers,
        content: state.customers.content.concat(newCustomer),
        updateStatus: LoadingState.Succeeded,
      },
      distributors: {
        ...state.distributors,
        content: updatedDistributors,
      },
    };
  }
  return {
    ...state,
    customers: {
      ...state.customers,
      content: state.customers.content.concat(newCustomer),
      updateStatus: LoadingState.Succeeded,
    },
  };
};

export const successfulCustomerDeleteCase = (
  state: any,
  action: PayloadAction<FullGetResponse<CustomerDeleteResponse>>
) => {
  const customerInfo = action.payload.data.result;
  const updatedCustomers = [...state.customers.content];
  const customerIndex = updatedCustomers.findIndex((val: Customer) => val.formData.id === customerInfo.customerId);
  if (customerIndex !== -1) {
    updatedCustomers.splice(customerIndex, 1);
    const updatedDistributors = [...state.distributors.content];
    const distributorIndex = updatedDistributors.findIndex(
      (val: Distributor) => val.formData.id === customerInfo.distributorId
    );
    if (distributorIndex !== -1) {
      const newDistributor = updatedDistributors[distributorIndex];
      updatedDistributors[distributorIndex] = {
        ...newDistributor,
        numOfCustomers: newDistributor.numOfCustomers - 1,
      };
      return {
        ...state,
        customers: {
          ...state.customers,
          content: updatedCustomers,
          updateStatus: LoadingState.Succeeded,
        },
        distributors: {
          ...state.distributors,
          content: updatedDistributors,
        },
      };
    }
  }
  return {
    ...state,
    customers: {
      ...state.customers,
      content: updatedCustomers,
      updateStatus: LoadingState.Succeeded,
    },
  };
};
