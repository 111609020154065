import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import RefreshIcon from 'assets/icons/RefreshIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import AddCustomerModal from 'components/modals/add/AddCustomerModal';
import ManageCustomerModal from 'components/modals/manage/ManageCustomerModal';
import Paginator from 'components/paginator/Paginator';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import RefreshContainer from 'components/styled-components/containers/RefreshContainer';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllCustomers } from 'helpers/organization/getters';
import { manageExistingCustomer } from 'helpers/organization/manage';
import getTranslation from 'helpers/translation/get-translation';
import { Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';
import CustomerRow from '../../components/rows/organization/CustomerRow';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;

interface CustomerAdminViewProps {
  customerParent: Distributor | undefined;
  navigateCallback: (customer: Customer, viewType: 'sites' | 'instruments') => void;
}

function CustomerAdminView({ customerParent, navigateCallback }: CustomerAdminViewProps) {
  const dispatch = useAppDispatch();
  const [headersState, customersState] = useAppSelector((state) => [state.headers, state.admin.customers.content]);

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(customersState.length === 0);
  const [addCustomerToggled, setAddCustomerToggled] = useState(false);
  const [sortedCustomers, setSortedCustomers] = useState<Customer[]>([]);
  const [currentCustomer, setCurrentCustomer] = useState<Customer>();
  const [page, setPage] = useState(1);
  const pageLimit = 8;

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const refreshCustomers = () => {
    setLoading(true);
  };

  const handleAddCustomerClose = () => {
    setAddCustomerToggled(false);
  };

  const saveManagedCustomer = (managedCustomer: Customer) => {
    manageExistingCustomer(managedCustomer.formData, () => {
      provideSnackbar(
        Status.Success,
        lodash.capitalize(Status.Success),
        `Customer "${managedCustomer.name}" was successfully changed.`
      );
      setCurrentCustomer(undefined);
    });
  };

  const filterCustomersByParent = () => {
    if (customerParent) {
      return customersState.filter((customer) => customer.distributorName === customerParent.name);
    }
    return customersState;
  };

  useEffect(() => {
    if (loading) {
      getAllCustomers(() => setLoading(false));
    } else if (customersState.length === 0) {
      provideSnackbar(Status.Info, 'Status', 'No customers currently exist.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading]);

  useEffect(() => {
    setSortedCustomers(lodash.sortBy(filterCustomersByParent(), (item) => item.name.toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersState, customerParent]);

  const availableCustomers = sortedCustomers
    .filter(
      (customer) =>
        !search.length ||
        (search.length && customer.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && customer.country.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (search.length && customer.distributorName.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    )
    .map((_customer) => (
      <CustomerRow
        key={_customer.formData.id}
        customer={_customer}
        sitesCallback={() => navigateCallback(_customer, 'sites')}
        instrumentsCallback={() => navigateCallback(_customer, 'instruments')}
        manageCustomerCallback={() => setCurrentCustomer(_customer)}
      />
    ));

  return (
    <div>
      {currentCustomer && (
        <ManageCustomerModal
          customer={currentCustomer}
          onSaveCallback={(customer: Customer) => saveManagedCustomer(customer)}
          onCloseCallback={() => setCurrentCustomer(undefined)}
        />
      )}
      {addCustomerToggled &&
        (customerParent ? (
          <AddCustomerModal
            onCloseCallback={() => handleAddCustomerClose()}
            distributor={{ label: customerParent.name, id: customerParent.formData.id }}
            country={{ label: customerParent.country, id: customerParent.formData.countryCode }}
          />
        ) : (
          <AddCustomerModal onCloseCallback={() => handleAddCustomerClose()} />
        ))}
      <ToolbarContainer>
        <SearchBarContainer>
          <SearchField
            type="text"
            value={search}
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
            iconUrl={magnyfyingGlass}
          />
        </SearchBarContainer>
        <ToolbarContainer hasFiltering={false}>
          {headersState.accesses.organization.customers.edit && (
            <PrimaryButton rounded type="button" onClick={() => setAddCustomerToggled(true)}>
              {getTranslation('Add customer')}
            </PrimaryButton>
          )}

          <RefreshContainer onClick={() => refreshCustomers()}>
            <RefreshIcon />
          </RefreshContainer>
        </ToolbarContainer>
      </ToolbarContainer>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="20% 20% 20% 20% 18% 6%" textAlign="start">
            <p>{getTranslation('Customer')}</p>
            <p>{getTranslation('Distributor')}</p>
            <p>{getTranslation('Country')}</p>
            <p>{getTranslation('Sites')}</p>
            <p>{getTranslation('Instruments')}</p>
            <div />
          </TableHeader>
          {loading ? (
            <LoadingSpinner containerBased />
          ) : (
            <div>{availableCustomers.slice(page * pageLimit - pageLimit, page * pageLimit)}</div>
          )}
        </Table>
      </HistoryTable>
      {availableCustomers.length > pageLimit && !loading && (
        <Paginator
          currentPage={page}
          maxPages={Math.ceil(availableCustomers.length / pageLimit)}
          paginationCallback={handlePagination}
        />
      )}
    </div>
  );
}

export default CustomerAdminView;
