import { useTheme } from 'app/AppStyling';
import { Status } from 'interfaces/common';
import IconProps from 'interfaces/icon-props';
import { IconSize } from 'interfaces/icon-size';
import styled from 'styled-components';

const StyledSvg = styled.svg<{ size?: IconSize }>`
  ${({ size }) => {
    if (size === 'small') return { width: '1em', height: '1em' };
    if (size === 'medium') return { width: '1.5em', height: '1.5em' };
    if (size === 'x-large') return { width: '2.5em', height: '2.5em' };
    return { width: '2em', height: '2em' };
  }};
`;

function StatusIcon({ size = 'medium', status: type }: IconProps) {
  const theme = useTheme();
  return (
    <StyledSvg size={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === Status.Success && (
        <>
          <circle cx="16" cy="16" r="16" fill={theme.colors.success.iconBackground} />
          <path
            d="M8.80005 16.1922L13.4703 20.8002L23.2 11.2002"
            stroke={theme.colors.success.iconDetails}
            strokeWidth="2.5"
          />
        </>
      )}
      {type === Status.Warning && (
        <>
          <circle cx="16" cy="16" r="16" fill={theme.colors.warning.iconBackground} />
          <path d="M17.5999 20.8H14.3999V24H17.5999V20.8Z" fill={theme.colors.warning.iconDetails} />
          <path d="M17.5999 8H14.3999V17.6H17.5999V8Z" fill={theme.colors.warning.iconDetails} />
        </>
      )}
      {type === Status.Failure && (
        <>
          <path
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill={theme.colors.danger.iconBackground}
          />
          <path
            d="M20.1436 9.6001L15.9996 13.7441L11.8556 9.6001L9.59961 11.8561L13.7436 16.0001L9.59961 20.1441L11.8556 22.4001L15.9996 18.2561L20.1436 22.4001L22.3996 20.1441L18.2556 16.0001L22.3996 11.8561L20.1436 9.6001Z"
            fill={theme.colors.danger.iconDetails}
          />
        </>
      )}
      {type === Status.Unknown && (
        <>
          <circle cx="16" cy="16" r="16" fill={theme.hoverBackground} />
          <path
            d="M16.9142 24H14.6285V21.7143H16.9142V24ZM19.28 15.1429L18.2514 16.1943C17.4285 17.0286 16.9142 17.7143 16.9142 19.4286H14.6285V18.8571C14.6285 17.6 15.1428 16.4571 15.9657 15.6229L17.3828 14.1829C17.8057 13.7714 18.0571 13.2 18.0571 12.5714C18.0571 11.3143 17.0285 10.2857 15.7714 10.2857C14.5142 10.2857 13.4857 11.3143 13.4857 12.5714H11.2C11.2 10.0457 13.2457 8 15.7714 8C18.2971 8 20.3428 10.0457 20.3428 12.5714C20.3428 13.5771 19.9314 14.4914 19.28 15.1429Z"
            fill={theme.colors.unknown.iconDetails}
          />
        </>
      )}
    </StyledSvg>
  );
}

export default StatusIcon;
