import { DefaultTheme } from 'app/AppStyling';

function ResgisterReagentSuccessIcon() {
  const theme = DefaultTheme;
  return (
    <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.5 34C68.5 52.4947 53.2846 67.5 34.5 67.5C15.7154 67.5 0.5 52.4947 0.5 34C0.5 15.5053 15.7154 0.5 34.5 0.5C53.2846 0.5 68.5 15.5053 68.5 34Z"
        fill={theme.icon.successLightBlue}
        stroke="#0048B2"
      />
      <path d="M18.9751 34.408L29.0454 44.2L50.0251 23.8" stroke={theme.icon.successDarkBlue} strokeWidth="2.5" />
    </svg>
  );
}

export default ResgisterReagentSuccessIcon;
