import TextField from '@mui/material/TextField';
import { useTheme } from 'app/AppStyling';
import useTextFieldStyles from 'components/styled-components/mui-styling/TextFieldStyling';
import { useState } from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}px` : '90px')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface NumberInputFieldProps {
  label: string;
  filled: boolean;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  helper?: string;
  value: string;
  width?: number;
  disabled?: boolean;
  containerHeight?: number;
  onChangeCallback: (numberInput: number) => void;
}

function NumberInputField({
  label,
  filled,
  multiline = false,
  rows = 1,
  error = false,
  value,
  helper = '',
  width = 18,
  disabled = false,
  containerHeight = 90,
  onChangeCallback,
}: NumberInputFieldProps) {
  const classes = useTextFieldStyles({ background: useTheme().background.light });
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <OuterContainer height={containerHeight}>
      <TextField
        disabled={disabled}
        id="numberfield-basic"
        type="number"
        style={{ margin: '1em', width: `${width}em` }}
        label={label}
        error={error && !focused}
        variant={filled ? 'filled' : undefined}
        multiline={multiline}
        rows={rows}
        value={value}
        helperText={error && !focused ? helper : ''}
        onChange={(e) => onChangeCallback(Number(e.target.value))}
        InputProps={{
          className: error && !focused ? classes.textFieldError : classes.textField,
        }}
        InputLabelProps={{ className: classes.textFieldLabel }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </OuterContainer>
  );
}

export default NumberInputField;
