import { useTheme } from 'app/AppStyling';

function SearchIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.125 0C11.06 0 14.25 3.18997 14.25 7.125C14.25 8.68645 13.7477 10.1306 12.8959 11.3046L17.6705 16.0795C18.1098 16.5188 18.1098 17.2312 17.6705 17.6705C17.2586 18.0824 16.6068 18.1081 16.1649 17.7477L16.0795 17.6705L11.3046 12.8959C10.1306 13.7477 8.68645 14.25 7.125 14.25C3.18997 14.25 0 11.06 0 7.125C0 3.18997 3.18997 0 7.125 0ZM7.125 2.25C4.43261 2.25 2.25 4.43261 2.25 7.125C2.25 9.81739 4.43261 12 7.125 12C9.81739 12 12 9.81739 12 7.125C12 4.43261 9.81739 2.25 7.125 2.25Z"
        fill={useTheme().icon.dark}
      />
    </svg>
  );
}

export default SearchIcon;
