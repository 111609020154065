import styled from 'styled-components';

const InTableStatusButton = styled.div<{ $color: string; $hoverColor: string }>`
  border-radius: 50px;
  background-color: ${({ $color }) => $color};
  width: fit-content;
  padding: 0 1em;
  justify-content: center;
  display: flex;
  :hover {
    background-color: ${({ $hoverColor }) => $hoverColor};
  }
`;

export default InTableStatusButton;
