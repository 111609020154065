import { DefaultTheme, useTheme } from 'app/AppStyling';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import UserIcon from 'assets/icons/UserIcon';
import MenuModal from 'components/modals/MenuModal';
import UserManual from 'components/user-manual/UserManual';
import getMenuItems from 'helpers/menu/get-menu-items';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
`;
const TabsContainer = styled.div<{ numberOfTabs: number }>`
  display: flex;
  gap: 6em;
  margin: 0 3em 0 1em;
  ${() => bpTheme.breakpoints.down('lg')} {
    gap: 4em;
  }
  ${() => bpTheme.breakpoints.down('md')} {
    display: ${({ numberOfTabs }) => (numberOfTabs > 1 ? 'none' : 'flex')};
  }
`;
const CompressedMenuContainer = styled.div<{ numberOfTabs: number }>`
  display: ${({ numberOfTabs }) => (numberOfTabs > 1 ? 'flex' : 'none')};
  align-items: center;
  margin-top: 0.3em;
  ${() => bpTheme.breakpoints.up('md')} {
    display: none;
  }
`;
const MenuItemDivider = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: 100;
  color: ${() => useTheme().colors.white};
`;
const MenuLinkItem = styled(Link)<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ color }) => color};
  :hover {
    color: ${() => useTheme().icon.blue};
  }
`;
const MenuItem = styled.div<{ color: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ color }) => color};
  margin: 0.3em 3em 0 3em;
  :hover {
    color: ${() => useTheme().icon.blue};
  }
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.25em 0.6em;
  ${() => bpTheme.breakpoints.down('xs')} {
    margin: 0.3em 1em 0 1em;
  }
`;
const MenuItemText = styled.div`
  cursor: pointer;
  ::selection {
    color: none;
    background: none;
  }
  /* For Mozilla Firefox */
  ::-moz-selection {
    color: none;
    background: none;
  }
`;
const ProfileMenuPopup = styled.div`
  cursor: auto;
  position: absolute;
  min-height: 2em;
  padding: 1em;
  width: 10em;
  background: ${() => useTheme().font.color.white};
  box-shadow: 1px 3px 10px ${() => useTheme().shadow.secondary};
  border-radius: 3px;
  top: 55px;
  right: 74px;
  -ms-grid-rows: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  ${() => bpTheme.breakpoints.down('xs')} {
    display: none;
  }
`;

const MenuPopupText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3em 0;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const ManualWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${() => useTheme().icon.blue};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0.3em 0;
`;

function HeaderMenu({ clearSessionCallback }: { clearSessionCallback: () => void }) {
  const theme = useTheme();
  const location = useLocation();
  const dropdownRef = useRef<any>(null);
  const menuButtonRef = useRef<any>(null);
  const headersState = useAppSelector((state) => state.headers);

  const [profileToggled, setProfileToggled] = useState(false);
  const [userMenuHovered, setUserMenuHovered] = useState(false);
  const [toggledItem, setToggledItem] = useState('instruments');

  const navigateToProfileView = () => {
    window.location.href = '/profile';
  };

  const clickOutsideDropdownHandler = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      menuButtonRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !menuButtonRef.current.contains(e.target)
    ) {
      setProfileToggled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideDropdownHandler);
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropdownHandler);
    };
  }, [dropdownRef, profileToggled]);

  useEffect(() => {
    setToggledItem(location.pathname);
  }, [location.pathname]);

  const menuItems = getMenuItems(headersState.accesses);

  return (
    <Container id="header-menu-ref">
      <TabsContainer numberOfTabs={menuItems.length}>
        {menuItems.map((item) => (
          <MenuLinkItem
            className={item.className}
            to={item.url}
            color={toggledItem === item.url ? theme.colors.primary : theme.font.color.white}
            key={item.url}
          >
            {item.displayName}
          </MenuLinkItem>
        ))}
      </TabsContainer>
      <CompressedMenuContainer numberOfTabs={menuItems.length}>
        <MenuModal />
      </CompressedMenuContainer>
      {menuItems.length > 1 && <MenuItemDivider>|</MenuItemDivider>}
      <MenuItem
        className="userProfile"
        onClick={() => setProfileToggled(!profileToggled)}
        color={profileToggled ? theme.icon.blue : theme.font.color.white}
        onMouseEnter={() => setUserMenuHovered(true)}
        onMouseLeave={() => setUserMenuHovered(false)}
        ref={menuButtonRef}
      >
        <UserIcon color={userMenuHovered || profileToggled ? theme.icon.blue : theme.font.color.white} />
        <ProfileWrapper>
          <MenuItemText>{headersState.userName}</MenuItemText>
          <ExpandArrowIcon color={userMenuHovered || profileToggled ? theme.icon.blue : theme.font.color.white} />
        </ProfileWrapper>
        {profileToggled && (
          <ProfileMenuPopup ref={dropdownRef}>
            <MenuPopupText className="userProfilePopup" onClick={() => navigateToProfileView()}>
              {getTranslation('My Profile')}
            </MenuPopupText>
            <ManualWrapper>
              <UserManual color={DefaultTheme.icon.blue} inMenu />
            </ManualWrapper>
            <MenuPopupText className="userProfilePopup" onClick={() => clearSessionCallback()}>
              {getTranslation('Log out')}
            </MenuPopupText>
          </ProfileMenuPopup>
        )}
      </MenuItem>
    </Container>
  );
}

export default HeaderMenu;
