import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

export const CheckboxOuter = styled.div<{ rounded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background: ${() => useTheme().font.color.white};
  border: 1px solid ${({ theme }) => theme.font.color.tertiary};
  border-radius: ${({ rounded }) => (rounded ? '15px' : '1px')};
  padding: 1px;
  margin: 0em 0.7em;
`;

export const CheckboxInner = styled.div<{ checked: boolean; rounded: boolean }>`
  height: 14px;
  width: 14px;
  border-radius: ${({ rounded }) => (rounded ? '15px' : '1px')};
  background-color: ${({ checked, theme }) => (checked ? theme.font.color.tertiary : useTheme().font.color.white)};
  transition: background-color 50ms ease-in-out;
`;
