import { UnitLists } from '@dewire/dbservices/models/permission-mangement';
import { OriginTypes } from '@dewire/models/definitions/origin-types';
import { getOrigins } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import CrossIcon from 'assets/icons/CrossIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import ViewButton from 'components/styled-components/buttons/ViewButton';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import OriginsTable from 'components/tables/OriginsTable';
import returnOriginType from 'helpers/formatters/origin-types';
import getTranslation from 'helpers/translation/get-translation';
import { Status } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: relative;
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  padding: 2em;
  width: 70%; // TODO is this hacky? [2023-04-26]
  margin-bottom: 100vh;
  min-height: 21em;
`;
const ModalInfo = styled.div``;
const IconWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  :hover {
    background: ${() => useTheme().background.medium};
  }
`;
const ViewContainer = styled.div<{ columns: number; rows: number }>`
  display: flex;
  gap: 5em;
  margin-bottom: 2em;
`;

interface AccessOriginModalProps {
  unit: {
    unitId: string;
    userId: string;
    unitName: string;
    originType: OriginTypes;
  };
  closeCallback: (isToggled: boolean) => void;
}

function AccessOriginModal({ unit, closeCallback }: AccessOriginModalProps) {
  const theme = useTheme();
  const { unitId, userId, unitName, originType } = unit;
  const headersState = useAppSelector((state) => state.headers);

  const [view, setView] = useState<'1' | '2'>('1');
  const [originsLoading, setOriginsLoading] = useState(true);
  const [accessValues, setAccessValues] = useState<UnitLists>({ levelOneList: [], levelTwoList: [] });

  const fetchOrigins = () => {
    if (originType && unitId && userId) {
      getOrigins(headersState, originType, unitId, userId).then((res) => {
        if (res.status === Status.Ok) {
          if (res.data.result) setAccessValues(res.data.result);
          setOriginsLoading(false);
        } else {
          setOriginsLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    fetchOrigins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeCallback(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeCallback]);

  return (
    <DimmedContainer>
      <ModalContainer>
        <IconWrapper onClick={() => closeCallback(false)}>
          <CrossIcon color={theme.icon.dark} />
        </IconWrapper>
        <TitleWrapper style={{ fontWeight: 'bold', margin: '0', fontSize: theme.font.size.large }}>
          {unitName}
        </TitleWrapper>
        <TitleWrapper style={{ color: theme.font.color.gray, margin: '0 0 1em 0', fontSize: theme.font.size.medium }}>
          {getTranslation(originType)}
        </TitleWrapper>
        {originsLoading ? (
          <LoadingSpinner inModal />
        ) : (
          <ModalInfo>
            <ViewContainer columns={0} rows={2}>
              {returnOriginType(unit.originType).primaryType !== '' && (
                <ViewButton
                  type="button"
                  active={view === '1'}
                  onClick={() => {
                    setView('1');
                  }}
                >
                  {returnOriginType(unit.originType).primaryType}
                </ViewButton>
              )}
              {returnOriginType(unit.originType).primaryType !== '' && (
                <ViewButton
                  type="button"
                  className="instrumentView"
                  active={view === '2'}
                  onClick={() => {
                    setView('2');
                  }}
                >
                  {returnOriginType(unit.originType).secondaryType}
                </ViewButton>
              )}
            </ViewContainer>
            {view === '1' &&
              (accessValues.levelOneList.length > 0 ? (
                <OriginsTable
                  originType={returnOriginType(unit.originType)?.primaryType}
                  units={accessValues?.levelOneList.map((originUnit) => ({
                    name: String(originUnit.name),
                    id: originUnit.id,
                    primaryInfo: String(originUnit.address1),
                    secondaryInfo: String(originUnit.address2),
                  }))}
                />
              ) : (
                `No ${returnOriginType(
                  unit.originType
                )?.primaryType.toLowerCase()} exist for this ${unit.originType.toLowerCase()}`
              ))}
            {view === '2' &&
              (accessValues.levelTwoList.length > 0 ? (
                <OriginsTable
                  secondary
                  originType={returnOriginType(unit.originType)?.secondaryType}
                  units={accessValues?.levelTwoList.map((originUnit) => ({
                    name: String(originUnit.name),
                    id: originUnit.id,
                    primaryInfo: String(originUnit.owner),
                    secondaryInfo: String(originUnit.address),
                  }))}
                />
              ) : (
                `No ${returnOriginType(
                  unit.originType
                )?.secondaryType.toLowerCase()} exist for this ${unit.originType.toLowerCase()}`
              ))}
          </ModalInfo>
        )}
      </ModalContainer>
    </DimmedContainer>
  );
}

export default AccessOriginModal;
