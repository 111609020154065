import { InstrumentReagent } from '@dewire/models/definitions/api-response/instrument-reagent';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import InTableLink from 'components/styled-components/buttons/InTableLink';
import TableRowItem from 'components/styled-components/items/TableRowItem';
import TableRow from 'components/styled-components/table/TableRow';

interface ReagentLotRowProps {
  instrumentReagent: InstrumentReagent;
}

function InstrumentReagentRow({ instrumentReagent }: ReagentLotRowProps) {
  return (
    <TableRow gridExpression="12% 12% 12% 12% 12% 12% 12% 12%">
      <InTableLink to={`/reagent/dashboard/instrument/${instrumentReagent.instrumentId}`}>
        {instrumentReagent.instrumentId}
        <ExpandArrowIcon open={false} color="black" />
      </InTableLink>
      <TableRowItem>{instrumentReagent.containerId}</TableRowItem>
      <TableRowItem>{instrumentReagent.lotNumber}</TableRowItem>
      <TableRowItem>{instrumentReagent.distributor.name}</TableRowItem>
      <TableRowItem>{instrumentReagent.customer.name}</TableRowItem>
      <TableRowItem>{instrumentReagent.site.name}</TableRowItem>
      <TableRowItem>{instrumentReagent.type}</TableRowItem>
      <TableRowItem>{instrumentReagent.brand}</TableRowItem>
    </TableRow>
  );
}
export default InstrumentReagentRow;
