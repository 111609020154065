import { Site } from '@dewire/models/definitions/api-response/site';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import DeleteButton from 'components/styled-components/buttons/DeleteButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import SaveButtonContainer from 'components/styled-components/containers/SaveButtonContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getSiteFieldInput } from 'helpers/finders/field-input-finders';
import { findCountry, getCountryCodes } from 'helpers/formatters/country-codes';
import { deleteSite } from 'helpers/organization/delete';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import DeleteModal from '../delete/DeleteModal';

const NewSiteTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const DropdownWrapper = styled.div`
  margin: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;
const OptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface ManageSiteModalProps {
  site: Site;
  onSaveCallback: (site: Site) => void;
  onCloseCallback: () => void;
}

function ManageSiteModal({ site, onSaveCallback, onCloseCallback }: ManageSiteModalProps) {
  const countryCodes = getCountryCodes();
  const [headersState, adminState, sitesState] = useAppSelector((state) => [
    state.headers,
    state.admin,
    state.admin.sites.content,
  ]);

  const [siteValue, setSiteValue] = useState<Partial<SiteFormInput>>(site.formData);
  const [error, setError] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [manageSiteLoading, setManageSiteLoading] = useState(false);
  const [deleteSiteLoading, setDeleteSiteLoading] = useState(false);

  const onChangeSite = (_site: Partial<SiteFormInput>) => {
    setSiteValue(_site);
  };

  const resetValues = () => {
    setSiteValue(site.formData);
  };

  const listOtherSites = () => lodash.xor([...sitesState], [site]);

  const listOtherSitesAsDropdown = () => {
    const customerSites = sitesState.filter((customerSite: Site) => customerSite.customerId === site.customerId);
    const availableSites = lodash.xor([...customerSites], [site]);
    const noneValue: FieldInput<number>[] = [{ label: 'None', id: undefined }];
    return lodash.union(
      noneValue,
      availableSites.map((_site) => ({ label: _site.name, id: _site.formData.id }))
    );
  };

  const isDeleteDisabled = () => manageSiteLoading || deleteSiteLoading || changeStatus;

  const isSaveDisabled = () => error || !changeStatus || manageSiteLoading || deleteSiteLoading;

  const handleDeleteModal = () => {
    if (site.numOfInstruments > 0) {
      provideSnackbar(
        Status.Warning,
        lodash.capitalize(Status.Warning),
        `Site "${site.name}" has instruments. They will need to be removed before deleting this site.`
      );
    } else {
      setDeleteActive(true);
    }
  };

  // Input validation
  useEffect(() => {
    if (
      !validateInput({
        value: siteValue.name,
        params: ['required'],
        sites: listOtherSites(),
      }).error &&
      !validateInput({ value: siteValue.address, params: ['required'] }).error &&
      !validateInput({ value: siteValue.city, params: ['required'] }).error &&
      !validateInput({ value: siteValue.countryCode, params: ['required'] }).error &&
      !validateInput({ value: siteValue.email, params: ['email'] }).error &&
      !validateInput({ value: siteValue.phoneNumber, params: ['phone'] }).error &&
      (headersState.accesses.organization.sites.edit ||
        !validateInput({ value: siteValue.parentSiteId?.toString(), params: ['required'] }).error)
    ) {
      setError(false);
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteValue, sitesState]);

  useEffect(() => {
    if (lodash.isEqual(site.formData, siteValue)) {
      setChangeStatus(false);
    } else {
      setChangeStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteValue]);

  useEffect(() => {
    const status = adminState.sites.updateStatus;
    setManageSiteLoading(status === LoadingState.Loading);
    setDeleteSiteLoading(status === LoadingState.Deleting);
  }, [adminState.sites.updateStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      {deleteActive && (
        <DeleteModal
          titleObject="site"
          element={siteValue.name ?? ''}
          warning="all connected data will be inaccessible"
          onCloseCallback={() => setDeleteActive(false)}
          onDelete={() => {
            setDeleteActive(false);
            deleteSite(
              siteValue.id ?? 0,
              () => {
                provideSnackbar(
                  Status.Success,
                  lodash.capitalize(Status.Success),
                  `Successfully deleted site "${site.name}".`
                );
                onCloseCallback();
              },
              () =>
                provideSnackbar(Status.Error, lodash.capitalize(Status.Error), `Failed to delete site "${site.name}".`)
            );
          }}
        />
      )}
      <AddCardContainer>
        <NewSiteTitleWrapper>
          Edit site
          <CloseButton closeCallback={onCloseCallback} />
        </NewSiteTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Name *')}
              filled
              value={siteValue.name ?? ''}
              onChangeCallback={(newName: string) => onChangeSite({ ...siteValue, name: newName })}
              error={
                validateInput({
                  value: siteValue.name,
                  params: ['required'],
                  sites: listOtherSites(),
                }).error
              }
              helper={
                validateInput({
                  value: siteValue.name,
                  params: ['required'],
                  sites: listOtherSites(),
                }).errorMessage
              }
            />
            <DropdownWrapper>
              <DropdownInput
                label={getTranslation('Top site')}
                value={getSiteFieldInput(listOtherSites(), siteValue.parentSiteId)}
                valueArray={listOtherSitesAsDropdown()}
                checkbox
                onChangeCallback={(newParentSite: number | undefined) =>
                  onChangeSite({ ...siteValue, parentSiteId: newParentSite })
                }
                error={
                  !headersState.accesses.organization.sites.edit &&
                  validateInput({ value: siteValue.parentSiteId?.toString(), params: ['requiredId'] }).error
                }
              />
            </DropdownWrapper>
          </DropdownInputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Address *')}
              filled
              value={siteValue.address ?? ''}
              onChangeCallback={(newAddress: string) => onChangeSite({ ...siteValue, address: newAddress })}
              error={validateInput({ value: siteValue.address, params: ['required'] }).error}
              helper={getTranslation('Please enter an address')}
            />
            <TextInputField
              label={getTranslation('City *')}
              filled
              value={siteValue.city ?? ''}
              onChangeCallback={(newCity: string) => onChangeSite({ ...siteValue, city: newCity })}
              error={validateInput({ value: siteValue.city, params: ['required'] }).error}
              helper={getTranslation('Please enter a city')}
              containerHeight={116}
            />
            <SearchInputField
              label={getTranslation('Country *')}
              value={findCountry(siteValue.countryCode)}
              valueArray={countryCodes}
              onChangeCallback={(newCountry: FieldInput<string>) =>
                onChangeSite({ ...siteValue, countryCode: newCountry.id })
              }
              loading={false}
              error={validateInput({ value: siteValue.countryCode, params: ['required'] }).error}
              helper={getTranslation('Please select a country')}
            />
            <TextInputField
              label={getTranslation('Postal Code')}
              filled
              value={siteValue.postalCode ?? ''}
              onChangeCallback={(newPostal: string) => onChangeSite({ ...siteValue, postalCode: newPostal })}
            />
          </InputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Email')}
              filled
              value={siteValue.email ?? ''}
              onChangeCallback={(newEmail: string) => onChangeSite({ ...siteValue, email: newEmail })}
              error={
                !!siteValue.email &&
                siteValue.email.length > 0 &&
                validateInput({ value: siteValue.email, params: ['email'] }).error
              }
              helper="Please enter a valid email address"
            />
            <TextInputField
              label={getTranslation('Phone')}
              filled
              value={siteValue.phoneNumber ?? ''}
              onChangeCallback={(newPhone: string) => onChangeSite({ ...siteValue, phoneNumber: newPhone })}
              error={
                !!siteValue.phoneNumber &&
                siteValue.phoneNumber.length > 0 &&
                validateInput({ value: siteValue.phoneNumber, params: ['phone'] }).error
              }
              helper="Please enter a valid phone number"
              containerHeight={116}
            />
            <TextInputField
              label={getTranslation('Comment')}
              filled
              multiline
              rows={6}
              value={siteValue.comment ?? ''}
              onChangeCallback={(newComment: string) => onChangeSite({ ...siteValue, comment: newComment })}
              containerHeight={205}
            />
          </InputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <OptionButtonContainer>
            {headersState.accesses.organization.sites.edit ||
            (headersState.accesses.organization.subsites.edit && site.formData.parentSiteId) ? (
              <DeleteButton onClick={() => handleDeleteModal()} disabled={isDeleteDisabled()}>
                {deleteSiteLoading ? <LoadingSpinner containerBased inButton error /> : getTranslation('Delete')}
              </DeleteButton>
            ) : (
              <div />
            )}
            <SaveButtonContainer>
              <SecondaryButton disabled={!changeStatus} onClick={() => resetValues()}>
                Reset
              </SecondaryButton>
              <PrimaryButton
                disabled={isSaveDisabled()}
                onClick={() => onSaveCallback({ ...site, formData: siteValue as SiteFormInput })}
              >
                {manageSiteLoading ? <LoadingSpinner containerBased inButton /> : getTranslation('Save')}
              </PrimaryButton>
            </SaveButtonContainer>
          </OptionButtonContainer>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default ManageSiteModal;
