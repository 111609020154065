import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { OrganizationAccess } from '@dewire/models/definitions/api-response/organization-access';
import { Site } from '@dewire/models/definitions/api-response/site';
import ViewButton from 'components/styled-components/buttons/ViewButton';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import Header3 from 'components/styled-components/headers/Header3';
import {
  getAllCustomers,
  getAllInstruments,
  getAllMarkets,
  getAllModels,
  getAllSites,
} from 'helpers/organization/getters';
import getTranslation from 'helpers/translation/get-translation';
import { OrganizationParents } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import CustomerAdminView from './CustomerAdminView';
import DistributorAdminView from './DistributorAdminView';
import InstrumentAdminView from './InstrumentAdminView';
import SiteAdminView from './SiteAdminView';

const HeaderWrapper = styled.div`
  margin-top: 1.5em;
`;
const TabContainer = styled.div`
  display: flex;
  gap: 5em;
`;

type Tabs = 'distributors' | 'customers' | 'sites' | 'instruments';

const getStartingTab = (access: OrganizationAccess): Tabs => {
  if (access.distributors.view) return 'distributors';
  if (access.customers.view) return 'customers';
  if (access.sites.view) return 'sites';
  if (access.instruments.view) return 'instruments';
  throw new Error('no visible orgunit');
};

function AdminView() {
  const [headersState, customersState] = useAppSelector((state) => [state.headers, state.admin.customers.content]);

  const [view, setView] = useState<Tabs>(getStartingTab(headersState.accesses.organization));
  const [parent, setParent] = useState<Distributor | Customer | Site | undefined>();

  const handleChildViewCallback = (
    newParent: Distributor | Customer | Site,
    viewType: 'customers' | 'sites' | 'instruments'
  ) => {
    setParent(newParent);
    setView(viewType);
  };

  const returnParentType = () => {
    if (parent) {
      if ('marketName' in parent) return OrganizationParents.Distributor;
      if ('distributorName' in parent) return OrganizationParents.Customer;
      if ('customerName' in parent) return OrganizationParents.Site;
      return OrganizationParents.Unknown;
    }
    return OrganizationParents.Unknown;
  };

  useEffect(() => {
    getAllCustomers();
    getAllSites();
    getAllInstruments();
    getAllMarkets();
    getAllModels();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [view]);

  useEffect(() => {
    if (!headersState.accesses.organization.customers.view) setParent(customersState[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersState]);

  return (
    <ViewContainer columns={0} rows={3}>
      <TabContainer>
        {headersState.accesses.organization.distributors.view && (
          <ViewButton
            type="button"
            active={view === 'distributors'}
            onClick={() => {
              setView('distributors');
              setParent(undefined);
            }}
          >
            {getTranslation('Distributors')}
          </ViewButton>
        )}
        {headersState.accesses.organization.customers.view && (
          <ViewButton
            type="button"
            active={view === 'customers'}
            onClick={() => {
              setView('customers');
              setParent(undefined);
            }}
          >
            {getTranslation('Customers')}
          </ViewButton>
        )}
        {headersState.accesses.organization.sites.view && (
          <ViewButton
            type="button"
            active={view === 'sites'}
            onClick={() => {
              setView('sites');
              setParent(undefined);
            }}
          >
            {getTranslation('Sites')}
          </ViewButton>
        )}
        {headersState.accesses.organization.instruments.view && (
          <ViewButton
            type="button"
            className="instrumentView"
            active={view === 'instruments'}
            onClick={() => {
              setView('instruments');
              setParent(undefined);
            }}
          >
            {getTranslation('Instruments')}
          </ViewButton>
        )}
      </TabContainer>
      <HeaderWrapper>{parent && <Header3>{parent.name}</Header3>}</HeaderWrapper>
      {headersState.accesses.organization.distributors.view && view === 'distributors' && (
        <DistributorAdminView
          navigateCallback={(distributor: Distributor, viewType: 'customers' | 'sites' | 'instruments') =>
            handleChildViewCallback(distributor, viewType)
          }
        />
      )}
      {headersState.accesses.organization.customers.view && view === 'customers' && (
        <CustomerAdminView
          customerParent={parent && 'marketName' in parent ? parent : undefined}
          navigateCallback={(customer: Customer, viewType: 'sites' | 'instruments') =>
            handleChildViewCallback(customer, viewType)
          }
        />
      )}
      {(headersState.accesses.organization.sites.view || headersState.accesses.instrumentMonitoring) &&
        view === 'sites' && (
          <SiteAdminView
            sitesParent={parent && 'distributorName' in parent ? parent : undefined}
            navigateCallback={(site: Site) => handleChildViewCallback(site, 'instruments')}
          />
        )}
      {headersState.accesses.organization.instruments.view && view === 'instruments' && (
        <InstrumentAdminView
          instrumentParent={{ parent, parentType: returnParentType() }}
          navigateCallback={(newParent: Distributor | Customer, viewType: 'customers' | 'sites') =>
            handleChildViewCallback(newParent, viewType)
          }
        />
      )}
    </ViewContainer>
  );
}

export default AdminView;
