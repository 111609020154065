import {
  ReagentLotDetails,
  ReagentLotReagents,
  ReagentLotWithReagentsResponse,
} from '@dewire/models/definitions/api-response/reagent-lot-with-reagents-response';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import InfiniteScrollList from 'components/lists/InfiniteScrollList';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import FilterModal from 'components/modals/FilterModal';
import NavContainer from 'components/nav/NavContainer';
import ReagentDetailsHeader from 'components/reagent/dashboard/ReagentDetailsHeader';
import FilterGroup from 'components/selection/filter/FilterGroup';
import NavigationLinkButton from 'components/styled-components/buttons/NavigationLinkButton';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getReagentLotWithReagentList } from 'helpers/reagent/getters';
import sortReagentsByStatus from 'helpers/sorting/sort-reagents-by-status';
import getTranslation from 'helpers/translation/get-translation';
import getDate from 'helpers/utils/date-utils';
import { LoadingState, Paths, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import magnifyingGlass from '../../../assets/icons/magnifying-glas.svg';
import ReagentStatus from '../../../views/reagents/ReagentStatus';
import ReagentLotContainerRow from '../../rows/reagent/ReagentLotContainerRow';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;

const tableHeaders = [
  'Container ID',
  'Last known volume',
  'Opening date',
  'Expiry date',
  'Status',
  'Paired instruments',
];

const DownloadIconWrapper = styled.button`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

const getSortStatuses = (urlStatus: string) => {
  switch (urlStatus) {
    case 'emptyexpired':
      return ['Expired', 'Finished'];
    case 'available':
      return ['Available'];
    case 'inuse':
      return ['In use'];
    default:
      return [];
  }
};

const exportCsv = (reagentLotDetails: ReagentLotDetails, reagents: ReagentLotReagents[]) => {
  const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: reagentLotDetails.lotNumber });

  const dataToExport = reagents.map((reagent) => ({
    lotNumber: reagentLotDetails.lotNumber,
    brand: reagentLotDetails.brand,
    lotExpiryDate: reagentLotDetails.expiryDate.toString(),
    type: reagentLotDetails.type,
    reagentId: reagent.id,
    reagentExpiryDate: reagent.expiryDate.toString(),
    openingDate: reagent.openingDate.toString(),
    usedVolume: reagent.usedVolume,
    numberOfPairedInstruments: reagent.numberOfPairedInstruments,
    status: reagent.status,
  }));

  const csv = generateCsv(csvConfig)(dataToExport);
  download(csvConfig)(csv);
  return true;
};

interface ReagentsOverviewFilters {
  statusFilter: string[];
  search: string;
  startDate: Date;
  endDate: Date;
  hasVolume: boolean;
  paired: boolean;
}

function ReagentsOverview() {
  const { lotNumber } = useParams();
  const [filter, setFilter] = useState<ReagentsOverviewFilters>({
    statusFilter: [],
    search: '',
    startDate: getDate(-60),
    endDate: new Date(),
    hasVolume: false,
    paired: false,
  });

  const [loading, setLoading] = useState(true);
  const [reagentLotDetails, setReagentLotDetails] = useState<ReagentLotDetails>({} as ReagentLotDetails);
  const [exportStatus, setExportStatus] = useState(LoadingState.Idle);
  const [reagents, setReagents] = useState<ReagentLotReagents[]>([]);
  const [filteredReagents, setFilteredReagents] = useState<ReagentLotReagents[]>([]);

  const params = new URLSearchParams(window.location.search);
  const status = params.get('status');

  const handleExport = () => {
    setExportStatus(LoadingState.Loading);
    let downloaded = false;
    try {
      if (reagentLotDetails && reagents) downloaded = exportCsv(reagentLotDetails, filteredReagents);
    } catch (error) {
      provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to export CSV');
    } finally {
      setExportStatus(downloaded ? LoadingState.Succeeded : LoadingState.Failed);
    }
  };

  const clearFilters = () => {
    if (filter) setFilter({ ...filter, statusFilter: [] });
  };

  const isFilterActive = () => filter.statusFilter.length > 0;

  const addOrRemoveFilter = (filters: string[], option: string): string[] => {
    if (filters.includes(option)) return filters.filter((element) => element !== option);
    filters.push(option);
    return filters;
  };

  const setStatusFilter = (option: string) => {
    const statusFilter = addOrRemoveFilter(filter?.statusFilter || [], option);
    if (filter) {
      setFilter({ ...filter, statusFilter });
    }
  };

  useEffect(() => {
    if (loading && lotNumber) {
      getReagentLotWithReagentList(
        lotNumber,
        (result: ReagentLotWithReagentsResponse) => {
          setReagentLotDetails(result.reagentLotDetails);
          setFilteredReagents(result.reagents);
          setReagents(result.reagents);
          setLoading(false);
        },
        () => {
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch Lot overview');
          setLoading(false);
        }
      );
    }
  }, [loading, lotNumber]);

  const applySearchFilter = (reagent: ReagentLotReagents) => {
    const { search } = filter;
    if (!search) return true;
    return reagent.id.toString().toLowerCase().includes(search.toLowerCase());
  };

  const applyStatusFilter = (reagentLot: ReagentLotReagents) => {
    if (filter?.statusFilter?.length === 0) return true;
    return filter?.statusFilter?.includes(reagentLot.status);
  };

  const applyVolumeFilter = (reagentLot: ReagentLotReagents) => {
    if (!filter?.hasVolume) return true;
    return reagentLot.usedVolume < reagentLotDetails.volume;
  };

  const applyPairedFilter = (reagentLot: ReagentLotReagents) => {
    if (!filter?.paired) return true;
    return reagentLot.numberOfPairedInstruments > 0;
  };

  const applyFilters = () => {
    const filteredLots = reagents
      .filter(applySearchFilter)
      .filter(applyStatusFilter)
      .filter(applyVolumeFilter)
      .filter(applyPairedFilter);

    setFilteredReagents(filteredLots);
  };

  useMemo(() => {
    if (!loading) applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reagents, filter]);

  const mapReagents = () => {
    if (!filteredReagents) return [];
    const sortedData = sortReagentsByStatus(filteredReagents, getSortStatuses(status ?? ''));
    return sortedData.map((container) => (
      <ReagentLotContainerRow key={container.id} volume={reagentLotDetails.volume} reagentContainer={container} />
    ));
  };

  return (
    <ViewContainer flexdircolumn columns={0} rows={3}>
      <NavContainer>
        <NavigationLinkButton to={Paths.ReagentDashboardUrl}>
          <ArrowBackIcon size="small" />
          <p>Reagent dashboard</p>
        </NavigationLinkButton>
      </NavContainer>
      {!loading && <ReagentDetailsHeader lot={reagentLotDetails} />}
      <ToolbarContainer>
        <SearchBarContainer>
          <SearchField
            type="text"
            placeholder="Search"
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            iconUrl={magnifyingGlass}
          />
        </SearchBarContainer>

        <FilterModal isFiltering={isFilterActive()} clearCallback={() => clearFilters()}>
          <>
            <FilterGroup
              groupTitle="Status"
              groupWidth={11}
              filterOptions={Object.values(ReagentStatus)}
              filterArray={filter?.statusFilter || []}
              onFilterCallback={(filterValue: string) => setStatusFilter(filterValue)}
            />
            <FilterGroup
              groupTitle="Volume"
              groupWidth={11}
              filterOptions={['Has volume']}
              filterArray={filter?.hasVolume ? ['Has volume'] : []}
              onFilterCallback={() => setFilter({ ...filter, hasVolume: !filter?.hasVolume })}
            />
            <FilterGroup
              groupTitle="Pairing"
              groupWidth={11}
              filterOptions={['Has been paired']}
              filterArray={filter?.paired ? ['Has been paired'] : []}
              onFilterCallback={() => setFilter({ ...filter, paired: !filter?.paired })}
            />
          </>
        </FilterModal>
        {exportStatus !== LoadingState.Loading ? (
          <DownloadIconWrapper onClick={() => handleExport()} title="Download list as CSV">
            <DownloadIcon />
          </DownloadIconWrapper>
        ) : (
          <LoadingSpinner containerBased inButton />
        )}
      </ToolbarContainer>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="15% 19% 17% 15% 17% 14%" textAlign="start">
            {tableHeaders.map((tableHeader: string) => (
              <p key={tableHeader}>{getTranslation(tableHeader)}</p>
            ))}
          </TableHeader>
          {loading ? <LoadingSpinner containerBased /> : <InfiniteScrollList items={mapReagents()} />}
        </Table>
      </HistoryTable>
    </ViewContainer>
  );
}

export default ReagentsOverview;
