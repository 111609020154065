import { LoadingState } from 'interfaces/common';
import UsersState from 'interfaces/users-state';

// State as 'any' due to Typescript not finding WritableDraft<object> nor Draft<object> in the project

export const usersLoadingCase = (
  state: any,
  sectionName: keyof UsersState,
  loadingState: LoadingState,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  [sectionName]: {
    ...state[sectionName],
    loadingStatus: loadingState,
    error: errorMessage,
  },
});

export default usersLoadingCase;
