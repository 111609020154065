import { bpTheme } from 'helpers/window/use-current-breakpoint';
import styled from 'styled-components';

const Header3 = styled.h3`
  font-weight: bold;
  font-size: large;
  text-decoration: none;
  .hover {
    text-decoration: none;
  }
  .visited {
    text-decoration: none;
  }
  .focused {
    text-decoration: none;
  }
  .active {
    text-decoration: none;
  }

  ${() => bpTheme.breakpoints.down('md')} {
    font-size: medium;
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    font-size: small;
  }
  ${() => bpTheme.breakpoints.down('xs')} {
    font-size: x-small;
  }
`;

export default Header3;
