import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const TableRow = styled.div<{ gridExpression: string; selected?: boolean; alignItems?: string }>`
  display: grid;
  ${({ gridExpression }) => ({ 'grid-template-columns': gridExpression })};
  ${({ alignItems }) => ({ 'align-items': alignItems || 'center' })};
  font-size: smaller;
  border-bottom: ${({ selected }) => (selected ? useTheme().breakLine.hard : useTheme().breakLine.soft)};
  min-height: 3em;
  padding: 1em;
  background-color: ${({ selected }) => (selected ? useTheme().background.light : useTheme().font.color.white)};
`;

export default TableRow;
