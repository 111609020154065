import styled from 'styled-components';

const RefreshContainer = styled.div`
  display: flex;
  cursor: pointer;
  transition-duration: 500ms;
  :hover {
    transform: rotate(110deg);
    transition-duration: 500ms;
  }
`;

export default RefreshContainer;
