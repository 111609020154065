import { useTheme } from 'app/AppStyling';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import CardContainer from 'components/styled-components/containers/CardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import styled from 'styled-components';

const CardBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em 0.5em 0em 0em;
`;
const IconContainer = styled.div`
  position: relative;
  top: 1em;
  left: -2em;
`;
const AlertTitle = styled(TitleWrapper)`
  font-weight: bold;
  margin-top: 8em;
`;

function ConnectReagentModal() {
  const theme = useTheme();

  return (
    <DimmedContainer>
      <CardContainer height={10} width={20}>
        <IconContainer>
          <LoadingSpinner />
        </IconContainer>
        <CardBanner>
          <AlertTitle color={theme.colors.blue}>Loading...</AlertTitle>
        </CardBanner>
      </CardContainer>
    </DimmedContainer>
  );
}

export default ConnectReagentModal;
