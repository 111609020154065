import { DefaultTheme } from 'app/AppStyling';
import { IconSize } from 'interfaces/icon-size';
import styled from 'styled-components';

const StyledSvg = styled.svg<{ size: IconSize | undefined }>`
  ${({ size }) => {
    if (size === 'x-small') return { width: '0.5em', height: '0.5em' };
    if (size === 'small') return { width: '0.7em', height: '0.7em' };
    if (size === 'large') return { width: '1.5em', height: '1.5em' };
    if (size === 'x-large') return { width: '2em', height: '2em' };
    return { width: '1em', height: '1em' };
  }};
`;

export type Direction = 'right' | 'left' | 'up' | 'down';

interface ArrowIconProps {
  size?: IconSize;
  direction?: Direction;
  arrowColor?: string;
}

const getDirection = (direction?: Direction) => {
  switch (direction) {
    case 'left':
      return 180;
    case 'up':
      return 90;
    case 'down':
      return 270;
    default:
      return 0;
  }
};

function ArrowIcon({ size = 'medium', direction = 'up', arrowColor = DefaultTheme.colors.blue }: ArrowIconProps) {
  const rotation = getDirection(direction);
  return (
    <StyledSvg
      size={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto', transform: `rotate(${rotation}deg)` }}
    >
      <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill={arrowColor} />
    </StyledSvg>
  );
}

export default ArrowIcon;
