import styled from 'styled-components';

const QrCodeWrapper = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
`;

export default QrCodeWrapper;
