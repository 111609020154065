import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const SecondaryButton = styled.button<{
  disabled?: boolean;
  rounded?: boolean;
  borderColor?: string;
  width?: number;
  height?: number;
}>`
  display: inline-block;
  background-color: ${() => useTheme().font.color.white};
  color: ${() => useTheme().colors.blue};
  width: 11em;
  height: 3em;
  font-weight: bold;
  line-height: 3em;
  margin: 1em;
  border-radius: ${({ rounded }) => (rounded ? '2em' : '0.5em')};
  cursor: pointer;
  border: 0.1px solid ${({ borderColor }) => borderColor ?? useTheme().colors.primary};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  width: ${({ width }) => (width ? `${width}em` : '')};
  height: ${({ height }) => (height ? `${height}em` : '')};
  opacity: ${({ disabled }) => (disabled ? '0.65' : '1')};
  filter: ${({ disabled }) => (disabled ? 'alpha(opacity=65)' : 'none')};
  :hover {
    border-color: ${() => useTheme().colors.blue};
  }
`;

export default SecondaryButton;
