import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: 10% 10% 5% 10% 5% 5% 45% 5% 5%;
  padding: 1em 2em;
  text-align: center;
  p:first-child {
    text-align: left;
  }
`;
