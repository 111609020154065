import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

export const InfoTableRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InfoTableItem = styled.div<{ width?: string }>`
  // property has poor usage but compiler provides nice fallbacks for many/all? browsers
  width: stretch;
  border-right: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  margin: 1em;
  :last-child {
    border: none;
  }
  &:first-of-type {
    margin-left: 0;
  }
`;
export const InfoTableItemSlim = styled(InfoTableItem)`
  margin: 0;
`;
export const Divider = styled.div`
  width: 100%;
  margin: 0.5em 0;
  border-bottom: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
`;
export const InfoTableContainer = styled.div`
  padding: 1em;
  background: ${() => useTheme().font.color.white};
  width: 100%;
  display: flex;
  flex-direction: column;
`;
