import { useTheme } from 'app/AppStyling';
import HamburgerMenuIcon from 'assets/icons/HamburgerMenuIcon';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import { Divider } from 'components/styled-components/table/info-table-style';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import getMenuItems from 'helpers/menu/get-menu-items';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  padding: 2em;
  width: 97%;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  gap: 1em;
  left: 1em;
  top: 1em;
  bottom: 1em;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
`;
const IconContainer = styled.div`
  display: flex;
  gap: 0.5em;
  width: fit-content;
  border-bottom: 1px solid transparent;
  align-items: center;
  text-align: center;
  margin-right: 3em;
  :hover {
    cursor: pointer;
  }
  ${() => bpTheme.breakpoints.down('xs')} {
    margin-right: 1em;
  }
`;
const IconWrapper = styled.div`
  display: flex;
`;
const MenuTitleText = styled(TextWrapper)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
`;
const MenuTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 0;
`;
const MenuIconWrapper = styled.div`
  display: flex;
  padding: 0.7em;
  background: ${() => useTheme().colors.lightblue};
  border-radius: 4px;
`;
const CompressedMenuLinkItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  gap: 1em;
  font-size: large;
  color: ${() => useTheme().colors.primary};
  :hover {
    background: ${() => useTheme().background.light};
  }
`;

function MenuModal() {
  const theme = useTheme();
  const location = useLocation();
  const dropdownRef = useRef<any>(null);
  const headersState = useAppSelector((state) => state.headers);

  const [menuHovered, setMenuHovered] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setDisplayModal(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setDisplayModal(false);
  }, [location.pathname]);

  const menuItems = getMenuItems(headersState.accesses);

  return (
    <Container ref={dropdownRef}>
      <IconContainer
        onClick={() => setDisplayModal((toggled) => !toggled)}
        onMouseEnter={() => setMenuHovered(true)}
        onMouseLeave={() => setMenuHovered(false)}
      >
        <IconWrapper>
          <HamburgerMenuIcon color={menuHovered ? theme.colors.blue : theme.font.color.white} />
        </IconWrapper>
        <MenuTitleText textColor={menuHovered ? theme.colors.blue : theme.font.color.white} bold>
          Menu
        </MenuTitleText>
      </IconContainer>
      {displayModal && (
        <ModalContainer>
          <MenuTitleWrapper>
            Menu
            <CloseButton closeCallback={() => setDisplayModal(false)} />
          </MenuTitleWrapper>
          <Divider />
          {menuItems.map((item) => (
            <>
              <CompressedMenuLinkItem className={item.className} to={item.url}>
                <MenuIconWrapper>
                  <item.icon />
                </MenuIconWrapper>
                {item.displayName}
              </CompressedMenuLinkItem>
              <Divider />
            </>
          ))}
        </ModalContainer>
      )}
    </Container>
  );
}

export default MenuModal;
