import { AddUserInput } from '@dewire/models/definitions/api-response/user-admin/add-user-input';
import { Origin } from '@dewire/models/definitions/api-response/user-admin/origin';
import { FormHelperText } from '@mui/material';
import { DefaultTheme, useTheme } from 'app/AppStyling';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import BackNav from 'components/nav/BackNav';
import TextInputField from 'components/selection/input/TextInputField';
import OriginSelection from 'components/selection/user/OriginSelection';
import RoleSelection from 'components/selection/user/RoleSelection';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import getTranslation from 'helpers/translation/get-translation';
import addNewUser from 'helpers/users/add';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status, TextColor } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const InputGroupContainer = styled.div`
  background: ${() => useTheme().font.color.white};
  display: grid;
  justify-content: start;
  width: 100%;
  padding: 3em 3em;
  gap: 2em 0;
`;

const InputGroup = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;
`;

const OriginWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5em;
`;

const FlexChild = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;

const initCurrentRole = { label: '', id: -1 };

function AddUserView() {
  const [firstChange, setFirstChange] = useState(true);
  const [error, setError] = useState(true);
  const [adminState] = useAppSelector((state) => [
    state.admin,
    state.admin.distributors.content,
    state.admin.customers.content,
  ]);

  const [addUserActive, setAddUserActive] = useState(false);
  const [userInstance, setUserInstance] = useState<Partial<AddUserInput>>({});
  const [currentRole, setCurrentRole] = useState<FieldInput<number>>(initCurrentRole);

  const onAddUser = async () => {
    const user = userInstance as AddUserInput;
    setAddUserActive(true);
    await addNewUser(
      user,
      () => {
        provideSnackbar(
          Status.Success,
          lodash.capitalize(Status.Success),
          `User "${user.username}" was successfully added.`
        );
        setUserInstance({});
      },
      (errorResponse) => {
        let message = 'Unable to add user.';
        if (errorResponse instanceof Error) message = errorResponse.message;
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), message);
      }
    );
    setAddUserActive(false);
  };

  useEffect(() => {
    setAddUserActive(adminState.customers.updateStatus === LoadingState.Loading);
  }, [adminState.customers.updateStatus]);

  useEffect(() => {
    if (
      lodash.isEqual(userInstance, {}) ||
      Object.values(userInstance).every((property) => property === '' || property === undefined)
    ) {
      setFirstChange(true);
    } else {
      setFirstChange(false);
    }
    if (
      !validateInput({ value: userInstance.username, params: ['required'] }).error &&
      !validateInput({ value: userInstance.tempPassword?.toString(), params: ['required'] }).error &&
      !validateInput({ value: userInstance.firstName, params: ['required'] }).error &&
      !validateInput({ value: userInstance.lastName, params: ['required'] }).error &&
      !validateInput({ value: userInstance.email, params: ['email', 'required'] }).error &&
      !validateInput({ value: userInstance.phone, params: ['phone'] }).error &&
      !validateInput({ value: userInstance.permissions?.accessTypeId.toString(), params: ['requiredId'] }).error &&
      userInstance.permissions?.accessTypeId !== -1 &&
      userInstance.permissions?.units.length
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [userInstance]);

  const setOrigins = (origins: Origin[]) => {
    const unitInputs = origins.map((origin) => ({ unitId: origin.id, origin: origin.type }));

    setUserInstance({
      ...userInstance,
      permissions: {
        accessTypeId: userInstance.permissions?.accessTypeId ?? -1,
        userId: -1,
        units: unitInputs,
      },
    });
  };

  const setRole = (roleId: string) => {
    setUserInstance({
      ...userInstance,
      permissions: {
        accessTypeId: Number(roleId),
        userId: -1,
        units: userInstance.permissions?.units ?? [],
      },
    });
  };

  return (
    <FlexContainer>
      <ViewContainer flexdircolumn>
        <BackNav
          navigate={() => {
            window.location.href = '/users';
          }}
          text="Users"
        />
        <InputGroupContainer>
          <div>
            <TitleWrapper bold color={TextColor.Primary} size="large">
              User information
            </TitleWrapper>
            <InputGroup>
              <FlexChild>
                <TextInputField
                  label="Username *"
                  margin="0"
                  filled
                  alignItems="start"
                  value={userInstance.username ?? ''}
                  onChangeCallback={(username: string) => setUserInstance({ ...userInstance, username })}
                  error={!firstChange && validateInput({ value: userInstance.username, params: ['required'] }).error}
                  helper={validateInput({ value: userInstance.username, params: ['required'] }).errorMessage}
                />
                <TextInputField
                  label="Password *"
                  margin="0"
                  filled
                  alignItems="start"
                  value={userInstance.tempPassword ?? ''}
                  onChangeCallback={(tempPassword: string) => setUserInstance({ ...userInstance, tempPassword })}
                  error={
                    !firstChange && validateInput({ value: userInstance.tempPassword, params: ['required'] }).error
                  }
                  helper="Please enter a password"
                />
              </FlexChild>
              <FlexChild>
                <TextInputField
                  label="First name *"
                  margin="0"
                  alignItems="start"
                  filled
                  value={userInstance.firstName ?? ''}
                  onChangeCallback={(firstName: string) => setUserInstance({ ...userInstance, firstName })}
                  helper="Please enter a first name"
                  error={!firstChange && validateInput({ value: userInstance.firstName, params: ['required'] }).error}
                />
                <TextInputField
                  label="Last name *"
                  margin="0"
                  filled
                  alignItems="start"
                  value={userInstance.lastName ?? ''}
                  onChangeCallback={(lastName: string) => setUserInstance({ ...userInstance, lastName })}
                  helper="Please enter a last name"
                  error={!firstChange && validateInput({ value: userInstance.lastName, params: ['required'] }).error}
                />
              </FlexChild>
              <FlexChild>
                <TextInputField
                  label="Phone number (+XX)"
                  margin="0"
                  filled
                  alignItems="start"
                  value={userInstance.phone ?? ''}
                  onChangeCallback={(phone: string) => setUserInstance({ ...userInstance, phone })}
                  helper="Please enter phone number starting with +"
                  error={
                    !firstChange &&
                    !!userInstance.phone &&
                    userInstance.phone.length > 0 &&
                    validateInput({ value: userInstance.phone, params: ['phone'] }).error
                  }
                />
                <TextInputField
                  label="Email *"
                  margin="0"
                  filled
                  alignItems="start"
                  type="email"
                  value={userInstance.email ?? ''}
                  onChangeCallback={(email: string) => setUserInstance({ ...userInstance, email })}
                  helper="Please enter an email"
                  error={
                    !firstChange && validateInput({ value: userInstance.email, params: ['required', 'email'] }).error
                  }
                />
              </FlexChild>
            </InputGroup>
          </div>
          <div>
            <TitleWrapper bold color={TextColor.Primary} size="large" margin="0 0 1em 0">
              Role selection
            </TitleWrapper>
            <InputGroup>
              <FlexChild>
                <RoleSelection
                  onChangeCallback={(roleId) => setRole(roleId)}
                  currentRole={currentRole}
                  setCurrentRole={setCurrentRole}
                  error={
                    !firstChange &&
                    !!userInstance.permissions?.accessTypeId &&
                    validateInput({ value: userInstance.permissions.accessTypeId.toString(), params: ['requiredId'] })
                      .error
                  }
                  helper="Please select a role"
                />
              </FlexChild>
              <FormHelperText />
            </InputGroup>
          </div>
          <div>
            <TitleWrapper bold color={TextColor.Primary} size="large">
              Access selection
            </TitleWrapper>
            <OriginWrapper>
              <OriginSelection onSaveCallback={(origins: Origin[]) => setOrigins(origins)} required />
              {!firstChange && !userInstance.permissions?.units.length && (
                <FormHelperText style={{ color: DefaultTheme.colors.error.light, margin: '0 1em' }}>
                  Please select at least one access
                </FormHelperText>
              )}
            </OriginWrapper>
          </div>
          <ButtonContainer>
            <span>* Required fields</span>
            <PrimaryButton disabled={error || addUserActive} onClick={() => onAddUser()}>
              {addUserActive ? <LoadingSpinner containerBased inButton /> : getTranslation('Add')}
            </PrimaryButton>
          </ButtonContainer>
        </InputGroupContainer>
      </ViewContainer>
    </FlexContainer>
  );
}

export default AddUserView;
