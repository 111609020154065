import styled from 'styled-components';

const ToolbarContainer = styled.div<{ hasFiltering?: boolean }>`
  display: flex;
  align-items: center;
  background-color: transparent;
  margin-left: ${({ hasFiltering = true }) => (hasFiltering ? '0em' : '1em')};
`;

export default ToolbarContainer;
