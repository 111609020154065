import TextField from '@mui/material/TextField';
import { useTheme } from 'app/AppStyling';
import useTextFieldStyles from 'components/styled-components/mui-styling/TextFieldStyling';
import { useState } from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div<{ height?: number; alignItems: string }>`
  height: ${({ height }) => (height ? `${height}px` : '90px')};
  display: flex;
  align-items: center;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  justify-content: center;
`;

interface TextInputFieldProps {
  label: string;
  filled: boolean;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  helper?: string;
  value: string;
  width?: number;
  margin?: string;
  alignItems?: string;
  disabled?: boolean;
  containerHeight?: number;
  onChangeCallback: (textInput: string) => void;
  type?: 'text' | 'email';
}

function TextInputField({
  label,
  filled,
  multiline = false,
  rows = 1,
  error = false,
  value,
  helper = '',
  width = 18,
  margin = '1em',
  alignItems = 'center',
  disabled = false,
  containerHeight = 90,
  type = 'text',
  onChangeCallback,
}: TextInputFieldProps) {
  const classes = useTextFieldStyles({ background: useTheme().background.light });
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <OuterContainer height={containerHeight} alignItems={alignItems}>
      <TextField
        disabled={disabled}
        type={type}
        id="textfield-basic"
        style={{ margin: `${margin}`, width: `${width}em` }}
        label={label}
        error={error && !focused}
        variant={filled ? 'filled' : undefined}
        multiline={multiline}
        rows={rows}
        value={value}
        helperText={error && !focused ? helper : ''}
        onChange={(e) => onChangeCallback(e.target.value)}
        InputProps={{
          className: error && !focused ? classes.textFieldError : classes.textField,
        }}
        InputLabelProps={{ className: classes.textFieldLabel }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </OuterContainer>
  );
}

export default TextInputField;
