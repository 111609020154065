import { FormControlLabel } from '@mui/material';
import { CheckboxInner, CheckboxOuter } from 'components/styled-components/selection/Checkbox';

interface CheckboxInputProps {
  text?: string;
  checked: boolean;
  rounded?: boolean;
}

function CheckboxInput({ text = '', checked, rounded = false }: CheckboxInputProps) {
  return (
    <FormControlLabel
      label={text}
      control={
        <CheckboxOuter rounded={rounded ?? false}>
          <CheckboxInner checked={checked} rounded={rounded ?? false} />
        </CheckboxOuter>
      }
    />
  );
}

export default CheckboxInput;
