function ReagentScannerIcon() {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3.44444V10.3333H3.55556V3.44444H10.6667V0H3.55556C1.6 0 0 1.55 0 3.44444ZM3.55556 20.6667H0V27.5556C0 29.45 1.6 31 3.55556 31H10.6667V27.5556H3.55556V20.6667ZM28.4444 27.5556H21.3333V31H28.4444C30.4 31 32 29.45 32 27.5556V20.6667H28.4444V27.5556ZM28.4444 0H21.3333V3.44444H28.4444V10.3333H32V3.44444C32 1.55 30.4 0 28.4444 0Z"
        fill="#3E5D74"
      />
    </svg>
  );
}

export default ReagentScannerIcon;
