import { FileRejection } from 'react-dropzone';

export interface UploadErrorStatus {
  uploadErrorHeader: string;
  uploadErrorMessage: string;
  hasError: boolean;
}

export const noUploadErrorStatus: UploadErrorStatus = {
  uploadErrorHeader: '',
  uploadErrorMessage: '',
  hasError: false,
};

function GetErrorMessage(rejections: FileRejection[]): UploadErrorStatus {
  const errorCodes = rejections.flatMap((r) => r.errors).flatMap((e) => e.code);
  if (errorCodes.includes('file-invalid-type'))
    return {
      uploadErrorHeader: 'Invalid file format',
      uploadErrorMessage: 'Following file formats are allowed: txt, xml for QC and xlsx, xls for assay data',
      hasError: true,
    };
  if (errorCodes.includes('too-many-files'))
    return {
      uploadErrorHeader: 'Too many files selected',
      uploadErrorMessage: 'Maximum 10 files',
      hasError: true,
    };
  if (errorCodes.includes('file-too-large'))
    return {
      uploadErrorHeader: 'File size limit',
      uploadErrorMessage: 'A selected file is too large',
      hasError: true,
    };
  if (errorCodes.length > 0)
    return {
      uploadErrorHeader: 'Unknown error',
      uploadErrorMessage: 'Contact your sys admin',
      hasError: true,
    };
  return noUploadErrorStatus;
}

export default GetErrorMessage;
