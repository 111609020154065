import TableRow from 'components/styled-components/table/TableRow';
import OriginUnit from 'interfaces/origin-unit';
import styled from 'styled-components';

const RowContainer = styled.div`
  border-bottom: 'none';
`;
const RowItem = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface OriginTableRowProps {
  unit: OriginUnit;
}

function OriginTableRow({ unit }: OriginTableRowProps) {
  const { name, id, primaryInfo, secondaryInfo } = unit;
  return (
    <RowContainer key={id}>
      <TableRow gridExpression="35% 35% 30%">
        <RowItem>{name}</RowItem>
        <RowItem>{primaryInfo}</RowItem>
        <RowItem>{secondaryInfo}</RowItem>
      </TableRow>
    </RowContainer>
  );
}

export default OriginTableRow;
