import Session from '@dewire/models/definitions/api-response/session';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFileUploadUrl as getFileUploadUrlFromApi, getUploadedFiles as getUploadedFilesFromApi } from 'api/Api';
import { LoadingState } from 'interfaces/common';
import FilesState from 'interfaces/files-state';
import filesLoadingCase from 'redux/resolvers/files/general-cases';

const initialState: FilesState = {
  upload: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  history: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const getUploadUrl = createAsyncThunk(
  'files/getUploadUrl',
  async (payload: { fileName: string; isAssay: boolean; session: Session }) =>
    getFileUploadUrlFromApi(payload.fileName, payload.isAssay, payload.session)
);

export const getFileHistory = createAsyncThunk('files/getFileHistory', async (session: Session) =>
  getUploadedFilesFromApi(session)
);

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // File history
      .addCase(getFileHistory.pending, (state) => filesLoadingCase(state, 'history', LoadingState.Loading))
      .addCase(getFileHistory.fulfilled, (state) => filesLoadingCase(state, 'history', LoadingState.Succeeded))
      .addCase(getFileHistory.rejected, (state, action) =>
        filesLoadingCase(state, 'history', LoadingState.Failed, action.error.message)
      )

      // File upload
      .addCase(getUploadUrl.pending, (state) => filesLoadingCase(state, 'upload', LoadingState.Loading))
      .addCase(getUploadUrl.fulfilled, (state) => filesLoadingCase(state, 'upload', LoadingState.Succeeded))
      .addCase(getUploadUrl.rejected, (state, action) =>
        filesLoadingCase(state, 'upload', LoadingState.Failed, action.error.message)
      );
  },
});

export default filesSlice.reducer;
