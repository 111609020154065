import { EqaMeasurementDetails } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import EQAReportRow from 'components/rows/eqa/EQAReportRow';
import KeyHeader from 'components/styled-components/headers/KeyHeader';
import MeasurementItem from 'components/styled-components/items/MeasurementItem';
import { EQAFilterOptions } from 'helpers/eqa/usage';
import sortLevelsBasedOnOrder from 'helpers/sorting/sort-data-by-level';
import styled from 'styled-components';

const MeasurementCard = styled.div`
  border-bottom: 1px solid gray;
  margin-bottom: 2px;
`;

interface ReportMeasurementCardProps {
  reportMeasurement: EqaMeasurementDetails;
  eQAFilters: EQAFilterOptions;
  serialNumber: string;
  year: number;
  month: number;
}

function ReportMeasurementCard({
  reportMeasurement,
  eQAFilters,
  serialNumber,
  year,
  month,
}: ReportMeasurementCardProps) {
  return (
    <MeasurementCard>
      <KeyHeader>
        <MeasurementItem key={reportMeasurement.key} margin={1.5}>
          {reportMeasurement.key}
        </MeasurementItem>
      </KeyHeader>
      {reportMeasurement.measureData &&
        sortLevelsBasedOnOrder(reportMeasurement.measureData).map((data) => {
          const uniqueKey = `${data.level} ${reportMeasurement.key}`;
          if (data.yourN) {
            return (
              eQAFilters &&
              (!eQAFilters.levels.length || eQAFilters.levels.includes(data.level)) && (
                <EQAReportRow
                  key={uniqueKey}
                  reportData={data}
                  filters={eQAFilters}
                  reportSerialNumber={serialNumber}
                  measurementKey={reportMeasurement.key}
                  year={year}
                  month={month}
                />
              )
            );
          }
          return <div />;
        })}
    </MeasurementCard>
  );
}

export default ReportMeasurementCard;
