export const BACKEND_API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL || '';
export const MAPBOX_USERNAME = process.env.REACT_APP_MAPBOX_USERNAME || '';
export const MAPBOX_STYLE_ID = process.env.REACT_APP_MAPBOX_STYLE_ID || '';
export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || '';
export const LOGIN_HOSTED_UI_URL = process.env.REACT_APP_LOGIN_HOSTED_UI_URL || '';
export const LOGOUT_HOSTED_UI_URL = process.env.REACT_APP_LOGOUT_HOSTED_UI_URL || '';
export const KEYCLOAK_ROOT_URL = process.env.REACT_APP_KEYCLOAK_ROOT_URL || '';
export const VERSION_LABEL = process.env.REACT_APP_VERSION_LABEL || '';

export const DASHBOARD_BACKEND_API_BASE_URL =
  process.env.REACT_APP_DASHBOARD_BACKEND_API_BASE_URL ||
  `${process.env.REACT_APP_BACKEND_API_BASE_URL}/dashboard` ||
  '';

export const FACTORY_BACKEND_API_BASE_URL =
  process.env.REACT_APP_FACTORY_BACKEND_API_BASE_URL || `${process.env.REACT_APP_BACKEND_API_BASE_URL}/factory` || '';
