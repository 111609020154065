import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

import endArrow from '../../assets/icons/pageEndArrow.svg';
import nextArrow from '../../assets/icons/pageNextArrow.svg';
import prevArrow from '../../assets/icons/pagePrevArrow.svg';
import startArrow from '../../assets/icons/pageStartArrow.svg';

const ArrowContainer = styled.div`
  display: grid;
  grid-template-columns: 2.5em 3.5em 4em 2.5em 2em;
  justify-content: center;
  margin: 1em;
`;
const Arrow = styled.div<{ img: string; disabled: boolean }>`
  background-image: url(${(img) => img.img});
  background-repeat: no-repeat, no-repeat;
  height: 2.2em;
  width: 2.2em;
  margin: 0 1em;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  border: 1px solid transparent;
  border-radius: 20px;
  :hover {
    border: 1px solid ${() => useTheme().background.dark};
  }
`;
const PageNumberContainer = styled.div`
  display: flex;
  margin: auto;
  align-items: baseline;
  color: ${() => useTheme().font.color.secondary};
`;

interface PaginatorProps {
  currentPage: number;
  maxPages: number;
  paginationCallback: (page: number) => void;
}

function Paginator({ currentPage, maxPages, paginationCallback }: PaginatorProps) {
  return (
    <div>
      <ArrowContainer>
        <Arrow img={startArrow} disabled={currentPage === 1} onClick={() => paginationCallback(1)} />
        <Arrow
          className="prevPage"
          img={prevArrow}
          disabled={currentPage === 1}
          onClick={() => paginationCallback(currentPage - 1)}
        />
        <PageNumberContainer>
          <p className="currentPage">{currentPage}/</p>
          <p className="maxPages">{maxPages}</p>
        </PageNumberContainer>
        <Arrow
          img={nextArrow}
          className="nextPage"
          disabled={currentPage === maxPages}
          onClick={() => paginationCallback(currentPage + 1)}
        />
        <Arrow img={endArrow} disabled={currentPage === maxPages} onClick={() => paginationCallback(maxPages)} />
      </ArrowContainer>
    </div>
  );
}

export default Paginator;
