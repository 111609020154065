import { CustomerDeleteResponse } from '@dewire/models/definitions/api-response/customer-delete-respons';
import { InstrumentDeleteResponse } from '@dewire/models/definitions/api-response/instrument-delete-respons';
import Session from '@dewire/models/definitions/api-response/session';
import { SiteDeleteResponse } from '@dewire/models/definitions/api-response/site-delete-respons';
import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addCustomer as addCustomerWithApi,
  addDistributor as addDistributorWithApi,
  addInstrument as addInstrumentWithApi,
  addSite as addSiteWithApi,
  disableCustomer as disableCustomerWithApi,
  disableDistributor as disableDistributorWithApi,
  disableInstrument as disableInstrumentWithApi,
  disableSite as disableSiteWithApi,
  getCustomers as getCustomersFromApi,
  getDistributors as getDistributorsFromApi,
  getInstrumentProductModels as getInstrumentProductModelsFromApi,
  getInstruments as getInstrumentsFromApi,
  getMarkets as getMarketsFromApi,
  getSites as getSitesFromApi,
  manageCustomer as manageCustomerWithApi,
  manageDistributor as manageDistributorWithApi,
  manageInstrument as manageInstrumentWithApi,
  manageSite as manageSiteWithApi,
} from 'api/Api';
import { AdminState } from 'interfaces/admin-state';
import { LoadingState } from 'interfaces/common';
import { successfulCustomerAddCase, successfulCustomerDeleteCase } from 'redux/resolvers/admin/customer-cases';
import { successfulDistributorAddCase, successfulDistributorDeleteCase } from 'redux/resolvers/admin/distributor-cases';
import { adminLoadingCase, adminUpdateCase } from 'redux/resolvers/admin/general-cases';
import { successfulInstrumentAddCase, successfulInstrumentDeleteCase } from 'redux/resolvers/admin/instrument-cases';
import { successfulSiteAddCase, successfulSiteDeleteCase } from 'redux/resolvers/admin/site-cases';

const initialState: AdminState = {
  markets: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  models: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  distributors: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  customers: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  sites: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  instruments: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const getMarkets = createAsyncThunk('admin/fetchMarkets', async (session: Session) =>
  getMarketsFromApi(session)
);

export const getInstrumentProductModels = createAsyncThunk('admin/fetchModels', async (session: Session) =>
  getInstrumentProductModelsFromApi(session)
);

export const getDistributors = createAsyncThunk('admin/fetchDistributors', async (session: Session) =>
  getDistributorsFromApi(session)
);

export const getCustomers = createAsyncThunk('admin/fetchCustomers', async (session: Session) =>
  getCustomersFromApi(session)
);

export const getSites = createAsyncThunk('admin/fetchSites', async (session: Session) => getSitesFromApi(session));

export const getInstruments = createAsyncThunk('admin/fetchInstruments', async (session: Session) =>
  getInstrumentsFromApi(session)
);

export const addDistributor = createAsyncThunk(
  'admin/addDistributor',
  async (payload: { session: Session; distributor: DistributorFormInput }) =>
    addDistributorWithApi(payload.session, payload.distributor)
);

export const addCustomer = createAsyncThunk(
  'admin/addCustomer',
  async (payload: { session: Session; customer: CustomerFormInput }) =>
    addCustomerWithApi(payload.session, payload.customer)
);

export const addSite = createAsyncThunk('admin/addSite', async (payload: { session: Session; site: SiteFormInput }) =>
  addSiteWithApi(payload.session, payload.site)
);

export const addInstrument = createAsyncThunk(
  'admin/addInstrument',
  async (payload: { session: Session; instrument: InstrumentFormInput }) =>
    addInstrumentWithApi(payload.session, payload.instrument)
);

export const manageDistributor = createAsyncThunk(
  'admin/manageDistributor',
  async (payload: { session: Session; distributor: DistributorFormInput }) =>
    manageDistributorWithApi(payload.session, payload.distributor)
);

export const manageCustomer = createAsyncThunk(
  'admin/manageCustomer',
  async (payload: { session: Session; customer: CustomerFormInput }) =>
    manageCustomerWithApi(payload.session, payload.customer)
);

export const manageSite = createAsyncThunk(
  'admin/manageSite',
  async (payload: { session: Session; site: SiteFormInput }) => manageSiteWithApi(payload.session, payload.site)
);

export const manageInstrument = createAsyncThunk(
  'admin/manageInstrument',
  async (payload: { session: Session; instrument: InstrumentFormInput }) =>
    manageInstrumentWithApi(payload.session, payload.instrument)
);

export const disableDistributor = createAsyncThunk(
  'admin/disableDistributor',
  async (payload: { session: Session; distributor: number }) =>
    disableDistributorWithApi(payload.session, payload.distributor)
);

export const disableCustomer = createAsyncThunk(
  'admin/disableCustomer',
  async (payload: { session: Session; customerInfo: CustomerDeleteResponse }) =>
    disableCustomerWithApi(payload.session, payload.customerInfo.customerId)
);

export const disableSite = createAsyncThunk(
  'admin/disableSite',
  async (payload: { session: Session; siteInfo: SiteDeleteResponse }) =>
    disableSiteWithApi(payload.session, payload.siteInfo.siteId)
);

export const disableInstrument = createAsyncThunk(
  'admin/disableInstrument',
  async (payload: { session: Session; instrumentInfo: InstrumentDeleteResponse }) =>
    disableInstrumentWithApi(payload.session, payload.instrumentInfo.instrumentSerial)
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get markets
      .addCase(getMarkets.pending, (state) => adminLoadingCase(state, 'markets', LoadingState.Loading))
      .addCase(getMarkets.fulfilled, (state, action) =>
        adminLoadingCase(state, 'markets', LoadingState.Succeeded, action)
      )
      .addCase(getMarkets.rejected, (state, action) =>
        adminLoadingCase(state, 'markets', LoadingState.Failed, undefined, action.error.message)
      )

      // Get instrumentProductModels
      .addCase(getInstrumentProductModels.pending, (state) => adminLoadingCase(state, 'models', LoadingState.Loading))
      .addCase(getInstrumentProductModels.fulfilled, (state, action) =>
        adminLoadingCase(state, 'models', LoadingState.Succeeded, action)
      )
      .addCase(getInstrumentProductModels.rejected, (state, action) =>
        adminLoadingCase(state, 'models', LoadingState.Failed, undefined, action.error.message)
      )

      // Get distributors
      .addCase(getDistributors.pending, (state) => adminLoadingCase(state, 'distributors', LoadingState.Loading))
      .addCase(getDistributors.fulfilled, (state, action) =>
        adminLoadingCase(state, 'distributors', LoadingState.Succeeded, action)
      )
      .addCase(getDistributors.rejected, (state, action) =>
        adminLoadingCase(state, 'distributors', LoadingState.Failed, undefined, action.error.message)
      )

      // Add distributor
      .addCase(addDistributor.pending, (state) => adminUpdateCase(state, 'distributors', LoadingState.Loading))
      .addCase(addDistributor.fulfilled, (state, action) => successfulDistributorAddCase(state, action))
      .addCase(addDistributor.rejected, (state, action) =>
        adminUpdateCase(state, 'distributors', LoadingState.Failed, undefined, action.error.message)
      )

      // Manage distributor
      .addCase(manageDistributor.pending, (state) => adminUpdateCase(state, 'distributors', LoadingState.Loading))
      .addCase(manageDistributor.fulfilled, (state, action) =>
        adminUpdateCase(state, 'distributors', LoadingState.Succeeded, action)
      )
      .addCase(manageDistributor.rejected, (state, action) =>
        adminUpdateCase(state, 'distributors', LoadingState.Failed, undefined, action.error.message)
      )

      // Disable distributor
      .addCase(disableDistributor.pending, (state) => adminUpdateCase(state, 'distributors', LoadingState.Deleting))
      .addCase(disableDistributor.fulfilled, (state, action) => successfulDistributorDeleteCase(state, action))
      .addCase(disableDistributor.rejected, (state, action) =>
        adminUpdateCase(state, 'distributors', LoadingState.Failed, undefined, action.error.message)
      )

      // Get customers
      .addCase(getCustomers.pending, (state) => adminLoadingCase(state, 'customers', LoadingState.Loading))
      .addCase(getCustomers.fulfilled, (state, action) =>
        adminLoadingCase(state, 'customers', LoadingState.Succeeded, action)
      )
      .addCase(getCustomers.rejected, (state, action) =>
        adminLoadingCase(state, 'customers', LoadingState.Failed, undefined, action.error.message)
      )

      // Add customer
      .addCase(addCustomer.pending, (state) => adminUpdateCase(state, 'customers', LoadingState.Loading))
      .addCase(addCustomer.fulfilled, (state, action) => successfulCustomerAddCase(state, action))
      .addCase(addCustomer.rejected, (state, action) =>
        adminUpdateCase(state, 'customers', LoadingState.Failed, undefined, action.error.message)
      )

      // Manage customer
      .addCase(manageCustomer.pending, (state) => adminUpdateCase(state, 'customers', LoadingState.Loading))
      .addCase(manageCustomer.fulfilled, (state, action) =>
        adminUpdateCase(state, 'customers', LoadingState.Succeeded, action)
      )
      .addCase(manageCustomer.rejected, (state, action) =>
        adminUpdateCase(state, 'customers', LoadingState.Failed, undefined, action.error.message)
      )

      // Disable customer
      .addCase(disableCustomer.pending, (state) => adminUpdateCase(state, 'customers', LoadingState.Deleting))
      .addCase(disableCustomer.fulfilled, (state, action) => successfulCustomerDeleteCase(state, action))
      .addCase(disableCustomer.rejected, (state, action) =>
        adminUpdateCase(state, 'customers', LoadingState.Failed, undefined, action.error.message)
      )

      // Get sites
      .addCase(getSites.pending, (state) => adminLoadingCase(state, 'sites', LoadingState.Loading))
      .addCase(getSites.fulfilled, (state, action) => adminLoadingCase(state, 'sites', LoadingState.Succeeded, action))
      .addCase(getSites.rejected, (state, action) =>
        adminLoadingCase(state, 'sites', LoadingState.Failed, undefined, action.error.message)
      )

      // Add site
      .addCase(addSite.pending, (state) => adminUpdateCase(state, 'sites', LoadingState.Loading))
      .addCase(addSite.fulfilled, (state, action) => successfulSiteAddCase(state, action))
      .addCase(addSite.rejected, (state, action) =>
        adminUpdateCase(state, 'sites', LoadingState.Failed, undefined, action.error.message)
      )

      // Manage site
      .addCase(manageSite.pending, (state) => adminUpdateCase(state, 'sites', LoadingState.Loading))
      .addCase(manageSite.fulfilled, (state, action) => adminUpdateCase(state, 'sites', LoadingState.Succeeded, action))
      .addCase(manageSite.rejected, (state, action) =>
        adminUpdateCase(state, 'sites', LoadingState.Failed, undefined, action.error.message)
      )

      // Disable site
      .addCase(disableSite.pending, (state) => adminUpdateCase(state, 'sites', LoadingState.Deleting))
      .addCase(disableSite.fulfilled, (state, action) => successfulSiteDeleteCase(state, action))
      .addCase(disableSite.rejected, (state, action) =>
        adminUpdateCase(state, 'sites', LoadingState.Failed, undefined, action.error.message)
      )

      // Get instruments
      .addCase(getInstruments.pending, (state) => adminLoadingCase(state, 'instruments', LoadingState.Loading))
      .addCase(getInstruments.fulfilled, (state, action) =>
        adminLoadingCase(state, 'instruments', LoadingState.Succeeded, action)
      )
      .addCase(getInstruments.rejected, (state, action) =>
        adminLoadingCase(state, 'instruments', LoadingState.Failed, undefined, action.error.message)
      )

      // Add instrument
      .addCase(addInstrument.pending, (state) => adminUpdateCase(state, 'instruments', LoadingState.Loading))
      .addCase(addInstrument.fulfilled, (state, action) => successfulInstrumentAddCase(state, action))
      .addCase(addInstrument.rejected, (state, action) =>
        adminUpdateCase(state, 'instruments', LoadingState.Failed, undefined, action.error.message)
      )

      // Manage instrument
      .addCase(manageInstrument.pending, (state) => adminUpdateCase(state, 'instruments', LoadingState.Loading))
      .addCase(manageInstrument.fulfilled, (state, action) =>
        adminUpdateCase(state, 'instruments', LoadingState.Succeeded, action)
      )
      .addCase(manageInstrument.rejected, (state, action) =>
        adminUpdateCase(state, 'instruments', LoadingState.Failed, undefined, action.error.message)
      )

      // Disable instrument
      .addCase(disableInstrument.pending, (state) => adminUpdateCase(state, 'instruments', LoadingState.Deleting))
      .addCase(disableInstrument.fulfilled, (state, action) => successfulInstrumentDeleteCase(state, action))
      .addCase(disableInstrument.rejected, (state, action) =>
        adminUpdateCase(state, 'instruments', LoadingState.Failed, undefined, action.error.message)
      );
  },
});

export default adminSlice.reducer;
