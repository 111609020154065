import { EqaDate } from '@dewire/models/definitions/api-response/eQA/eqa-selectionkeys';
import { Months } from 'interfaces/common';

export const formatEQADate = (date: EqaDate | undefined) => {
  if (date) {
    const month = Object.keys(Months)[date.month - 1];
    return `${month} ${date.year}`;
  }
  return Months.Unknown;
};

export const formatEQAMonth = (month: number, pdf?: boolean) => {
  if (month < 0 || month > 12) return Months.Unknown;
  return pdf ? Object.keys(Months)[month - 1].substring(0, 3) : Object.keys(Months)[month - 1];
};
