import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

import TableRowBase from './TableRowBase';

const ExpandableTableRow = styled(TableRowBase)<{ open: boolean }>`
  background-color: ${(open) => (open ? useTheme().hoverBackground : useTheme().font.color.white)};
  cursor: pointer;
  :hover {
    background-color: ${() => useTheme().hoverBackground};
  }
`;

export default ExpandableTableRow;
