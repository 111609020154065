import handleResponse from 'helpers/error-handling/handle-response';
import { disableCustomer, disableDistributor, disableInstrument, disableSite } from 'redux/slices/admin.slice';
import { store } from 'redux/store';

export async function deleteDistributor(
  deletedDistributorId: number,
  onDeletedDistributor: () => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(
    disableDistributor({ session: headersState, distributor: deletedDistributorId })
  );

  handleResponse(response.meta.requestStatus, onDeletedDistributor, onError);
}

export async function deleteCustomer(deletedCustomerId: number, onDeletedCustomer: () => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(
    disableCustomer({ session: headersState, customerInfo: { customerId: deletedCustomerId, distributorId: 0 } })
  );

  handleResponse(response.meta.requestStatus, onDeletedCustomer, onError);
}

export async function deleteSite(deletedSiteId: number, onDeletedSite: () => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(
    disableSite({ session: headersState, siteInfo: { siteId: deletedSiteId, customerId: 0 } })
  );

  handleResponse(response.meta.requestStatus, onDeletedSite, onError);
}

export async function deleteInstrument(
  deletedInstrumentSerial: string,
  onDeletedInstrument: () => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(
    disableInstrument({
      session: headersState,
      instrumentInfo: { instrumentSerial: deletedInstrumentSerial, siteId: 0, customerId: 0, distributorId: 0 },
    })
  );

  handleResponse(response.meta.requestStatus, onDeletedInstrument, onError);
}
