import { EqaMeasurementDetails } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { EqaSdiHistory } from '@dewire/models/definitions/api-response/eQA/eqa-sdi';
import SdiGraph from 'components/charts/SdiGraph';
import EQAMeasurementRow from 'components/rows/eqa/EQAMeasurementRow';
import GraphContainer from 'components/styled-components/containers/GraphContainer';
import KeyHeader from 'components/styled-components/headers/KeyHeader';
import MeasurementItem from 'components/styled-components/items/MeasurementItem';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { getUniqueKey } from 'helpers/eqa/getters';
import formatGraphData from 'helpers/formatters/format-graph-data';
import sortMeasurementDataByLevel from 'helpers/sorting/sort-data-by-level';
import lodash from 'lodash';
import styled, { useTheme } from 'styled-components';

const getNthChildValue = (nbrOfLevels: number) => {
  switch (nbrOfLevels) {
    case 3:
      return '2n + 2';
    case 2:
      return '2n + 2';
    case 1:
      return '3n + 2';
    default:
      throw new Error('Invalid nbrOfLevels');
  }
};

const PdfMeasurementContent = styled.div<{ nbrOfLevels: 1 | 2 | 3 }>`
  background-color: ${() => useTheme().background.lightest};
  padding: 0 0 1em 0;
  margin: 0 12.3em;
  font-size: small;
  width: auto;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  // this selector handles amount of cards per page, depending on nbrOfLevels
  :nth-child(${(props) => getNthChildValue(props.nbrOfLevels)}) {
    margin-top: 2em;
    break-before: page;
  }
`;
const GraphWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
`;
const GraphLevel = styled(TextWrapper)`
  display: flex;
  justify-content: right;
  padding: 0.5em 1em;
`;

interface PdfMeasurementCardProps {
  reportMeasurement: EqaMeasurementDetails;
  sdiHistory: EqaSdiHistory;
}

const getNbrOfLevels = (history: EqaSdiHistory): 1 | 2 | 3 => {
  const uniqueLevels: Set<string> = new Set();
  history.measureData.forEach((data) => {
    data.measurements.forEach((measurement) => {
      if (measurement.data.length > 0) uniqueLevels.add(measurement.level);
    });
  });
  if (uniqueLevels.size < 1 || uniqueLevels.size > 3) return 2; // this means an unexpected error occured, and we default to a 'safe' option
  return uniqueLevels.size as 1 | 2 | 3;
};

function PdfMeasurementCard({ reportMeasurement, sdiHistory }: PdfMeasurementCardProps) {
  return (
    <PdfMeasurementContent nbrOfLevels={getNbrOfLevels(sdiHistory)}>
      <KeyHeader>
        <MeasurementItem margin={1.5}>{reportMeasurement.key}</MeasurementItem>
      </KeyHeader>
      {reportMeasurement.measureData &&
        sortMeasurementDataByLevel(reportMeasurement.measureData).map(
          (measurementData) =>
            measurementData.yourN && (
              <EQAMeasurementRow
                key={getUniqueKey(measurementData.level, reportMeasurement.key)}
                reportData={measurementData}
                isPdf
              />
            )
        )}
      <GraphWrapper>
        {sdiHistory.measureData.map(
          (measureData) =>
            measureData.key === reportMeasurement.key &&
            measureData.measurements.map(
              (measurement) =>
                measurement.data.length > 0 && (
                  <GraphContainer key={getUniqueKey(measureData.key, measurement.level)} flexDirection="column">
                    <GraphLevel bold size="small">
                      {lodash.capitalize(measurement.level)}
                    </GraphLevel>
                    <SdiGraph data={formatGraphData(measurement.data, true)} pdf />
                  </GraphContainer>
                )
            )
        )}
      </GraphWrapper>
    </PdfMeasurementContent>
  );
}

export default PdfMeasurementCard;
