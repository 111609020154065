import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const DeleteButton = styled.button<{ disabled?: boolean; filled?: boolean }>`
  display: inline-block;
  background-color: ${({ filled }) => (filled ? useTheme().colors.error.delete : useTheme().colors.transparent)};
  color: ${({ filled }) => (filled ? useTheme().font.color.white : useTheme().colors.error.dark)};
  width: 11em;
  height: 3em;
  font-weight: bold;
  line-height: 3em;
  margin: 1em;
  border-radius: 0.5em;
  cursor: pointer;
  border: ${({ filled }) => (filled ? 'none' : '1px solid red')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.65' : '1')};
  filter: ${({ disabled }) => (disabled ? 'alpha(opacity=65)' : 'none')};
`;

export default DeleteButton;
