import QrCodeIcon from 'assets/icons/QrCodeIcon';
import ScanButtonIcon from 'assets/icons/ScanButtonIcon';
import ReagentLockScanner from 'components/reagent/connect/ReagentConnectScanner';
import ReagentPairInstrument from 'components/reagent/connect/ReagentPairInstrument';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import QrCodeContainer from 'components/styled-components/containers/QrCodeContainer';
import QrReaderContainer from 'components/styled-components/containers/QrReaderContainer';
import ReagentContentContainer from 'components/styled-components/containers/ReagentContentContainer';
import QrCodeWrapper from 'components/styled-components/wrappers/QrCodeWrapper';
import QrReaderWrapper from 'components/styled-components/wrappers/QrReaderWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import getTranslation from 'helpers/translation/get-translation';
import { TextColor } from 'interfaces/common';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function ReagentConnectInstrumentView() {
  const [scanReagentActive, setScanReagentActive] = useState(false);
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get('jwt');
  const payload = searchParams.get('payload');

  const onScanReagent = () => {
    setScanReagentActive(true);
  };

  return (
    <ReagentContentContainer>
      {jwt && payload ? (
        <QrReaderContainer>
          <ReagentPairInstrument />
        </QrReaderContainer>
      ) : (
        <QrReaderContainer>
          {scanReagentActive ? (
            <QrReaderWrapper>
              <ReagentLockScanner />
            </QrReaderWrapper>
          ) : (
            <QrCodeWrapper>
              <TextWrapper color={TextColor.Blue} bold height={3} textAlignCenter justifyContentCenter width={20}>
                Pair your instrument with reagent by scanning the QR code on the instrument
              </TextWrapper>
              <QrCodeContainer onClick={() => onScanReagent()}>
                <QrCodeIcon />
              </QrCodeContainer>
              <PrimaryButton width={24} height={4} margin={false} gap={1} onClick={() => onScanReagent()}>
                <ScanButtonIcon />
                {getTranslation('Scan QR code')}
              </PrimaryButton>
            </QrCodeWrapper>
          )}
        </QrReaderContainer>
      )}
    </ReagentContentContainer>
  );
}
export default ReagentConnectInstrumentView;
