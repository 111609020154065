import styled from 'styled-components';

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 1em;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: bold;
  width: fit-content;
  transition: 1s;
  :hover {
    padding: 0 1em;
    transition: 1s;
  }
`;

export default BackButton;
