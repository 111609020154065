import { ReagentLotReagents } from '@dewire/models/definitions/api-response/reagent-lot-with-reagents-response';

export default function sortReagentsByStatus(
  reagents: ReagentLotReagents[],
  reagentStatuses: string[]
): ReagentLotReagents[] {
  return [...reagents].sort((a, b) => {
    const statusIndexA = reagentStatuses.indexOf(a.status);
    const statusIndexB = reagentStatuses.indexOf(b.status);

    if (statusIndexA === -1 && statusIndexB === -1) return 0;
    if (statusIndexA === -1) return 1;
    if (statusIndexB === -1) return -1;
    return statusIndexA - statusIndexB;
  });
}
