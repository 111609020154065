import { DefaultTheme } from 'app/AppStyling';

interface CrossIconProps {
  color?: string;
}

function CrossIcon({ color = DefaultTheme.font.color.black }: CrossIconProps) {
  return (
    <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 14L14 2" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M14 14L2 2" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}

export default CrossIcon;
