import { EqaReportResponse } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import type {
  EqaSelectionKey,
  EqaSelectionKeysResponse,
} from '@dewire/models/definitions/api-response/eQA/eqa-selectionkeys';
import { EqaReportFormInput } from '@dewire/models/definitions/form-input/eqa-report-form-input';
import { useTheme } from 'app/AppStyling';
import SearchInputField from 'components/selection/search/SearchInputField';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import { getAllSelectionKeys, getGeneratedReport } from 'helpers/eqa/getters';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getInstrumentFieldInput } from 'helpers/finders/field-input-finders';
import { formatEQADate } from 'helpers/formatters/format-eqa-date';
import { getAllInstruments } from 'helpers/organization/getters';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { bpTheme, useCurrentBreakpoint } from 'helpers/window/use-current-breakpoint';
import { LoadingState, Status, TextColor } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const InputContainer = styled.div`
  gap: 1em;
  background-color: ${() => useTheme().font.color.white};
  padding: 2em;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  ${() => bpTheme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  ${() => bpTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;
const ReportTitleWrapper = styled(TitleWrapper)`
  font-weight: bold;
  padding: 0 0 0 1em;
  ${() => bpTheme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${() => bpTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;

interface GenerateReportCardProps {
  generateReportCallback: (response: EqaReportResponse, serialNumber: string | undefined) => void;
}

const findSelectedMonth = (data: EqaReportFormInput): string => {
  if (data.year && data.month) return formatEQADate({ year: data.year, month: data.month });
  return formatEQADate(undefined);
};

export default function GenerateReportCard({ generateReportCallback }: GenerateReportCardProps) {
  const [adminState, eQAState] = useAppSelector((state) => [state.admin, state.eqa]);
  const instrumentsLoadingState = adminState.instruments.loadingStatus;
  const instrumentsState = adminState.instruments.content;
  const currentBP = useCurrentBreakpoint();
  const [selectedKeys, setSelectedKeys] = useState<EqaReportFormInput>({
    serialNumber: undefined,
    lotKitNumber: undefined,
    month: undefined,
    year: undefined,
  });
  const [error, setError] = useState(true);
  const [keysLoading, setKeysLoading] = useState(false);
  const [instrumentsLoading, setInstrumentsLoading] = useState(false);
  const [availableInstruments, setAvailableInstruments] = useState([{ label: '', id: '' }]);
  const [availableSelectionKeys, setAvailableSelectionKeys] = useState<EqaSelectionKey[]>();

  const onGenerateReport = (report: EqaReportFormInput) => {
    if (report && report.serialNumber && report.lotKitNumber && report.month && report.year) {
      getGeneratedReport(
        report.serialNumber,
        report.lotKitNumber,
        report.month,
        report.year,
        (result: EqaReportResponse) => {
          generateReportCallback(result, report.serialNumber);
        },
        () => {
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch report.');
        }
      );
    } else {
      provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Invalid input.');
    }
  };

  const fetchSelectionKeys = (id: string) => {
    getAllSelectionKeys(
      id,
      (result: EqaSelectionKeysResponse) => {
        setAvailableSelectionKeys(result.selectionKeys);
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch selection keys.');
      }
    );
  };

  const getLotKitNumbers = () => {
    if (availableSelectionKeys?.length !== 0)
      return availableSelectionKeys?.map((lot: EqaSelectionKey) => ({
        label: lot.lotKitNumber || 'No lotKit number available',
        id: lot.lotKitNumber || '',
      }));
    return [
      {
        label: 'No lotKit number available',
        id: '',
      },
    ];
  };

  const getMonths = () => {
    if (selectedKeys.lotKitNumber) {
      return availableSelectionKeys
        ?.find((eqaSelKey: EqaSelectionKey) => eqaSelKey.lotKitNumber === selectedKeys.lotKitNumber)
        ?.dates.map((date) => ({
          label: date ? formatEQADate(date) : 'Invalid date',
          id: date.month,
        }));
    }
    return [
      {
        label: 'No date available',
        id: -1,
      },
    ];
  };

  const calculateTextFieldWidth = () => {
    if (currentBP < bpTheme.breakpoints.values.lg) {
      return 15;
    }
    if (currentBP < bpTheme.breakpoints.values.md) {
      return 12;
    }
    return undefined;
  };

  const handleOnChangeInstrument = (instrument: FieldInput<string>) => {
    setSelectedKeys({
      serialNumber: instrument.label,
      lotKitNumber: undefined,
      month: undefined,
      year: undefined,
    });
    if (instrument.id) fetchSelectionKeys(instrument.id);
  };

  const handleOnChangeLotKit = (lotKit: FieldInput<string>) => {
    setSelectedKeys({
      ...selectedKeys,
      lotKitNumber: lotKit.id,
      month: undefined,
    });
  };

  const handleOnChangeMonth = (input: FieldInput<number>) => {
    setSelectedKeys({
      ...selectedKeys,
      month: input.id,
      year: Number(input.label.split(' ').at(1)),
    });
  };

  // Input validation
  useEffect(() => {
    if (
      !validateInput({ value: selectedKeys.lotKitNumber?.toString(), params: ['requiredId'] }).error &&
      !validateInput({ value: selectedKeys.month?.toString(), params: ['requiredId'] }).error
    ) {
      setError(false);
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  useEffect(() => {
    setKeysLoading(eQAState.selectionKeys.loadingStatus === LoadingState.Loading);
  }, [eQAState.selectionKeys]);

  useEffect(() => {
    getAllInstruments();
  }, []);

  useEffect(() => {
    setInstrumentsLoading(instrumentsLoadingState === LoadingState.Loading);
  }, [instrumentsLoadingState]);

  useEffect(() => {
    setAvailableInstruments(
      instrumentsState.map((instrument) => ({ label: instrument.serialNumber, id: instrument.serialNumber ?? '' }))
    );
  }, [instrumentsState]);

  return (
    <InputContainer>
      <ReportTitleWrapper>Generate a new Quality Assurance report</ReportTitleWrapper>
      <InputWrapper>
        <SearchInputField
          width={calculateTextFieldWidth()}
          label={getTranslation('Serialnumber *')}
          value={getInstrumentFieldInput(availableInstruments, selectedKeys.serialNumber)}
          valueArray={availableInstruments}
          disabled={!availableInstruments}
          onChangeCallback={(newInstrument: FieldInput<string>) => handleOnChangeInstrument(newInstrument)}
          loading={instrumentsLoading}
        />
        <SearchInputField
          width={calculateTextFieldWidth()}
          label={getTranslation('LotKit number *')}
          value={{
            label: selectedKeys.lotKitNumber ? `${selectedKeys.lotKitNumber}` : '',
            id: selectedKeys.lotKitNumber ?? '',
          }}
          valueArray={getLotKitNumbers() ?? []}
          disabled={!selectedKeys.serialNumber && keysLoading}
          onChangeCallback={(newLotKitNumber: FieldInput<string>) => handleOnChangeLotKit(newLotKitNumber)}
          loading={keysLoading}
          error={
            !!selectedKeys.serialNumber &&
            validateInput({ value: selectedKeys.lotKitNumber?.toString(), params: ['required'] }).error
          }
          helper={validateInput({ value: selectedKeys.lotKitNumber?.toString(), params: ['required'] }).errorMessage}
        />
        <SearchInputField
          width={calculateTextFieldWidth()}
          label={getTranslation('Month *')}
          value={{ label: findSelectedMonth(selectedKeys), id: -1 }}
          valueArray={getMonths() ?? []}
          disabled={!selectedKeys.lotKitNumber && keysLoading}
          onChangeCallback={(newMonth: FieldInput<number>) => handleOnChangeMonth(newMonth)}
          loading={keysLoading}
          error={
            !!selectedKeys.lotKitNumber &&
            validateInput({ value: selectedKeys.month?.toString(), params: ['required'] }).error
          }
          helper={validateInput({ value: selectedKeys.month?.toString(), params: ['required'] }).errorMessage}
        />
      </InputWrapper>
      <ButtonContainer>
        <RequiredTextWrapper subtitle size="small" color={TextColor.Secondary}>
          {getTranslation('* Required fields')}
        </RequiredTextWrapper>
        <PrimaryButton disabled={error} onClick={() => onGenerateReport(selectedKeys)}>
          {getTranslation('Generate')}
        </PrimaryButton>
      </ButtonContainer>
    </InputContainer>
  );
}
