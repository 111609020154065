import styled, { useTheme } from 'styled-components';

const ReagentContentContainer = styled.div<{ margin?: boolean }>`
  display: flex;
  align-content: center;
  justify-content: center;
  background: ${() => useTheme().background.lightest};
  min-height: 80vh;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  margin: ${({ margin }) => (margin ? '3em auto' : '0em')};
`;

export default ReagentContentContainer;
