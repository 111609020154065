import { useTheme } from 'app/AppStyling';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import MailIcon from 'assets/icons/MailIcon';
import PhoneIcon from 'assets/icons/PhoneIcon';
import ReversedNavigationButton from 'components/styled-components/buttons/ReversedNavigationButton';
import getTranslation from 'helpers/translation/get-translation';
import parsePhoneNumber from 'libphonenumber-js';
import { useRef } from 'react';
import styled from 'styled-components';

const UserCardContainer = styled.div`
  background: ${() => useTheme().font.color.white};
  width: 25em;
  height: 13em; // 13em
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1em;
`;
const GeneralInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
  width: 100%;
  gap: 0.5em;
`;
const NameText = styled.div`
  font-weight: 800;
  font-size: 1.1em;
`;
const RolesText = styled.div`
  font-size: 0.9em;
  color: ${() => useTheme().font.color.secondary};
`;
const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1em;
  width: 100%;
`;
const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 14em;
`;
const DetailedInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  gap: 1em;
`;
const InfoWrapperText = styled.div`
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12em;
`;
const ContactIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface UserCardProps {
  userName: string;
  userRoles: string[];
  userPhone: string;
  userEmail: string;
  setUserCallback: () => void;
}

function UserCard({ userName, userRoles, userPhone, userEmail, setUserCallback }: UserCardProps) {
  const wrapperRef = useRef<any>(null);

  return (
    <UserCardContainer>
      <GeneralInformationWrapper>
        <NameText>{userName === '' ? 'Nameless' : userName}</NameText>
        <RolesText>
          {userRoles.length === 0
            ? 'No roles assigned'
            : userRoles.map((a) => a.charAt(0).toUpperCase() + a.substring(1)).join(', ')}
        </RolesText>
      </GeneralInformationWrapper>
      <BottomWrapper>
        <ContactInfoWrapper ref={wrapperRef}>
          <DetailedInfoWrapper>
            <ContactIconWrapper>
              <PhoneIcon />
            </ContactIconWrapper>
            <InfoWrapperText>
              {userPhone === '' ? '-' : parsePhoneNumber(userPhone)?.formatInternational()}
            </InfoWrapperText>
          </DetailedInfoWrapper>
          <DetailedInfoWrapper>
            <ContactIconWrapper>
              <MailIcon />
            </ContactIconWrapper>
            <InfoWrapperText>{userEmail === '' ? 'Missing e-mail' : userEmail}</InfoWrapperText>
          </DetailedInfoWrapper>
        </ContactInfoWrapper>
        <ReversedNavigationButton onClick={() => setUserCallback()} inContainer buttonColor={useTheme().colors.blue}>
          <p>{getTranslation('View profile')}</p>
          <ArrowBackIcon size="small" reversed arrowColor={useTheme().colors.blue} />
        </ReversedNavigationButton>
      </BottomWrapper>
    </UserCardContainer>
  );
}

export default UserCard;
