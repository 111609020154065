import { useTheme } from 'app/AppStyling';
import Header3 from 'components/styled-components/headers/Header3';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const DashboardCardContainer = styled.div<{ isToggleable: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 5px 5px ${() => useTheme().shadow.secondary};
  cursor: ${({ isToggleable }) => (isToggleable ? 'pointer' : 'auto')};
  :hover {
    box-shadow: ${({ isToggleable }) =>
      isToggleable ? `3px 3px 10px${useTheme().shadow.secondary}` : `2px 5px 5px${useTheme().shadow.secondary}`};
  }
  border-radius: 4px;
  min-height: 11em;
  height: 100%;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 0.5em;
  padding: 1em;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: ${() => useTheme().background.blue};
`;
const TitleHeader = styled(Header3)<{ isToggleable: boolean }>`
  color: ${() => useTheme().font.color.primary};
  text-decoration: ${({ isToggleable }) => (isToggleable ? 'underline' : 'none')};
`;
const ContentWrapper = styled.div<{ isToggleable: boolean; justifyContent: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: center;
  padding: 1em;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${({ isToggleable }) =>
    isToggleable ? useTheme().background.lightBlue : useTheme().background.lightest};
`;

interface DashboardCardProps {
  cardTitle: string;
  children: React.ReactNode;
  navigationPath?: string;
  justifyContent?: string;
}

function DashboardCard({
  cardTitle,
  children,
  navigationPath = '',
  justifyContent = 'space-between',
}: DashboardCardProps) {
  const navigate = useNavigate();
  const toggleable = navigationPath !== '';

  return (
    <DashboardCardContainer
      isToggleable={toggleable}
      onClick={() => {
        if (toggleable) navigate(navigationPath);
      }}
    >
      <TitleWrapper>
        <TitleHeader isToggleable={toggleable} color="tertiary">
          {cardTitle}
        </TitleHeader>
      </TitleWrapper>
      <ContentWrapper justifyContent={justifyContent} isToggleable={toggleable}>
        {children}
      </ContentWrapper>
    </DashboardCardContainer>
  );
}

export default DashboardCard;
