import { StatusType } from '@dewire/models/definitions/status-type';
import { Status, TextColor } from 'interfaces/common';

export default function getTextColor(_status: StatusType): TextColor {
  switch (_status) {
    case TextColor.Success:
      return TextColor.Success;
    case Status.Failure:
      return TextColor.Danger;
    case TextColor.Warning:
      return TextColor.Warning;
    default:
      return TextColor.Primary;
  }
}
