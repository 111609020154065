import { ReagentLot } from '@dewire/models/definitions/api-response/reagentlot';
import { useTheme } from 'app/AppStyling';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import InTableLink from 'components/styled-components/buttons/InTableLink';
import InTableStatusLink from 'components/styled-components/buttons/InTableStatusLink';
import TableRowItem from 'components/styled-components/items/TableRowItem';
import TableRow from 'components/styled-components/table/TableRow';
import { formatDisplayDate } from 'helpers/formatters/date-formatter';
import styled from 'styled-components';

const StatusRowItem = styled.div`
  display: flex;
  gap: 0.5em;
`;

interface ReagentLotRowProps {
  reagentLot: ReagentLot;
}

function ReagentLotRow({ reagentLot }: ReagentLotRowProps) {
  return (
    <TableRow gridExpression="14% 12.5% 12% 9.5% 20% 17% 1%">
      <InTableLink to={`${reagentLot.lotNumber}`}>
        {reagentLot.lotNumber}
        <ExpandArrowIcon open={false} color="black" />
      </InTableLink>
      <TableRowItem>{reagentLot.brand}</TableRowItem>
      <TableRowItem>{reagentLot.volume} ml</TableRowItem>
      <TableRowItem>{reagentLot.type}</TableRowItem>
      <TableRowItem>{formatDisplayDate(reagentLot.manufacturingDate)}</TableRowItem>
      <TableRowItem>{formatDisplayDate(reagentLot.expiryDate)}</TableRowItem>
      <StatusRowItem>
        <InTableStatusLink
          title="In use"
          to={`${reagentLot.lotNumber}?status=inuse`}
          $color={useTheme().background.green}
          $hoverColor={useTheme().background.hoverGreen}
        >
          {reagentLot.numberOfReagentsInUse}
        </InTableStatusLink>
        <InTableStatusLink
          title="Empty and expired"
          to={`${reagentLot.lotNumber}?status=emptyexpired`}
          $color={useTheme().background.red}
          $hoverColor={useTheme().background.hoverRed}
        >
          {reagentLot.numberOfReagentsEmptyOrExpired}
        </InTableStatusLink>
        <InTableStatusLink
          title="Available"
          to={`${reagentLot.lotNumber}?status=available`}
          $color="none"
          $hoverColor={useTheme().background.hoverLight}
        >
          {reagentLot.numberOfReagentsAvailable}
        </InTableStatusLink>
      </StatusRowItem>
    </TableRow>
  );
}
export default ReagentLotRow;
