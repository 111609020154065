import {
  getCustomers,
  getDistributors,
  getInstrumentProductModels,
  getInstruments,
  getMarkets,
  getSites,
} from 'redux/slices/admin.slice';
import { store } from 'redux/store';

export async function getAllMarkets() {
  const headersState = store.getState().headers;
  store.dispatch(getMarkets(headersState));
}

export async function getAllModels() {
  const headersState = store.getState().headers;
  store.dispatch(getInstrumentProductModels(headersState));
}

export async function getAllDistributors(onDisitributorsFinishedLoading?: () => void) {
  const headersState = store.getState().headers;
  store
    .dispatch(getDistributors(headersState))
    .then(() => onDisitributorsFinishedLoading && onDisitributorsFinishedLoading());
}

export async function getAllCustomers(onCustomersFinishedLoading?: () => void) {
  const headersState = store.getState().headers;
  store.dispatch(getCustomers(headersState)).then(() => onCustomersFinishedLoading && onCustomersFinishedLoading());
}

export async function getAllSites(onSitesFinishedLoading?: () => void) {
  const headersState = store.getState().headers;
  store.dispatch(getSites(headersState)).then(() => onSitesFinishedLoading && onSitesFinishedLoading());
}

export async function getAllInstruments(onInstrumentsFinishedLoading?: () => void) {
  const headersState = store.getState().headers;
  store
    .dispatch(getInstruments(headersState))
    .then(() => onInstrumentsFinishedLoading && onInstrumentsFinishedLoading());
}
