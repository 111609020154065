import { Customer } from '@dewire/models/definitions/api-response/customer';
import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import DeleteButton from 'components/styled-components/buttons/DeleteButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import SaveButtonContainer from 'components/styled-components/containers/SaveButtonContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import {
  getDistributorFieldInput,
  getDistributorFieldInputWithoutDistributorAccess,
} from 'helpers/finders/field-input-finders';
import { findCountry, getCountryCodes } from 'helpers/formatters/country-codes';
import { deleteCustomer } from 'helpers/organization/delete';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import { FieldInput } from '../../../interfaces/field-input';
import DeleteModal from '../delete/DeleteModal';

const NewCustomerTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const OptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface ManageCustomerModalProps {
  customer: Customer;
  onSaveCallback: (customer: Customer) => void;
  onCloseCallback: () => void;
}

function ManageCustomerModal({ customer, onSaveCallback, onCloseCallback }: ManageCustomerModalProps) {
  const countryCodes = getCountryCodes();
  const [headersState, adminState, distributorsState, customersState] = useAppSelector((state) => [
    state.headers,
    state.admin,
    state.admin.distributors.content,
    state.admin.customers.content,
  ]);

  const hasDistributorViewAccess = headersState.accesses.organization.distributors.view;
  const [error, setError] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [manageCustomerLoading, setManageCustomerLoading] = useState(false);
  const [deleteCustomerLoading, setDeleteCustomerLoading] = useState(false);
  const [customerValue, setCustomerValue] = useState<Partial<CustomerFormInput>>(customer.formData);

  const listOtherCustomers = () => lodash.xor([...customersState], [customer]);

  const listAvailableDistributors = () => {
    if (!hasDistributorViewAccess) {
      return customersState.map((_customer) => ({
        label: _customer.distributorName,
        id: _customer.distributorId,
      }));
    }
    return distributorsState.map((distributor) => ({
      label: distributor.name,
      id: distributor.formData.id,
    }));
  };

  const handleDeleteModal = () => {
    if (customer.numOfSites > 0) {
      provideSnackbar(
        Status.Warning,
        lodash.capitalize(Status.Warning),
        `Customer "${customer.name}" has sites. They will need to be removed before deleting this customer.`
      );
    } else {
      setDeleteActive(true);
    }
  };

  const onChangeCustomer = (_customer: Partial<CustomerFormInput>) => {
    setCustomerValue(_customer);
  };

  const isDeleteDisabled = () => manageCustomerLoading || deleteCustomerLoading || changeStatus;

  const isSaveDisabled = () => error || !changeStatus || manageCustomerLoading || deleteCustomerLoading;

  // Input validation
  useEffect(() => {
    if (
      !validateInput({ value: customerValue.name, params: ['required'], customers: listOtherCustomers() }).error &&
      !validateInput({
        value: customerValue.distributorId?.toString(),
        params: ['requiredId'],
      }).error &&
      !validateInput({ value: customerValue.countryCode, params: ['required'] }).error &&
      !validateInput({ value: customerValue.email, params: ['email'] }).error &&
      !validateInput({ value: customerValue.phoneNumber, params: ['phone'] }).error
    ) {
      setError(false);
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerValue]);

  useEffect(() => {
    const status = adminState.customers.updateStatus;
    setManageCustomerLoading(status === LoadingState.Loading);
    setDeleteCustomerLoading(status === LoadingState.Deleting);
  }, [adminState.customers.updateStatus]);

  useEffect(() => {
    if (lodash.isEqual(customer.formData, customerValue)) {
      setChangeStatus(false);
      setManageCustomerLoading(false);
    } else {
      setChangeStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerValue]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      {deleteActive && (
        <DeleteModal
          titleObject="customer"
          element={customer.name}
          warning="all connected data will be inaccessible"
          onCloseCallback={() => setDeleteActive(false)}
          onDelete={() => {
            setDeleteActive(false);
            deleteCustomer(
              customerValue.id ?? 0,
              () => {
                provideSnackbar(
                  Status.Success,
                  lodash.capitalize(Status.Success),
                  `Successfully deleted customer "${customer.name}".`
                );
                onCloseCallback();
              },
              () =>
                provideSnackbar(
                  Status.Error,
                  lodash.capitalize(Status.Error),
                  `Failed to delete customer "${customer.name}".`
                )
            );
          }}
        />
      )}
      <AddCardContainer>
        <NewCustomerTitleWrapper>
          {getTranslation('Edit customer')}
          <CloseButton closeCallback={onCloseCallback} />
        </NewCustomerTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Name *')}
              filled
              value={customerValue.name ?? ''}
              onChangeCallback={(newName: string) => onChangeCustomer({ ...customerValue, name: newName })}
              error={
                validateInput({ value: customerValue.name, params: ['required'], customers: listOtherCustomers() })
                  .error
              }
              helper={
                validateInput({ value: customerValue.name, params: ['required'], customers: listOtherCustomers() })
                  .errorMessage
              }
            />
            <SearchInputField
              label={getTranslation('Distributor *')}
              value={
                !hasDistributorViewAccess
                  ? getDistributorFieldInputWithoutDistributorAccess(customersState, customerValue.distributorId)
                  : getDistributorFieldInput(distributorsState, customerValue.distributorId)
              }
              valueArray={listAvailableDistributors()}
              onChangeCallback={(newDistributor: FieldInput<number>) => {
                onChangeCustomer({ ...customerValue, distributorId: newDistributor.id });
              }}
              loading={false}
              error={
                validateInput({ value: customerValue.distributorId?.toString() ?? undefined, params: ['requiredId'] })
                  .error
              }
              helper="Please select a distributor"
            />
          </DropdownInputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Address')}
              filled
              value={customerValue.address ?? ''}
              onChangeCallback={(newAddress: string) => onChangeCustomer({ ...customerValue, address: newAddress })}
            />
            <TextInputField
              label={getTranslation('City')}
              filled
              value={customerValue.city ?? ''}
              onChangeCallback={(newCity: string) => onChangeCustomer({ ...customerValue, city: newCity })}
              containerHeight={116}
            />
            <SearchInputField
              label={getTranslation('Country *')}
              value={findCountry(customerValue.countryCode)}
              valueArray={countryCodes}
              onChangeCallback={(newCountry: FieldInput<string>) =>
                onChangeCustomer({ ...customerValue, countryCode: newCountry.id })
              }
              loading={false}
              error={validateInput({ value: customerValue.countryCode, params: ['required'] }).error}
              helper={getTranslation('Please select a country')}
            />
            <TextInputField
              label={getTranslation('Postal Code')}
              filled
              value={customerValue.postalCode ?? ''}
              onChangeCallback={(newPostal: string) => onChangeCustomer({ ...customerValue, postalCode: newPostal })}
            />
          </InputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Email')}
              filled
              value={customerValue.email ?? ''}
              onChangeCallback={(newEmail: string) => onChangeCustomer({ ...customerValue, email: newEmail })}
              error={customerValue && validateInput({ value: customerValue.email, params: ['email'] }).error}
              helper="Please enter a valid email address"
            />
            <TextInputField
              label={getTranslation('Phone')}
              filled
              value={customerValue.phoneNumber ?? ''}
              onChangeCallback={(newPhone: string) => onChangeCustomer({ ...customerValue, phoneNumber: newPhone })}
              error={customerValue && validateInput({ value: customerValue.phoneNumber, params: ['phone'] }).error}
              helper="Please enter a valid phone number"
              containerHeight={116}
            />
            <TextInputField
              label={getTranslation('Comment')}
              filled
              multiline
              rows={6}
              value={customerValue.comment ?? ''}
              onChangeCallback={(newComment: string) => onChangeCustomer({ ...customerValue, comment: newComment })}
              containerHeight={205}
            />
          </InputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <OptionButtonContainer>
            {headersState.accesses.organization.customers.edit ? (
              <DeleteButton onClick={() => handleDeleteModal()} disabled={isDeleteDisabled()}>
                {deleteCustomerLoading ? <LoadingSpinner containerBased inButton error /> : getTranslation('Delete')}
              </DeleteButton>
            ) : (
              <div />
            )}
            <SaveButtonContainer>
              <SecondaryButton disabled={!changeStatus} onClick={() => setCustomerValue(customer.formData)}>
                Reset
              </SecondaryButton>
              <PrimaryButton
                disabled={isSaveDisabled()}
                onClick={() => onSaveCallback({ ...customer, formData: customerValue as CustomerFormInput })}
              >
                {manageCustomerLoading ? <LoadingSpinner containerBased inButton /> : getTranslation('Save')}
              </PrimaryButton>
            </SaveButtonContainer>
          </OptionButtonContainer>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default ManageCustomerModal;
