import InstrumentCycleCounter from '@dewire/models/definitions/api-response/instrument-cycle-counter';
import { InstrumentQcOverview } from '@dewire/models/definitions/api-response/instrument-overview';
import { getInstrumentQcOverview, getInstrumentTotalCycles } from 'api/Api';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import DashboardCard from 'components/cards/instrument-dashboard/DashboardCard';
import QcCardDisplay from 'components/cards/instrument-dashboard/QcCardDisplay';
import InstrumentHeader from 'components/instrument-header/InstrumentHeader';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import BackButton from 'components/styled-components/buttons/BackButton';
import ViewFlexContainer from 'components/styled-components/containers/ViewFlexContainer';
import HeaderWithDateContainer from 'components/styled-components/headers/HeaderWithDateContainer';
import DateStampWrapper from 'components/styled-components/wrappers/DateStampWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import LatestBackground from '../../components/instrument-dashboard/LatestBackground';
import TotalCycles from '../../components/instrument-dashboard/TotalCycles';

const InstrumentViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em;
`;
const QcCardContainer = styled.div<{ isLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
  width: 100%;
`;
const QcCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1em;
`;
const DashboardSection = styled.div`
  display: flex;
  gap: 1em;
  ${() => bpTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;
const InstrumentLogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FlexTwoContainer = styled.div`
  flex: 4;
  min-width: 20em;
`;
const FlexOneContainer = styled.div`
  flex: 2;
  min-width: 10em;
`;
const NavContainer = styled.div`
  margin: 1em 0;
`;

function InstrumentView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const headersState = useAppSelector((state) => state.headers);

  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState('');
  const [totalCyclesApiError, setTotalCyclesApiError] = useState('');
  const [instrumentLogCycles, setInstrumentLogCycles] = useState<InstrumentCycleCounter>();
  const [instrumentQcOverview, setInstrumentQcOverview] = useState<InstrumentQcOverview>();

  const fetchCycles = () => {
    if (id) {
      getInstrumentTotalCycles(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentLogCycles(res.data.result);
        } else {
          setTotalCyclesApiError(res.status);
        }
      });
    } else navigate('/');
  };

  const fetchOverview = () => {
    if (id)
      getInstrumentQcOverview(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentQcOverview(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
  };

  useEffect(() => {
    fetchCycles();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchOverview();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (instrumentQcOverview || apiError) setLoading(false);
    return () => {};
  }, [instrumentQcOverview, apiError]);

  return (
    <InstrumentViewContainer>
      {id && <InstrumentHeader instrumentId={id} />}
      <ViewFlexContainer>
        <NavContainer>
          <nav>
            <BackButton onClick={() => navigate(-1)}>
              <ArrowBackIcon size="small" />
              <p>Instruments</p>
            </BackButton>
          </nav>
        </NavContainer>
        <DashboardSection>
          <FlexTwoContainer>
            <DashboardCard cardTitle="Quality Control" navigationPath="qc">
              <QcCardContainer isLoading={loading}>
                {loading ? (
                  <LoadingSpinner containerBased />
                ) : (
                  <QcCardWrapper>
                    {instrumentQcOverview?.qc.map((qc) => (
                      <QcCardDisplay
                        key={qc.displayName}
                        qc={{
                          ...qc,
                          sampleCreatedAt: qc.time,
                          reportCreatedAt: qc.expiryTime,
                          controlBloodExpiry: qc.expiryTime,
                        }}
                      />
                    ))}
                    {apiError !== '' && <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>}
                  </QcCardWrapper>
                )}
              </QcCardContainer>
            </DashboardCard>
          </FlexTwoContainer>
          <FlexOneContainer>
            <DashboardCard cardTitle="Background">
              <LatestBackground />
            </DashboardCard>
          </FlexOneContainer>
        </DashboardSection>
        <DashboardCard cardTitle="Instrument log" navigationPath="instrument-log">
          <InstrumentLogContainer>
            <HeaderWithDateContainer>
              {totalCyclesApiError === '' ? (
                <>
                  <TextWrapper subtitle>
                    Instrument configured :{' '}
                    {instrumentLogCycles?.instrumentConfigured
                      ? dayjs(instrumentLogCycles.instrumentConfigured).format('YYYY-MM-DD')
                      : ' ---'}
                  </TextWrapper>
                  <DateStampWrapper>
                    <TextWrapper color={TextColor.Tertiary}>{getTranslation('Last export')}</TextWrapper>
                    <TextWrapper color={TextColor.Tertiary}>
                      {instrumentLogCycles?.lastExport
                        ? dayjs(instrumentLogCycles.lastExport).format('YYYY-MM-DD HH:mm')
                        : '----'}
                    </TextWrapper>
                  </DateStampWrapper>
                </>
              ) : (
                <TextWrapper color={TextColor.Danger}>{totalCyclesApiError}</TextWrapper>
              )}
            </HeaderWithDateContainer>
            <TotalCycles />
          </InstrumentLogContainer>
        </DashboardCard>
      </ViewFlexContainer>
    </InstrumentViewContainer>
  );
}

export default InstrumentView;
