import { SimpleUserPermissionInput } from '@dewire/models/definitions/form-input/user-permission-input';
import handleResponse from 'helpers/error-handling/handle-response';
import { manageUser } from 'redux/slices/users.slice';
import { store } from 'redux/store';

export default async function manageExistingUserRole(
  newUser: SimpleUserPermissionInput,
  onManagedUser: () => void,
  onManageUserFailed: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(manageUser({ session: headersState, user: newUser }));
  handleResponse(response.meta.requestStatus, onManagedUser, onManageUserFailed);
}
