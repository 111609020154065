import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import { InstrumentDeleteResponse } from '@dewire/models/definitions/api-response/instrument-delete-respons';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';

export const successfulInstrumentAddCase = (state: any, action: PayloadAction<FullGetResponse<Instrument>>) => {
  const newInstrument = action.payload.data.result;
  const updatedDistributors: Distributor[] = [...state.distributors.content];
  const updatedCustomers: Customer[] = [...state.customers.content];
  const distributorIndex = updatedDistributors.findIndex(
    (val: Distributor) => val.formData.id === newInstrument.formData.distributorId
  );
  const customerIndex = updatedCustomers.findIndex(
    (val: Customer) => val.formData.id === newInstrument.formData.customerId
  );
  if (distributorIndex !== -1) {
    const newDistributor = updatedDistributors[distributorIndex];
    updatedDistributors[distributorIndex] = {
      ...newDistributor,
      numOfInstruments: newDistributor.numOfInstruments + 1,
    };
    if (customerIndex !== -1) {
      const newCustomer = updatedCustomers[customerIndex];
      updatedCustomers[customerIndex] = {
        ...newCustomer,
        numOfInstruments: newCustomer.numOfInstruments + 1,
      };
      return {
        ...state,
        instruments: {
          ...state.instruments,
          content: state.instruments.content.concat(newInstrument),
          updateStatus: LoadingState.Succeeded,
        },
        distributors: {
          ...state.distributors,
          content: updatedDistributors,
        },
        customers: {
          ...state.customers,
          content: updatedCustomers,
        },
      };
    }
    return {
      ...state,
      instruments: {
        ...state.instruments,
        content: state.instruments.content.concat(newInstrument),
        updateStatus: LoadingState.Succeeded,
      },
      distributors: {
        ...state.distributors,
        content: updatedDistributors,
      },
    };
  }
  return {
    ...state,
    instruments: {
      ...state.instruments,
      content: state.instruments.content.concat(newInstrument),
      updateStatus: LoadingState.Succeeded,
    },
  };
};

export const successfulInstrumentDeleteCase = (
  state: any,
  action: PayloadAction<FullGetResponse<InstrumentDeleteResponse>>
) => {
  const instrumentSerial: string = action.payload.data.result.instrumentSerial.toString();
  const { distributorId, customerId } = action.payload.data.result;
  const updatedInstruments = [...state.instruments.content];
  const updatedDistributors: Distributor[] = [...state.distributors.content];
  const updatedCustomers: Customer[] = [...state.customers.content];
  const distributorIndex = updatedDistributors.findIndex((val: Distributor) => val.formData.id === distributorId);
  const customerIndex = updatedCustomers.findIndex((val: Customer) => val.formData.id === customerId);
  const instrumentIndex = updatedInstruments.findIndex(
    (val: Instrument) => val.formData.serialNumber === instrumentSerial
  );
  if (instrumentIndex !== -1) {
    updatedInstruments.splice(instrumentIndex, 1);
    if (distributorIndex !== -1) {
      const newDistributor = updatedDistributors[distributorIndex];
      updatedDistributors[distributorIndex] = {
        ...newDistributor,
        numOfInstruments: newDistributor.numOfInstruments - 1,
      };
      if (customerIndex !== -1) {
        const newCustomer = updatedCustomers[customerIndex];
        updatedCustomers[customerIndex] = {
          ...newCustomer,
          numOfInstruments: newCustomer.numOfInstruments - 1,
        };
        return {
          ...state,
          instruments: {
            ...state.instruments,
            content: updatedInstruments,
            updateStatus: LoadingState.Succeeded,
          },
          distributors: {
            ...state.distributors,
            content: updatedDistributors,
          },
          customers: {
            ...state.customers,
            content: updatedCustomers,
          },
        };
      }
      return {
        ...state,
        instruments: {
          ...state.instruments,
          content: updatedInstruments,
          updateStatus: LoadingState.Succeeded,
        },
        distributors: {
          ...state.distributors,
          content: updatedDistributors,
        },
      };
    }
    return {
      ...state,
      instruments: {
        ...state.instruments,
        content: updatedInstruments,
        updateStatus: LoadingState.Succeeded,
      },
    };
  }
  return {
    ...state,
    instruments: {
      ...state.instruments,
      updateStatus: LoadingState.Succeeded,
    },
  };
};
