import { useTheme } from 'app/AppStyling';
import ResgisterReagentFailedIcon from 'assets/icons/FailedIcon';
import ResgisterReagentSuccessIcon from 'assets/icons/SuccessIcon';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import CardContainer from 'components/styled-components/containers/CardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import styled from 'styled-components';

const CardBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em 0.5em 0em 0em;
  height: 2em;
`;
const IconContainer = styled.div<{ success: boolean }>`
  position: relative;
  top: -2em;
  left: ${({ success }) => (success ? '10em' : '9.5em')};
`;
const AlertTitle = styled(TitleWrapper)`
  font-weight: bold;
  margin-bottom: 1em;
`;
const WarningText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em 4em;
  max-width: 15em;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
`;

interface ReagentModalProps {
  success: boolean;
  message: string;
  onFailedCallback: () => void;
}

function ReagentModal({ success, message, onFailedCallback }: ReagentModalProps) {
  const theme = useTheme();

  return (
    <DimmedContainer>
      <CardContainer>
        <IconContainer success={success}>
          {success ? <ResgisterReagentSuccessIcon /> : <ResgisterReagentFailedIcon />}
        </IconContainer>
        <CardBanner>
          {success ? (
            <AlertTitle color={theme.colors.blue}>Success!</AlertTitle>
          ) : (
            <AlertTitle color={theme.colors.red}>Failed</AlertTitle>
          )}
        </CardBanner>
        <>
          <WarningText>
            <p>{message}</p>
          </WarningText>
          <ButtonContainer>
            <PrimaryButton onClick={() => (success ? window.location.reload() : onFailedCallback())}>OK</PrimaryButton>
          </ButtonContainer>
        </>
      </CardContainer>
    </DimmedContainer>
  );
}

export default ReagentModal;
