import { DefaultTheme } from 'app/AppStyling';
import { IconSize } from 'interfaces/icon-size';
import styled from 'styled-components';

const StyledSvg = styled.svg<{ size: IconSize | undefined }>`
  ${({ size }) => {
    if (size === 'x-small') return { width: '0.5em', height: '0.5em' };
    if (size === 'small') return { width: '0.7em', height: '0.7em' };
    if (size === 'large') return { width: '1.5em', height: '1.5em' };
    if (size === 'x-large') return { width: '2em', height: '2em' };
    return { width: '1em', height: '1em' };
  }};
`;

interface ArrowBackIconProps {
  size?: IconSize;
  reversed?: boolean;
  arrowColor?: string;
}

function ArrowBackIcon({
  size = 'medium',
  reversed = false,
  arrowColor = DefaultTheme.colors.primary,
}: ArrowBackIconProps) {
  return (
    <StyledSvg
      size={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${reversed ? '180' : '0'}deg)` }}
    >
      <path d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" fill={arrowColor} />
    </StyledSvg>
  );
}

export default ArrowBackIcon;
