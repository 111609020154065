// TODO: Move to enum (need to get rid of BigInt dependency first)
export const cycleTypes = {
  cleanEmpty: BigInt(19),
  cleanFill: BigInt(18),
  clotPrevention: BigInt(20),
  clotRemoval: BigInt(21),
  burn: BigInt(17),

  openTube: BigInt(2),
  prediluted: BigInt(3),
  capPiercer: BigInt(5),
  autosampler: BigInt(6),
  microCapillary: BigInt(4),
};
