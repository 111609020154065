import Snackbar from '@mui/material/Snackbar';
import { DefaultTheme } from 'app/AppStyling';
import CheckSnackbarIcon from 'assets/icons/CheckSnackbarIcon';
import CrossIcon from 'assets/icons/CrossIcon';
import ErrorSnackbarIcon from 'assets/icons/ErrorSnackbarIcon';
import InfoIcon from 'assets/icons/InfoIcon';
import QuestionSnackbarIcon from 'assets/icons/QuestionSnackbarIcon';
import WarningSnackbarIcon from 'assets/icons/WarningSnackbarIcon';
import SlideTransition from 'components/animations/SlideTransition';
import useSnackbarStyles from 'components/styled-components/mui-styling/SnackbarStyling';
import { Status } from 'interfaces/common';
import { SnackbarType } from 'interfaces/snackbar-type';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeSnackbar } from 'redux/slices/snackbar.slice';
import styled from 'styled-components';

const SnackbarContainer = styled.div``;
const SnackbarContent = styled.div<{ statusColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  margin-top: 5em;
  box-shadow: 0 20px 32px -8px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.25);
  border-radius: 4px;
  border-left: 4px solid;
  border-color: ${({ statusColor }) => statusColor};
`;
const SnackbarIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
`;
const SnackbarTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
`;
const SnackbarTitle = styled.p`
  display: flex;
  font-weight: 800;
`;
const SnackbarMessage = styled.p`
  display: flex;
`;
const ExitContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 1em;
`;

function SnackbarCard() {
  const dispatch = useAppDispatch();
  const snackbarClass = useSnackbarStyles();
  const snackbarState = useAppSelector((state) => state.snackbar);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  function renderStatus(type: SnackbarType) {
    switch (type) {
      case Status.Success:
        return { icon: <CheckSnackbarIcon />, color: DefaultTheme.colors.success.iconDetails };
      case Status.Warning:
        return { icon: <WarningSnackbarIcon />, color: DefaultTheme.colors.warning.iconDetails };
      case Status.Error:
        return { icon: <ErrorSnackbarIcon />, color: DefaultTheme.colors.error.delete };
      case Status.Unknown:
        return { icon: <QuestionSnackbarIcon />, color: DefaultTheme.colors.primary };
      case Status.Info:
        return { icon: <InfoIcon />, color: DefaultTheme.colors.primary };
      default:
        return { icon: <QuestionSnackbarIcon />, color: DefaultTheme.colors.primary };
    }
  }

  return (
    <SnackbarContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={snackbarClass.default}
        open={snackbarState.open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        autoHideDuration={10000}
      >
        <SnackbarContent statusColor={renderStatus(snackbarState.type).color}>
          <SnackbarIconContainer>{renderStatus(snackbarState.type).icon}</SnackbarIconContainer>
          <SnackbarTextInfo>
            <SnackbarTitle>{snackbarState.title}</SnackbarTitle>
            <SnackbarMessage>{snackbarState.message}</SnackbarMessage>
          </SnackbarTextInfo>
          <ExitContainer onClick={handleClose}>
            <CrossIcon />
          </ExitContainer>
        </SnackbarContent>
      </Snackbar>
    </SnackbarContainer>
  );
}

export default SnackbarCard;
