import Slide, { SlideProps } from '@mui/material/Slide';
import { forwardRef, PropsWithChildren, Ref } from 'react';

const SlideTransition = forwardRef(
  (
    props: PropsWithChildren<SlideProps>,
    ref: Ref<unknown>
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide {...props} direction="left" ref={ref} />
);

export default SlideTransition;
