import { useTheme } from 'app/AppStyling';

function YoutubeIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3418_10242)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C17.0711 0 22 4.92895 22 11C22 17.0711 17.0711 22 11 22C4.92895 22 0 17.0711 0 11C0 4.92895 4.92895 0 11 0ZM17.5878 7.69046C17.4296 7.09882 16.9638 6.633 16.3722 6.47487C15.2998 6.1875 11 6.1875 11 6.1875C11 6.1875 6.70025 6.1875 5.62783 6.47487C5.03637 6.633 4.57037 7.09882 4.41225 7.69046C4.125 8.76275 4.125 11.0001 4.125 11.0001C4.125 11.0001 4.125 13.2373 4.41225 14.3095C4.57037 14.9012 5.03637 15.3672 5.62783 15.5253C6.70025 15.8125 11 15.8125 11 15.8125C11 15.8125 15.2998 15.8125 16.3722 15.5253C16.9638 15.3672 17.4296 14.9012 17.5878 14.3095C17.875 13.2373 17.875 11.0001 17.875 11.0001C17.875 11.0001 17.875 8.76275 17.5878 7.69046ZM9.62496 13.0626V8.93754L13.1972 11.0001L9.62496 13.0626Z"
          fill={useTheme().colors.danger.iconDetails}
        />
      </g>
      <defs>
        <clipPath id="clip0_3418_10242">
          <rect width="22" height="22" fill={useTheme().font.color.white} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default YoutubeIcon;
