import CycleInfoBox from 'components/styled-components/cycles/CycleInfoBox';
import CycleItemBox from 'components/styled-components/cycles/CycleItemBox';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { TextColor } from 'interfaces/common';
import styled from 'styled-components';

const CycleItemElementNested = styled(CycleItemBox)`
  padding-left: 2em;
  &:before {
    font-size: 1.5em;
    // Tested for value
    left: 0.11em;
  }
`;
const CycleItemFakeElement = styled(CycleItemBox)`
  height: 1em;
  &:before {
    font-size: 1em;
    // Tested for value
    left: 0.29em;
  }
`;
const CycleItemRowElement = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em;
  justify-content: start;
`;
const CycleItemRowItem = styled.div`
  min-width: 10em;
  margin: 0.2em;
`;
const CycleContainer = styled.ul`
  list-style: none;
  margin: 1em;
`;

interface CycleGroup {
  cycleName: string;
  number: number;
  firstStart: Date;
  lastStart: Date;
}

function CycleGroupCard({ cycleName, number, firstStart, lastStart }: CycleGroup) {
  return (
    <CycleItemBox key={`${cycleName}+${number}`}>
      <CycleInfoBox>
        <CycleItemRowElement>
          <CycleItemRowItem>
            <TextWrapper color={TextColor.Secondary}>{`${getTranslation('Samples')}:`}</TextWrapper>
          </CycleItemRowItem>
          <CycleItemRowItem>
            <TextWrapper bold>{`${number} ${cycleName}`}</TextWrapper>
          </CycleItemRowItem>
        </CycleItemRowElement>
        <CycleContainer>
          <CycleItemElementNested>
            <TextWrapper color={TextColor.Secondary} size="small">
              {dayjs(firstStart).format('HH:mm')}
            </TextWrapper>{' '}
          </CycleItemElementNested>
          <CycleItemFakeElement />
          <CycleItemFakeElement />
          <CycleItemFakeElement />
          <CycleItemElementNested>
            <TextWrapper color={TextColor.Secondary} size="small">
              {dayjs(lastStart).format('HH:mm')}
            </TextWrapper>{' '}
          </CycleItemElementNested>
        </CycleContainer>
      </CycleInfoBox>
    </CycleItemBox>
  );
}

export default CycleGroupCard;
