import { AccessType } from '@dewire/models/definitions/api-response/access-type';
import { BouleUserDetails } from '@dewire/models/definitions/api-response/boule-user-details';
import { Origin } from '@dewire/models/definitions/api-response/user-admin/origin';
import { OriginTypes } from '@dewire/models/definitions/origin-types';
import { getAccessTypes, getPersonalUser, getUserDetails } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import MailIcon from 'assets/icons/MailIcon';
import PhoneIcon from 'assets/icons/PhoneIcon';
import ManageListButtonCard from 'components/cards/origins/ManageListButtonCard';
import NumericInfoCard from 'components/cards/origins/NumericInfoCard';
import UserAccessCard from 'components/cards/origins/UserAccessCard';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import AccessOriginModal from 'components/modals/AccessOriginModal';
import LoadingSpinnerModal from 'components/modals/LoadingSpinnerModal';
import ManageUserAccessModal from 'components/modals/manage/ManageUserAccessModal';
import NavigationModal from 'components/modals/NavigationModal';
import SavePermissionModal from 'components/modals/save/SavePermissionModal';
import BackNav from 'components/nav/BackNav';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import getTranslation from 'helpers/translation/get-translation';
import manageExistingUserRole from 'helpers/users/manage';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { HelperText, LoadingState, Paths, Status, TextColor } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import parsePhoneNumber from 'libphonenumber-js';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import AccessContainer from '../../components/users/AccessContainer';

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3em;
`;
const TopContainer = styled.div`
  display: flex;
`;
const ProfileTitle = styled(TextWrapper)`
  margin-bottom: 1em;
`;
const InformationContainer = styled.div`
  display: flex;
  gap: 1em;
  justify-content: space-between;
  margin: 1em 0 6em 0;
  ${() => bpTheme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;
const GeneralInformation = styled.div`
  background: ${() => useTheme().font.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ContentInformation = styled.div`
  background: ${() => useTheme().font.color.white};
  display: flex;
  flex-direction: column;
  padding: 2em;
`;
const NumericalContainer = styled.div`
  background: ${() => useTheme().font.color.white};
  display: flex;
  padding: 2em;
`;
const DividerContainer = styled.div`
  border-left: 2px solid ${() => useTheme().background.medium};
  border-right: 2px solid ${() => useTheme().background.medium};
  padding: 0 2em;
  margin: 0 2em;
`;
const OriginsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em 0;
`;
const RoleSelectContainer = styled.div`
  margin-bottom: 2em;
`;
const RolesTitleContainer = styled.div`
  margin: 1em 0;
`;
const AccessOverviewContainer = styled(TextWrapper)`
  margin-bottom: 6em;
`;
const OriginsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`;

const UserDetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  justify-content: space-between;
  align-items: start;
`;
const userDetailMargin = '0.5em';
const UserDetailInformation = styled.h1`
  font-size: x-large;
  margin-bottom: 12.5px;
`;
const UserContactDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${() => useTheme().font.color.gray};
`;
const UserContactDetail = styled.p`
  margin-right: ${userDetailMargin};
  font-size: medium;
  margin-right: 3em;
  color: ${() => useTheme().font.color.secondary};
`;
const ContactIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${userDetailMargin};
`;

interface UserPermissionsViewProps {
  isProfileView?: boolean;
}

function UserPermissionsView({ isProfileView = false }: UserPermissionsViewProps) {
  const theme = useTheme();
  const userIdAsParam = useParams();
  const [headersState, usersState] = useAppSelector((state) => [state.headers, state.users]);

  const [apiError, setApiError] = useState('');
  const [userDetailsLoading, setUserDetailsLoading] = useState(true);
  const [accessLoading, setAccessLoading] = useState(true);
  const [accessOrigin, setAccessOrigin] = useState<{ unitId: string; originType: OriginTypes; unitName: string }>();
  const [userDetails, setUserDetails] = useState<BouleUserDetails>();
  const [manageOriginActive, setManageOriginActive] = useState(false);
  const [accessTypes, setAccessTypes] = useState<AccessType>();
  const [allTypes, setAllTypes] = useState<AccessType[]>([]);
  const [roles, setRoles] = useState<FieldInput<number>[]>([]);
  const [currentRole, setCurrentRole] = useState<FieldInput<number>>({ label: '', id: undefined });
  const [startingAccessRole, setStartingAccessRole] = useState(0);
  const [userId, setUserId] = useState(userIdAsParam?.userId);
  const [showSave, setShowSave] = useState(false);
  const [manageUserLoading, setManageUserLoading] = useState(false);
  const [userRoleName, setUserRoleName] = useState(userDetails && userDetails.roles);
  const [saveModalToggled, setSaveModalToggled] = useState(false);
  const userPhone = parsePhoneNumber(userDetails?.phone ? userDetails.phone : '')?.formatInternational();

  const fetchUserDetails = () => {
    if (userId) {
      getUserDetails(headersState, userId).then((res) => {
        if (res.status === Status.Ok) {
          setUserDetails(res.data.result);
          setApiError('');
          setUserDetailsLoading(false);
        } else {
          setApiError(res.status);
          setUserDetailsLoading(false);
        }
      });
    }
  };

  const fetchProfileDetails = () => {
    getPersonalUser(headersState).then((res) => {
      if (res.status === Status.Ok) {
        setUserDetails(res.data.result);
        setUserDetailsLoading(false);
      } else {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), res.status);
        setUserDetailsLoading(false);
      }
    });
  };

  const fetchAccessTypes = (accessId: number) => {
    getAccessTypes(headersState).then((res) => {
      if (res.status === Status.Ok) {
        const fetchedTypes = res.data.result;
        const fetchedRoles = fetchedTypes.map((role) => ({ label: role.name, id: role.id }));
        setRoles(fetchedRoles);
        setAllTypes(fetchedTypes);

        const accessIndex = fetchedTypes.findIndex((access: AccessType) => access.id === accessId);
        const userAccessType = fetchedTypes[accessIndex];
        setCurrentRole({ label: userAccessType.name, id: userAccessType.id });
        setAccessTypes(userAccessType);
        setStartingAccessRole(userAccessType.id);

        if (accessIndex === -1) {
          setAccessLoading(false);
        } else {
          setAccessLoading(false);
        }
      } else {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), res.status);
        setAccessLoading(false);
      }
    });
  };

  const handleFindRoleCallback = (roleId: number | undefined) => {
    if (roleId !== undefined) {
      const newRole = roles.find((role) => role.id === roleId);
      if (newRole?.id) {
        const accessIndex = allTypes.findIndex((access: AccessType) => access.id === newRole.id);
        const userAccessType = allTypes[accessIndex];
        setCurrentRole({ label: userAccessType.name, id: userAccessType.id });
        setAccessTypes(userAccessType);
        setShowSave(roleId !== startingAccessRole);
      }
    }
  };

  const saveNewPermissionWithOrigins = (units: Origin[]) => {
    if (accessTypes && accessTypes.id && userDetails && userDetails.units && userId) {
      const userUnits = units.map((unit) => ({ unitId: unit.id, origin: unit.type }));
      manageExistingUserRole(
        {
          accessTypeId: accessTypes.id,
          units: userUnits,
          userId: Number(userId),
        },
        () => {
          if (isProfileView) {
            fetchProfileDetails();
          } else {
            fetchUserDetails();
          }
          setManageOriginActive(false);
        },
        () => {
          setManageOriginActive(false);
        }
      );
    }
  };

  const saveAccessType = () => {
    if (userDetails) saveNewPermissionWithOrigins(userDetails.units as Origin[]);
  };

  const onCloseModalSaveRole = () => {
    if (userDetails) saveAccessType();
    setSaveModalToggled(false);
  };

  const handleUpdateStatusChange = (status: LoadingState) => {
    switch (status) {
      case LoadingState.Succeeded:
        provideSnackbar(Status.Success, lodash.capitalize(Status.Success), `User was successfully updated`);
        if (accessTypes) setStartingAccessRole(accessTypes.id);
        setShowSave(false);
        setUserRoleName([currentRole.label]);
        setManageUserLoading(false);
        break;
      case LoadingState.Failed:
        if (userDetails)
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), `Unable to change role or origins for user`);
        setManageUserLoading(false);
        break;
      case LoadingState.Loading:
        setManageUserLoading(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isProfileView && userDetails) {
      setUserId(userDetails.id.toString());
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  useEffect(() => {
    if (isProfileView) {
      fetchProfileDetails();
    } else {
      fetchUserDetails();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const status = usersState.singleUser.updateStatus;
    handleUpdateStatusChange(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersState.singleUser.updateStatus]);

  useEffect(() => {
    if (userDetails) {
      const accessId = userDetails.accessTypeId;
      if (accessId) fetchAccessTypes(accessId);
      else {
        setAccessLoading(false);
        provideSnackbar(
          Status.Error,
          lodash.capitalize(Status.Error),
          `No accessTypeId found for user ${userDetails.name}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.accessTypeId]);

  useEffect(() => {
    setUserRoleName(userDetails?.roles && userDetails.roles);
  }, [userDetails?.roles]);

  return (
    <UserContainer id="userContainer">
      {accessOrigin &&
        userId &&
        (accessOrigin.originType === 'ADMIN' ? (
          <NavigationModal
            infoTitle="Full access"
            infoText={HelperText.OrganizationNavigation}
            navigationButtonText="Organization"
            navigationPath={Paths.OrganizationUrl}
            onCloseCallback={() => setAccessOrigin(undefined)}
          />
        ) : (
          <AccessOriginModal unit={{ ...accessOrigin, userId }} closeCallback={() => setAccessOrigin(undefined)} />
        ))}
      {saveModalToggled && (
        <SavePermissionModal discardChanges={() => setSaveModalToggled(false)} onSave={() => onCloseModalSaveRole()} />
      )}
      {manageOriginActive && userDetails && (
        <ManageUserAccessModal
          origins={userDetails.units}
          onSaveCallback={(origins: Origin[]) => saveNewPermissionWithOrigins(origins)}
          onCloseCallback={() => setManageOriginActive(false)}
        />
      )}
      {manageUserLoading && <LoadingSpinnerModal />}
      <TopContainer id="topContainer">
        {isProfileView ? (
          <ProfileTitle color={TextColor.Secondary} size="x-large">
            My Profile
          </ProfileTitle>
        ) : (
          <BackNav
            navigate={() => {
              window.location.href = '/users';
            }}
            text="Users"
          />
        )}
      </TopContainer>
      <InformationContainer>
        <GeneralInformation>
          {userDetailsLoading ? (
            <LoadingSpinner containerBased />
          ) : (
            <UserDetailsContainer>
              {userDetails?.name && (
                <UserDetailInformation>
                  {userDetails.name} {`| ${userRoleName}`}
                </UserDetailInformation>
              )}
              <UserContactDetailsContainer>
                <ContactIconWrapper>
                  <PhoneIcon color={theme.font.color.gray} />
                </ContactIconWrapper>
                <UserContactDetail>{userDetails?.phone ? userPhone : '-'}</UserContactDetail>

                <ContactIconWrapper>
                  <MailIcon color={theme.font.color.gray} />
                </ContactIconWrapper>
                <UserContactDetail>{userDetails?.email ? userDetails?.email : '-'}</UserContactDetail>
              </UserContactDetailsContainer>
            </UserDetailsContainer>
          )}
        </GeneralInformation>
        <ContentInformation id="userAccessNumericalContainer">
          <p>Overview of access</p>
          <NumericalContainer>
            <NumericInfoCard category="customers" amount={userDetails?.customers ?? 0} />
            <DividerContainer>
              <NumericInfoCard category="sites" amount={userDetails?.sites ?? 0} />
            </DividerContainer>
            <NumericInfoCard category="instruments" amount={userDetails?.instruments ?? 0} />
          </NumericalContainer>
        </ContentInformation>
      </InformationContainer>
      {!isProfileView && (
        <RoleSelectContainer>
          <RolesTitleContainer>
            <TextWrapper bold color={TextColor.Primary} size="large">
              Role Selection
            </TextWrapper>
          </RolesTitleContainer>
          <DropdownInput
            label={getTranslation('Role')}
            value={currentRole}
            valueArray={roles}
            confirmButton
            disabledConfirm={!showSave}
            checkbox
            backgroundColor={theme.font.color.white}
            onChangeCallback={(roleId: number | undefined) => handleFindRoleCallback(roleId)}
            confirmCallback={() => {
              if (showSave) saveAccessType();
            }}
            closeCallback={() => setSaveModalToggled(showSave)}
          />
        </RoleSelectContainer>
      )}
      {isProfileView && (
        <TextWrapper bold color={TextColor.Primary} size="large">
          Role overview
        </TextWrapper>
      )}

      <AccessOverviewContainer>
        {accessLoading ? <LoadingSpinner containerBased /> : <AccessContainer accessOverview={accessTypes} />}
      </AccessOverviewContainer>

      <OriginsContainer>
        <TextWrapper bold color={TextColor.Primary} size="large">
          {isProfileView ? 'Access overview' : 'Access selection'}
        </TextWrapper>
        <OriginsList>
          {apiError === '' && !userDetailsLoading && (
            <>
              {!isProfileView && (
                <ManageListButtonCard title="Manage access" onClickCallback={() => setManageOriginActive(true)} />
              )}
              {userDetails?.units.map((unit) => (
                <UserAccessCard
                  key={unit.id}
                  name={unit.name}
                  level={unit.type}
                  toggleModalCallback={() =>
                    setAccessOrigin({ unitId: unit.id.toString(), originType: unit.type, unitName: unit.name })
                  }
                />
              ))}
            </>
          )}
          {userDetailsLoading && <LoadingSpinner containerBased />} {apiError && <p> {apiError}</p>}
        </OriginsList>
      </OriginsContainer>
    </UserContainer>
  );
}

export default UserPermissionsView;
