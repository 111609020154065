import { useTheme } from 'app/AppStyling';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import NavigationButton from 'components/styled-components/buttons/NavigationButton';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ModalContainer = styled.div`
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  margin-bottom: 100vh;
  gap: 2em;
`;
const ModalTitle = styled(TitleWrapper)`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
`;
const EmptyContainer = styled.div`
  width: 0.9em;
`;
const ButtonContainer = styled.div`
  padding-left: 3em;
  margin: -1em 0 0 -1em;
`;

interface NavigationModalProps {
  infoText: string;
  infoTitle: string;
  navigationButtonText: string;
  navigationPath: string;
  onCloseCallback: () => void;
}

function NavigationModal({
  infoText,
  infoTitle,
  navigationButtonText,
  navigationPath,
  onCloseCallback,
}: NavigationModalProps) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      <ModalContainer>
        <ModalTitle color={useTheme().font.color.black}>
          <EmptyContainer />
          {infoTitle}
          <CloseButton closeCallback={onCloseCallback} />
        </ModalTitle>
        <p>{infoText}</p>
        <ButtonContainer>
          <NavigationButton onClick={() => navigate(navigationPath)}>
            <p>{navigationButtonText}</p>
            <ArrowBackIcon size="small" reversed />
          </NavigationButton>
        </ButtonContainer>
      </ModalContainer>
    </DimmedContainer>
  );
}

export default NavigationModal;
