import { makeStyles } from '@mui/styles';
import { DefaultTheme } from 'app/AppStyling';

const useSnackbarStyles = makeStyles(() => ({
  default: {
    [`& div`]: {
      backgroundColor: DefaultTheme.font.color.white,
      color: DefaultTheme.font.color.black,
      [`&.MuiSnackbarContent-root`]: {
        borderLeft: '4px solid green',
      },
    },
  },
  warning: {
    [`& div`]: {
      backgroundColor: DefaultTheme.colors.warning.iconDetails,
      color: DefaultTheme.font.color.black,
    },
  },
  danger: {
    [`& div`]: {
      backgroundColor: DefaultTheme.colors.danger.icon,
      color: DefaultTheme.colors.danger.iconDetails,
    },
  },
}));

export default useSnackbarStyles;
