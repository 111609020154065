import { useTheme } from 'app/AppStyling';
import CalenderIcon from 'assets/icons/CalenderIcon';
import { forwardRef } from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  margin: 0 1em;
  display: flex;
  align-items: center;
`;
const DateInput = styled.div`
  padding: 0.5em;
  cursor: pointer;
  width: fit-content;
  border-radius: 4px;
  margin: 1em 0em;
  background: ${() => useTheme().background.light};
  color: ${() => useTheme().font.color.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DateText = styled.div`
  padding: 0.5em;
`;
const SeparatorText = styled.div`
  color: #668399;
  margin: 0 1em;
`;

interface DateInputFieldProps {
  value: string;
  // This component needs an onClick to work but can be undefined
  onClick: undefined;
}

const DateInputField = forwardRef(({ value, onClick }: DateInputFieldProps, ref: any) => {
  const startDate = value.split(' - ')[0];
  const endDate = value.split(' - ')[1];
  return (
    <DateInput ref={ref} onClick={onClick} aria-hidden="true">
      <DateText>{startDate}</DateText>
      <SeparatorText>to</SeparatorText>
      <DateText>{endDate}</DateText>
      <IconContainer>
        <CalenderIcon height={25} width={25} />
      </IconContainer>
    </DateInput>
  );
});

export default DateInputField;
