import { useTheme } from 'app/AppStyling';
import { formatEQAMonth } from 'helpers/formatters/format-eqa-date';
import lodash from 'lodash';
import styled from 'styled-components';

const GraphCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${() => useTheme().font.color.white};
  padding: 1.5em;
  gap: 1em;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;
const GraphInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7em;
  width: 100%;
`;
const GraphInfoTitle = styled.h4`
  display: flex;
  align-items: center;
`;
const GraphInfoText = styled.p<{ colorProp?: string }>`
  display: flex;
  justify-content: flex-start;
  color: ${({ colorProp }) => colorProp ?? useTheme().font.color.tertiary};
`;

interface GraphInfoCardProps {
  level: string;
  measurements: { value: number; date: { month: number; year: number } }[];
}

function GraphInfoCard({ level, measurements }: GraphInfoCardProps) {
  const appTheme = useTheme();
  return (
    <GraphCardContainer>
      <GraphInfoTitle>SDI value: {lodash.capitalize(level)}</GraphInfoTitle>
      {measurements.map((measurement) => (
        <GraphInfo key={measurement.date.month}>
          <GraphInfoText>
            {formatEQAMonth(measurement.date.month)} {measurement.date.year}
          </GraphInfoText>
          <GraphInfoText colorProp={appTheme.font.color.primary}>{measurement.value?.toFixed(3)}</GraphInfoText>
        </GraphInfo>
      ))}
    </GraphCardContainer>
  );
}

export default GraphInfoCard;
