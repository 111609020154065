import { PayloadAction } from '@reduxjs/toolkit';
import { AdminState } from 'interfaces/admin-state';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';

// State as 'any' due to Typescript not finding WritableDraft<object> nor Draft<object> in the project

export const adminLoadingCase = (
  state: any,
  sectionName: keyof AdminState,
  loadingState: LoadingState,
  action: PayloadAction<FullGetResponse<any>> | undefined = undefined,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  [sectionName]: {
    ...state[sectionName],
    loadingStatus: loadingState,
    content: action ? action.payload.data.result : state[sectionName].content,
    error: errorMessage,
  },
});

export const adminUpdateCase = (
  state: any,
  sectionName: keyof AdminState,
  updateState: LoadingState,
  action: PayloadAction<FullGetResponse<any>> | undefined = undefined,
  errorMessage: string | undefined = undefined
) => {
  if (action) {
    const newItem = action?.payload.data.result;
    const updatedValues = [...state[sectionName].content];
    const itemIndex = updatedValues.findIndex((val) => val.formData.id === newItem.formData.id);
    if (itemIndex !== -1) {
      updatedValues[itemIndex] = { ...newItem };
      return {
        ...state,
        [sectionName]: {
          ...state[sectionName],
          content: action && itemIndex !== -1 ? updatedValues : state[sectionName].content,
          updateStatus: LoadingState.Succeeded,
        },
      };
    }
  }
  return {
    ...state,
    [sectionName]: {
      ...state[sectionName],
      updateStatus: updateState,
      error: errorMessage,
    },
  };
};
