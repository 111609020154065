import { useTheme } from 'app/AppStyling';
import Plus from 'assets/icons/PlusIcon';
import styled from 'styled-components';

const AddUserCardContainer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid ${() => useTheme().font.color.white};
`;
const AddUserTextWrapper = styled.div`
  color: ${() => useTheme().font.color.tertiary};
  margin-top: 1em;
`;
const AddUserIcon = styled.div`
  background: ${() => useTheme().font.color.white};
  border-radius: 5em;
  border: 4px solid ${() => useTheme().font.color.white};
  color: ${() => useTheme().colors.blue};
  font-size: 6em;
  font-weight: 100;
  width: 1.3em;
  height: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface AddUserCardProps {
  onClickCallback: () => void;
}

function AddUserCard({ onClickCallback }: AddUserCardProps) {
  return (
    <AddUserCardContainer>
      <AddUserIcon onClick={onClickCallback}>
        <Plus color={useTheme().colors.blue} />
      </AddUserIcon>
      <AddUserTextWrapper>Add new user</AddUserTextWrapper>
    </AddUserCardContainer>
  );
}

export default AddUserCard;
