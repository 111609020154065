import styled from 'styled-components';

const PopoverInfoItem = styled.div`
  font-size: small;
  text-align: left;
  gap: 0.5em;
  display: flex;
`;

export default PopoverInfoItem;
