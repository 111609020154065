import styled from 'styled-components';

const ViewButton = styled.button<{ active?: boolean }>`
  padding: 0.2em;
  border: none;
  background: none;
  font-size: 1.2em;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  cursor: pointer;
  border-bottom: ${({ theme: { colors }, active }) => (active ? `1px solid ${colors.primary}` : 'none')};
  color: ${({ theme: { colors } }) => colors.primary};
  :hover {
    font-weight: bold;
  }
`;

export default ViewButton;
