/* eslint-disable jsx-a11y/media-has-caption */
import { ReagentConnectionResponse } from '@dewire/models/definitions/api-response/reagent-connection-response';
import QrFrameIcon from 'assets/icons/QrFrameIcon';
import ConnectReagentModal from 'components/modals/reagent/ConnectReagentModal';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import QrFrameWrapper from 'components/styled-components/wrappers/QrFrameWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import VideoWrapper from 'components/styled-components/wrappers/VideoWrapper';
import mapToPairingRequest from 'helpers/map/map-to-pairing-request';
import connectReagentToInstrument from 'helpers/reagent/connect';
import getTranslation from 'helpers/translation/get-translation';
import { TextColor } from 'interfaces/common';
import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

const QrCodeWrapper = styled.div`
  display: flex;
  gap: 2em;
  flex-direction: column;
  align-items: center;
`;

function ReagentConnectScanner() {
  const scanner = useRef<QrScanner>();
  const videoElement = useRef<HTMLVideoElement>(null);
  const qrBoxElement = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  const [scannedResult, setScannedResult] = useState<string | undefined>('');
  const [qrCodeResponse, setQrCodeResponse] = useState<string>('');
  const [showReagentModal, setShowReagentModal] = useState(false);

  const onScanSuccess = async (result: QrScanner.ScanResult) => {
    setScannedResult(result?.data);
    const pairingRequest = mapToPairingRequest(result?.data);
    if (pairingRequest.payload && pairingRequest.jwt) {
      setShowReagentModal(true);
      await connectReagentToInstrument(
        pairingRequest.payload,
        pairingRequest.jwt,
        (res: ReagentConnectionResponse) => {
          setQrCodeResponse(JSON.stringify(res));
        },
        () => {
          // Add error handling here from new error implementation in Api.ts (spike)
          setShowReagentModal(false);
          throw new Error('Something went wrong');
        }
      );
      setShowReagentModal(false);
    } else {
      throw new Error('Missing data for sending pairing request');
    }
    scanner.current?.stop();
  };

  useEffect(() => {
    if (videoElement?.current && !scanner.current) {
      scanner.current = new QrScanner(videoElement?.current, onScanSuccess, {
        maxScansPerSecond: 10,
        // In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: 'environment',
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!videoElement?.current) {
        scanner?.current?.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!qrOn)
      // eslint-disable-next-line no-alert
      alert('Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.');
  }, [qrOn]);

  return (
    <div>
      {!scannedResult && (
        <VideoWrapper>
          <video ref={videoElement} width="100%" height="100%" style={{ objectFit: 'cover' }} />
          <QrFrameWrapper ref={qrBoxElement} className="qr-box">
            <QrFrameIcon />
          </QrFrameWrapper>
        </VideoWrapper>
      )}
      {showReagentModal && <ConnectReagentModal />}
      {scannedResult && qrCodeResponse !== '' && (
        <QrCodeWrapper>
          <TextWrapper color={TextColor.Blue} bold height={3} textAlignCenter justifyContentCenter width={20}>
            Finalize pairing by scanning this QR code with your instrument!
          </TextWrapper>
          <QRCode value={qrCodeResponse} />
          <PrimaryButton width={24} height={4} margin={false} onClick={() => window.location.reload()}>
            {getTranslation('Pair new instrument')}
          </PrimaryButton>
        </QrCodeWrapper>
      )}
    </div>
  );
}

export default ReagentConnectScanner;
