import { Accesses } from '@dewire/models/definitions/api-response/accesses';
import { saveStateToStorage } from 'helpers/local-storage-service/local-storage';
import { setAccesses, setHeadersWithPayload, setUserName } from 'redux/slices/headers.slice';
import { store } from 'redux/store';

export default () => {
  saveStateToStorage('accessToken', '');
  saveStateToStorage('idToken', '');
  saveStateToStorage('refreshToken', '');
  saveStateToStorage('timestamp', 0);
  saveStateToStorage('accesses', undefined);
  saveStateToStorage('userName', '');

  store.dispatch(
    setHeadersWithPayload({
      accessToken: '',
      idToken: '',
      refreshToken: '',
      timestamp: 0,
    })
  );
  store.dispatch(setAccesses({} as Accesses));
  store.dispatch(setUserName(''));
};
