import { Instrument } from '@dewire/models/definitions/api-response/instrument';
import { InstrumentProductModel } from '@dewire/models/definitions/api-response/instrument-product-models';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import DeleteButton from 'components/styled-components/buttons/DeleteButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import FlexContainer from 'components/styled-components/containers/FlexContainer';
import SaveButtonContainer from 'components/styled-components/containers/SaveButtonContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import {
  getCustomerFieldInput,
  getCustomerFieldInputWithoutCustomerAccess,
  getDistributorFieldInput,
  getDistributorFieldInputWithoutDistributorAccess,
  getSiteFieldInput,
} from 'helpers/finders/field-input-finders';
import { deleteInstrument } from 'helpers/organization/delete';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import DeleteModal from '../delete/DeleteModal';

const NewInstrumentTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const ModelDropdownWrapper = styled.div`
  margin: 1em;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const SiteDropdownWrapper = styled.div`
  margin: -9px 1em;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;
const OptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  :has(> :last-child:nth-child(2)) {
    // if container has two elements in it
    justify-content: space-between;
  }
`;

interface ManageInstrumentModalProps {
  instrument: Instrument;
  onSaveCallback: (instrument: Instrument) => void;
  onCloseCallback: () => void;
}

function ManageInstrumentModal({ instrument, onSaveCallback, onCloseCallback }: ManageInstrumentModalProps) {
  const [headersState, adminState, distributorsState, customersState, instrumentsState, sitesState, modelsState] =
    useAppSelector((state) => [
      state.headers,
      state.admin,
      state.admin.distributors.content,
      state.admin.customers.content,
      state.admin.instruments.content,
      state.admin.sites.content,
      state.admin.models.content,
    ]);

  const hasCustomerViewAccess = headersState.accesses.organization.customers.view;
  const hasDistributorViewAccess = headersState.accesses.organization.distributors.view;
  const [error, setError] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [manageInstrumentLoading, setManageInstrumentLoading] = useState(false);
  const [deleteInstrumentLoading, setDeleteInstrumentLoading] = useState(false);
  const [instrumentValue, setInstrumentValue] = useState<Partial<InstrumentFormInput>>(instrument.formData);
  const models = modelsState.map((model: InstrumentProductModel) => ({ label: model.name, id: model.id }));

  const listOtherInstruments = () => lodash.xor([...instrumentsState], [instrument]);

  const findInstrumentModelNameById = (id: number) => modelsState.find((model) => model.id === id)?.name ?? '';

  const findInstrumentModelIdByName = (name: string | undefined) =>
    modelsState.find((model) => model.name === name)?.id ?? -1;

  const onChangeInstrument = (_instrument: Partial<InstrumentFormInput>) => {
    setInstrumentValue(_instrument);
  };

  const resetValues = () => {
    setInstrumentValue(instrument.formData);
  };

  const getAvailableSitesAsFieldInput = () => {
    if (!hasCustomerViewAccess)
      return sitesState.map((site) => ({
        label: site.name,
        id: site.formData.id,
      }));
    if (sitesState.length > 0)
      return sitesState.map((site) => ({
        label: site.formData.name,
        id: site.formData.id ?? undefined,
      }));
    return [{ label: '', id: undefined }];
  };

  const getAvailableCustomers = () => {
    const id = instrumentValue.distributorId;
    if (id !== undefined) {
      const newAvailableCustomers = customersState
        .filter((customer) => customer.formData.distributorId === id)
        .map((customer) => ({ label: customer.name, id: customer.formData.id }));
      return newAvailableCustomers;
    }
    return [{ label: '', id: undefined }];
  };

  const isDeleteDisabled = () => manageInstrumentLoading || deleteInstrumentLoading || changeStatus;

  const isSaveDisabled = () => error || !changeStatus || manageInstrumentLoading || deleteInstrumentLoading;

  const handleError = (_error: boolean) => {
    setError(_error);
  };

  const listDistributorFromAccess = () => {
    if (hasCustomerViewAccess) {
      return customersState.map((_customer) => ({
        label: _customer.distributorName,
        id: _customer.distributorId,
      }));
    }
    return distributorsState.map((distributor) => ({
      label: distributor.name,
      id: distributor.formData.id,
    }));
  };

  useEffect(() => {
    const status = adminState.instruments.updateStatus;
    setManageInstrumentLoading(status === LoadingState.Loading);
    setDeleteInstrumentLoading(status === LoadingState.Deleting);
  }, [adminState.instruments.updateStatus]);

  useEffect(() => {
    if (
      // Treat undefined and no property as equal by dropping all undefined fields
      lodash.isEqual(
        lodash.omitBy(instrument.formData, (property) => property === undefined),
        lodash.omitBy(instrumentValue, (property) => property === undefined)
      )
    ) {
      setChangeStatus(false);
    } else {
      setChangeStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentValue]);

  // Input validation
  useEffect(() => {
    if (
      !validateInput({
        value: instrumentValue.serialNumber,
        params: ['required', 'number'],
        instruments: listOtherInstruments(),
      }).error &&
      !validateInput({ value: instrumentValue.distributorId?.toString(), params: ['requiredId'] }).error &&
      !(
        instrumentValue.customerId !== undefined &&
        validateInput({ value: instrumentValue.siteId?.toString(), params: ['requiredId'] }).error
      )
    ) {
      handleError(false);
    } else {
      handleError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentValue, instrumentsState]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      {deleteActive && (
        <DeleteModal
          titleObject="instrument"
          element={instrument.serialNumber}
          warning="all connected data and uploaded files will be inaccessible"
          onCloseCallback={() => setDeleteActive(false)}
          onDelete={() => {
            setDeleteActive(false);
            deleteInstrument(
              instrumentValue.serialNumber ?? '',
              () => {
                provideSnackbar(
                  Status.Success,
                  lodash.capitalize(Status.Success),
                  `Successfully deleted instrument "${instrumentValue.serialNumber}".`
                );
                onCloseCallback();
              },
              () =>
                provideSnackbar(
                  Status.Error,
                  lodash.capitalize(Status.Error),
                  `Failed to delete instrument "${instrumentValue.serialNumber}".`
                )
            );
          }}
        />
      )}
      <AddCardContainer>
        <NewInstrumentTitleWrapper>
          Edit instrument
          <CloseButton closeCallback={onCloseCallback} />
        </NewInstrumentTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Serial number *')}
              filled
              value={instrumentValue.serialNumber ?? ''}
              onChangeCallback={(newSerialNumber: string) =>
                onChangeInstrument({ ...instrumentValue, serialNumber: newSerialNumber })
              }
              error={
                validateInput({
                  value: instrumentValue.serialNumber,
                  params: ['required', 'number'],
                  instruments: listOtherInstruments(),
                }).error
              }
              helper={
                validateInput({
                  value: instrumentValue.serialNumber,
                  params: ['required', 'number'],
                  instruments: listOtherInstruments(),
                }).errorMessage
              }
            />
            <ModelDropdownWrapper>
              <DropdownInput
                label={getTranslation('Model *')}
                value={{ label: instrumentValue.model ?? '', id: findInstrumentModelIdByName(instrumentValue.model) }}
                valueArray={models}
                checkbox
                onChangeCallback={(newModelId: number | undefined) =>
                  onChangeInstrument({ ...instrumentValue, model: findInstrumentModelNameById(newModelId ?? -1) })
                }
                error={validateInput({ value: instrumentValue.model?.toString(), params: ['requiredId'] }).error}
                helper="Please select a model"
              />
            </ModelDropdownWrapper>
          </DropdownInputContainer>
          {!hasDistributorViewAccess ?? (
            <InputContainer>
              <SearchInputField
                label={getTranslation('Distributor  *')}
                value={
                  hasCustomerViewAccess
                    ? getDistributorFieldInputWithoutDistributorAccess(customersState, instrumentValue.distributorId)
                    : getDistributorFieldInput(distributorsState, instrumentValue.distributorId)
                }
                valueArray={listDistributorFromAccess()}
                onChangeCallback={(newDistributor: FieldInput<number>) => {
                  onChangeInstrument({
                    ...instrumentValue,
                    distributorId: newDistributor.id,
                    customerId: undefined,
                    siteId: undefined,
                  });
                }}
                loading={false}
                error={
                  validateInput({ value: instrumentValue.distributorId?.toString(), params: ['requiredId'] }).error
                }
                helper={getTranslation('Please select a distributor')}
              />
            </InputContainer>
          )}
          <DropdownInputContainer>
            <SearchInputField
              label={getTranslation('Customer')}
              value={
                !hasCustomerViewAccess
                  ? getCustomerFieldInputWithoutCustomerAccess(sitesState, instrumentValue.customerId)
                  : getCustomerFieldInput(customersState, instrumentValue.customerId)
              }
              valueArray={getAvailableCustomers()}
              disabled={instrumentValue.distributorId === undefined || !hasCustomerViewAccess}
              onChangeCallback={(newCustomer: FieldInput<number>) =>
                onChangeInstrument({ ...instrumentValue, customerId: newCustomer.id, siteId: undefined })
              }
              loading={false}
            />
            <SiteDropdownWrapper>
              <DropdownInput
                label={getTranslation('Site')}
                value={getSiteFieldInput(sitesState, instrumentValue.siteId)}
                valueArray={getAvailableSitesAsFieldInput()}
                checkbox
                disabled={!instrumentValue.distributorId || !instrumentValue.customerId}
                onChangeCallback={(newSite: number | undefined) =>
                  onChangeInstrument({ ...instrumentValue, siteId: newSite })
                }
                error={
                  instrumentValue.customerId !== undefined &&
                  validateInput({ value: instrumentValue.siteId?.toString(), params: ['requiredId'] }).error
                }
                helper={getTranslation('Please select a site')}
              />
            </SiteDropdownWrapper>
          </DropdownInputContainer>
        </FlexContainer>
        <RequiredTextWrapper subtitle size="small">
          {getTranslation('* Required fields')}
        </RequiredTextWrapper>
        <OptionButtonContainer>
          {headersState.accesses.organization.instruments.edit ? (
            <DeleteButton onClick={() => setDeleteActive(true)} disabled={isDeleteDisabled()}>
              {deleteInstrumentLoading ? <LoadingSpinner containerBased inButton error /> : getTranslation('Delete')}
            </DeleteButton>
          ) : (
            <div />
          )}
          <SaveButtonContainer>
            <SecondaryButton disabled={!changeStatus} onClick={() => resetValues()}>
              Reset
            </SecondaryButton>
            <PrimaryButton
              disabled={isSaveDisabled()}
              onClick={() => onSaveCallback({ ...instrument, formData: instrumentValue as InstrumentFormInput })}
            >
              {manageInstrumentLoading ? <LoadingSpinner containerBased inButton /> : getTranslation('Save')}
            </PrimaryButton>
          </SaveButtonContainer>
        </OptionButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default ManageInstrumentModal;
