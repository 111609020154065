import './App.css';

import { authenticate } from 'api/Api';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import { KEYCLOAK_ROOT_URL, LOGOUT_HOSTED_UI_URL } from 'config/config';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { store } from 'redux/store';
import DecoupledView from 'views/decoupled/DecoupledView';
import LoginView from 'views/login/LoginView';

import AppContent from './AppContent';
import Unauthorized from './Unathorized';

function App() {
  const [hasSession, setHasSession] = useState(false);
  const [isDecoupled, setIsDecoupled] = useState(false);
  const [loadingSession, setWaitingForSession] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const firstRender = useRef(true);
  const location = useLocation();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;

      const state = store.getState();

      const now = dayjs().unix();
      const limit = state.headers.timestamp;

      const loggedIn = state.headers.accessToken !== '' && now < limit && limit !== 0;
      const urlAuthCode = new URLSearchParams(window.location.search).get('code') ?? '';
      const urlState = new URLSearchParams(window.location.search).get('state') ?? '';

      if (loggedIn) {
        setHasSession(true);
      } else if (urlAuthCode) {
        const authPromise = authenticate(urlAuthCode, urlState);
        setWaitingForSession(true);
        authPromise.catch(() => {
          setUnauthorized(true);
          setWaitingForSession(false);
        });
      }

      store.subscribe(() => {
        const currentState = store.getState();
        // need to run getState again to get updated state
        const loggedInStatus = currentState.headers.accessToken !== '';

        setHasSession(loggedInStatus);
        if (loggedInStatus) setWaitingForSession(false);
      });
    }
  }, [hasSession]);

  useEffect(() => {
    setIsDecoupled(location.pathname.includes('/pdf') || location.pathname.includes('/reagent/connect'));
  }, [location.pathname]);

  return (
    <Provider store={store}>
      {isDecoupled && <DecoupledView />}
      {hasSession && !isDecoupled && <AppContent />}
      {unauthorized && (
        <Unauthorized
          clearSessionCallback={() => {
            setUnauthorized(false);
            window
              .open(`https://${KEYCLOAK_ROOT_URL}/realms/boule-realm/protocol/openid-connect/logout`, '_blank')!
              .focus();
            window.location.href = LOGOUT_HOSTED_UI_URL;
          }}
        />
      )}
      {loadingSession && <LoadingSpinner />}
      {!unauthorized && !hasSession && !loadingSession && !isDecoupled && <LoginView />}
    </Provider>
  );
}

export default App;
