import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { Market } from '@dewire/models/definitions/api-response/market';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import DeleteButton from 'components/styled-components/buttons/DeleteButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import SaveButtonContainer from 'components/styled-components/containers/SaveButtonContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getMarketFieldInput } from 'helpers/finders/field-input-finders';
import { findCountry, getCountryCodes } from 'helpers/formatters/country-codes';
import { deleteDistributor } from 'helpers/organization/delete';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import DeleteModal from '../delete/DeleteModal';

const NewDistributorTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const DropdownWrapper = styled.div`
  margin: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;
const OptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface ManageDistributorModalProps {
  distributor: Distributor;
  onSaveCallback: (distributor: Distributor) => void;
  onCloseCallback: () => void;
}

function ManageDistributorModal({ distributor, onSaveCallback, onCloseCallback }: ManageDistributorModalProps) {
  const countryCodes = getCountryCodes();
  const [headersState, adminState, distributorsState, marketsState] = useAppSelector((state) => [
    state.headers,
    state.admin,
    state.admin.distributors.content,
    state.admin.models.content,
  ]);

  const hasMarketViewAccess = headersState.accesses.organization.markets.view;
  const [error, setError] = useState(true);
  const [changeStatus, setChangeStatus] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [manageDistributorLoading, setManageDistributorLoading] = useState(false);
  const [deleteDistributorLoading, setDeleteDistributorLoading] = useState(false);
  const [distributorValue, setDistributorValue] = useState<Partial<DistributorFormInput>>(distributor.formData);

  const resetValues = () => {
    setDistributorValue(distributor.formData);
  };

  const listOtherDistributors = () => lodash.xor([...distributorsState], [distributor]);

  const isDeleteDisabled = () => manageDistributorLoading || deleteDistributorLoading || changeStatus;

  const isSaveDisabled = () => error || !changeStatus || manageDistributorLoading || deleteDistributorLoading;

  const handleDeleteModal = () => {
    if (distributor.numOfCustomers > 0) {
      provideSnackbar(
        Status.Warning,
        lodash.capitalize(Status.Warning),
        `Distributor "${distributor.name}" has customers. They will need to be removed before deleting this distributor.`
      );
    } else {
      setDeleteActive(true);
    }
  };

  const listMarkets = () => marketsState.map((market: Market) => ({ label: market.name, id: market.id }));

  // Input validation
  useEffect(() => {
    if (
      !validateInput({ value: distributorValue.name, params: ['required'], distributors: listOtherDistributors() })
        .error &&
      !validateInput({ value: distributorValue.marketId?.toString(), params: ['requiredId'] }).error &&
      !validateInput({ value: distributorValue.countryCode, params: ['required'] }).error &&
      !validateInput({ value: distributorValue.email, params: ['email'] }).error &&
      !validateInput({ value: distributorValue.phoneNumber, params: ['phone'] }).error
    ) {
      setError(false);
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorValue]);

  useEffect(() => {
    if (lodash.isEqual(distributor.formData, distributorValue)) {
      setChangeStatus(false);
    } else {
      setChangeStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorValue]);

  useEffect(() => {
    const status = adminState.distributors.updateStatus;
    setManageDistributorLoading(status === LoadingState.Loading);
    setDeleteDistributorLoading(status === LoadingState.Deleting);
  }, [adminState.distributors.updateStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      {deleteActive && (
        <DeleteModal
          titleObject="distributor"
          element={distributor.name}
          warning="all connected data will be inaccessible"
          onCloseCallback={() => setDeleteActive(false)}
          onDelete={() => {
            setDeleteActive(false);
            deleteDistributor(
              distributorValue.id ?? 0,
              () => {
                provideSnackbar(
                  Status.Success,
                  lodash.capitalize(Status.Success),
                  `Successfully deleted distributor "${distributor.name}".`
                );
                onCloseCallback();
              },
              () =>
                provideSnackbar(
                  Status.Error,
                  lodash.capitalize(Status.Error),
                  `Failed to delete distributor "${distributor.name}".`
                )
            );
          }}
        />
      )}
      <AddCardContainer>
        <NewDistributorTitleWrapper>
          {getTranslation('Edit distributor')}
          <CloseButton closeCallback={onCloseCallback} />
        </NewDistributorTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Name *')}
              filled
              value={distributorValue.name ?? ''}
              onChangeCallback={(newName: string) => setDistributorValue({ ...distributorValue, name: newName })}
              error={
                validateInput({
                  value: distributorValue.name,
                  params: ['required'],
                  distributors: listOtherDistributors(),
                }).error
              }
              helper={
                validateInput({
                  value: distributorValue.name,
                  params: ['required'],
                  distributors: listOtherDistributors(),
                }).errorMessage
              }
            />
            <DropdownWrapper>
              {!hasMarketViewAccess ?? (
                <DropdownInput
                  label={getTranslation('Market *')}
                  value={getMarketFieldInput(marketsState, distributorValue.marketId)}
                  valueArray={listMarkets()}
                  checkbox
                  onChangeCallback={(newMarket: number | undefined) => {
                    setDistributorValue({ ...distributorValue, marketId: newMarket });
                  }}
                  error={validateInput({ value: distributorValue.marketId?.toString(), params: ['requiredId'] }).error}
                  helper="Please select a market"
                />
              )}
            </DropdownWrapper>
          </DropdownInputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Address')}
              filled
              value={distributorValue.address ?? ''}
              onChangeCallback={(newAddress: string) =>
                setDistributorValue({ ...distributorValue, address: newAddress })
              }
            />
            <TextInputField
              label={getTranslation('City')}
              filled
              value={distributorValue.city ?? ''}
              onChangeCallback={(newCity: string) => setDistributorValue({ ...distributorValue, city: newCity })}
              containerHeight={116}
            />
            <SearchInputField
              label={getTranslation('Country *')}
              value={findCountry(distributorValue.countryCode)}
              valueArray={countryCodes}
              onChangeCallback={(newCountry: FieldInput<string>) =>
                setDistributorValue({ ...distributorValue, countryCode: newCountry.id })
              }
              loading={false}
              error={validateInput({ value: distributorValue.countryCode, params: ['required'] }).error}
              helper={getTranslation('Please select a country')}
            />
            <TextInputField
              label={getTranslation('Postal Code')}
              filled
              value={distributorValue.postalCode ?? ''}
              onChangeCallback={(newPostal: string) =>
                setDistributorValue({ ...distributorValue, postalCode: newPostal })
              }
            />
          </InputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Email')}
              filled
              value={distributorValue.email ?? ''}
              onChangeCallback={(newEmail: string) => setDistributorValue({ ...distributorValue, email: newEmail })}
              error={
                !!distributorValue.email &&
                distributorValue.email.length > 0 &&
                validateInput({ value: distributorValue.email, params: ['email'] }).error
              }
              helper="Please enter a valid email address"
            />
            <TextInputField
              label={getTranslation('Phone')}
              filled
              value={distributorValue.phoneNumber ?? ''}
              onChangeCallback={(newPhone: string) =>
                setDistributorValue({ ...distributorValue, phoneNumber: newPhone })
              }
              error={
                !!distributorValue.phoneNumber &&
                distributorValue.phoneNumber.length > 0 &&
                validateInput({ value: distributorValue.phoneNumber, params: ['phone'] }).error
              }
              helper="Please enter a valid phone number"
              containerHeight={116}
            />
            <TextInputField
              label={getTranslation('Comment')}
              filled
              multiline
              rows={6}
              value={distributorValue.comment ?? ''}
              onChangeCallback={(newComment: string) =>
                setDistributorValue({ ...distributorValue, comment: newComment })
              }
              containerHeight={205}
            />
          </InputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <OptionButtonContainer>
            {headersState.accesses.organization.distributors.edit ? (
              <DeleteButton onClick={() => handleDeleteModal()} disabled={isDeleteDisabled()}>
                {deleteDistributorLoading ? <LoadingSpinner containerBased inButton error /> : getTranslation('Delete')}
              </DeleteButton>
            ) : (
              <div />
            )}
            <SaveButtonContainer>
              <SecondaryButton disabled={!changeStatus} onClick={() => resetValues()}>
                Reset
              </SecondaryButton>
              <PrimaryButton
                disabled={isSaveDisabled()}
                onClick={() => onSaveCallback({ ...distributor, formData: distributorValue as DistributorFormInput })}
              >
                {manageDistributorLoading ? <LoadingSpinner containerBased inButton /> : getTranslation('Save')}
              </PrimaryButton>
            </SaveButtonContainer>
          </OptionButtonContainer>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default ManageDistributorModal;
