import styled, { useTheme } from 'styled-components';

const GraphContainer = styled.div<{ flexDirection?: 'column' | 'row' }>`
  display: flex;
  background-color: ${() => useTheme().background.lightest};
  justify-content: center;
  align-items: start;
  flex-direction: ${({ flexDirection }) => flexDirection};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export default GraphContainer;
