import styled, { useTheme } from 'styled-components';

export const IDPContainer = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5em auto;
  width: ${({ width }) => (width ? `${width}em` : '18em')};
  min-height: 18em;
  border-radius: 0.5em;
  background-color: ${() => useTheme().background.light}; // was #f0f6ff
`;

export default IDPContainer;
