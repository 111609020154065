import { useTheme } from 'app/AppStyling';

function CloudUploadIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 -1 24 24"
      id="meteor-icon-kit__regular-cloud-upload"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 9.4142V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V9.4142L9.70711 10.7071C9.31658 11.0976 8.68342 11.0976 8.29289 10.7071C7.90237 10.3166 7.90237 9.6834 8.29289 9.2929L11.2929 6.29289C11.6834 5.90237 12.3166 5.90237 12.7071 6.29289L15.7071 9.2929C16.0976 9.6834 16.0976 10.3166 15.7071 10.7071C15.3166 11.0976 14.6834 11.0976 14.2929 10.7071L13 9.4142zM13 0C15.8074 0 18.2963 1.66786 19.4019 4.16474C22.0611 4.8004 24 7.1925 24 10C24 13.3137 21.3137 16 18 16H16C15.4477 16 15 15.5523 15 15C15 14.4477 15.4477 14 16 14H18C20.2091 14 22 12.2091 22 10C22 7.9919 20.511 6.30173 18.5407 6.03612L17.9296 5.95374L17.7288 5.37076C17.0398 3.37028 15.1506 2 13 2C11.4953 2 10.1017 2.66735 9.1566 3.80159L8.76027 4.27722L8.15784 4.13451C7.78192 4.04545 7.39432 4 7 4C4.23858 4 2 6.23858 2 9C2 11.7614 4.23858 14 7 14H8C8.55229 14 9 14.4477 9 15C9 15.5523 8.55229 16 8 16H7C3.13401 16 0 12.866 0 9C0 5.13401 3.13401 2 7 2C7.34554 2 7.68811 2.02511 8.02571 2.07475C9.32518 0.76157 11.1013 0 13 0z"
        fill={useTheme().font.color.secondary}
      />
    </svg>
  );
}

export default CloudUploadIcon;
