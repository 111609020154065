import IDPContainer from 'components/styled-components/containers/IdpContainer';
import RootContainer from 'components/styled-components/containers/RootContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import getTranslation from 'helpers/translation/get-translation';
import { TextColor } from 'interfaces/common';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  text-align: center;
  gap: 2em;
`;

function Unauthorized({ clearSessionCallback }: { clearSessionCallback: () => void }) {
  return (
    <IDPContainer width={25}>
      <StyledWrapper>
        <TextWrapper color={TextColor.Danger} size="large" bold>
          No permission
        </TextWrapper>
        <TextWrapper textAlignCenter>
          {getTranslation(
            'The system could not find any accesses for this account. Please contact the administrator to gain access. Log out from the SSO to try with another account.'
          )}
        </TextWrapper>
        <RootContainer margin={false} onClick={() => clearSessionCallback()}>
          <TextWrapper size="small" color={TextColor.White}>
            Log out
          </TextWrapper>
        </RootContainer>
      </StyledWrapper>
    </IDPContainer>
  );
}

export default Unauthorized;
