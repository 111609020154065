/* eslint-disable no-nested-ternary */
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { TextColor } from 'interfaces/common';
import { IconSize } from 'interfaces/icon-size';
import styled from 'styled-components';

interface ITextProps {
  subtitle?: boolean;
  bold?: boolean;
  size?: IconSize;
  color?: TextColor;
  rows?: number;
  height?: number;
  width?: number;
  textAlignCenter?: boolean;
  justifyContentCenter?: boolean;
}

const TextWrapper = styled.div<ITextProps>`
  display: flex;
  text-align: ${({ textAlignCenter }) => (textAlignCenter ? 'center' : 'start')};
  justify-content: ${({ justifyContentCenter }) => (justifyContentCenter ? 'center' : 'start')};
  height: ${({ height }) => `${height}em`};
  width: ${({ width }) => `${width}em`};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'regular')};
  font-size: ${({ size }) => size};
  -webkit-line-clamp: ${({ rows }) => rows || 1};
  overflow: hidden;
  line-clamp: ${({ rows }) => rows || 1};
  -webkit-box-orient: vertical;

  ${() => bpTheme.breakpoints.down('xs')} {
    font-size: ${({ size }) => (size === 'large' ? 'medium' : size !== 'small' ? 'small' : size)};
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    font-size: ${({ size }) => (size === 'large' ? 'medium' : size !== 'small' ? 'small' : size)};
  }
  ${() => bpTheme.breakpoints.down('md')} {
    font-size: ${({ size }) => (size === 'large' ? 'medium' : size !== 'small' ? 'small' : size)};
  }

  ${({ theme: { font }, color, subtitle }) => {
    if (color === 'primary') return { color: font.color.primary };
    if (color === 'secondary') return { color: font.color.secondary };
    if (color === 'tertiary') return { color: font.color.tertiary };
    if (color === 'danger') return { color: font.color.danger };
    if (color === 'warning') return { color: font.color.warning };
    if (color === 'success') return { color: font.color.success };
    if (color === 'white') return { color: font.color.white };
    if (color === 'blue') return { color: font.color.blue };
    if (subtitle) return { color: font.color.secondary };
    return {};
  }};
`;

export default TextWrapper;
