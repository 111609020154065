import { Reagent } from '@dewire/models/definitions/api-response/reagent';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';
import ReagentState from 'interfaces/reagent-state';

export const successfulReagentAddCase = (state: any, action: PayloadAction<FullGetResponse<Reagent>>) => ({
  ...state,
  reagents: {
    ...state.reagents,
    content: state.reagents.content.concat(action.payload.data.result),
    updateStatus: LoadingState.Succeeded,
  },
});

export const reagentUpdateCase = (
  state: any,
  loadingState: LoadingState,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  reagents: {
    ...state.reagents,
    updateStatus: loadingState,
    error: errorMessage,
  },
});

export const connectReagentLoadingCase = (
  state: any,
  loadingState: LoadingState,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  connectedreagents: {
    ...state.connectedreagents,
    updateStatus: loadingState,
    error: errorMessage,
  },
});

export const reagentLotLoadingCase = (
  state: any,
  sectionName: keyof ReagentState,
  loadingState: LoadingState,
  action: PayloadAction<FullGetResponse<any>> | undefined = undefined,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  [sectionName]: {
    ...state[sectionName],
    loadingStatus: loadingState,
    content: action ? action.payload : state.reagentLots.content,
    error: errorMessage,
  },
});
