import { createTheme } from '@mui/material';
import { DefaultTheme } from 'app/AppStyling';
import { useEffect, useState } from 'react';

export const bpTheme = createTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 900,
      md: 1200,
      lg: 1536,
      xl: 1920,
    },
  },
});

function getBreakpointValue(width: number): number {
  const breakPoints = DefaultTheme.breakpoints;
  switch (true) {
    case width < breakPoints.mobile:
      return breakPoints.xs; // xs
    case width < breakPoints.sm:
      return breakPoints.mobile; // mobile
    case width < breakPoints.md:
      return breakPoints.sm; // sm
    case width < breakPoints.lg:
      return breakPoints.md; // md
    case width < breakPoints.xl:
      return breakPoints.lg; // lg
    default:
      return breakPoints.xl; // xl
  }
}

export function useCurrentBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(getBreakpointValue(window.innerWidth));
  useEffect(() => {
    const isMounted = true;
    const timeoutDelay = 50;
    let timeoutId: NodeJS.Timeout;
    const resizeListener = () => {
      if (isMounted) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setBreakpoint(getBreakpointValue(window.innerWidth));
        }, timeoutDelay);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  return breakpoint;
}
