import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

interface HistoryTableProps {
  width?: string;
}

const HistoryTable = styled.div<HistoryTableProps>`
  position: relative;
  background-color: ${() => useTheme().font.color.white};
  ${({ width }) => width && `width: ${width};`}
`;
export default HistoryTable;
