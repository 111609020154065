import { DefaultTheme } from 'app/AppStyling';

interface HamburgerMenuIconProps {
  color?: string;
}

function HamburgerMenuIcon({ color = DefaultTheme.colors.white }: HamburgerMenuIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 24 24" fill={color}>
      <path d="M4 18L20 18" stroke={color} strokeWidth="2" strokeLinecap="round" fill={color} />
      <path d="M4 12L20 12" stroke={color} strokeWidth="2" strokeLinecap="round" fill={color} />
      <path d="M4 6L20 6" stroke={color} strokeWidth="2" strokeLinecap="round" fill={color} />
    </svg>
  );
}

export default HamburgerMenuIcon;
