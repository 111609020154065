import 'react-datepicker/dist/react-datepicker.css';

import InstrumentCycleCounter from '@dewire/models/definitions/api-response/instrument-cycle-counter';
import InstrumentCycleDaySummary from '@dewire/models/definitions/api-response/instrument-cycle-day-summary';
import { getInstrumentCycleHistory, getInstrumentTotalCycles } from 'api/Api';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import DashboardCard from 'components/cards/instrument-dashboard/DashboardCard';
import InstrumentHeader from 'components/instrument-header/InstrumentHeader';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DateInputField from 'components/selection/input/DateInputField';
import BackButton from 'components/styled-components/buttons/BackButton';
import Header3 from 'components/styled-components/headers/Header3';
import HeaderWithDateContainer from 'components/styled-components/headers/HeaderWithDateContainer';
import DateRangePicker from 'components/styled-components/selection/DateRangePicker';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import DateStampWrapper from 'components/styled-components/wrappers/DateStampWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import LatestCleanCycles from '../../../components/instrument-dashboard/LatestCleanCycles';
import TotalCycles from '../../../components/instrument-dashboard/TotalCycles';
import CycleRow from '../../../components/rows/instrument-dashboard/CycleRow';

const LogViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em;
`;
const RootContainer = styled.div<{ columns: number; rows: number }>`
  gap: 1em;
`;
const NavContainer = styled.div`
  margin: 1em 0;
`;
const TableWrapper = styled.div`
  width: 100%;
  padding: 1em;
`;
const Table = styled.div``;
const FlexOneContainer = styled.div`
  flex: 2;
  min-width: 10em;
  margin-top: 1em;
`;
const InstrumentLogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function InstrumentLogView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const headersState = useAppSelector((state) => state.headers);

  const [instrumentLogCycles, setinstrumentLogCycles] = useState<InstrumentCycleCounter>();
  const [instrumentLogHistory, setInstrumentLogHistory] = useState<InstrumentCycleDaySummary[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [apiError, setApiError] = useState('');
  const [expandedCycle, setExpandedCycle] = useState('');
  const [historyApiError, setHistoryApiError] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 90)));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const onChangeDate = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setExpandedCycle('');
  };

  const handleExpandRow = (isExpanded: boolean, cycleId: string) => {
    if (isExpanded) {
      setExpandedCycle('');
    } else {
      setExpandedCycle(cycleId);
    }
  };

  const fetchCycles = () => {
    if (id) {
      getInstrumentTotalCycles(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setinstrumentLogCycles(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
    } else navigate('/');
  };

  const fetchHistory = () => {
    if (id) {
      getInstrumentCycleHistory(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentLogHistory(res.data.result);
        } else {
          setHistoryApiError(res.status);
        }
      });
      setLoadingHistory(false);
    } else navigate('/');
  };

  useEffect(() => {
    fetchCycles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setApiError]);

  useEffect(() => {
    fetchHistory();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const instrumentLogHistoryView = instrumentLogHistory
    .filter((ilh) => {
      startDate.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59);
      return new Date(ilh.date) >= startDate && new Date(ilh.date) <= endDate;
    })
    .map((cycleLog) => (
      <CycleRow
        cycleLog={cycleLog}
        key={cycleLog.date.toLocaleString()}
        expandRowCallback={(open: boolean) => handleExpandRow(open, cycleLog.date.toLocaleString())}
        expanded={expandedCycle === cycleLog.date.toLocaleString()}
      />
    ));

  return (
    <LogViewContainer>
      {id && <InstrumentHeader instrumentId={id} />}
      <RootContainer columns={1} rows={0}>
        <div>
          <NavContainer>
            <nav>
              <BackButton onClick={() => navigate(-1)}>
                <ArrowBackIcon size="small" />
                <p>Instrument dashboard</p>
              </BackButton>
            </nav>
          </NavContainer>
          <TitleWrapper>
            <HeaderWithDateContainer>
              <Header3>Instrument log</Header3>
            </HeaderWithDateContainer>
          </TitleWrapper>
          <DashboardCard cardTitle="Latest clean cycles">
            <InstrumentLogContainer>
              {apiError === '' ? (
                <DateStampWrapper>
                  <TextWrapper color={TextColor.Tertiary}>{getTranslation('Last export')}</TextWrapper>
                  <TextWrapper color={TextColor.Tertiary}>
                    {instrumentLogCycles?.lastExport
                      ? dayjs(instrumentLogCycles.lastExport).format('YYYY-MM-DD HH:mm')
                      : '----'}
                  </TextWrapper>
                </DateStampWrapper>
              ) : (
                <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>
              )}
              <LatestCleanCycles />
            </InstrumentLogContainer>
          </DashboardCard>
          <FlexOneContainer>
            <DashboardCard cardTitle="Total cycles">
              <InstrumentLogContainer>
                <DateStampWrapper>
                  <TextWrapper subtitle>
                    Instrument configured :{' '}
                    {instrumentLogCycles?.instrumentConfigured
                      ? dayjs(instrumentLogCycles.instrumentConfigured).format('YYYY-MM-DD')
                      : ' ---'}
                  </TextWrapper>
                </DateStampWrapper>
                <TotalCycles />
              </InstrumentLogContainer>
            </DashboardCard>
          </FlexOneContainer>
          <FlexOneContainer>
            <DashboardCard cardTitle="Cycle log">
              <TableWrapper>
                <DateRangePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  monthsShown={2}
                  filterDate={(date) => date < new Date()}
                  customInput={<DateInputField value={`${startDate} ${endDate}`} onClick={undefined} />}
                  className="react-datepicker"
                />
                <HistoryTable>
                  <Table>
                    <TableHeader gridExpression="15% 25% 55% 7%" textAlign="center" style={{ padding: '1em 0' }}>
                      <p>{getTranslation('Date')}</p>
                      <p>{getTranslation('No. of samples')}</p>
                      <p>{getTranslation('No. of errors')}</p>
                      <div />
                    </TableHeader>
                    {loadingHistory ? (
                      <LoadingSpinner containerBased />
                    ) : (
                      <div>
                        {historyApiError ? (
                          <TextWrapper color={TextColor.Danger}>{historyApiError}</TextWrapper>
                        ) : (
                          instrumentLogHistoryView
                        )}
                      </div>
                    )}
                  </Table>
                </HistoryTable>
              </TableWrapper>
            </DashboardCard>
          </FlexOneContainer>
        </div>
      </RootContainer>
    </LogViewContainer>
  );
}

export default InstrumentLogView;
