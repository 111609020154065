import { QcMeasurmentDataPoint } from '@dewire/models/definitions/api-response/qc-detailed-result';
import FlagIcon from 'assets/icons/FlagIcon';
import QcOutOfRangeIndicatorIcon from 'assets/icons/QcOutOfRangeIndicatorIcon';
import InfoIconPopover from 'components/popovers/InfoIconPopover';
import Scale from 'components/scale/Scale';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { IQcDisplayRow } from 'config/IQcDisplayRow';
import { TextColor } from 'interfaces/common';
import { ScaleState } from 'interfaces/scale-state';
import { useState } from 'react';
import styled from 'styled-components';

import QcDetailsTableRow from '../styled-components/table/QcDetailsTableRow';

const WarningWrapper = styled(TextWrapper)`
  overflow: visible;
  cursor: pointer;
  margin-left: 0.5em;
`;

const QcDetailsTableStatusRow = styled(QcDetailsTableRow)<{ state: ScaleState; lastInGroup: boolean | undefined }>`
  ${({ state, theme }) => {
    if (state !== 'inRange') return { color: theme.font.color.danger };
    return {};
  }};
  ${({ lastInGroup, theme }) => {
    if (lastInGroup === undefined) return {};
    if (lastInGroup) return { 'border-bottom': theme.breakLine.hard };
    return { 'border-bottom': theme.breakLine.soft };
  }}
`;

interface IQcMeasurementDetailsProps {
  absoluteMeasurement: QcMeasurmentDataPoint;
  relativeMeasurement: QcMeasurmentDataPoint | undefined;
  row: IQcDisplayRow;
  nextRowGroup: number | undefined;
}

function QcMeasurementDetails({
  absoluteMeasurement,
  relativeMeasurement,
  row,
  nextRowGroup,
}: IQcMeasurementDetailsProps) {
  const [markerState, setMarkerState] = useState<ScaleState>('inRange');

  function formatUnit(unit: string): string {
    const splitedOnSubscriptStart = unit.split('^');
    if (splitedOnSubscriptStart.length !== 2) return unit;
    const splitedOnSubscriptEnd = splitedOnSubscriptStart[1].split('/');
    return unit.replace(`^${splitedOnSubscriptEnd[0]}`, `<sup>${splitedOnSubscriptEnd[0]}</sup>`);
  }

  return (
    <QcDetailsTableStatusRow state={markerState} lastInGroup={nextRowGroup ? row.group !== nextRowGroup : undefined}>
      <TextWrapper bold>{row.name}</TextWrapper>
      <p>{absoluteMeasurement.value ? absoluteMeasurement.value : '-'}</p>
      <TextWrapper color={TextColor.Primary} size="small" dangerouslySetInnerHTML={{ __html: formatUnit(row.unit) }} />
      <p>{relativeMeasurement ? `${relativeMeasurement.value}%` : null}</p>
      <p>
        <QcOutOfRangeIndicatorIcon valueState={markerState} />
      </p>
      <TextWrapper color={TextColor.Primary}>{absoluteMeasurement.referenceRange.low}</TextWrapper>
      <Scale
        value={absoluteMeasurement.value}
        limitHigh={absoluteMeasurement.referenceRange.high}
        limitLow={absoluteMeasurement.referenceRange.low}
        setState={setMarkerState}
        status={absoluteMeasurement.status}
      />
      <TextWrapper color={TextColor.Primary}>{absoluteMeasurement.referenceRange.high}</TextWrapper>
      <WarningWrapper>
        {absoluteMeasurement.errorFlag ? (
          <InfoIconPopover icon={<FlagIcon />} text={<p>{absoluteMeasurement.errorFlag.code}</p>} leftAlign={-12}>
            <b>{absoluteMeasurement.errorFlag.name}</b>
            <p>{absoluteMeasurement.errorFlag.description}</p>
            <p>{absoluteMeasurement.errorFlag.message}</p>
            <p>{absoluteMeasurement.errorFlag.action}</p>
          </InfoIconPopover>
        ) : null}
      </WarningWrapper>
    </QcDetailsTableStatusRow>
  );
}

export default QcMeasurementDetails;
