import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { TextColor } from 'interfaces/common';
import styled from 'styled-components';

const CycleItemRowElement = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em;
  justify-content: start;
`;
const CycleItemRowItem = styled.div`
  min-width: 10em;
  margin: 0.2em;
`;

interface CycleItemRowProps {
  title: string;
  value: string;
}

function CycleItemRow({ title, value }: CycleItemRowProps) {
  return (
    <CycleItemRowElement>
      <CycleItemRowItem>
        <TextWrapper color={TextColor.Secondary}>{title}</TextWrapper>
      </CycleItemRowItem>
      <CycleItemRowItem>
        <TextWrapper>{value}</TextWrapper>
      </CycleItemRowItem>
    </CycleItemRowElement>
  );
}

export default CycleItemRow;
