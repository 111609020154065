import { OriginTypes } from '@dewire/models/definitions/origin-types';

export default function returnOriginType(origin: OriginTypes) {
  switch (origin) {
    case 'ADMIN':
      return { primaryType: 'DISTRIBUTORS', secondaryType: 'CUSTOMERS' };
    case 'MARKET':
      return { primaryType: 'DISTRIBUTORS', secondaryType: 'CUSTOMERS' };
    case 'DISTRIBUTOR':
      return { primaryType: 'CUSTOMERS', secondaryType: 'SITES' };
    case 'CUSTOMER':
      return { primaryType: 'SITES', secondaryType: 'INSTRUMENTS' };
    case 'SITE':
      return { primaryType: 'SUBSITES', secondaryType: 'INSTRUMENTS' };
    default:
      return { primaryType: '', secondaryType: '' };
  }
}
