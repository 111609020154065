import { DefaultTheme } from 'app/AppStyling';
import styled from 'styled-components';

const IconContainer = styled.div`
  :hover {
    transform: scale(1.15);
  }
`;

interface InfoProps {
  color?: string;
}

function InfoIcon({ color = DefaultTheme.colors.primary }: InfoProps) {
  return (
    <IconContainer>
      <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.06 12C8.23333 12 8.37667 11.9433 8.49 11.83C8.60333 11.7167 8.66 11.5733 8.66 11.4V7.78C8.66 7.62 8.6 7.48333 8.48 7.37C8.36 7.25667 8.22 7.2 8.06 7.2C7.88667 7.2 7.74333 7.25667 7.63 7.37C7.51667 7.48333 7.46 7.62667 7.46 7.8V11.42C7.46 11.58 7.52 11.7167 7.64 11.83C7.76 11.9433 7.9 12 8.06 12ZM8 5.72C8.18667 5.72 8.34333 5.66 8.47 5.54C8.59667 5.42 8.66 5.26667 8.66 5.08C8.66 4.89333 8.59667 4.73333 8.47 4.6C8.34333 4.46667 8.18667 4.4 8 4.4C7.81333 4.4 7.65667 4.46667 7.53 4.6C7.40333 4.73333 7.34 4.89333 7.34 5.08C7.34 5.26667 7.40333 5.42 7.53 5.54C7.65667 5.66 7.81333 5.72 8 5.72ZM8 16C6.86667 16 5.81333 15.7967 4.84 15.39C3.86667 14.9833 3.02 14.42 2.3 13.7C1.58 12.98 1.01667 12.1333 0.61 11.16C0.203333 10.1867 0 9.13333 0 8C0 6.88 0.203333 5.83333 0.61 4.86C1.01667 3.88667 1.58 3.04 2.3 2.32C3.02 1.6 3.86667 1.03333 4.84 0.62C5.81333 0.206667 6.86667 0 8 0C9.12 0 10.1667 0.206667 11.14 0.62C12.1133 1.03333 12.96 1.6 13.68 2.32C14.4 3.04 14.9667 3.88667 15.38 4.86C15.7933 5.83333 16 6.88 16 8C16 9.13333 15.7933 10.1867 15.38 11.16C14.9667 12.1333 14.4 12.98 13.68 13.7C12.96 14.42 12.1133 14.9833 11.14 15.39C10.1667 15.7967 9.12 16 8 16ZM8 14.8C9.86667 14.8 11.4667 14.1333 12.8 12.8C14.1333 11.4667 14.8 9.86667 14.8 8C14.8 6.13333 14.1333 4.53333 12.8 3.2C11.4667 1.86667 9.86667 1.2 8 1.2C6.13333 1.2 4.53333 1.86667 3.2 3.2C1.86667 4.53333 1.2 6.13333 1.2 8C1.2 9.86667 1.86667 11.4667 3.2 12.8C4.53333 14.1333 6.13333 14.8 8 14.8Z"
          fill={color}
        />
      </svg>
    </IconContainer>
  );
}

export default InfoIcon;
