import { InstrumentReagent } from '@dewire/models/definitions/api-response/instrument-reagent';
import { ReagentLotWithReagentsResponse } from '@dewire/models/definitions/api-response/reagent-lot-with-reagents-response';
import { ReagentOverviewForInstrument } from '@dewire/models/definitions/api-response/reagent-overview-for-instrument';
import { ReagentWithPairedInstruments } from '@dewire/models/definitions/api-response/reagent-with-paired-instruments';
import { ReagentLot } from '@dewire/models/definitions/api-response/reagentlot';
import {
  getInstrumentReagentOverview,
  getReagentLots,
  getReagentLotWithReagents,
  getReagentOverviewForInstrument as getReagentOverviewForInstrumentSlice,
  getReagentPairedInstruments,
} from 'redux/slices/reagent.slice';
import { store } from 'redux/store';

import { ApiResponse } from '../../api/response';

export const getAllReagentLots = async (onFetched: (reagentLots: ReagentLot[]) => void, onError: () => void) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getReagentLots(headersState));
  const payload = response.payload as ApiResponse<ReagentLot[]>;
  if (response.meta.requestStatus === 'rejected' ?? payload === undefined) onError();
  else onFetched(payload.data.result);
};

export const getReagentLotWithReagentList = async (
  lotnumber: string,
  onFetched: (reagentLotWithReagents: ReagentLotWithReagentsResponse) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getReagentLotWithReagents({ session: headersState, lotnumber }));
  const payload = response.payload as ApiResponse<ReagentLotWithReagentsResponse>;

  if (response.meta.requestStatus === 'rejected' || payload === undefined || payload.status === 'error') onError();
  else onFetched(payload.data.result);
};

export const getReagentPairedInstrument = async (
  reagentId: string,
  onFetched: (reagentPairedInstruments: ReagentWithPairedInstruments) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getReagentPairedInstruments({ session: headersState, reagentId }));
  const payload = response.payload as ApiResponse<ReagentWithPairedInstruments>;

  if (response.meta.requestStatus === 'rejected' || payload === undefined || payload.status === 'error') onError();
  else onFetched(payload.data.result);
};

export const getInstrumentReagentList = async (
  onFetched: (instrumentReagents: InstrumentReagent[]) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getInstrumentReagentOverview(headersState));
  const payload = response.payload as ApiResponse<InstrumentReagent[]>;
  if (response.meta.requestStatus === 'rejected' || payload === undefined || payload.status === 'error') onError();
  else onFetched(payload.data.result);
};

export const getReagentOverviewForInstrument = async (
  instrumentId: string,
  onFetched: (reagents: ReagentOverviewForInstrument[]) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getReagentOverviewForInstrumentSlice({ session: headersState, instrumentId }));
  const payload = response.payload as ApiResponse<ReagentOverviewForInstrument[]>;
  if (response.meta.requestStatus === 'rejected' || payload === undefined || payload.status === 'error') onError();
  else onFetched(payload.data.result);
};
