import { StatusType } from '@dewire/models/definitions/status-type';
import { useTheme } from 'app/AppStyling';
import FlagIcon from 'assets/icons/FlagIcon';
import StatusIcon from 'assets/icons/StatusIcon';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { TextColor } from 'interfaces/common';
import InstrumentQcDisplay from 'interfaces/instrument-qc-display';
// import { ISamples } from 'interfaces/IQc'; // Add in prod
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 16em;
  background-color: ${() => useTheme().font.color.white};
  padding: 1em;
  margin: 1em 0;
  box-shadow: 1px 2px 11px #acacac47;
`;
const CardRow = styled.div``;
const CardContent = styled(CardRow)`
  justify-content: space-between;
  gap: 1em;
`;
const TextContainer = styled.div`
  margin: 1em 0;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const FlagWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const StatusWrapper = styled(FlexContainer)`
  justify-content: flex-start;
  gap: 1em;
`;
const DataBox = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;
const DataRow = styled.div`
  display: grid;
  grid-template-columns: 5em auto;

  &:not(:first-child) {
    border-top: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  }
`;
const DataCell = styled.div`
  margin: 0.5em 0 0.5em 0;
  &:not(:first-child) {
    border-left: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
    padding-left: 1em;
  }
`;

export interface IInstrument {
  id: string;
  distributor_id: string;
  sn: string;
  instrumentid: string;
  name: string;
  model: string;
  sw_version: string;
  updated_at: string;
  created_at: string;
  gatewayId: string;
}

export interface ISite {
  id: string;
  site_id: string;
  distributor_id: string;
  name: string;
  status: string;
  loc_longitude: string;
  loc_latitude: string;
  updated_at: string;
  created_at: string;
}

interface QcCardDisplayProps {
  qc: InstrumentQcDisplay;
  detailedView?: boolean;
}

function QcCardDisplay({
  qc: { sampleCreatedAt, status = 'unknown', numberOfFlags, displayName, lotNumber, controlBloodExpiry },
  detailedView = false,
}: QcCardDisplayProps) {
  const [iconType, setIconType] = useState<StatusType>('unknown');

  const controlBloodExpiryDate = dayjs(controlBloodExpiry).format('YYYY-MM-DD');

  useEffect(() => {
    setIconType(status);
  }, [status]);

  return (
    <Container>
      <TextWrapper size="large">{displayName}</TextWrapper>
      <StatusWrapper>
        <StatusIcon status={iconType} size="x-large" />
        {numberOfFlags !== 0 && (
          <div>
            <TextWrapper color={TextColor.Tertiary} size="x-small">
              Flags
            </TextWrapper>

            <FlagWrapper>
              <FlagIcon />
              <TextWrapper color={TextColor.Secondary} size="x-small">
                x {numberOfFlags}
              </TextWrapper>
            </FlagWrapper>
          </div>
        )}
      </StatusWrapper>
      <CardContent>
        <TextWrapper>{dayjs(sampleCreatedAt).format('YYYY-MM-DD HH:mm:ss')}</TextWrapper>
        {detailedView ? (
          <DataBox>
            <DataRow>
              <DataCell>{getTranslation('Lot no.')}</DataCell>
              <DataCell>{lotNumber}</DataCell>
            </DataRow>
            <DataRow>
              <DataCell>{getTranslation('Expiry')}</DataCell>
              <DataCell>{controlBloodExpiryDate}</DataCell>
            </DataRow>
          </DataBox>
        ) : (
          <TextContainer>
            <TextWrapper size="small" color={TextColor.Secondary}>
              Control Expires
            </TextWrapper>
            <TextWrapper size="small" color={TextColor.Tertiary}>
              {controlBloodExpiryDate}
            </TextWrapper>
          </TextContainer>
        )}
      </CardContent>
    </Container>
  );
}

export default QcCardDisplay;
