import { useTheme } from 'app/AppStyling';

interface PlusIconProps {
  color: string;
  size?: number;
}

function PlusIcon({ color, size = 50 }: PlusIconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4266_6473)">
        <path d="M6.88623 1V13" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
        <path d="M1 6.88611L13 6.88611" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_4266_6473">
          <rect width="14" height="14" fill={useTheme().font.color.white} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlusIcon;
