import styled from 'styled-components';

import NavigationButton from './NavigationButton';

export const ReversedNavigationButton = styled(NavigationButton)`
  justify-content: flex-end;
  margin: 0;
  transition: 1s;
  height: none;
  line-height: none;
  :hover {
    padding: 1em 0;
    transition: 1s;
  }
`;

export default ReversedNavigationButton;
