import { useTheme } from 'app/AppStyling';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

const DateRangePicker = styled(DatePicker)`
  width: 50%;
  min-width: 25em;
  padding: 1em;
  margin: 1em 1em 1em 0;
  box-shadow: inset 1px 1px 1px ${() => useTheme().shadow.secondary};
  border: none;
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
`;

export default DateRangePicker;
