import styled from 'styled-components';

const KeyHeader = styled.div`
  display: grid;
  grid-template-columns: 9% 91%;
  background-color: ${({ theme }) => theme.background.light};
  font-weight: bold;
  height: 3em;
  align-items: center;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 1em;
`;

export default KeyHeader;
