import { useTheme } from 'app/AppStyling';

function ReportIcon() {
  return (
    <svg
      width="30"
      height="30"
      stroke={useTheme().font.color.secondary}
      strokeWidth="0.75"
      fill={useTheme().font.color.secondary}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M56.5,49L56.5,49V1c0-0.6-0.4-1-1-1h-45c-0.6,0-1,0.4-1,1v14h2V2h43v46h-9c-0.6,0-1,0.4-1,1v9h-33V43h-2v16
            c0,0.6,0.4,1,1,1h35c0.3,0,0.5-0.1,0.7-0.3l10-10c0.1-0.1,0.1-0.2,0.2-0.3v-0.1C56.5,49.2,56.5,49.1,56.5,49z M46.5,50h6.6
            l-3.3,3.3l-3.3,3.3L46.5,50L46.5,50z"
          />
          <path
            d="M16.5,38h6h4v-2h-3V17c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v6h-5c-0.6,0-1,0.4-1,1v4h-5c-0.6,0-1,0.4-1,1v8
            c0,0.6,0.4,1,1,1h6H16.5z M17.5,18h4v18h-4V24V18z M11.5,25h4v11h-4v-7V25z M5.5,30h4v6h-4V30z"
          />
          <path
            d="M50.5,24V7c0-0.6-0.4-1-1-1h-21c-0.6,0-1,0.4-1,1v17c0,0.6,0.4,1,1,1h21C50.1,25,50.5,24.6,50.5,24z M48.5,12h-12V8h12V12
            z M34.5,8v4h-5c0-1.6,0-4,0-4H34.5z M29.5,14h5v9h-5C29.5,23,29.5,18.3,29.5,14z M36.5,23v-9h12v9H36.5z"
          />
          <rect x="28.5" y="28" width="21" height="2" />
          <rect x="28.5" y="33" width="21" height="2" />
          <rect x="28.5" y="38" width="21" height="2" />
          <rect x="14.5" y="6" width="6" height="2" />
          <rect x="14.5" y="11" width="9" height="2" />
          <rect x="14.5" y="43" width="7" height="2" />
          <rect x="24.5" y="43" width="7" height="2" />
          <rect x="34.5" y="43" width="7" height="2" />
          <rect x="14.5" y="48" width="7" height="2" />
          <rect x="24.5" y="48" width="7" height="2" />
          <rect x="34.5" y="48" width="7" height="2" />
          <rect x="14.5" y="53" width="7" height="2" />
          <rect x="24.5" y="53" width="7" height="2" />
          <rect x="34.5" y="53" width="7" height="2" />
        </g>
      </g>
    </svg>
  );
}

export default ReportIcon;
