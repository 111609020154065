import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const CardContainer = styled.div<{ width?: number; height?: number }>`
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  margin-bottom: 100vh;
  height: ${({ height }) => `${height}em`};
  width: ${({ width }) => `${width}em`};
`;

export default CardContainer;
