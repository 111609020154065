import { ReagentOverviewForInstrument as ReagentOverviewForInstrumentModel } from '@dewire/models/definitions/api-response/reagent-overview-for-instrument';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import NavContainer from 'components/nav/NavContainer';
import ReagentOverviewForInstrumentHeader from 'components/reagent/dashboard/ReagentOverviewForInstrumentHeader';
import NavigationLinkButton from 'components/styled-components/buttons/NavigationLinkButton';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getReagentOverviewForInstrument } from 'helpers/reagent/getters';
import getTranslation from 'helpers/translation/get-translation';
import getDate from 'helpers/utils/date-utils';
import { Paths, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import magnifyingGlass from '../../assets/icons/magnifying-glas.svg';
import ReagentOverviewForInstrumentRow from './ReagentOverviewForInstrumentRow';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;

const tableHeaders = ['Pairing date', 'Decoupling date', 'Container ID', 'Volume', 'Opening date', 'Expiry date'];

interface Filters {
  search: string;
  startDate: Date;
  endDate: Date;
}

function ReagentOverviewForInstrument() {
  const { instrumentId } = useParams();
  const [filter, setFilter] = useState<Filters>({
    search: '',
    startDate: getDate(-60),
    endDate: new Date(),
  });

  const [loading, setLoading] = useState(true);
  const [reagents, setReagents] = useState<ReagentOverviewForInstrumentModel[]>([]);
  const [filteredReagents, setFilteredReagents] = useState<ReagentOverviewForInstrumentModel[]>([]);

  useEffect(() => {
    if (loading && instrumentId) {
      getReagentOverviewForInstrument(
        instrumentId,
        (result: ReagentOverviewForInstrumentModel[]) => {
          setFilteredReagents(result);
          setReagents(result);
          setLoading(false);
        },
        () => {
          provideSnackbar(
            Status.Error,
            lodash.capitalize(Status.Error),
            'Unable to fetch reagent overview for instrument'
          );
          setLoading(false);
        }
      );
    }
  }, [loading, instrumentId]);

  const filterConditions = (reagent: ReagentOverviewForInstrumentModel) => {
    const { search } = filter;
    if (!search) return true;
    return reagent.containerId.toString().toLowerCase().includes(search.toLowerCase());
  };

  const applyFilters = () => {
    const filtered = reagents.filter(filterConditions);
    setFilteredReagents(filtered);
  };

  useMemo(() => {
    if (!loading) applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reagents, filter]);

  return (
    <ViewContainer flexdircolumn columns={0} rows={3}>
      <NavContainer>
        <NavigationLinkButton to={Paths.ReagentDashboardUrl}>
          <ArrowBackIcon size="small" />
          <p>Reagent dashboard</p>
        </NavigationLinkButton>
      </NavContainer>
      {instrumentId && <ReagentOverviewForInstrumentHeader instrumentId={instrumentId} />}
      <ToolbarContainer>
        <SearchBarContainer>
          <SearchField
            type="text"
            placeholder="Search"
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            iconUrl={magnifyingGlass}
          />
        </SearchBarContainer>
      </ToolbarContainer>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="20% 20% 15% 12% 17% 20%" textAlign="start">
            {tableHeaders.map((tableHeader: string) => (
              <p key={tableHeader}>{getTranslation(tableHeader)}</p>
            ))}
          </TableHeader>
          {loading ? (
            <LoadingSpinner containerBased />
          ) : (
            <div>
              {filteredReagents &&
                filteredReagents.map((reagent) => (
                  <ReagentOverviewForInstrumentRow key={reagent.containerId} reagent={reagent} />
                ))}
            </div>
          )}
        </Table>
      </HistoryTable>
    </ViewContainer>
  );
}

export default ReagentOverviewForInstrument;
