import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { FieldInput } from 'interfaces/field-input';

export function getCountryCodes(): FieldInput<string>[] {
  countries.registerLocale(en);
  const countryList = Object.entries(countries.getNames('en', { select: 'official' }));
  return countryList.map((entry) => ({ label: entry[1], id: entry[0] }));
}

export function findCountry(countryCode: string | undefined) {
  const countryCodes = getCountryCodes();
  return countryCodes.find((country) => countryCode === country.id) ?? { label: '', id: undefined }
}