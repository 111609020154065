import { setHeadersWithPayload } from 'redux/slices/headers.slice';
import { store } from 'redux/store';

import { saveStateToStorage } from './local-storage';

function initHeadersService() {
  store.subscribe(() => {
    const headersState = store.getState().headers;
    saveStateToStorage('accessToken', headersState.accessToken);
    saveStateToStorage('idToken', headersState.idToken);
    saveStateToStorage('refreshToken', headersState.refreshToken);
    saveStateToStorage('timestamp', headersState.timestamp);
    saveStateToStorage('accesses', headersState.accesses);
    saveStateToStorage('userName', headersState.userName);
  });
}

export const setAuthorizationandAccessToken = (newAccessToken: string, newAuthToken: string): Promise<void> =>
  new Promise((resolve) => {
    store.dispatch(
      setHeadersWithPayload({
        accessToken: newAccessToken,
        idToken: newAuthToken,
      })
    );
    resolve();
  });

export default initHeadersService;
