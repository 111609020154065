import { Link } from 'react-router-dom';
import styled from 'styled-components';

const InTableStatusLink = styled(Link)<{ $color: string; $hoverColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 1em;
  padding: 0.2em 1.2em;
  background-color: ${({ $color }) => $color};
  text-decoration: none;
  color: #000000;
  width: 1.5em;
  border-radius: 50px;
  :hover {
    background-color: ${({ $hoverColor }) => $hoverColor};
  }
`;

export default InTableStatusLink;
