import { useTheme } from 'app/AppStyling';

function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path
        d="M21.9836 33C18.9581 33 16.3712 31.9312 14.2227 29.7937C12.0742 27.6562 11 25.0638 11 22.0164C11 18.991 12.0742 16.3986 14.2227 14.2392C16.3712 12.0797 18.9581 11 21.9836 11C23.9347 11 25.6447 11.3946 27.1136 12.1839C28.5825 12.9731 29.854 14.0583 30.9282 15.4395V12.1181C30.9282 11.7892 31.0269 11.5207 31.2242 11.3124C31.4215 11.1041 31.6846 11 32.0135 11C32.3204 11 32.578 11.1041 32.7862 11.3124C32.9945 11.5207 33.0987 11.7892 33.0987 12.1181V18.7608C33.0987 19.1335 32.9726 19.4405 32.7205 19.6816C32.4684 19.9228 32.156 20.0433 31.7833 20.0433H25.1405C24.8336 20.0433 24.576 19.9447 24.3677 19.7474C24.1594 19.5501 24.0553 19.287 24.0553 18.9581C24.0553 18.6512 24.1594 18.3936 24.3677 18.1854C24.576 17.9771 24.8445 17.8729 25.1734 17.8729H29.5142C28.6592 16.6014 27.6124 15.5765 26.3737 14.7982C25.135 14.0199 23.6716 13.6308 21.9836 13.6308C19.6378 13.6308 17.6537 14.442 16.0314 16.0643C14.4091 17.6866 13.5979 19.6707 13.5979 22.0164C13.5979 24.3622 14.4091 26.3463 16.0314 27.9686C17.6537 29.5909 19.6378 30.4021 21.9836 30.4021C23.6059 30.4021 25.1076 29.9691 26.4888 29.1031C27.87 28.2372 28.9113 27.0807 29.6129 25.6338C29.7444 25.4365 29.9307 25.2611 30.1719 25.1076C30.4131 24.9542 30.6542 24.8774 30.8954 24.8774C31.4215 24.8774 31.7997 25.0473 32.0299 25.3871C32.2601 25.727 32.2656 26.1161 32.0463 26.5546C31.1694 28.5057 29.8266 30.0678 28.0179 31.2407C26.2093 32.4136 24.1978 33 21.9836 33Z"
        fill={useTheme().colors.primary}
      />
    </svg>
  );
}

export default RefreshIcon;
