import { InstrumentLatestCleaningCycles } from '@dewire/models/definitions/api-response/instrument-latest-cleaning-cycles';
import { getInstrumentLatestCleanCycles } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import dayjs from 'dayjs';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Status } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import CycleItem from './CycleItem';

const CleanCycleContainer = styled.div`
  width: 100%;
`;

const CyclesContainer = styled.div<{ isLoading: boolean }>`
  padding: 1em;
  background: ${() => useTheme().font.color.white};
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isLoading }) => (isLoading ? 'auto' : '1fr 1fr 1fr')};
  min-height: 13em;
  ${() => bpTheme.breakpoints.down('sm')} {
    grid-template-columns: ${({ isLoading }) => (isLoading ? 'auto' : '1fr 1fr')};
  }
  ${() => bpTheme.breakpoints.down('xs')} {
    grid-template-columns: auto;
  }
`;
const CycleColumn = styled.div`
  display: grid;
  grid-gap: 1em;
  border-right: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
  :last-child {
    border: none;
  }
  :not(:first-child) {
    margin-left: 1em;
  }

  ${() => bpTheme.breakpoints.down('sm')} {
    border-right: none;
    border-bottom: ${({ theme }) => theme.lineWidth} solid ${({ theme }) => theme.lineColor};
    :last-child {
      border-bottom: none;
    }
    :not(:first-child) {
      margin-left: 0;
      margin-top: 1em;
    }
  }
`;
const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
`;

function LatestCleanCycles() {
  const { id } = useParams();
  const headersState = useAppSelector((state) => state.headers);

  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState('');
  const [instrumentLatestCleanCycles, setinstrumentLatestCleanCycles] = useState<InstrumentLatestCleaningCycles>();

  const fetchCycles = () => {
    if (id) {
      getInstrumentLatestCleanCycles(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setinstrumentLatestCleanCycles(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
    }
  };

  useEffect(() => {
    fetchCycles();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (instrumentLatestCleanCycles || apiError !== '') setLoading(false);
  }, [instrumentLatestCleanCycles, apiError]);

  return (
    <CleanCycleContainer>
      <CyclesContainer isLoading={loading}>
        {loading ? (
          <SpinnerContainer>
            <LoadingSpinner containerBased />
          </SpinnerContainer>
        ) : (
          <>
            <CycleColumn>
              <CycleItem
                text="Clean empty"
                dateText={
                  instrumentLatestCleanCycles?.cleanEmpty
                    ? dayjs(instrumentLatestCleanCycles?.cleanEmpty).format('YYYY-MM-DD HH:mm')
                    : '----'
                }
              />
              <CycleItem
                text="Clean fill"
                dateText={
                  instrumentLatestCleanCycles?.cleanFill
                    ? dayjs(instrumentLatestCleanCycles?.cleanFill).format('YYYY-MM-DD HH:mm')
                    : '----'
                }
              />
            </CycleColumn>
            <CycleColumn>
              <CycleItem
                text="Clot prevention"
                dateText={
                  instrumentLatestCleanCycles?.clotPrevention
                    ? dayjs(instrumentLatestCleanCycles?.clotPrevention).format('YYYY-MM-DD HH:mm')
                    : '----'
                }
              />
              <CycleItem
                text="Clot removal"
                dateText={
                  instrumentLatestCleanCycles?.clotRemoval
                    ? dayjs(instrumentLatestCleanCycles?.clotRemoval).format('YYYY-MM-DD HH:mm')
                    : '----'
                }
              />
            </CycleColumn>
            <CycleColumn>
              <CycleItem
                text="Burn"
                dateText={
                  instrumentLatestCleanCycles?.burn
                    ? dayjs(instrumentLatestCleanCycles?.burn).format('YYYY-MM-DD HH:mm')
                    : '----'
                }
              />
            </CycleColumn>
          </>
        )}
      </CyclesContainer>
    </CleanCycleContainer>
  );
}

export default LatestCleanCycles;
