import { useTheme } from 'app/AppStyling';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const InTableLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 1em;
  padding: 0.2em 1em;
  background-color: ${() => useTheme().background.blue};
  text-decoration: none;
  width: fit-content;
  min-width: 5em;
  max-width: 16em;
  border-radius: 50px;
  color: #000000;
  :hover {
    background-color: ${() => useTheme().background.hover};
  }
`;

export default InTableLink;
