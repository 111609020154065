import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import styled from 'styled-components';

const Dimmis = styled(DimmedContainer)`
  z-index: 1001;
`;

function LoadingSpinnerModal() {
  return (
    <Dimmis>
      <LoadingSpinner inModal />
    </Dimmis>
  );
}

export default LoadingSpinnerModal;
