import InstrumentBackground, { BackgroundValue } from '@dewire/models/definitions/api-response/instrument-background';
import { getLatestBackground } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import {
  Divider,
  InfoTableContainer,
  InfoTableItemSlim,
  InfoTableRow,
} from 'components/styled-components/table/info-table-style';
import DateStampWrapper from 'components/styled-components/wrappers/DateStampWrapper';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import getTranslation from 'helpers/translation/get-translation';
import { Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

export const BackgroundMeasurementContainer = styled.div<{ isLoading: boolean }>`
  padding: 0 1em;
  background: ${() => useTheme().font.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  min-height: 256px;
  width: 100%;
`;
const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const BackgroundTableContainer = styled.div`
  display: flex;
  gap: 1em;
`;
const BackgroundSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Unit = styled.div`
  height: 2.45em;
  line-height: 2.45em;
`;
const ValueRow = styled.div`
  height: 1.3em;
  line-height: 1.3em;
`;
const Value = styled.div<{ passes: boolean }>`
  width: 3em;
  text-align: left;
  display: inline-block;
  ${({ theme: { font }, passes }) => {
    if (!passes) return { color: font.color.danger };
    return {};
  }};
`;
const InfoTableValue = styled(InfoTableItemSlim)`
  text-align: right;
`;
const InfoTableContainerSlim = styled(InfoTableContainer)`
  padding-left: 0;
  margin-right: 1em;
`;

function getValueTableRow(name: string, background: BackgroundValue | undefined) {
  return (
    <InfoTableRow>
      <InfoTableItemSlim>
        <TextWrapper
          color={(background?.value ?? 0) <= (background?.limit ?? 0) ? TextColor.Secondary : TextColor.Danger}
        >
          {name}
        </TextWrapper>
      </InfoTableItemSlim>
      <InfoTableValue>
        <Value passes={(background?.value ?? 0) <= (background?.limit ?? 0)}>{background?.value}</Value>
      </InfoTableValue>
    </InfoTableRow>
  );
}

function LatestBackground() {
  const { id } = useParams();
  const headersState = useAppSelector((state) => state.headers);

  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState('');
  const [latestBackground, setLatestBackground] = useState<InstrumentBackground>();

  const fetchBackground = () => {
    if (id)
      getLatestBackground(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setLatestBackground(res.data.result);
        } else {
          setApiError(res.status);
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchBackground();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <BackgroundContainer>
      <>
        <DateStampWrapper>
          <TextWrapper color={TextColor.Tertiary}>{getTranslation('Last export')}</TextWrapper>
          <TextWrapper color={TextColor.Tertiary}>
            {latestBackground?.date ? dayjs(latestBackground.date).format('YYYY-MM-DD HH:mm') : '----'}
          </TextWrapper>
        </DateStampWrapper>
        <BackgroundMeasurementContainer isLoading={loading}>
          {loading ? (
            <LoadingSpinner containerBased />
          ) : (
            <BackgroundTableContainer>
              <BackgroundSubContainer>
                <TextWrapper color={TextColor.Secondary}>{getTranslation('Latest background')}</TextWrapper>
                <InfoTableContainerSlim>
                  <ValueRow>{getValueTableRow('RBC', latestBackground?.redBloodCellConcentration)}</ValueRow>
                  <Divider />
                  <ValueRow>{getValueTableRow('WBC', latestBackground?.whiteBloodCellConcentration)}</ValueRow>
                  <Divider />
                  <ValueRow>{getValueTableRow('HGB', latestBackground?.hemoglobinConcentration)}</ValueRow>
                  <Divider />
                  <ValueRow>{getValueTableRow('PLT', latestBackground?.plateletConcentration)}</ValueRow>
                </InfoTableContainerSlim>
                {apiError !== '' && <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>}
              </BackgroundSubContainer>
              <BackgroundSubContainer>
                <TextWrapper color={TextColor.Secondary}>{getTranslation('Recommendation')}</TextWrapper>
                <Unit>
                  <TextWrapper color={TextColor.Secondary} size="small">
                    ≤ {latestBackground?.redBloodCellConcentration?.limit} (10<sup>12</sup>/L)
                  </TextWrapper>
                </Unit>
                <Unit>
                  <TextWrapper color={TextColor.Secondary} size="small">
                    ≤ {latestBackground?.whiteBloodCellConcentration?.limit} (10<sup>9</sup>/L)
                  </TextWrapper>
                </Unit>
                <Unit>
                  <TextWrapper color={TextColor.Secondary} size="small">
                    ≤ {latestBackground?.hemoglobinConcentration?.limit} (g
                    <sup />
                    /dL)
                  </TextWrapper>
                </Unit>
                <Unit>
                  <TextWrapper color={TextColor.Secondary} size="small">
                    ≤ {latestBackground?.plateletConcentration?.limit} (10<sup>9</sup>/L)
                  </TextWrapper>
                </Unit>
              </BackgroundSubContainer>
            </BackgroundTableContainer>
          )}
        </BackgroundMeasurementContainer>
      </>
    </BackgroundContainer>
  );
}

export default LatestBackground;
