import { Accesses } from '@dewire/models/definitions/api-response/accesses';
import CloudUploadIcon from 'assets/icons/CloudUploadIcon';
import MachineryIcon from 'assets/icons/MachineryIcon';
import OrganizationAdminIcon from 'assets/icons/OrganizationAdminIcon';
import ReagentScannerIcon from 'assets/icons/ReagentScannerIcon';
import ReportIcon from 'assets/icons/ReportIcon';
import UsersIcon from 'assets/icons/UsersIcon';
import locateEnvironment from 'helpers/environment/locate-environment';
import getTranslation from 'helpers/translation/get-translation';
import { Environments, Paths, Views } from 'interfaces/common';
import MenuItem from 'interfaces/menu-item';

const getMenuItems = (accesses: Accesses): MenuItem[] => {
  const menuItems = [];
  if (accesses.instrumentMonitoring) {
    menuItems.push({
      displayName: getTranslation(Views.Instruments),
      className: 'instruments',
      url: Paths.InstrumentsUrl,
      icon: MachineryIcon,
    });
  }
  if (
    locateEnvironment([Environments.Dev, Environments.Cont, Environments.Test, Environments.Staging]) &&
    accesses.instrumentMonitoring
  ) {
    menuItems.push({
      displayName: getTranslation(Views.EQA),
      className: 'eqaNavigation',
      url: Paths.EQAUrl,
      icon: ReportIcon,
    });
  }
  if (accesses.instrumentFileUpload) {
    menuItems.push({
      displayName: getTranslation(Views.FileUpload),
      className: 'fileUpload',
      url: Paths.FileUploadUrl,
      icon: CloudUploadIcon,
    });
  }
  if (accesses.organization.instruments.view) {
    menuItems.push({
      displayName: getTranslation(Views.Organization),
      className: 'organizationNavigation',
      url: Paths.OrganizationUrl,
      icon: OrganizationAdminIcon,
    });
  }
  if (accesses.reagentLotRegistration || accesses.reagentLotList) {
    menuItems.push({
      displayName: getTranslation(Views.Reagent),
      className: 'reagentNavigation',
      url: accesses.reagentLotList ? Paths.ReagentDashboardUrl : Paths.ReagentRegisterUrl,
      icon: ReagentScannerIcon,
    });
  }
  if (accesses.users) {
    menuItems.push({
      displayName: getTranslation(Views.Users),
      url: Paths.UsersUrl,
      icon: UsersIcon,
    });
  }
  return menuItems;
};

export default getMenuItems;
