import { ChartMeasurement } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { EqaSdiRaw } from '@dewire/models/definitions/api-response/eQA/eqa-sdi';

import { formatEQAMonth } from './format-eqa-date';

const formatGraphData = (graphDataMeasurements: EqaSdiRaw[] | ChartMeasurement[], pdf?: boolean) =>
  graphDataMeasurements.map((val) => ({
    ...val,
    date: { ...val.date, month: formatEQAMonth(val.date.month, pdf) },
  }));

export default formatGraphData;
