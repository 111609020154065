import { BouleUser } from '@dewire/models/definitions/api-response/boule-user';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';

const successfulUsersGetCase = (state: any, action: PayloadAction<FullGetResponse<BouleUser[]>>) => ({
  ...state,
  users: {
    ...state.users,
    content: action.payload.data.result,
    loadingStatus: LoadingState.Succeeded,
  },
});

export default successfulUsersGetCase;
