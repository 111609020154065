import { DropdownColumn, DropdownTitle } from 'components/styled-components/selection/Dropdown';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';

import FilterOption from './FilterOption';

interface FilterGroupProps {
  groupTitle?: string;
  filterOptions: string[] | undefined;
  filterOptionsNames?: string[];
  filterArray: string[];
  groupWidth?: number;
  onFilterCallback: (callbackValue: string) => void;
}

function FilterGroup({
  groupTitle = undefined,
  filterOptions,
  filterOptionsNames = [],
  filterArray,
  groupWidth = 10,
  onFilterCallback,
}: FilterGroupProps) {
  return (
    <DropdownColumn dropdownWidth={groupWidth}>
      {groupTitle && (
        <DropdownTitle>
          <TextWrapper bold>{groupTitle}</TextWrapper>
        </DropdownTitle>
      )}
      {filterOptions?.map((option: string, index: number) => (
        <FilterOption
          key={option}
          text={
            filterOptionsNames && filterOptionsNames.length === filterOptions.length
              ? filterOptionsNames[index]
              : option
          }
          isChecked={filterArray.map((filter) => filter.toLowerCase()).includes(option.toLowerCase())}
          selectCallback={() => onFilterCallback(option)}
        />
      ))}
    </DropdownColumn>
  );
}

export default FilterGroup;
