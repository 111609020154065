/* eslint-disable @typescript-eslint/no-unused-vars */
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTheme } from 'app/AppStyling';
import SearchIcon from 'assets/icons/SearchIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import useSearchInputStyles from 'components/styled-components/mui-styling/SearchInputFieldStyling';
import useTextFieldStyles from 'components/styled-components/mui-styling/TextFieldStyling';
import { FieldInput } from 'interfaces/field-input';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const OuterContainer = styled.div`
  height: 115px;
`;

interface SearchInputFieldProps<T extends string | number> {
  label: string;
  value: FieldInput<T>;
  valueArray: FieldInput<T>[];
  width?: number;
  disabled?: boolean;
  error?: boolean;
  helper?: string;
  loading: boolean;
  onChangeCallback: (searchInput: FieldInput<T>) => void;
}

function SearchInputField<T extends string | number>({
  label,
  value,
  valueArray,
  width = 18,
  disabled = false,
  error = false,
  helper = '',
  loading,
  onChangeCallback,
}: SearchInputFieldProps<T>) {
  const searchInputClasses = useSearchInputStyles();
  const textFieldClasses = useTextFieldStyles({ background: useTheme().background.light });

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  function handleOnChange(_value: FieldInput<T>) {
    if (_value) {
      onChangeCallback(_value);
    }
  }

  return (
    <OuterContainer>
      <Autocomplete
        disabled={disabled}
        id="autocomplete"
        freeSolo
        disableClearable={value.id === undefined}
        sx={{ margin: '1em', width: `${width}em` }}
        options={loading ? [] : valueArray}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        onFocus={onFocus}
        onBlur={onBlur}
        className={error && !focused ? searchInputClasses.searchInputError : textFieldClasses.textField}
        value={value.label ?? ''}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading -- Can't use mui library and this rule at the same time without specifying 250 props for the component
            {...params}
            label={label}
            helperText={error && !focused ? helper : ''}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {loading ? <LoadingSpinner containerBased inButton /> : <SearchIcon />}
                </InputAdornment>
              ),
            }}
          />
        )}
        onChange={(e, v) => {
          if (v && typeof v !== 'string') {
            handleOnChange(v);
          } else {
            handleOnChange({ label: '', id: undefined });
          }
        }}
      />
    </OuterContainer>
  );
}

export default SearchInputField;
