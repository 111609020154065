import { LoadingState } from 'interfaces/common';

const singleUserUpdateCase = (
  state: any,
  loadingState: LoadingState,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  singleUser: {
    ...state.singleUser,
    updateStatus: loadingState,
    error: errorMessage,
  },
});

export default singleUserUpdateCase;
