import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface PdfWrapperProps {
  text: string;
  pdfRef: string;
  textColor: string;
}

const PdfWrapperText = styled(Link)<{ textinputcolor: string }>`
  color: ${({ textinputcolor }) => textinputcolor};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

function PdfWrapper({ text, pdfRef, textColor }: PdfWrapperProps) {
  return (
    <PdfWrapperText to={pdfRef} target="_blank" rel="noreferrer" textinputcolor={textColor}>
      {text}
    </PdfWrapperText>
  );
}

export default PdfWrapper;
