import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

const NavigationButton = styled.button<{
  active?: boolean;
  inContainer?: boolean;
  buttonColor?: string;
  margin?: boolean;
}>`
  display: flex;
  width: ${({ inContainer }) => (inContainer ? '8em' : '11em')};
  height: 3em;
  font-weight: bold;
  line-height: 3em;
  margin: ${({ margin }) => (margin ? '1em' : '0em')};
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-items: center;
  gap: 1em;
  color: ${({ buttonColor }) => buttonColor ?? useTheme().colors.primary};
  text-decoration: none;
`;

export default NavigationButton;
