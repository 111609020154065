import { Accesses } from '@dewire/models/definitions/api-response/accesses';
import ReagentNoOfPairedInstruments from 'components/reagent/dashboard/ReagentNoOfPairedInstruments';
import ReagentsOverview from 'components/reagent/dashboard/ReagentsOverview';
import locateEnvironment from 'helpers/environment/locate-environment';
import { Environments, Paths as paths } from 'interfaces/common';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import EQAView from 'views/EQA/EQAView';
import FileUploadView from 'views/file-upload/FileUploadView';
import InstrumentLogView from 'views/instrument-dashboard/cycles/InstrumentLogView';
import InstrumentView from 'views/instrument-dashboard/InstrumentView';
import QcView from 'views/instrument-dashboard/qcHistory/QcView';
import InstrumentsStatusView from 'views/instruments-status/InstrumentsStatusView';
import AdminView from 'views/organization/AdminView';
import ReagentDashboardView from 'views/reagents/ReagentDashboardView';
import ReagentOverviewForInstrumentView from 'views/reagents/ReagentOverviewForInstrument';
import ReagentRegistrationView from 'views/reagents/ReagentRegistrationView';
import AddUserView from 'views/users/AddUserView';
import UserPermissionsView from 'views/users/UserPermissionsView';
import UsersView from 'views/users/UsersView';

const getDefaultUrl = (accesses: Accesses): string => {
  if (accesses.instrumentMonitoring) return paths.InstrumentsUrl;
  if (accesses.instrumentFileUpload) return paths.FileUploadUrl;
  if (accesses.organization.instruments.edit) return paths.OrganizationUrl;
  if (accesses.assayFileUpload) return paths.FileUploadUrl;
  if (accesses.reagentLotRegistration) return paths.ReagentRegisterUrl;
  if (accesses.reagentLotList) return paths.ReagentDashboardUrl;

  return '';
};

function Router() {
  const location = useLocation();
  const headersState = useAppSelector((state) => state.headers);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <Routes>
      <Route path={paths.InstrumentsUrl} element={<InstrumentsStatusView />} />
      <Route path={paths.SingleInstrumentUrl} element={<InstrumentView />} />
      {!locateEnvironment([Environments.Prod]) && <Route path={paths.EQAUrl} element={<EQAView />} />}
      <Route path={`${paths.SingleInstrumentUrl}/qc`} element={<QcView />} />
      <Route path={`${paths.SingleInstrumentUrl}/instrument-log`} element={<InstrumentLogView />} />
      <Route path={paths.ProfileUrl} element={<UserPermissionsView isProfileView />} />
      <Route path={paths.AddUserUrl} element={<AddUserView />} />
      <Route path={paths.FileUploadUrl} element={<FileUploadView />} />
      <Route path={paths.OrganizationUrl} element={<AdminView />} />
      {headersState.accesses.reagentLotList && (
        <>
          <Route path={paths.ReagentDashboardUrl} element={<ReagentDashboardView />} />
          <Route path={paths.ReagentDashboardLotUrl} element={<ReagentsOverview />} />
          <Route path={paths.ReagentDashboardPairedInstrumentUrl} element={<ReagentNoOfPairedInstruments />} />
          <Route path={paths.ReagentOnInstrumentUrl} element={<ReagentOverviewForInstrumentView />} />
        </>
      )}
      {headersState.accesses.reagentLotRegistration && (
        <Route
          path={paths.ReagentRegisterUrl}
          element={<ReagentRegistrationView hasReagentDashboardAccess={headersState.accesses.reagentLotList} />}
        />
      )}
      {headersState.accesses.users && (
        <>
          <Route path={paths.UsersUrl} element={<UsersView />} />
          <Route path={`${paths.UsersUrl}/:userId`} element={<UserPermissionsView />} />
        </>
      )}
      <Route path="*" element={<Navigate replace to={getDefaultUrl(headersState.accesses)} />} />
    </Routes>
  );
}

export default Router;
