import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DefaultTheme } from 'app/AppStyling';

export interface StyleProps {
  background: string;
}

const useTextFieldStyles = makeStyles<Theme, StyleProps>(() => ({
  textField: {
    backgroundColor: ({ background }) => background,
    borderBottom: DefaultTheme.border.gray,
    color: DefaultTheme.font.color.secondary,
    [`&.MuiInputBase-root.Mui-disabled`]: {
      backgroundColor: ({ background }) => background,
    },
    [`&.MuiInputLabel-root`]: {
      margin: 0,
    },
    [`&.css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root`]: {
      backgroundColor: ({ background }) => background,
      [`&.Mui-focused`]: {
        backgroundColor: ({ background }) => background,
      },
    },
  },
  textFieldError: {
    color: DefaultTheme.colors.error.dark,
    backgroundColor: ({ background }) => background,
  },
  textFieldLabel: {
    color: DefaultTheme.font.color.tertiary,
    [`&:hover, &.Mui-focusVisible`]: {
      backgroundColor: DefaultTheme.font.color.tertiary,
    },
    [`&.Mui-active`]: {
      backgroundColor: DefaultTheme.font.color.tertiary,
    },
    [`&.MuiInputBase-root.Mui-disabled`]: {
      color: DefaultTheme.font.color.black,
    },
  },
}));

export default useTextFieldStyles;
