import { useTheme } from 'app/AppStyling';

function CheckIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.73094 13C5.64126 13 5.55904 12.9851 5.48431 12.9552C5.40957 12.9253 5.33483 12.8729 5.26009 12.7982L1.20179 8.73991C1.06726 8.60538 1 8.44096 1 8.24664C1 8.05232 1.06726 7.88789 1.20179 7.75336C1.33632 7.61883 1.49327 7.55157 1.67265 7.55157C1.85202 7.55157 2.00897 7.61883 2.1435 7.75336L5.73094 11.3408L13.87 3.20179C14.0045 3.06726 14.1652 3 14.352 3C14.5389 3 14.6996 3.06726 14.8341 3.20179C14.9686 3.33632 15.0359 3.49701 15.0359 3.68386C15.0359 3.8707 14.9686 4.03139 14.8341 4.16592L6.20179 12.7982C6.12706 12.8729 6.05232 12.9253 5.97758 12.9552C5.90284 12.9851 5.82063 13 5.73094 13Z"
        fill={useTheme().icon.light}
      />
    </svg>
  );
}

export default CheckIcon;
