import { QcDetails } from '@dewire/models/definitions/api-response/qc-detail';
import IQcTeaser from '@dewire/models/definitions/api-response/qc-teaser'; // TODO: Fix
import { getInstrumentQcDetails } from 'api/Api';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import StatusIcon from 'assets/icons/StatusIcon';
import Flex from 'components/flex/Flex';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import ExpandableTableRow from 'components/styled-components/table/ExpandableTableRow';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import dayjs from 'dayjs';
import { Status, TextColor } from 'interfaces/common';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import QcRowDetails from './QcRowDetails';

const StyledTableRow = styled.div<{ qc: IQcTeaser; nextDate: Date }>`
  ${({ qc, nextDate, theme }) => {
    const newDate = dayjs(nextDate).unix();
    const currentDate = dayjs(qc.date).unix();

    if (newDate !== currentDate) return { borderBottom: theme.breakLine.hard };
    return { borderBottom: theme.breakLine.soft };
  }};
`;

const SpinnerContainer = styled.div`
  margin-bottom: 1em;
`;

interface IQcRowProps {
  qc: IQcTeaser;
  nextDate: Date;
  expanded: boolean;
  expandRowCallback: (isExpanded: boolean) => void;
}

function QcRow({ qc, nextDate, expanded, expandRowCallback }: IQcRowProps) {
  const { id } = useParams();
  const headersState = useAppSelector((state) => state.headers);

  const [qcDetails, setQcDetails] = useState({} as QcDetails);
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getQcDetails = () => {
    setIsLoading(true);
    getInstrumentQcDetails(id ?? '', qc.id, headersState).then((res) => {
      if (res.status === Status.Ok && res.data.result) {
        setQcDetails(res.data.result);
      } else {
        setApiError(res.status);
      }
      setIsLoading(false);
    });
  };

  return (
    <StyledTableRow nextDate={nextDate} qc={qc}>
      <ExpandableTableRow
        textAlign="center"
        gridExpression="10% 20% 20% 45% 7%"
        open={expanded}
        onClick={() => {
          expandRowCallback(expanded);
          if (!Object.keys(qcDetails).length) {
            getQcDetails();
          }
        }}
      >
        <Flex gap="5px" justifyContent="center">
          <StatusIcon size="medium" status={qc.status} />
        </Flex>
        <p>{qc.profile}</p>
        <p>{qc.method}</p>
        <p>{dayjs(qc.date).format('YYYY-MM-DD HH:mm:ss')}</p>
        <Flex gap="5px" justifyContent="center" alignItems="center">
          <ExpandArrowIcon open={expanded} />
        </Flex>
      </ExpandableTableRow>
      {expanded &&
        (isLoading ? (
          <SpinnerContainer>
            <LoadingSpinner containerBased />
          </SpinnerContainer>
        ) : (
          <>
            {Object.keys(qcDetails).length ? (
              <QcRowDetails samples={qcDetails} />
            ) : (
              <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>
            )}{' '}
          </>
        ))}
    </StyledTableRow>
  );
}

export default QcRow;
