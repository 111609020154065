import { Customer } from '@dewire/models/definitions/api-response/customer';
import { InstrumentProductModel } from '@dewire/models/definitions/api-response/instrument-product-models';
import { InstrumentFormInput } from '@dewire/models/definitions/form-input/instrument-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getCustomerFieldInput, getDistributorFieldInput } from 'helpers/finders/field-input-finders';
import { addNewInstrument } from 'helpers/organization/add';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, OrganizationParents, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const NewInstrumentTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
const ModelDropdownWrapper = styled.div`
  margin: 1em;
`;
const SiteDropdownWrapper = styled.div<{ customerViewAccess: boolean }>`
  margin: -9px 1em;
  margin-top: ${({ customerViewAccess }) => (customerViewAccess ? '0' : '1em')};
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;

interface AddInstrumentModalProps {
  parentToFilter: {
    distributorId: number | undefined;
    customerId: number | undefined;
    siteId: number | undefined;
    parentType: OrganizationParents;
  };
  onCloseCallback: () => void;
}

function AddInstrumentModal({ parentToFilter, onCloseCallback }: AddInstrumentModalProps) {
  const [headersState, adminState, distributorsState, customersState, sitesState, modelsState] = useAppSelector(
    (state) => [
      state.headers,
      state.admin,
      state.admin.distributors.content,
      state.admin.customers.content,
      state.admin.sites.content,
      state.admin.models.content,
    ]
  );

  const hasDistributorViewAccess = headersState.accesses.organization.distributors.view;
  const hasCustomerViewAccess = headersState.accesses.organization.customers.view;
  const tempDistributors = customersState.map((_customer: Customer) => ({
    label: _customer.distributorName,
    id: _customer.formData.distributorId,
  }));
  const distributors = lodash.uniqBy(tempDistributors, 'id');
  const instrumentsState = adminState.instruments.content;
  const [instrumentInstance, setInstrumentInstance] = useState<Partial<InstrumentFormInput>>(
    {
      distributorId: parentToFilter.distributorId,
      customerId: parentToFilter.customerId,
      siteId: parentToFilter.siteId,
    } ?? {}
  );
  const [error, setError] = useState(true);
  const [firstChange, setFirstChange] = useState(true);
  const [addInstrumentActive, setAddInstrumentActive] = useState(false);
  const [availableSites, setAvailableSites] = useState([{ label: '', id: -1 }]);
  const [availableCustomers, setAvailableCustomers] = useState([{ label: '', id: -1 }]);

  const models = useMemo(
    () => modelsState.map((model: InstrumentProductModel) => ({ label: model.name, id: model.id })),
    [modelsState]
  );

  const shouldDisableSiteInput = () => {
    if (hasDistributorViewAccess)
      return instrumentInstance.distributorId === undefined || instrumentInstance.customerId === undefined;
    if (hasCustomerViewAccess) return instrumentInstance.customerId === undefined;
    return false;
  };

  const shouldDisableCustomerInput = () => {
    if (hasDistributorViewAccess) return instrumentInstance.distributorId === undefined;
    return false;
  };

  const onAddInstrument = () => {
    // the disabled flag promises that instrumentInstance is a valid InstrumentFormInput when the button is clickable
    addNewInstrument(
      instrumentInstance as InstrumentFormInput,
      () => {
        provideSnackbar(
          Status.Success,
          lodash.capitalize(Status.Success),
          `Instrument "${instrumentInstance.serialNumber}" was successfully added.`
        );
        onCloseCallback();
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to add instrument.');
        onCloseCallback();
      }
    );
  };

  const onSiteChange = (newSite: number | undefined) => {
    if (newSite !== undefined) {
      const distributorId = sitesState.find((site) => site.formData.id === newSite)?.formData.distributorId;
      setInstrumentInstance({ ...instrumentInstance, siteId: newSite, distributorId });
    }
  };

  const findInstrumentModelNameById = (id: number) => modelsState.find((model) => model.id === id)?.name ?? '';

  const listDistributorId = () => {
    if (parentToFilter.parentType !== OrganizationParents.Unknown) return parentToFilter.distributorId;
    return instrumentInstance.distributorId;
  };

  const listCustomerId = () => {
    if (
      parentToFilter.parentType === OrganizationParents.Customer ||
      parentToFilter.parentType === OrganizationParents.Site
    )
      return parentToFilter.customerId;
    return instrumentInstance.customerId;
  };

  const listSiteId = () => {
    if (parentToFilter.parentType === OrganizationParents.Site) return parentToFilter.siteId;
    return instrumentInstance.siteId;
  };

  // Set available customers
  useEffect(() => {
    const id = instrumentInstance.distributorId;
    if (id) {
      const newAvailableCustomers = customersState
        .filter((_customer) => _customer.formData.distributorId === id)
        .map((_customer) => ({ label: _customer.name, id: _customer.formData.id }));
      setAvailableCustomers(newAvailableCustomers);
    } else {
      setAvailableCustomers(customersState.map((customer) => ({ label: customer.name, id: customer.formData.id })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersState, instrumentInstance.distributorId]);

  // Set available sites
  useEffect(() => {
    if (!hasCustomerViewAccess) {
      setAvailableSites(
        sitesState.map((_site) => ({
          label: _site.name,
          id: _site.formData.id ?? -1,
        }))
      );
    } else {
      const id = instrumentInstance.customerId;
      const newAvailableCustomer = customersState.find((_customer) => _customer.formData.id === id);
      if (id && newAvailableCustomer) {
        const newAvailableSites = sitesState
          .filter((_site) => _site.customerId === id)
          .map((_site) => ({
            label: _site.name,
            id: _site.formData.id ?? -1,
          }));
        setAvailableSites(newAvailableSites);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersState, instrumentInstance.customerId]);

  // Input validation
  useEffect(() => {
    if (
      lodash.isEqual(instrumentInstance, {}) ||
      Object.values(instrumentInstance).every((property) => property === '' || property === undefined)
    ) {
      setFirstChange(true);
    } else {
      setFirstChange(false);
    }
    if (
      !validateInput({
        value: instrumentInstance.serialNumber,
        params: ['required', 'number'],
        instruments: instrumentsState,
      }).error &&
      !validateInput({ value: instrumentInstance.distributorId?.toString(), params: ['requiredId'] }).error &&
      !validateInput({ value: instrumentInstance.model?.toString(), params: ['requiredId'] }).error &&
      !(
        instrumentInstance.customerId !== undefined &&
        validateInput({ value: instrumentInstance.siteId?.toString(), params: ['requiredId'] }).error
      )
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [instrumentInstance, instrumentsState]);

  useEffect(() => {
    setAddInstrumentActive(adminState.instruments.updateStatus === LoadingState.Loading);
  }, [adminState.instruments.updateStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      <AddCardContainer>
        <NewInstrumentTitleWrapper>
          New instrument
          <CloseButton closeCallback={onCloseCallback} />
        </NewInstrumentTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Serial number *')}
              filled
              value={instrumentInstance.serialNumber ?? ''}
              onChangeCallback={(newSerialNumber: string) =>
                setInstrumentInstance({ ...instrumentInstance, serialNumber: newSerialNumber })
              }
              error={
                !firstChange &&
                validateInput({
                  value: instrumentInstance.serialNumber,
                  params: ['required', 'number'],
                  instruments: instrumentsState,
                }).error
              }
              helper={
                validateInput({
                  value: instrumentInstance.serialNumber,
                  params: ['required', 'number'],
                  instruments: instrumentsState,
                }).errorMessage
              }
            />
            <ModelDropdownWrapper>
              <DropdownInput
                label={getTranslation('Model *')}
                value={{ label: instrumentInstance.model ?? '', id: instrumentInstance.instrumentProductModelId }}
                valueArray={models}
                checkbox
                onChangeCallback={(subModelId: number | undefined) =>
                  setInstrumentInstance({
                    ...instrumentInstance,
                    model: findInstrumentModelNameById(subModelId ?? -1),
                    instrumentProductModelId: subModelId,
                  })
                }
                error={
                  !firstChange &&
                  validateInput({ value: instrumentInstance.model?.toString(), params: ['requiredId'] }).error
                }
                helper="Please select a model"
              />
            </ModelDropdownWrapper>
          </DropdownInputContainer>
          {hasDistributorViewAccess && (
            <InputContainer>
              <SearchInputField
                label={getTranslation('Distributor *')}
                value={getDistributorFieldInput(distributorsState, listDistributorId())}
                valueArray={distributors}
                onChangeCallback={(newDistributor: FieldInput<number>) => {
                  setInstrumentInstance({
                    ...instrumentInstance,
                    distributorId: newDistributor.id,
                    customerId: undefined,
                    siteId: undefined,
                  });
                }}
                loading={false}
                error={
                  !firstChange &&
                  validateInput({ value: instrumentInstance.distributorId?.toString(), params: ['requiredId'] }).error
                }
                helper={getTranslation('Please select a distributor')}
              />
            </InputContainer>
          )}
          <DropdownInputContainer>
            {hasCustomerViewAccess && (
              <SearchInputField
                label={getTranslation('Customer')}
                value={getCustomerFieldInput(customersState, listCustomerId())}
                valueArray={availableCustomers}
                disabled={shouldDisableCustomerInput()}
                onChangeCallback={(newCustomer: FieldInput<number>) =>
                  setInstrumentInstance({ ...instrumentInstance, customerId: newCustomer.id, siteId: undefined })
                }
                loading={false}
              />
            )}
            <SiteDropdownWrapper customerViewAccess={hasCustomerViewAccess}>
              <DropdownInput
                label={getTranslation('Site')}
                value={
                  availableSites.find((inputValue) => inputValue.id === listSiteId()) ?? { label: '', id: undefined }
                }
                valueArray={availableSites}
                checkbox
                disabled={shouldDisableSiteInput()}
                onChangeCallback={(newSite: number | undefined) => onSiteChange(newSite)}
                error={
                  instrumentInstance.customerId !== undefined &&
                  validateInput({ value: instrumentInstance.siteId?.toString(), params: ['requiredId'] }).error
                }
                helper={getTranslation('Please select a site')}
              />
            </SiteDropdownWrapper>
          </DropdownInputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <PrimaryButton disabled={error || addInstrumentActive} onClick={() => onAddInstrument()}>
            {addInstrumentActive ? <LoadingSpinner containerBased inButton /> : getTranslation('Add')}
          </PrimaryButton>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default AddInstrumentModal;
