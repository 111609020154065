import { bpTheme } from 'helpers/window/use-current-breakpoint';
import styled from 'styled-components';

const Header2 = styled.h2`
  font-weight: bold;
  font-size: x-large;

  ${() => bpTheme.breakpoints.down('md')} {
    font-size: large;
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    font-size: medium;
  }
  ${() => bpTheme.breakpoints.down('xs')} {
    font-size: small;
  }
`;

export default Header2;
