import styled from 'styled-components';

const MeasurementItem = styled.div<{ margin?: number }>`
  width: 2.5em;
  display: flex;
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  margin-left: ${({ margin }) => `${margin}em`};
`;

export default MeasurementItem;
