import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import styled, { useTheme } from 'styled-components';

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${() => useTheme().font.color.white};
  padding: 2em;
  gap: 1em;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
`;
const DetailTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;

interface ReagentOverviewForInstrumentHeaderProps {
  instrumentId: string;
}

function ReagentOverviewForInstrumentHeader({ instrumentId }: ReagentOverviewForInstrumentHeaderProps) {
  return (
    <DetailsHeader>
      <h4>Reagent overview for instrument</h4>
      <DetailsContainer>
        <DetailTextWrapper>
          <TextWrapper bold>Instrument ID:</TextWrapper>
          <TextWrapper>{instrumentId}</TextWrapper>
        </DetailTextWrapper>
      </DetailsContainer>
    </DetailsHeader>
  );
}

export default ReagentOverviewForInstrumentHeader;
