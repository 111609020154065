import { LoadingState } from 'interfaces/common';
import EQAState from 'interfaces/eqa-state';

// State as 'any' due to Typescript not finding WritableDraft<object> nor Draft<object> in the project

const eQALoadingCase = (
  state: any,
  sectionName: keyof EQAState,
  loadingState: LoadingState,
  errorMessage: string | undefined = undefined
) => ({
  ...state,
  [sectionName]: {
    ...state[sectionName],
    loadingStatus: loadingState,
    error: errorMessage,
  },
});

export default eQALoadingCase;
