import styled, { useTheme } from 'styled-components';

const QrCodeContainer = styled.div`
  height: 20em;
  width: 20em;
  border: 2px dashed ${({ theme }) => theme.lineColor};
  background-color: ${({ theme }) => theme.background.light};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    border: 2px solid ${() => useTheme().lineColor};
  }
`;

export default QrCodeContainer;
