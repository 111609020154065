import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import getTranslation from 'helpers/translation/get-translation';
import styled from 'styled-components';

const Table = styled.div`
  padding: 1em;
  text-align: center;
`;

interface FileUploadProps {
  fileUploadHistory: JSX.Element[];
  historyIsLoading: boolean;
}

function FileUploadHistory({ fileUploadHistory, historyIsLoading }: FileUploadProps) {
  return (
    <HistoryTable>
      <Table>
        <TableHeader gridExpression="25% 20% 25% 20% 10%" textAlign="left">
          <p className="col1">{getTranslation('Exported file')}</p>
          <p className="col4">{getTranslation('User')}</p>
          <p className="col5">{getTranslation('Uploaded')}</p>
          <p className="col6">{getTranslation('File name')}</p>
          <div />
        </TableHeader>
        {historyIsLoading ? <LoadingSpinner containerBased /> : <div>{fileUploadHistory}</div>}
      </Table>
    </HistoryTable>
  );
}

export default FileUploadHistory;
