import { InstrumentReagent } from '@dewire/models/definitions/api-response/instrument-reagent';
import InfiniteScrollList from 'components/lists/InfiniteScrollList';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import InstrumentReagentRow from 'components/rows/reagent/InstrumentReagentRow';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getInstrumentReagentList } from 'helpers/reagent/getters';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnifyingGlass from '../../../assets/icons/magnifying-glas.svg';

const Table = styled.div`
  padding: 1.5em;
  text-align: start;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const ToolbarContainerWithWrap = styled(ToolbarContainer)`
  justify-content: flex-start;
  ${() => bpTheme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const tableHeaders = [
  'Instrument ID',
  'Container ID',
  'Lot Number',
  'Distributor',
  'Customer',
  'Site',
  'Type',
  'Brand',
];

interface ReagentLotOverviewFilters {
  search: string;
}

function InstrumentReagentOverview() {
  const reagentLotState = useAppSelector((state) => state.reagent.reagentInstrument.content);
  const [instruments, setInstruments] = useState<InstrumentReagent[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredInstruments, setFilteredInstruments] = useState<InstrumentReagent[]>([]);

  const [filter, setFilter] = useState<ReagentLotOverviewFilters>({
    search: '',
  });

  useEffect(() => {
    if (loading) {
      getInstrumentReagentList(
        (result: InstrumentReagent[]) => {
          setInstruments(result);
          setFilteredInstruments(result);
          setLoading(false);
        },
        () => {
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch reagent lots.');
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (reagentLotState.length !== 0) setInstruments(reagentLotState);
  }, [reagentLotState]);

  const applySearchFilter = (reagentLot: InstrumentReagent) => {
    const { search } = filter;
    const lowerCaseSearch = search.toLowerCase();

    return (
      !search ||
      reagentLot.customer.name.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.site.name.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.distributor.name.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.lotNumber.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.containerId.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.instrumentId.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.type.toLowerCase().includes(lowerCaseSearch) ||
      reagentLot.brand.toLowerCase().includes(lowerCaseSearch)
    );
  };

  const mapInstruments = () => {
    if (!filteredInstruments) return [];
    return filteredInstruments?.map((lot) => <InstrumentReagentRow key={lot.instrumentId} instrumentReagent={lot} />);
  };

  useMemo(() => {
    if (!loading) setFilteredInstruments(instruments.filter(applySearchFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instruments, filter]);

  return (
    <>
      <ToolbarContainerWithWrap>
        <FlexContainer>
          <SearchBarContainer>
            <SearchField
              type="text"
              placeholder="Search"
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
              iconUrl={magnifyingGlass}
            />
          </SearchBarContainer>
        </FlexContainer>
      </ToolbarContainerWithWrap>
      <HistoryTable>
        <Table>
          <TableHeader gridExpression="12% 12% 12% 12% 12% 12% 12% 12%" textAlign="start">
            {tableHeaders.map((tableHeader: string) => (
              <p key={tableHeader}>{getTranslation(tableHeader)}</p>
            ))}
          </TableHeader>
          {loading ? <LoadingSpinner containerBased /> : <InfiniteScrollList items={mapInstruments()} />}
        </Table>
      </HistoryTable>
    </>
  );
}

export default InstrumentReagentOverview;
