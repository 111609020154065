import { StatusType } from '@dewire/models/definitions/status-type';
import { icon, LatLngTuple, LeafletMouseEvent } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { DefaultTheme, useTheme } from 'styled-components';

import Pin from '../../assets/icons/Pin';

const MarkerIcon = (status: StatusType, active: boolean, number: number, theme: DefaultTheme) => {
  const IconPin = <Pin active={active} status={status} theme={theme} number={number} />;
  const iconHtml = `data:image/svg+xml;utf8,${encodeURIComponent(`${renderToStaticMarkup(IconPin)}`)}`;
  return icon({ iconUrl: iconHtml, iconAnchor: [10, 25], className: '', iconSize: [37, 37] });
};

interface SiteMarkerProps {
  coordinates: LatLngTuple;
  setInstrumentsCallback: (selectedInstrument: number) => void;
  instrumentListIndex: number;
  numberOfInstruments: number;
  clickCallback: (index: number) => void;
  status: StatusType;
  active: boolean;
}

function SiteMarker({
  coordinates,
  setInstrumentsCallback,
  instrumentListIndex,
  numberOfInstruments,
  clickCallback,
  status,
  active,
}: SiteMarkerProps) {
  const theme = useTheme();

  function handleMouseOut(event: LeafletMouseEvent) {
    // this function wraps setInstrumentsCallback(-1) and in the process prevents a flickering bug on cards
    // it also requires the QcCardAction to use the onMouseLeave event to call setInstrumentsCallback(-1)
    const { relatedTarget } = event.originalEvent;
    if (relatedTarget instanceof Element) {
      const element = event.originalEvent.relatedTarget as HTMLElement;
      // element will either be a child of Leaflet Map or our custom instrument card
      // all leaflet map elements form the lib has 'leaflet-*' in their class name
      if (element.className.includes('leaflet')) setInstrumentsCallback(-1);
    }
  }

  return (
    <Marker
      position={coordinates}
      icon={MarkerIcon(status, active, numberOfInstruments, theme)}
      eventHandlers={{
        mouseover: () => setInstrumentsCallback(instrumentListIndex),
        mouseout: (event) => handleMouseOut(event),
        click: () => clickCallback(instrumentListIndex),
      }}
    />
  );
}
export default SiteMarker;
