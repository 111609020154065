import Session from '@dewire/models/definitions/api-response/session';
import { ReagentFormInput } from '@dewire/models/definitions/form-input/reagent-form-input';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addReagent as addReagentWithApi,
  connectReagent as connectReagentWithApi,
  getAllReagentLots as getReagentLotsFromApi,
  getAllReagentsForLot as getAllReagentLotWithReagentsFromApi,
  getInstrumentReagentOverview as getInstrumentReagentOverviewFromApi,
  getReagentOverviewForInstrument as getReagentOverviewForInstrumentFromApi,
  getReagentPairedInstruments as getReagentPairedInstrumentsFromApi,
} from 'api/reagent';
import { LoadingState } from 'interfaces/common';
import ReagentState from 'interfaces/reagent-state';
import {
  connectReagentLoadingCase,
  reagentLotLoadingCase,
  reagentUpdateCase,
  successfulReagentAddCase,
} from 'redux/resolvers/reagent/reagent-cases';

const initialState: ReagentState = {
  reagents: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  connectedReagents: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  reagentLots: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  reagentLotWithReagents: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  reagentPairedInstruments: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  reagentInstrument: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const addReagent = createAsyncThunk(
  'factory/addReagent',
  async (payload: { session: Session; reagent: ReagentFormInput }) =>
    addReagentWithApi(payload.session, payload.reagent)
);

export const connectReagent = createAsyncThunk(
  'factory/connectReagent',
  async (payload: { encryptedPayload: string; jwt: string }) =>
    connectReagentWithApi(payload.encryptedPayload, payload.jwt)
);

export const getReagentLots = createAsyncThunk('factory/reagentlots', async (session: Session) =>
  getReagentLotsFromApi(session)
);

export const getInstrumentReagentOverview = createAsyncThunk(
  'factory/reagents/instrument-reagent-overview',
  async (session: Session) => getInstrumentReagentOverviewFromApi(session)
);

export const getReagentOverviewForInstrument = createAsyncThunk(
  'factory/reagent-overview-for-instrument/:instrumentId',
  async (payload: { session: Session; instrumentId: string }) => getReagentOverviewForInstrumentFromApi(payload)
);

export const getReagentLotWithReagents = createAsyncThunk(
  'factory/reagentlot/:lotnumber',
  async (payload: { session: Session; lotnumber: string }) =>
    getAllReagentLotWithReagentsFromApi(payload.session, payload.lotnumber)
);

export const getReagentPairedInstruments = createAsyncThunk(
  'factory/reagents/:reagentId',
  async (payload: { session: Session; reagentId: string }) =>
    getReagentPairedInstrumentsFromApi(payload.session, payload.reagentId)
);

export const getInstrumentReagents = createAsyncThunk(
  'factory/instrumentReagent',
  async (payload: { session: Session; lotnumber: string }) =>
    getAllReagentLotWithReagentsFromApi(payload.session, payload.lotnumber)
);

export const reagentSlice = createSlice({
  name: 'reagent',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addReagent.pending, (state) => reagentUpdateCase(state, LoadingState.Loading))
      .addCase(addReagent.fulfilled, (state, action) => successfulReagentAddCase(state, action))
      .addCase(addReagent.rejected, (state, action) =>
        reagentUpdateCase(state, LoadingState.Failed, action.error.message)
      )
      .addCase(connectReagent.pending, (state) => connectReagentLoadingCase(state, LoadingState.Loading))
      .addCase(connectReagent.fulfilled, (state) => connectReagentLoadingCase(state, LoadingState.Succeeded))
      .addCase(connectReagent.rejected, (state, action) =>
        connectReagentLoadingCase(state, LoadingState.Failed, action.error.message)
      )
      .addCase(getReagentLots.pending, (state) => reagentLotLoadingCase(state, 'reagentLots', LoadingState.Loading))
      .addCase(getReagentLots.fulfilled, (state, action) =>
        reagentLotLoadingCase(state, 'reagentLots', LoadingState.Succeeded, action)
      )
      .addCase(getReagentLots.rejected, (state, action) =>
        reagentLotLoadingCase(state, 'reagentLots', LoadingState.Failed, action.error.message as any)
      )
      .addCase(getReagentLotWithReagents.pending, (state) =>
        reagentLotLoadingCase(state, 'reagentLotWithReagents', LoadingState.Loading)
      )
      .addCase(getReagentLotWithReagents.fulfilled, (state, action) =>
        reagentLotLoadingCase(state, 'reagentLotWithReagents', LoadingState.Succeeded, action)
      )
      .addCase(getReagentLotWithReagents.rejected, (state, action) =>
        reagentLotLoadingCase(state, 'reagentLotWithReagents', LoadingState.Failed, action.error.message as any)
      )
      .addCase(getReagentPairedInstruments.pending, (state) =>
        reagentLotLoadingCase(state, 'reagentPairedInstruments', LoadingState.Loading)
      )
      .addCase(getReagentPairedInstruments.fulfilled, (state, action) =>
        reagentLotLoadingCase(state, 'reagentPairedInstruments', LoadingState.Succeeded, action)
      )
      .addCase(getReagentPairedInstruments.rejected, (state, action) =>
        reagentLotLoadingCase(state, 'reagentPairedInstruments', LoadingState.Failed, action.error.message as any)
      );
  },
});

export default reagentSlice.reducer;
