import { Accesses } from '@dewire/models/definitions/api-response/accesses';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loadStateFromStorage } from 'helpers/local-storage-service/local-storage';
import { LoadingState } from 'interfaces/common';

interface HeaderState {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  accesses: Accesses;
  timestamp: number;
  userName: string;
  status: string;
  error: string | null;
}

const initialState: HeaderState = {
  accessToken: loadStateFromStorage('accessToken') ?? '',
  idToken: loadStateFromStorage('idToken') ?? '',
  refreshToken: loadStateFromStorage('refreshToken') ?? '',
  timestamp: loadStateFromStorage('timestamp') ?? 0,
  accesses: loadStateFromStorage('accesses') ?? {
    fileUpload: false,
    serviceTechnicianHeader: false,
    adminPortal: false,
    instrumentBrowser: false,
  },
  userName: loadStateFromStorage('userName') ?? 'Default',
  status: LoadingState.Idle,
  error: null,
};

export const headersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setHeadersWithPayload: (state, action: PayloadAction<object>) => ({ ...state, ...action.payload }),
    setAccesses: (state, action: PayloadAction<Accesses>) => ({ ...state, accesses: action.payload }),
    setUserName: (state, action: PayloadAction<string>) => ({ ...state, userName: action.payload }),
  },
});

export const { setHeadersWithPayload, setAccesses, setUserName } = headersSlice.actions;

export default headersSlice.reducer;
