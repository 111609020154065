import { useTheme } from 'app/AppStyling';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import NavigationLinkButton from 'components/styled-components/buttons/NavigationLinkButton';
import getTranslation from 'helpers/translation/get-translation';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div<{ left: number; minimumWidth: number }>`
  cursor: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${() => useTheme().font.color.white};
  border-radius: 0.5em;
  padding: 2em 2em;
  min-width: ${({ minimumWidth }) => `${minimumWidth}em`};
  min-height: 10em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  gap: 1em;
  right: 0;
  text-align: left;
  left: ${({ left }) => `${left}em`};
  top: 2em;
  z-index: 100;
`;
const Container = styled.div`
  position: relative;
`;
const IconContainer = styled.div<{ isTexted?: boolean }>`
  display: flex;
  gap: 0.5em;
  cursor: pointer;
  width: fit-content;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: 1px solid ${({ isTexted }) => (isTexted ? useTheme().colors.danger.iconDetails : 'transparent')};
  }
`;

interface InfoIconPopoverProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  text?: React.ReactNode | string;
  leftAlign?: number;
  minWidth?: number;
  link?: string;
  linkColor?: string;
}

function InfoIconPopover({
  children,
  icon,
  text = '',
  leftAlign = -10,
  minWidth = 22,
  link = '',
  linkColor = '',
}: InfoIconPopoverProps) {
  const dropdownRef = useRef<any>(null);
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    function clickOutsideDropdownHandler(e: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target) && displayModal) {
        setDisplayModal(false);
      }
    }

    document.addEventListener('mousedown', clickOutsideDropdownHandler);
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropdownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef, displayModal]);

  return (
    <Container ref={dropdownRef}>
      <IconContainer onClick={() => setDisplayModal((toggled) => !toggled)} isTexted={text !== ''}>
        {icon}
        {text}
      </IconContainer>
      {displayModal && (
        <ModalContainer left={leftAlign} minimumWidth={minWidth}>
          {children}
          {link !== '' && (
            <NavigationLinkButton to={`/instrument/${link}`} $inContainer={false} $buttonColor={linkColor}>
              <p>{getTranslation('Instrument')}</p>
              <ArrowBackIcon size="small" reversed arrowColor={linkColor} />
            </NavigationLinkButton>
          )}
        </ModalContainer>
      )}
    </Container>
  );
}

export default InfoIconPopover;
