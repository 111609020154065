import Session from '@dewire/models/definitions/api-response/session';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getGeneratedReport as getGeneratedReportFromApi,
  getGraphData as getGraphDataFromApi,
  getReportAsPdf as getReportPdfFromApi,
  getSdiHistory as getSdiHistoryFromApi,
  getSelectionKeys as getSelectionKeysFromApi,
} from 'api/eqa';
import { LoadingState } from 'interfaces/common';
import EQAState from 'interfaces/eqa-state';
import eQALoadingCase from 'redux/resolvers/eqa/general-cases';

const initialState: EQAState = {
  selectionKeys: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
  generatedReport: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
  graphData: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
  reportPdf: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
  sdiHistory: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
};

export const getSelectionKeys = createAsyncThunk(
  'eqa/getSelectionKeys',
  async (payload: { id: string; session: Session }) => getSelectionKeysFromApi(payload.id, payload.session)
);

export const getReport = createAsyncThunk(
  'eqa/getReportDetails',
  async (payload: { serialNumber: string; lotKitNumber: string; month: number; year: number; session: Session }) =>
    getGeneratedReportFromApi(
      {
        serialNumber: payload.serialNumber,
        lotKitNumber: payload.lotKitNumber,
        month: payload.month,
        year: payload.year,
      },
      payload.session
    )
);

export const getGraphData = createAsyncThunk(
  'eqa/getGraphData',
  async (payload: {
    serialNumber: string;
    key: string;
    level: string;
    year: number;
    month: number;
    session: Session;
  }) =>
    getGraphDataFromApi(payload.serialNumber, payload.key, payload.level, payload.year, payload.month, payload.session)
);

export const getReportPdf = createAsyncThunk(
  'eqa/getReportPdf',
  async (payload: { serialNumber: string; lotKitNumber: string; year: number; month: number; session: Session }) =>
    getReportPdfFromApi(payload.serialNumber, payload.lotKitNumber, payload.year, payload.month, payload.session)
);

export const getSdiHistory = createAsyncThunk(
  'eqa/getSdiHistory',
  async (payload: { serialNumber: string; month: number; year: number; session: Session }) =>
    getSdiHistoryFromApi(payload.serialNumber, payload.month, payload.year, payload.session)
);

export const eqaSlice = createSlice({
  name: 'eqa',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get selection keys
      .addCase(getSelectionKeys.pending, (state) => eQALoadingCase(state, 'selectionKeys', LoadingState.Loading))
      .addCase(getSelectionKeys.fulfilled, (state) => eQALoadingCase(state, 'selectionKeys', LoadingState.Succeeded))
      .addCase(getSelectionKeys.rejected, (state, action) =>
        eQALoadingCase(state, 'selectionKeys', LoadingState.Succeeded, action.error.message)
      )

      // Get report
      .addCase(getReport.pending, (state) => eQALoadingCase(state, 'generatedReport', LoadingState.Loading))
      .addCase(getReport.fulfilled, (state) => eQALoadingCase(state, 'generatedReport', LoadingState.Succeeded))
      .addCase(getReport.rejected, (state, action) =>
        eQALoadingCase(state, 'generatedReport', LoadingState.Succeeded, action.error.message)
      )

      // Get graph data
      .addCase(getGraphData.pending, (state) => eQALoadingCase(state, 'graphData', LoadingState.Loading))
      .addCase(getGraphData.fulfilled, (state) => eQALoadingCase(state, 'graphData', LoadingState.Succeeded))
      .addCase(getGraphData.rejected, (state, action) =>
        eQALoadingCase(state, 'graphData', LoadingState.Succeeded, action.error.message)
      )

      // Get report pdf
      .addCase(getReportPdf.pending, (state) => eQALoadingCase(state, 'reportPdf', LoadingState.Loading))
      .addCase(getReportPdf.fulfilled, (state) => eQALoadingCase(state, 'reportPdf', LoadingState.Succeeded))
      .addCase(getReportPdf.rejected, (state, action) =>
        eQALoadingCase(state, 'reportPdf', LoadingState.Succeeded, action.error.message)
      )

      // Get SDI history
      .addCase(getSdiHistory.pending, (state) => eQALoadingCase(state, 'sdiHistory', LoadingState.Loading))
      .addCase(getSdiHistory.fulfilled, (state) => eQALoadingCase(state, 'sdiHistory', LoadingState.Succeeded))
      .addCase(getSdiHistory.rejected, (state, action) =>
        eQALoadingCase(state, 'sdiHistory', LoadingState.Succeeded, action.error.message)
      );
  },
});
