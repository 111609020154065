import { DefaultTheme, useTheme } from 'app/AppStyling';
import CrossIcon from 'assets/icons/CrossIcon';
import styled from 'styled-components';

const IconWrapper = styled.div`
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  :hover {
    background: ${() => useTheme().background.medium};
  }
`;

interface CloseButtonProps {
  closeCallback: () => void;
  buttonColor?: string;
}

function CloseButton({ closeCallback, buttonColor = DefaultTheme.icon.dark }: CloseButtonProps) {
  return (
    <IconWrapper onClick={() => closeCallback()}>
      <CrossIcon color={buttonColor} />
    </IconWrapper>
  );
}

export default CloseButton;
