const DefaultTheme = {
  breakpoints: {
    xs: 600,
    mobile: 768,
    sm: 900,
    md: 1200,
    lg: 1536,
    xl: 1920,
  },
  lineColor: '#dcdcdc',
  lineWidth: '1px',
  lineWidthBold: '2px',
  hoverBackground: '#e9eef2',
  breakLine: {
    hard: '1px solid #929292',
    soft: '1px solid #dae1e7',
  },
  font: {
    size: {
      small: '12px',
      medium: '16px',
      large: '18px',
    },
    weight: {
      bold: 'bold',
    },
    color: {
      primary: '#03223a',
      secondary: '#3e5d74',
      tertiary: '#668399',
      danger: '#c93a51',
      error: '#AA162E',
      warning: '#805f00',
      success: '#007053',
      white: '#ffffff',
      black: '#000000',
      gray: '#81909c',
      uploadSuccess: '#00BE8C',
      uploadFailed: '#EE2A4A',
      blue: '#0048B2',
    },
  },
  border: {
    gray: '#6e8294',
    blue: '#66a3ff',
  },
  shadow: {
    primary: 'rgba(0, 0, 0, 0.35)',
    secondary: '#acacac47',
  },
  icon: {
    blue: '#236bc5',
    dark: '#698296',
    light: '#94a8b8',
    facebook: '#1877f2',
    linkedIn: '#0077b5',
    check: '#78b13f',
    uploadSuccess: '#00BE8C',
    uploadFailed: '#EE2A4A',
    QCUploadSuccessNoAssay: '#FFCA30',
    failedLightRed: '#FDE7EB',
    failedDarkRed: '#EE2A4A',
    successLightBlue: '#E5F0FF',
    successDarkBlue: '#0048B2',
  },
  colors: {
    primary: '#0167ff',
    secondary: '#b2d1ff',
    tertiary: '#ebf3ff',
    blue: '#0048b2',
    red: '#EE2A4A',
    lightblue: '#F0F6FF',
    white: '#ffffff',
    transparent: 'transparent',
    danger: {
      iconDetails: '#ee2a4a',
      icon: '#f7a1af',
      iconBackground: '#fde7eb',
      pin: '#f7a1af',
      pinBorder: '#ee2a4a',
    },
    warning: {
      iconDetails: '#ffca30',
      icon: '#ffe38c',
      iconBackground: '#fff1c7',
      pin: '#ffe38c',
      pinBorder: '#ffca30',
    },
    success: {
      iconDetails: '#00Be8c',
      icon: '#8af5cd',
      iconBackground: '#d4f7ee',
      pin: '#89dcbe',
      pinBorder: '#68a68f',
    },
    unknown: {
      iconDetails: '#668399',
      icon: '#c2cdd6',
      iconBackground: '#9badbc',
      pin: '#c2cdd6',
      pinBorder: '#668399',
    },
    error: {
      dark: '#aa162e',
      delete: '#c8102e',
      light: '#d32f2f',
      lighter: '#ffdddd',
    },
  },
  background: {
    lightest: '#ffffff',
    light: '#f9fafb',
    medium: '#dae1e7',
    dark: '#03223a',
    blue: '#e5f0ff',
    green: '#D4F7EE',
    red: '#FDE7EB',
    lightBlue: '#f4fbff',
    hover: '#cce0ff',
    hoverGreen: '#6AE4C4',
    hoverRed: '#FFC5CF',
    hoverLight: '#F0F0F0',
  },
};

function useTheme() {
  return DefaultTheme;
}

export { DefaultTheme, useTheme };
