import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const selectUser = (state: RootState) => state.users;
export const selectFiles = (state: RootState) => state.files;
export const selectEQA = (state: RootState) => state.eqa;
export const selectAdmin = (state: RootState) => state.admin;
export const selectSnackbar = (state: RootState) => state.snackbar;
export const selectReagent = (state: RootState) => state.reagent;
