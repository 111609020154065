import { EqaReportResponse } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { EqaSdiHistory } from '@dewire/models/definitions/api-response/eQA/eqa-sdi';
import { useTheme } from 'app/AppStyling';
import PdfMeasurementCard from 'components/cards/eqa/PdfMeasurementCard';
import ReportDetailsCard from 'components/cards/eqa/ReportDetailsCard';
import ReportGuideCard from 'components/cards/eqa/ReportGuideCard';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import TableHeader from 'components/styled-components/table/TableHeader';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { getGeneratedReport, getSdiHistory } from 'helpers/eqa/getters';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { setAuthorizationandAccessToken as setAuthorizationAndAccessToken } from 'helpers/local-storage-service/headers-service';
import getTranslation from 'helpers/translation/get-translation';
import { EQAValues, EQAValuesAsArray, LoadingState, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const PdfContent = styled.div`
  width: min-content;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: ${() => useTheme().font.color.white};
`;
const ReportGuideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  break-before: page;
  margin: 2.5em 8em;
`;
const ReportGuideTitle = styled(TextWrapper)`
  padding-left: 2em;
  background: ${() => useTheme().background.light};
`;
const ReportGuide = styled.div`
  padding-left: 2em;
`;
const ReportHistoryHeader = styled(TableHeader)`
  padding: 0.5em 0;
  margin: 0 10em;
  background: ${() => useTheme().background.medium};
  @media print {
    -webkit-print-color-adjust: exact; // keeps background color when printed
  }
`;
const TitleItem = styled.div<{ margin?: boolean }>`
  width: 2.5em;
  margin-left: ${({ margin }) => (margin ? '1.5em' : '0')};
  display: flex;
  align-items: flex-start;
  text-align: center;
`;

function PDFView() {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accesstoken');
  const authToken = searchParams.get('authtoken');
  const serialNumber = searchParams.get('serialnumber');
  const month = Number(searchParams.get('month'));
  const year = Number(searchParams.get('year'));
  const lotKitNumber = searchParams.get('lotkitnumber');
  const eQAState = useAppSelector((state) => state.eqa);

  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState<EqaReportResponse>();
  const [sdiHistory, setSdiHistory] = useState<EqaSdiHistory>();

  const onGenerateReport = () => {
    if (serialNumber && lotKitNumber && month && year) {
      getGeneratedReport(
        serialNumber,
        lotKitNumber,
        month,
        year,
        (result: EqaReportResponse) => {
          setReport(result);
        },
        () => {
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch report.');
        }
      );

      getSdiHistory(
        serialNumber,
        year,
        month,
        (result: EqaSdiHistory) => {
          setSdiHistory(result);
        },
        () => {
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch report.');
        }
      );
    } else {
      provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Invalid input.');
    }
  };

  useEffect(() => {
    const setAuthToken = async () => {
      if (accessToken && authToken) {
        await setAuthorizationAndAccessToken(accessToken, authToken);
        onGenerateReport();
      }
    };
    setAuthToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, authToken]);

  useEffect(() => {
    setLoading(
      eQAState.generatedReport.loadingStatus === LoadingState.Loading ||
        eQAState.sdiHistory.loadingStatus === LoadingState.Loading
    );
  }, [eQAState.generatedReport, eQAState.sdiHistory]);

  return (
    <PdfContent>
      {loading && <LoadingSpinner containerBased />}
      {!loading && report && report.reportDetails && report.measurements && serialNumber !== '' && (
        <ReportDetailsCard report={report} serialNumber={serialNumber ?? ''} isPdf />
      )}
      <ReportHistoryHeader gridExpression="9% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7%" textAlign="center">
        <TitleItem margin>{getTranslation('Level')}</TitleItem>
        {EQAValuesAsArray.map((eQAKey) => (
          <TitleItem key={EQAValues[eQAKey]}>{getTranslation(EQAValues[eQAKey])}</TitleItem>
        ))}
      </ReportHistoryHeader>
      {!loading &&
        report &&
        report.measurements &&
        sdiHistory &&
        report.measurements.map((rep) => (
          <PdfMeasurementCard reportMeasurement={rep} sdiHistory={sdiHistory} key={rep.key} />
        ))}
      <ReportGuideWrapper>
        <ReportGuideTitle>Report Guide</ReportGuideTitle>
        <ReportGuide>
          <ReportGuideCard />
        </ReportGuide>
      </ReportGuideWrapper>
    </PdfContent>
  );
}
export default PDFView;
