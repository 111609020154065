import { UploadHistoryEntry } from '@dewire/models/definitions/api-response/upload-history-entry';
import { ApiResponse } from 'api/response';
import { getFileHistory, getUploadUrl } from 'redux/slices/files.slice';
import { store } from 'redux/store';

export async function getNewFileUploadUrl(
  nameOfFile: string,
  isAssay: boolean,
  onFetchedUrl: (url: string) => void,
  onError: () => void
) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getUploadUrl({ fileName: nameOfFile, isAssay, session: headersState }));
  const payload = response.payload as ApiResponse<{ url: string }>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedUrl(payload.data.result.url);
}

export async function getAllFiles(onFetchedFiles: (files: UploadHistoryEntry[]) => void, onError: () => void) {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getFileHistory(headersState));
  const payload = response.payload as ApiResponse<UploadHistoryEntry[]>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedFiles(payload.data.result);
}
