import { EqaReportResponse } from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { useTheme } from 'app/AppStyling';
import GenerateReportCard from 'components/cards/eqa/GenerateReportCard';
import ReportDetailsCard from 'components/cards/eqa/ReportDetailsCard';
import ReportMeasurementCard from 'components/cards/eqa/ReportMeasurementCard';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import TableHeader from 'components/styled-components/table/TableHeader';
import { EQAFilterOptions } from 'helpers/eqa/usage';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { EQAValues, EQAValuesAsArray, LoadingState } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const Container = styled.div`
  padding: 3em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  ${() => bpTheme.breakpoints.down('sm')} {
    width: fit-content;
  }
`;
const HistoryContainer = styled.div`
  width: fit-content;
`;
const ReportHistory = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;
const ReportMeasurementHistory = styled.div`
  padding: 1em;
  background-color: ${() => useTheme().font.color.white};
  font-size: small;
  margin-top: 0.5em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
`;
const ReportHistoryHeader = styled(TableHeader)`
  background-color: #dae1e7;
  padding: 1em;
`;
const TitleItem = styled.div<{ margin?: boolean }>`
  width: 2.5em;
  margin-left: ${({ margin }) => (margin ? '1.5em' : '0')};
  display: flex;
  align-items: flex-start;
  text-align: center;
`;

function EQAView() {
  const eQAState = useAppSelector((state) => state.eqa);

  const [loading, setLoading] = useState(false);
  const [generatedReport, setGeneratedReport] = useState<EqaReportResponse>();
  const [serialNumber, setSerialNumber] = useState<string | undefined>();
  const [filters, setFilters] = useState<EQAFilterOptions>({ parameters: [], values: [], levels: [] });

  const generateReportCard = (report: EqaReportResponse, reportSerialNumber: string | undefined) => {
    setGeneratedReport(report);
    setSerialNumber(reportSerialNumber);
  };

  useEffect(() => {
    setLoading(eQAState.generatedReport.loadingStatus === LoadingState.Loading);
  }, [eQAState.generatedReport]);

  return (
    <Container>
      <GenerateReportCard
        generateReportCallback={(response: EqaReportResponse, responseSerialNumber: string | undefined) =>
          generateReportCard(response, responseSerialNumber)
        }
      />
      <HistoryContainer>
        {loading ? (
          <LoadingSpinner containerBased />
        ) : (
          generatedReport && (
            <ReportHistory>
              <ReportDetailsCard
                report={generatedReport}
                serialNumber={serialNumber ?? ''}
                filterCallback={(newFilter) => setFilters(newFilter)}
              />
              <ReportMeasurementHistory>
                <ReportHistoryHeader gridExpression="9% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7% 7%" textAlign="center">
                  <TitleItem margin>{getTranslation('Level')}</TitleItem>
                  {EQAValuesAsArray.map(
                    (eQAKey) =>
                      (!filters.values.length || filters.values.includes(EQAValues[eQAKey])) && (
                        <TitleItem key={EQAValues[eQAKey]}>{getTranslation(EQAValues[eQAKey])}</TitleItem>
                      )
                  )}
                </ReportHistoryHeader>
                {generatedReport.measurements &&
                  generatedReport.measurements.map(
                    (report) =>
                      (!filters.parameters.length || filters.parameters.includes(report.key)) && (
                        <ReportMeasurementCard
                          reportMeasurement={report}
                          key={report.key}
                          eQAFilters={filters}
                          serialNumber={serialNumber ?? ''}
                          year={generatedReport.reportDetails.date.year}
                          month={generatedReport.reportDetails.date.month}
                        />
                      )
                  )}
              </ReportMeasurementHistory>
            </ReportHistory>
          )
        )}
      </HistoryContainer>
    </Container>
  );
}

export default EQAView;
