import { useTheme } from 'app/AppStyling';
import FacebookIcon from 'assets/icons/FacebookIcon';
import LinkedInIcon from 'assets/icons/LinkedInIcon';
import YoutubeIcon from 'assets/icons/YoutubeIcon';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import UserManual from 'components/user-manual/UserManual';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Paths as paths, TextColor, Views } from 'interfaces/common';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.font.color.primary};
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 16em;
  ${() => bpTheme.breakpoints.down('lg')} {
    margin-left: 8em;
  }
  ${() => bpTheme.breakpoints.down('md')} {
    margin-left: 4em;
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;
const SectionTitleWrapper = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;
const ContactInfoWrapper = styled.div`
  margin: 6px 0px;
`;
const ContactAddressInfoWrapper = styled.div`
  margin: 25px 0px 6px 0px;
`;
const FooterMenuSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 7em;
  ${() => bpTheme.breakpoints.down('md')} {
    margin-left: 3em;
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    margin-left: 1em;
  }
`;
const SectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const MenuLinkItem = styled(Link)<{ color: string }>`
  gap: 1em;
  text-decoration: none;
  color: ${({ color }) => color};
  margin: 6px 0px;
  :hover {
    color: ${() => useTheme().icon.blue};
  }
`;
const IconContainer = styled.div`
  display: flex;
`;
const IconWrapper = styled.div`
  cursor: pointer;
  margin: 5px 10px 5px 0px;
`;
const UserManualWrapper = styled.div`
  margin: 25px 0px 8px 0px;
  font-weight: bold;
`;

interface FooterMenuProps {
  handleLinkCallback: (linkName: string) => void;
}

function FooterMenu({ handleLinkCallback }: FooterMenuProps) {
  const headersState = useAppSelector((state) => state.headers);
  return (
    <Container id="footer-menu-ref">
      <FooterMenuSection>
        <SectionColumn>
          <SectionTitleWrapper>
            <TextWrapper size="large" color={TextColor.White}>
              Sitemap
            </TextWrapper>
          </SectionTitleWrapper>

          {headersState.accesses.instrumentMonitoring && (
            <MenuLinkItem className="instrumentsNavigation" to={paths.InstrumentsUrl} color={TextColor.White}>
              <p>{getTranslation(Views.Instruments)}</p>
            </MenuLinkItem>
          )}
          {headersState.accesses.assayFileUpload && (
            <MenuLinkItem className="eqaNavigation" to={paths.EQAUrl} color={TextColor.White}>
              <p>{getTranslation(Views.EQA)}</p>
            </MenuLinkItem>
          )}
          {headersState.accesses.instrumentFileUpload && (
            <MenuLinkItem className="fileUploadNavigation" to={paths.FileUploadUrl} color={TextColor.White}>
              <p>{getTranslation(Views.FileUpload)}</p>
            </MenuLinkItem>
          )}
          {headersState.accesses.organization.instruments.view && (
            <MenuLinkItem className="organizationNavigation" to={paths.OrganizationUrl} color={TextColor.White}>
              <p>{getTranslation(Views.Organization)}</p>
            </MenuLinkItem>
          )}
          {headersState.accesses.users && (
            <MenuLinkItem className="usersNavigation" to={paths.UsersUrl} color={TextColor.White}>
              <p>{getTranslation(Views.Users)}</p>
            </MenuLinkItem>
          )}
        </SectionColumn>
      </FooterMenuSection>
      <FooterMenuSection>
        <SectionColumn>
          <SectionTitleWrapper>
            <TextWrapper size="large" color={TextColor.White}>
              Contact
            </TextWrapper>
          </SectionTitleWrapper>
          <ContactInfoWrapper>
            <TextWrapper size="medium" color={TextColor.White}>
              info@boule.com
            </TextWrapper>
          </ContactInfoWrapper>
          <ContactInfoWrapper>
            <TextWrapper size="medium" color={TextColor.White}>
              +46 8 7447700
            </TextWrapper>
          </ContactInfoWrapper>
          <ContactAddressInfoWrapper>
            <TextWrapper size="medium" color={TextColor.White}>
              Domnarvsgatan 4
            </TextWrapper>
          </ContactAddressInfoWrapper>
          <ContactInfoWrapper>
            <TextWrapper size="medium" color={TextColor.White}>
              SE-163 53 Spånga, Sweden
            </TextWrapper>
          </ContactInfoWrapper>
        </SectionColumn>
      </FooterMenuSection>
      <FooterMenuSection>
        <SectionTitleWrapper>
          <TextWrapper size="large" color={TextColor.White}>
            Follow us
          </TextWrapper>
        </SectionTitleWrapper>
        <IconContainer>
          <IconWrapper onClick={() => handleLinkCallback('linkedIn')}>
            <LinkedInIcon />
          </IconWrapper>
          <IconWrapper onClick={() => handleLinkCallback('youtube')}>
            <YoutubeIcon />
          </IconWrapper>
          <IconWrapper onClick={() => handleLinkCallback('facebook')}>
            <FacebookIcon />
          </IconWrapper>
        </IconContainer>
        <UserManualWrapper>
          <TextWrapper size="large" color={TextColor.White}>
            User manual
          </TextWrapper>
        </UserManualWrapper>
        <UserManual color={useTheme().font.color.white} />
      </FooterMenuSection>
    </Container>
  );
}

export default FooterMenu;
