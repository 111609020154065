import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { CustomerFormInput } from '@dewire/models/definitions/form-input/customer-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getDistributorFieldInput } from 'helpers/finders/field-input-finders';
import { findCountry, getCountryCodes } from 'helpers/formatters/country-codes';
import { addNewCustomer } from 'helpers/organization/add';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const NewCustomerTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;

interface AddCustomerModalProps {
  distributor?: FieldInput<number>;
  country?: FieldInput<string>;
  onCloseCallback: () => void;
}

function AddCustomerModal({ distributor = undefined, country = undefined, onCloseCallback }: AddCustomerModalProps) {
  const countryCodes = getCountryCodes();
  const [adminState, distributorsState, customersState] = useAppSelector((state) => [
    state.admin,
    state.admin.distributors.content,
    state.admin.customers.content,
  ]);

  const [customerInstance, setCustomerInstance] = useState<Partial<CustomerFormInput>>(
    { distributorId: distributor?.id, countryCode: country?.id } ?? {}
  );
  const [error, setError] = useState(true);
  const [firstChange, setFirstChange] = useState(true);
  const [addCustomerActive, setAddCustomerActive] = useState(false);

  const onAddCustomer = () => {
    addNewCustomer(
      customerInstance as CustomerFormInput,
      () => {
        provideSnackbar(
          Status.Success,
          lodash.capitalize(Status.Success),
          `Customer "${customerInstance.name}" was successfully added.`
        );
        onCloseCallback();
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to add customer');
        onCloseCallback();
      }
    );
  };

  // Input validation
  useEffect(() => {
    if (
      lodash.isEqual(customerInstance, {}) ||
      Object.values(customerInstance).every((property) => property === '' || property === undefined)
    ) {
      setFirstChange(true);
    } else {
      setFirstChange(false);
    }
    if (
      !validateInput({ value: customerInstance.name, params: ['required'], customers: customersState }).error &&
      !validateInput({ value: customerInstance.distributorId?.toString(), params: ['required'] }).error &&
      !validateInput({ value: customerInstance.countryCode, params: ['required'] }).error &&
      !validateInput({ value: customerInstance.email, params: ['email'] }).error &&
      !validateInput({ value: customerInstance.phoneNumber, params: ['phone'] }).error
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [customerInstance, customersState, distributorsState]);

  useEffect(() => {
    setAddCustomerActive(adminState.customers.updateStatus === LoadingState.Loading);
  }, [adminState.customers.updateStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      <AddCardContainer>
        <NewCustomerTitleWrapper>
          {getTranslation('New customer')}
          <CloseButton closeCallback={onCloseCallback} />
        </NewCustomerTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Name *')}
              filled
              value={customerInstance.name ?? ''}
              onChangeCallback={(newName: string) => setCustomerInstance({ ...customerInstance, name: newName })}
              error={
                !firstChange &&
                validateInput({ value: customerInstance.name, params: ['required'], customers: customersState }).error
              }
              helper={
                validateInput({ value: customerInstance.name, params: ['required'], customers: customersState })
                  .errorMessage
              }
            />
            <SearchInputField
              label={getTranslation('Distributor *')}
              value={distributor ?? getDistributorFieldInput(distributorsState, customerInstance.distributorId)}
              valueArray={distributorsState.map((_distributor: Distributor) => ({
                label: _distributor.name,
                id: _distributor.formData.id,
              }))}
              onChangeCallback={(newDistributor: FieldInput<number>) =>
                setCustomerInstance({ ...customerInstance, distributorId: newDistributor.id })
              }
              loading={false}
              error={
                !firstChange &&
                validateInput({
                  value: customerInstance.distributorId ? customerInstance.distributorId.toString() : undefined,
                  params: ['requiredId'],
                }).error
              }
              helper="Please select a distributor"
            />
          </DropdownInputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Address')}
              filled
              value={customerInstance.address ?? ''}
              onChangeCallback={(newAddress: string) =>
                setCustomerInstance({ ...customerInstance, address: newAddress })
              }
            />
            <TextInputField
              label={getTranslation('City')}
              filled
              value={customerInstance.city ?? ''}
              onChangeCallback={(newCity: string) => setCustomerInstance({ ...customerInstance, city: newCity })}
              containerHeight={116}
            />
            <SearchInputField
              label={getTranslation('Country *')}
              value={country ?? findCountry(customerInstance.countryCode)}
              valueArray={countryCodes}
              onChangeCallback={(newCountry: FieldInput<string>) =>
                setCustomerInstance({ ...customerInstance, countryCode: newCountry.id })
              }
              loading={false}
              error={!firstChange && validateInput({ value: customerInstance.countryCode, params: ['required'] }).error}
              helper={getTranslation('Please select a country')}
            />
            <TextInputField
              label={getTranslation('Postal Code')}
              filled
              value={customerInstance.postalCode ?? ''}
              onChangeCallback={(newPostal: string) =>
                setCustomerInstance({ ...customerInstance, postalCode: newPostal })
              }
            />
          </InputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Email')}
              filled
              value={customerInstance.email ?? ''}
              onChangeCallback={(newEmail: string) => setCustomerInstance({ ...customerInstance, email: newEmail })}
              error={
                !firstChange &&
                !!customerInstance.email &&
                customerInstance.email.length > 0 &&
                validateInput({ value: customerInstance.email, params: ['email'] }).error
              }
              helper="Please enter a valid email address"
            />
            <TextInputField
              label={getTranslation('Phone')}
              filled
              value={customerInstance.phoneNumber ?? ''}
              onChangeCallback={(newPhone: string) =>
                setCustomerInstance({ ...customerInstance, phoneNumber: newPhone })
              }
              error={
                !firstChange &&
                !!customerInstance.phoneNumber &&
                customerInstance.phoneNumber.length > 0 &&
                validateInput({ value: customerInstance.phoneNumber, params: ['phone'] }).error
              }
              helper="Please enter a valid phone number"
              containerHeight={116}
            />
            <TextInputField
              label={getTranslation('Comment')}
              filled
              multiline
              rows={6}
              value={customerInstance.comment ?? ''}
              onChangeCallback={(newComment: string | undefined) =>
                setCustomerInstance({ ...customerInstance, comment: newComment })
              }
              containerHeight={205}
            />
          </InputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <PrimaryButton disabled={error || addCustomerActive} onClick={() => onAddCustomer()}>
            {addCustomerActive ? <LoadingSpinner containerBased inButton /> : getTranslation('Add')}
          </PrimaryButton>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default AddCustomerModal;
