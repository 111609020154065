import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'interfaces/common';
import { SnackbarType } from 'interfaces/snackbar-type';

interface SnackbarState {
  open: boolean;
  type: SnackbarType;
  title: string;
  message: string;
}

const initialState: SnackbarState = {
  open: false,
  type: Status.Info,
  title: '',
  message: '',
};

export const snackbarSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSnackbarWithPayload: (state, action: PayloadAction<object>) => ({ ...state, ...action.payload }),
    closeSnackbar: () => initialState,
  },
});

export const { setSnackbarWithPayload, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
