import Session from '@dewire/models/definitions/api-response/session';
import { AddUserInput } from '@dewire/models/definitions/api-response/user-admin/add-user-input';
import { SimpleUserPermissionInput } from '@dewire/models/definitions/form-input/user-permission-input';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addUser as addUserWithApi,
  getOriginsOverview as getOriginsOverviewFromApi,
  getUsers as getUsersFromApi,
  managePersonalUser as managePersonalUserWithApi,
} from 'api/Api';
import { LoadingState } from 'interfaces/common';
import UsersState from 'interfaces/users-state';
import usersLoadingCase from 'redux/resolvers/users/general-cases';
import singleUserUpdateCase from 'redux/resolvers/users/single-user-cases';
import successfulUsersGetCase from 'redux/resolvers/users/users-cases';

const initialState: UsersState = {
  users: {
    content: [],
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  singleUser: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
  origins: {
    loadingStatus: LoadingState.Idle,
    error: null,
  },
  tableHeaders: {
    loadingStatus: LoadingState.Idle,
    updateStatus: LoadingState.Idle,
    error: null,
  },
};

export const getUsers = createAsyncThunk('user/getUsers', async (session: Session) => getUsersFromApi(session));

export const getOriginsOverview = createAsyncThunk('user/getOriginsOverview', async (session: Session) =>
  getOriginsOverviewFromApi(session)
);

export const manageUser = createAsyncThunk(
  'user/manageUser',
  async (payload: { session: Session; user: SimpleUserPermissionInput }) =>
    managePersonalUserWithApi(payload.session, payload.user)
);

export const addUser = createAsyncThunk('user/addUser', async (payload: { session: Session; user: AddUserInput }) =>
  addUserWithApi(payload.session, payload.user)
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Get users
      .addCase(getUsers.pending, (state) => usersLoadingCase(state, 'users', LoadingState.Loading))
      .addCase(getUsers.fulfilled, (state, action) => successfulUsersGetCase(state, action))
      .addCase(getUsers.rejected, (state, action) =>
        usersLoadingCase(state, 'users', LoadingState.Failed, action.error.message)
      )

      // Get origins
      .addCase(getOriginsOverview.pending, (state) => usersLoadingCase(state, 'origins', LoadingState.Loading))
      .addCase(getOriginsOverview.fulfilled, (state) => usersLoadingCase(state, 'origins', LoadingState.Succeeded))
      .addCase(getOriginsOverview.rejected, (state, action) =>
        usersLoadingCase(state, 'origins', LoadingState.Failed, action.error.message)
      )

      // Add single user
      .addCase(addUser.pending, (state) => singleUserUpdateCase(state, LoadingState.Loading))
      .addCase(addUser.fulfilled, (state) => singleUserUpdateCase(state, LoadingState.Succeeded))
      .addCase(addUser.rejected, (state, action) =>
        singleUserUpdateCase(state, LoadingState.Failed, action.error.message)
      )

      // Manage single user
      .addCase(manageUser.pending, (state) => singleUserUpdateCase(state, LoadingState.Loading))
      .addCase(manageUser.fulfilled, (state) => singleUserUpdateCase(state, LoadingState.Succeeded))
      .addCase(manageUser.rejected, (state, action) =>
        singleUserUpdateCase(state, LoadingState.Failed, action.error.message)
      );
  },
});
