import Flex from 'components/flex/Flex';
import StyledCycleItem from 'components/styled-components/table/StyledCycleItem';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';

interface CycleItemProps {
  text: string;
  dateText: string;
}

function CycleItem({ text, dateText }: CycleItemProps) {
  return (
    <StyledCycleItem>
      <TextWrapper size="large" bold>
        {text}
      </TextWrapper>
      <Flex gap="5px" alignItems="flex-end">
        <TextWrapper size="large">{dateText}</TextWrapper>
      </Flex>
    </StyledCycleItem>
  );
}

export default CycleItem;
