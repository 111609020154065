import { DefaultTheme } from 'app/AppStyling';

interface CalenderIconProps {
  width?: number;
  height?: number;
  color?: string;
}

function CalenderIcon({ width = 19, height = 21, color = DefaultTheme.icon.dark }: CalenderIconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.57895 20.5005C1.15789 20.5005 0.789474 20.3426 0.473684 20.0268C0.157895 19.711 0 19.3426 0 18.9215V3.65838C0 3.23732 0.157895 2.8689 0.473684 2.55311C0.789474 2.23732 1.15789 2.07943 1.57895 2.07943H3.28947V0.5C3.28947 0.223858 3.51333 0 3.78947 0H4.5C4.77614 0 5 0.223858 5 0.5V2.07943H13.9474V0.5C13.9474 0.223858 14.1712 0 14.4474 0H15.1579C15.434 0 15.6579 0.223858 15.6579 0.5V2.07943H17.3684C17.7895 2.07943 18.1579 2.23732 18.4737 2.55311C18.7895 2.8689 18.9474 3.23732 18.9474 3.65838V18.9215C18.9474 19.3426 18.7895 19.711 18.4737 20.0268C18.1579 20.3426 17.7895 20.5005 17.3684 20.5005H1.57895ZM1.57895 18.9215H17.3684V8.65838H1.57895V18.9215ZM1.57895 7.07943H17.3684V3.65838H1.57895V7.07943Z"
        fill={color}
      />
    </svg>
  );
}

export default CalenderIcon;
