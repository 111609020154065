import { useTheme } from 'app/AppStyling';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import styled from 'styled-components';

const ManageListButtonCardContainer = styled.div`
  background: transparent;
  width: 20em;
  height: 4.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${() => useTheme().border.blue};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border-style: solid;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }
`;
const CenterContainer = styled.div`
  color: ${() => useTheme().colors.primary};
  display: flex;
  justify-content: center;
  gap: 1em;
  align-items: center;
`;

interface ManageListButtonCardProps {
  title: string;
  onClickCallback: () => void;
}

function ManageListButtonCard({ title, onClickCallback }: ManageListButtonCardProps) {
  return (
    <ManageListButtonCardContainer onClick={onClickCallback}>
      <CenterContainer>
        <TextWrapper>{title}</TextWrapper>
      </CenterContainer>
    </ManageListButtonCardContainer>
  );
}

export default ManageListButtonCard;
