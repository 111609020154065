import { ManageOriginLists } from '@dewire/models/definitions/api-response/user-admin/manage-origin-list';
import { Origin } from '@dewire/models/definitions/api-response/user-admin/origin';
import { useTheme } from 'app/AppStyling';
import ManageAccessCard from 'components/cards/origins/ManageAccessCard';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import ViewButton from 'components/styled-components/buttons/ViewButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import getTranslation from 'helpers/translation/get-translation';
import { getAllOrigins } from 'helpers/users/getters';
import { LoadingState, Status } from 'interfaces/common';
import { OriginViewTypes } from 'interfaces/origin-view-types';
import lodash, { keys } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../../assets/icons/magnifying-glas.svg';

const DimmedModal = styled(DimmedContainer)`
  z-index: 1001;
`;
const ModalContainer = styled(AddCardContainer)`
  height: 50em;
`;
const ManageTitle = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
`;
const TabContainer = styled(ViewContainer)`
  display: flex;
  flex-direction: row;
  padding: 2em 0 1em 0;
  gap: 5em;
`;
const ListBackground = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: ${() => useTheme().background.light};
  max-height: 25em;
  overflow: scroll;
`;
const OptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const InfoCard = styled.p`
  margin-top: 1em;
  font-weight: 600;
`;

const existsInArray = (origin: Origin, array: Origin[]) =>
  array.findIndex((originItem: Origin) => originItem.id === origin.id && originItem.type === origin.type) !== -1;
// compare to type as well because if only using id
// then an origin from another level with the same id can accidentally picked up

const formatName = (rawName: string): string => {
  const capitalizedFirstLetterName = rawName[0].toUpperCase() + rawName.substring(1);
  return capitalizedFirstLetterName.replace(/([A-Z])/g, ' $1').trim();
};

interface ManageUserAccessModalProps {
  origins: Origin[];
  onSaveCallback: (savedOrigins: Origin[]) => void;
  onCloseCallback: () => void;
}

function ManageUserAccessModal({ origins: originsProp, onSaveCallback, onCloseCallback }: ManageUserAccessModalProps) {
  const originsState = useAppSelector((state) => state.users.origins);

  const [loadingOrigins, setLoadingOrigins] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [search, setSearch] = useState('');
  const [view, setView] = useState<OriginViewTypes>();
  const [allOrigins, setAllOrigins] = useState<ManageOriginLists>();
  const [childrenOrigins, setChildrenOrigins] = useState<Origin[]>([]);
  const [topLevelOrigins, setTopLevelOrigins] = useState<Origin[]>([]);
  const [displayOrigins, setDisplayOrigins] = useState<Origin[]>([]);
  const isInitialized = useRef(false);

  const setDefaultView = (viewData: ManageOriginLists) => {
    if (viewData.fullOrganization) setView('fullOrganization');
    else if (viewData.markets) setView('markets');
    else if (viewData.distributors) setView('distributors');
    else if (viewData.customers) setView('customers');
    else if (viewData.sites) setView('sites');
    else if (viewData.instruments) setView('instruments');
  };

  const handleOnSave = () => {
    onSaveCallback(topLevelOrigins);
  };

  const fetchOrigins = () => {
    getAllOrigins(
      (origins) => {
        setAllOrigins(origins);
        setDefaultView(origins);
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch origins.');
      }
    );
  };

  const findOriginsByParentId = (parentId: number, originType: OriginViewTypes): Origin[] => {
    let origins: Origin[] = [];
    if (allOrigins && allOrigins[originType]) {
      origins = allOrigins[originType]?.filter((origin) => origin.parentId === parentId) ?? [];
    }
    return origins;
  };

  const getOffsprings = (parent: Origin): Origin[] => {
    let children: Origin[] = [];
    if (allOrigins) {
      switch (parent.type) {
        case 'ADMIN':
          children = allOrigins.markets ?? [];
          break;
        case 'MARKET':
          children = findOriginsByParentId(parent.id, 'distributors');
          break;
        case 'DISTRIBUTOR':
          children = findOriginsByParentId(parent.id, 'customers');
          break;
        case 'CUSTOMER':
          children = findOriginsByParentId(parent.id, 'sites');
          break;
        case 'SITE':
          return findOriginsByParentId(parent.id, 'instruments');
        case 'INSTRUMENT':
          return [];
        default:
          break;
      }
    }
    const grandChildren = children.map((child) => getOffsprings(child));
    return [...children, ...grandChildren.flatMap((a) => a)];
  };

  const initCheckAndDisable = () => {
    let parents: Origin[] = [];
    let children: Origin[] = [];

    if (!isInitialized.current) {
      // prevents issues with state if multiple (initial) renders occurs unintentionally
      if (allOrigins) {
        originsProp.forEach((origin) => {
          if (!topLevelOrigins.includes(origin) && !childrenOrigins.includes(origin)) {
            parents = [...parents, origin];
            children = [...children, ...getOffsprings(origin)];
          }
        });
      }
      setTopLevelOrigins((prevTopLevels) => [...prevTopLevels, ...parents]);
      setChildrenOrigins(children);

      isInitialized.current = true;
    }
  };

  const handleUncheck = (origin: Origin) => {
    const newChildrenOrigins = [...childrenOrigins];
    const children = getOffsprings(origin);

    children.forEach((child) => {
      const childIndex = newChildrenOrigins.findIndex((orgin) => orgin.id === child.id);
      newChildrenOrigins.splice(childIndex, 1);
    });
    const topIndex = topLevelOrigins.findIndex((top) => top.id === origin.id);
    const newTopLevelOrigins = [...topLevelOrigins];
    newTopLevelOrigins.splice(topIndex, 1);
    setTopLevelOrigins(newTopLevelOrigins);
    setChildrenOrigins(newChildrenOrigins);
  };

  const handleCheck = (origin: Origin) => {
    setTopLevelOrigins([...topLevelOrigins, origin]);
    const children = getOffsprings(origin);
    setChildrenOrigins([...childrenOrigins, ...children]);
  };

  const onOriginClick = (origin: Origin) => {
    const isChecked = existsInArray(origin, [...childrenOrigins, ...topLevelOrigins]);

    if (!isChecked) {
      handleCheck(origin);
    } else {
      handleUncheck(origin);
    }
  };

  useEffect(() => {
    if (allOrigins) initCheckAndDisable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrigins]);

  useEffect(() => {
    fetchOrigins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const status = originsState.loadingStatus;
    setLoadingOrigins(status === LoadingState.Loading);
  }, [originsState.loadingStatus]);

  useEffect(() => {
    setChangesMade(!lodash.isEqual([...topLevelOrigins], originsProp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenOrigins, topLevelOrigins]);

  useEffect(() => {
    if (view && allOrigins) {
      if (search === '') {
        setDisplayOrigins(allOrigins[view] ?? []);
      } else {
        const searchedOrigins = allOrigins[view]?.filter((origin) =>
          origin.name.toLowerCase().includes(search.toLowerCase())
        );
        setDisplayOrigins(searchedOrigins ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, view]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedModal>
      <ModalContainer>
        <ManageTitle bold size="larger">
          {getTranslation('Manage organization access')}
          <CloseButton closeCallback={onCloseCallback} />
        </ManageTitle>
        <FlexContainer>
          Selected access enables access to all downstream components within an organization. For instance, access to a
          particular customer would automatically include access to all affiliated sites and the instruments located at
          those sites.
          {loadingOrigins ? (
            <LoadingSpinner containerBased />
          ) : (
            <>
              <TabContainer columns={0} rows={3}>
                {keys(allOrigins).map((originView) => (
                  <ViewButton
                    type="button"
                    key={originView}
                    active={view === originView}
                    onClick={() => {
                      setView(originView as OriginViewTypes); // key string will always match type OriginViewTypes
                    }}
                  >
                    {formatName(originView)}
                  </ViewButton>
                ))}
              </TabContainer>
              {
                // using the fact that 0 is falsy
              }
              {allOrigins && view && allOrigins[view]?.length && (
                <SearchBarContainer>
                  <SearchField
                    type="text"
                    value={search}
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                    iconUrl={magnyfyingGlass}
                    dark
                  />
                </SearchBarContainer>
              )}
              {
                // using the fact that 0 is falsy
              }
              {allOrigins && view && allOrigins[view]?.length ? (
                <ListBackground>
                  {displayOrigins.map((access) => (
                    <ManageAccessCard
                      key={access.id}
                      name={access.name}
                      primarySubInfo={access.primarySubInfo ? access.primarySubInfo : ''}
                      secondarySubInfo={access.secondarySubInfo ? access.secondarySubInfo : ''}
                      isChecked={existsInArray(access, [...childrenOrigins, ...topLevelOrigins])}
                      handleCheckCallback={() => onOriginClick(access)}
                      disabled={existsInArray(access, childrenOrigins)}
                    />
                  ))}
                </ListBackground>
              ) : (
                <InfoCard>{`There are no ${view ?? 'components'} available.`}</InfoCard>
              )}
            </>
          )}
        </FlexContainer>
        <OptionButtonContainer>
          <PrimaryButton disabled={!changesMade} onClick={() => handleOnSave()}>
            {getTranslation('Save')}
          </PrimaryButton>
        </OptionButtonContainer>
      </ModalContainer>
    </DimmedModal>
  );
}

export default ManageUserAccessModal;
