import { InstrumentQcOverview } from '@dewire/models/definitions/api-response/instrument-overview';
import IQcTeaser from '@dewire/models/definitions/api-response/qc-teaser';
import { getInstrumentQcHistory, getInstrumentQcOverview } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import DashboardCard from 'components/cards/instrument-dashboard/DashboardCard';
import QcCardDisplay from 'components/cards/instrument-dashboard/QcCardDisplay';
import InstrumentHeader from 'components/instrument-header/InstrumentHeader';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import FilterModal from 'components/modals/FilterModal';
import FilterGroup from 'components/selection/filter/FilterGroup';
import DateInputField from 'components/selection/input/DateInputField';
import BackButton from 'components/styled-components/buttons/BackButton';
import ViewFlexContainer from 'components/styled-components/containers/ViewFlexContainer';
import DateRangePicker from 'components/styled-components/selection/DateRangePicker';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableRowBase from 'components/styled-components/table/TableRowBase';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import filterByString from 'helpers/filter/filter-handler';
import getTranslation from 'helpers/translation/get-translation';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { Status, TextColor } from 'interfaces/common';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import QcRow from '../../../components/rows/instrument-dashboard/QcRow';

const QcViewContainer = styled.div`
  margin: 1em;
`;
const Table = styled.div`
  padding: 1em;
  overflow-x: auto;
  min-width: 900px;
  idth: 100%;
  ${() => bpTheme.breakpoints.down('md')} {
    padding: 0.5em;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5em;
  ${() => bpTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;
const SpinnerContainer = styled.div`
  margin: 1em;
`;
const ResultTableHeader = styled(TableRowBase)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.background.light};
  border-bottom: 1px solid ${() => useTheme().font.color.tertiary};
`;
const TableHeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1em;
  gap: 1em;
  position: relative;
  ${() => bpTheme.breakpoints.down('md')} {
    margin: 0 0.5em;
  }
`;

const ScrollableContainer = styled.div`
  max-width: 100%;
  overflow: auto;
`;

function QcView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const headersState = useAppSelector((state) => state.headers);

  const [methodFilter, setMethodFilter] = useState<string[]>([]);
  const [profileFilter, setProfileFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [expandedQc, setExpandedQc] = useState('');
  const [instrumentQcOverview, setInstrumentQcOverview] = useState<InstrumentQcOverview>();
  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [qcHistory, setQcHistory] = useState<IQcTeaser[]>([]);
  const [apiError, setApiError] = useState('');
  const [historyApiError, setHistroyApiError] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 90)));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const qcArray = ['Successful QC', 'Failed QC', 'Flagged QC'];
  const methodArray = ['Open tube', 'Prediluted sample', 'Cap piercer', 'Micro-capillary', 'Autosampler', 'Unknown'];

  const filterActive = methodFilter.length !== 0 || profileFilter.length !== 0 || statusFilter.length !== 0;

  const handleFilter = (selection: string, filterType: 'method' | 'profile' | 'status') => {
    switch (filterType) {
      case 'method': {
        filterByString(methodFilter, setMethodFilter, selection);
        break;
      }
      case 'profile': {
        filterByString(profileFilter, setProfileFilter, selection);
        break;
      }
      case 'status': {
        filterByString(statusFilter, setStatusFilter, selection);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleFilterClear = () => {
    setMethodFilter([]);
    setProfileFilter([]);
    setStatusFilter([]);
  };

  const onChangeDate = (dates: [any, any]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleExpandCallback = (isExpanded: boolean, qcId: string) => {
    if (isExpanded) {
      setExpandedQc('');
    } else {
      setExpandedQc(qcId);
    }
  };

  const fetchHistory = () => {
    if (id) {
      getInstrumentQcHistory(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setQcHistory(res.data.result);
        } else {
          setHistroyApiError(res.status);
        }
        setLoadingHistory(false);
      });
    } else navigate('/');
  };

  const fetchOverview = () => {
    if (id) {
      getInstrumentQcOverview(id, headersState).then((res) => {
        if (res.status === Status.Ok) {
          setInstrumentQcOverview(res.data.result);
        } else {
          setApiError(res.status);
        }
      });
    }
  };

  useEffect(() => {
    fetchHistory();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchOverview();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (instrumentQcOverview || apiError) setLoading(false);
  }, [instrumentQcOverview, apiError]);

  const qcHistoryView = qcHistory
    .filter(
      (qc) =>
        (!methodFilter.length || methodFilter.find((m) => qc.method.toLowerCase().indexOf(m.toLowerCase()) !== -1)) &&
        (!profileFilter.length || profileFilter.includes(qc.profile)) &&
        (!statusFilter.length || statusFilter.includes(qc.status))
    )
    .filter((qc) => {
      startDate.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59);
      return new Date(qc.date) >= startDate && new Date(qc.date) <= endDate;
    })
    .map((qc, index) => (
      <QcRow
        key={qc.id.toString()}
        qc={qc}
        nextDate={qcHistory[index + 1] ? qcHistory[index + 1].date : qc.date}
        expandRowCallback={(open: boolean) => handleExpandCallback(open, qc.id.toLocaleString())}
        expanded={expandedQc === qc.id.toLocaleString()}
      />
    ));

  return (
    <QcViewContainer>
      {id && <InstrumentHeader instrumentId={id} />}
      <ViewFlexContainer>
        <nav>
          <BackButton onClick={() => navigate(-1)}>
            <ArrowBackIcon size="small" />
            <p>{getTranslation('Instrument dashboard')}</p>
          </BackButton>
        </nav>
        <section>
          <DashboardCard cardTitle="Quality Control" justifyContent="space-evenly">
            <FlexContainer>
              {loading ? (
                <LoadingSpinner containerBased />
              ) : (
                <>
                  {instrumentQcOverview?.qc.map((qc) => (
                    <QcCardDisplay
                      key={qc.displayName}
                      qc={{
                        ...qc,
                        sampleCreatedAt: qc.time,
                        reportCreatedAt: qc.expiryTime,
                        controlBloodExpiry: qc.expiryTime,
                      }}
                      detailedView
                    />
                  ))}
                  {apiError !== '' && <TextWrapper color={TextColor.Danger}>{apiError}</TextWrapper>}
                </>
              )}
            </FlexContainer>
          </DashboardCard>
        </section>
        <section>
          <DashboardCard cardTitle="Results">
            <HistoryTable width="100%">
              <TableHeaderBar>
                <FilterContainer>
                  <DateRangePicker
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    dateFormat="yyyy-MM-dd"
                    monthsShown={2}
                    filterDate={(date) => date < new Date()}
                    customInput={<DateInputField value={`${startDate} ${endDate}`} onClick={undefined} />}
                    className="react-datepicker"
                  />

                  <FilterModal isFiltering={filterActive} clearCallback={() => handleFilterClear()}>
                    <>
                      <FilterGroup
                        groupTitle="Result"
                        filterOptions={[Status.Success, Status.Failure, Status.Warning]}
                        filterOptionsNames={qcArray}
                        filterArray={statusFilter}
                        onFilterCallback={(filterValue) => handleFilter(filterValue, 'status')}
                      />
                      <FilterGroup
                        groupTitle="Profile"
                        filterOptions={instrumentQcOverview?.qc.map((option) => option.displayName)}
                        filterArray={profileFilter}
                        onFilterCallback={(filterValue) => handleFilter(filterValue, 'profile')}
                      />
                      <FilterGroup
                        groupTitle="Method"
                        groupWidth={11.5}
                        filterOptions={methodArray}
                        filterArray={methodFilter}
                        onFilterCallback={(filterValue) => handleFilter(filterValue, 'method')}
                      />
                    </>
                  </FilterModal>
                </FilterContainer>
              </TableHeaderBar>
              <ScrollableContainer>
                <Table>
                  <ResultTableHeader gridExpression="10% 20% 20% 45% 7%" textAlign="center">
                    <p>{getTranslation('Result')}</p>
                    <p>{getTranslation('Profile')}</p>
                    <p>{getTranslation('Method')}</p>
                    <p>{getTranslation('Date & Time')}</p>
                    <div />
                  </ResultTableHeader>
                  {loadingHistory ? (
                    <SpinnerContainer>
                      <LoadingSpinner containerBased />
                    </SpinnerContainer>
                  ) : (
                    <div>
                      {qcHistoryView}
                      {historyApiError && <TextWrapper color={TextColor.Danger}>{historyApiError}</TextWrapper>}
                    </div>
                  )}
                </Table>
              </ScrollableContainer>
            </HistoryTable>
          </DashboardCard>
        </section>
      </ViewFlexContainer>
    </QcViewContainer>
  );
}

export default QcView;
