import { DefaultTheme } from 'app/AppStyling';

interface UserIconProps {
  color?: string;
}

function UserIcon({ color = DefaultTheme.colors.white }: UserIconProps) {
  return (
    <svg width="21" height="17" viewBox="0 0 17 15" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3744 14.5455H0.747803C0.747803 14.5455 2.40998 9.7372 8.56108 9.7373C14.7122 9.73741 16.3744 14.5455 16.3744 14.5455Z"
        fill={color}
      />
      <circle cx="8.56115" cy="4.32824" r="4.20715" fill={color} />
    </svg>
  );
}

export default UserIcon;
