import { EqaGraphData } from '@dewire/models/definitions/api-response/eQA/eqa-graph';
import type { EqaSelectionKeysResponse } from '@dewire/models/definitions/api-response/eQA/eqa-selectionkeys';
import Session from '@dewire/models/definitions/api-response/session';
import { EqaReportFormInput } from '@dewire/models/definitions/form-input/eqa-report-form-input';
import { DASHBOARD_BACKEND_API_BASE_URL } from 'config/config';
import FullGetResponse from 'interfaces/full-get-response';

import { getRequest } from './utils/api-utils';

const monthConverter = (month: number): string => {
  if (month < 10) {
    return `0${month}`;
  }
  return `${month}`;
};

export const getSelectionKeys = async (
  serialNumber: string,
  session: Session
): Promise<FullGetResponse<EqaSelectionKeysResponse[]>> =>
  getRequest<EqaSelectionKeysResponse[]>(
    session,
    `${DASHBOARD_BACKEND_API_BASE_URL}/instruments/${serialNumber}/reports/selectionKeys`,
    []
  );

export const getGeneratedReport = async (
  reportDetails: EqaReportFormInput,
  session: Session
): Promise<FullGetResponse<EqaSelectionKeysResponse[]>> => {
  const monthStr = monthConverter(reportDetails.month ?? 0);
  return getRequest<EqaSelectionKeysResponse[]>(
    session,
    `${DASHBOARD_BACKEND_API_BASE_URL}/instruments/${reportDetails.serialNumber}/reports?lotKitNumber=${reportDetails.lotKitNumber}&month=${monthStr}&year=${reportDetails.year}`,
    []
  );
};

export const getGraphData = async (
  serialNumber: string,
  measurementKey: string,
  level: string,
  year: number,
  month: number,
  session: Session
): Promise<FullGetResponse<EqaGraphData[]>> =>
  getRequest<EqaGraphData[]>(
    session,
    `${DASHBOARD_BACKEND_API_BASE_URL}/instruments/${serialNumber}/measurements?measurementKey=${measurementKey}&level=${level}&year=${year}&month=${month}`,
    []
  );

export const getReportAsPdf = async (
  serialNumber: string,
  lotKitNumber: string,
  year: number,
  month: number,
  session: Session
): Promise<FullGetResponse<string>> =>
  getRequest<string>(
    session,
    `${DASHBOARD_BACKEND_API_BASE_URL}/instruments/${serialNumber}/export?lotKitNumber=${lotKitNumber}&year=${year}&month=${month}`,
    '',
    true
  );

export const getSdiHistory = async (
  serialNumber: string,
  year: number,
  month: number,
  session: Session
): Promise<FullGetResponse<EqaSelectionKeysResponse[]>> =>
  getRequest<EqaSelectionKeysResponse[]>(
    session,
    `${DASHBOARD_BACKEND_API_BASE_URL}/instruments/${serialNumber}/sdi/history?month=${month}&year=${year}`,
    []
  );
