import { Site } from '@dewire/models/definitions/api-response/site';
import { SiteFormInput } from '@dewire/models/definitions/form-input/site-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getCustomerFieldInput, getCustomerFieldInputWithoutCustomerAccess } from 'helpers/finders/field-input-finders';
import { findCountry, getCountryCodes } from 'helpers/formatters/country-codes';
import { addNewSite } from 'helpers/organization/add';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const NewSiteTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const DropdownWrapper = styled.div`
  margin: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;

interface AddSiteModalProps {
  customer?: FieldInput<number>;
  country?: FieldInput<string>;
  onCloseCallback: () => void;
}

function AddSiteModal({ customer = undefined, country = undefined, onCloseCallback }: AddSiteModalProps) {
  const countryCodes = getCountryCodes();
  const [headersState, adminState, customersState, sitesState] = useAppSelector((state) => [
    state.headers,
    state.admin,
    state.admin.customers.content,
    state.admin.sites.content,
  ]);

  const hasCustomerViewAccess = headersState.accesses.organization.customers.view;
  const [siteInstance, setSiteInstance] = useState<Partial<SiteFormInput>>(
    { customerId: customer?.id, countryCode: country?.id } ?? {}
  );
  const [error, setError] = useState(true);
  const [firstChange, setFirstChange] = useState(true);
  const [addSiteActive, setAddSiteActive] = useState(false);

  const onAddSite = () => {
    // the disabled flag promise that siteInstance is a valid SiteFormInput when the button is clickable
    addNewSite(
      siteInstance as SiteFormInput,
      () => {
        provideSnackbar(
          Status.Success,
          lodash.capitalize(Status.Success),
          `Site "${siteInstance.name}" was successfully added.`
        );
        onCloseCallback();
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to add site.');
        onCloseCallback();
      }
    );
  };

  const listParentSitesAsDropdown = () => {
    const noneValue: FieldInput<number>[] = [{ label: 'None', id: undefined }];
    if (siteInstance.customerId) {
      const customerSites = sitesState.filter(
        (customerSite: Site) => customerSite.customerId === siteInstance.customerId
      );
      return lodash.union(
        noneValue,
        customerSites.map((site) => ({ label: site.name, id: site.formData.id }))
      );
    }
    return lodash.union(
      noneValue,
      sitesState.map((site) => ({ label: site.name, id: site.formData.id }))
    );
  };

  const getParentSiteValue = () => {
    const parentSites = listParentSitesAsDropdown();
    return parentSites.find((parent) => parent.id === siteInstance.parentSiteId) ?? { label: '', id: undefined };
  };

  const listAvailableCustomers = () => {
    if (!hasCustomerViewAccess) {
      return sitesState
        .map((site) => ({
          label: site.customerName,
          id: site.customerId,
        }))
        .filter((availableCustomer, i, arr) => arr.findIndex((cus) => cus.id === availableCustomer.id) === i);
    }
    return customersState.map((_customer) => ({
      label: _customer.name,
      id: _customer.formData.id,
    }));
  };

  // Input validation
  useEffect(() => {
    if (
      lodash.isEqual(siteInstance, {}) ||
      Object.values(siteInstance).every((property) => property === '' || property === undefined)
    ) {
      setFirstChange(true);
    } else {
      setFirstChange(false);
    }
    if (
      !validateInput({
        value: siteInstance.name,
        params: ['required'],
        sites: sitesState,
      }).error &&
      !validateInput({ value: siteInstance.address, params: ['required'] }).error &&
      !validateInput({ value: siteInstance.city, params: ['required'] }).error &&
      !validateInput({ value: siteInstance.countryCode, params: ['required'] }).error &&
      !validateInput({ value: siteInstance.email, params: ['email'] }).error &&
      !validateInput({ value: siteInstance.customerId?.toString(), params: ['required'] }).error &&
      (headersState.accesses.organization.sites.edit ||
        !validateInput({ value: siteInstance.parentSiteId?.toString(), params: ['required'] }).error) &&
      !validateInput({ value: siteInstance.phoneNumber, params: ['phone'] }).error
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [headersState.accesses.organization.sites.edit, siteInstance, sitesState]);

  useEffect(() => {
    setAddSiteActive(adminState.sites.updateStatus === LoadingState.Loading);
  }, [adminState.sites.updateStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      <AddCardContainer>
        <NewSiteTitleWrapper>
          New site
          <CloseButton closeCallback={onCloseCallback} />
        </NewSiteTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Name *')}
              filled
              value={siteInstance.name ?? ''}
              onChangeCallback={(newName: string) => setSiteInstance({ ...siteInstance, name: newName })}
              error={
                !firstChange &&
                validateInput({
                  value: siteInstance.name,
                  params: ['required'],
                  sites: sitesState,
                }).error
              }
              helper={
                validateInput({
                  value: siteInstance.name,
                  params: ['required'],
                  sites: sitesState,
                }).errorMessage
              }
            />
            <SearchInputField
              label={getTranslation('Customer *')}
              value={
                !hasCustomerViewAccess
                  ? getCustomerFieldInputWithoutCustomerAccess(sitesState, siteInstance.customerId)
                  : getCustomerFieldInput(customersState, siteInstance.customerId)
              }
              valueArray={listAvailableCustomers()}
              onChangeCallback={(newCustomer: FieldInput<number>) =>
                !hasCustomerViewAccess
                  ? setSiteInstance({ ...siteInstance, customerId: newCustomer.id, id: newCustomer.id })
                  : setSiteInstance({ ...siteInstance, customerId: newCustomer.id, id: undefined })
              }
              loading={false}
              error={
                !firstChange &&
                validateInput({ value: siteInstance.customerId?.toString(), params: ['requiredId'] }).error
              }
              helper={getTranslation('Please select a customer')}
            />
            <DropdownWrapper>
              <DropdownInput
                label={getTranslation('Top site') + (headersState.accesses.organization.sites ? '' : ' *')}
                value={getParentSiteValue()}
                valueArray={listParentSitesAsDropdown()}
                checkbox
                onChangeCallback={(newParentSite: number | undefined) =>
                  setSiteInstance({ ...siteInstance, parentSiteId: newParentSite })
                }
                error={
                  !firstChange &&
                  !headersState.accesses.organization.sites.edit &&
                  validateInput({ value: siteInstance.parentSiteId?.toString(), params: ['requiredId'] }).error
                }
                disabled={!siteInstance.customerId}
              />
            </DropdownWrapper>
          </DropdownInputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Address *')}
              filled
              value={siteInstance.address ?? ''}
              onChangeCallback={(newAddress: string) => setSiteInstance({ ...siteInstance, address: newAddress })}
              error={!firstChange && validateInput({ value: siteInstance.address, params: ['required'] }).error}
              helper={getTranslation('Please enter an address')}
            />
            <TextInputField
              label={getTranslation('City *')}
              filled
              value={siteInstance.city ?? ''}
              onChangeCallback={(newCity: string) => setSiteInstance({ ...siteInstance, city: newCity })}
              error={!firstChange && validateInput({ value: siteInstance.city, params: ['required'] }).error}
              helper={getTranslation('Please enter a city')}
              containerHeight={116}
            />
            <SearchInputField
              label={getTranslation('Country *')}
              value={findCountry(siteInstance.countryCode)}
              valueArray={countryCodes}
              onChangeCallback={(newCountry: FieldInput<string>) =>
                setSiteInstance({ ...siteInstance, countryCode: newCountry.id })
              }
              loading={false}
              error={!firstChange && validateInput({ value: siteInstance.countryCode, params: ['required'] }).error}
              helper={getTranslation('Please select a country')}
            />
            <TextInputField
              label={getTranslation('Postal Code')}
              filled
              value={siteInstance.postalCode ?? ''}
              onChangeCallback={(newPostal: string) => setSiteInstance({ ...siteInstance, postalCode: newPostal })}
            />
          </InputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Email')}
              filled
              value={siteInstance.email ?? ''}
              onChangeCallback={(newEmail: string) => setSiteInstance({ ...siteInstance, email: newEmail })}
              error={
                !firstChange &&
                !!siteInstance.email &&
                siteInstance.email.length > 0 &&
                validateInput({ value: siteInstance.email, params: ['email'] }).error
              }
              helper="Please enter a valid email address"
            />
            <TextInputField
              label={getTranslation('Phone')}
              filled
              value={siteInstance.phoneNumber ?? ''}
              onChangeCallback={(newPhone: string) => setSiteInstance({ ...siteInstance, phoneNumber: newPhone })}
              error={
                !firstChange &&
                !!siteInstance.phoneNumber &&
                siteInstance.phoneNumber.length > 0 &&
                validateInput({ value: siteInstance.phoneNumber, params: ['phone'] }).error
              }
              helper="Please enter a valid phone number"
              containerHeight={116}
            />
            <TextInputField
              label={getTranslation('Comment')}
              filled
              multiline
              rows={6}
              value={siteInstance.comment ?? ''}
              onChangeCallback={(newComment: string) => setSiteInstance({ ...siteInstance, comment: newComment })}
              containerHeight={205}
            />
          </InputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <PrimaryButton disabled={error || addSiteActive} onClick={() => onAddSite()}>
            {addSiteActive ? <LoadingSpinner containerBased inButton /> : getTranslation('Add')}
          </PrimaryButton>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default AddSiteModal;
