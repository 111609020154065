import { Customer } from '@dewire/models/definitions/api-response/customer';
import { Site } from '@dewire/models/definitions/api-response/site';
import { SiteDeleteResponse } from '@dewire/models/definitions/api-response/site-delete-respons';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';
import lodash from 'lodash';

export const successfulSiteAddCase = (state: any, action: PayloadAction<FullGetResponse<Site>>) => {
  const newSite: Site = action.payload.data.result;
  const updatedCustomers = [...state.customers.content];
  const customerIndex = updatedCustomers.findIndex((val: Customer) => val.formData.id === newSite.formData.customerId);
  if (customerIndex !== -1) {
    const customer = { ...updatedCustomers[customerIndex] };
    updatedCustomers[customerIndex] = {
      ...customer,
      numOfSites: customer.numOfSites + 1,
    };
    return {
      ...state,
      sites: {
        ...state.sites,
        content: state.sites.content.concat(action.payload.data.result),
        updateStatus: LoadingState.Succeeded,
      },
      customers: {
        ...state.customers,
        content: updatedCustomers,
        updateStatus: LoadingState.Succeeded,
      },
    };
  }
  return {
    ...state,
    sites: {
      ...state.sites,
      updateStatus: LoadingState.Succeeded,
    },
  };
};

export const successfulSiteDeleteCase = (state: any, action: PayloadAction<FullGetResponse<SiteDeleteResponse>>) => {
  const { customerId, siteId } = action.payload.data.result;
  const updatedSites = [...state.sites.content];
  const siteIndex = updatedSites.findIndex((val: Site) => val.formData.id === siteId);
  if (siteIndex !== -1) {
    updatedSites.splice(siteIndex, 1);
    const updatedCustomers = lodash.cloneDeep(state.customers.content); // change this to shallow copy once sites attribute is removed from Customer object
    const customerIndex = updatedCustomers.findIndex((val: Customer) => val.formData.id === customerId);
    if (customerIndex !== -1) {
      const newCustomer = updatedCustomers[customerIndex];
      updatedCustomers[customerIndex] = {
        ...newCustomer,
        numOfSites: newCustomer.numOfInstruments - 1,
      };
      return {
        ...state,
        sites: {
          ...state.sites,
          content: updatedSites,
          updateStatus: LoadingState.Succeeded,
        },
        customers: {
          ...state.customers,
          content: updatedCustomers,
          updateStatus: LoadingState.Succeeded,
        },
      };
    }
    return {
      ...state,
      sites: {
        ...state.sites,
        content: updatedSites,
        updateStatus: LoadingState.Succeeded,
      },
    };
  }
  return {
    ...state,
    sites: {
      ...state.sites,
      updateStatus: LoadingState.Succeeded,
    },
  };
};
