import { useTheme } from 'app/AppStyling';

function FilterIcon() {
  return (
    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 12C14.5523 12 15 12.4477 15 13C15 13.5523 14.5523 14 14 14H8C7.44772 14 7 13.5523 7 13C7 12.4477 7.44772 12 8 12H14ZM18 6C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H18ZM21 0C21.5523 0 22 0.447715 22 1C22 1.55228 21.5523 2 21 2H1C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0H21Z"
        fill={useTheme().icon.dark}
      />
    </svg>
  );
}

export default FilterIcon;
