function WarningSnackbarIcon() {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.671016 16C0.539509 16 0.425157 15.9699 0.327958 15.9096C0.23076 15.8493 0.155158 15.7699 0.101153 15.6712C0.0402652 15.5748 0.00678211 15.4703 0.000703575 15.3578C-0.00538958 15.2453 0.0280935 15.1306 0.101153 15.0137L8.60526 0.328767C8.67832 0.211872 8.76234 0.127854 8.85732 0.0767123C8.95229 0.0255708 9.05823 0 9.17513 0C9.29202 0 9.39796 0.0255708 9.49293 0.0767123C9.58791 0.127854 9.67193 0.211872 9.74499 0.328767L18.2491 15.0137C18.3222 15.1306 18.3556 15.2453 18.3495 15.3578C18.3435 15.4703 18.31 15.5748 18.2491 15.6712C18.1951 15.7699 18.1195 15.8493 18.0223 15.9096C17.9251 15.9699 17.8107 16 17.6792 16H0.671016ZM1.81074 14.6849H16.5395L9.17513 1.9726L1.81074 14.6849ZM9.26663 13.4356C9.45403 13.4356 9.60983 13.3722 9.73403 13.2455C9.85823 13.1187 9.92033 12.9616 9.92033 12.7742C9.92033 12.5868 9.85695 12.4311 9.73019 12.3068C9.60342 12.1826 9.44634 12.1205 9.25896 12.1205C9.07156 12.1205 8.91577 12.1839 8.79156 12.3107C8.66736 12.4375 8.60526 12.5945 8.60526 12.7819C8.60526 12.9693 8.66864 13.1251 8.7954 13.2493C8.92217 13.3735 9.07925 13.4356 9.26663 13.4356ZM9.26663 11.0027C9.45403 11.0027 9.60983 10.9397 9.73403 10.8137C9.85823 10.6877 9.92033 10.5315 9.92033 10.3452V6.75069C9.92033 6.56438 9.85695 6.40822 9.73019 6.28219C9.60342 6.15616 9.44634 6.09315 9.25896 6.09315C9.07156 6.09315 8.91577 6.15616 8.79156 6.28219C8.66736 6.40822 8.60526 6.56438 8.60526 6.75069V10.3452C8.60526 10.5315 8.66864 10.6877 8.7954 10.8137C8.92217 10.9397 9.07925 11.0027 9.26663 11.0027Z"
        fill="#FFCA30"
      />
    </svg>
  );
}

export default WarningSnackbarIcon;
