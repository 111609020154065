import {
  InstrumentPairing,
  ReagentWithPairedInstruments,
} from '@dewire/models/definitions/api-response/reagent-with-paired-instruments';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import InfiniteScrollList from 'components/lists/InfiniteScrollList';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import NavContainer from 'components/nav/NavContainer';
import ReagentPairedInstrumentRow from 'components/rows/reagent/ReagentPairedInstrumentRow';
import NavigationLinkButton from 'components/styled-components/buttons/NavigationLinkButton';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import HistoryTable from 'components/styled-components/table/HistoryTable';
import TableHeader from 'components/styled-components/table/TableHeader';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getReagentPairedInstrument } from 'helpers/reagent/getters';
import getTranslation from 'helpers/translation/get-translation';
import { Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import magnifyingGlass from '../../../assets/icons/magnifying-glas.svg';
import ReagentNoOfPairedInstrumentsHeader from './ReagentNoOfPairedInstrumentsHeader';

const Table = styled.div`
  padding: 1em;
  text-align: start;
`;

let displayMessage = 'There is currently no instrument that has been or are currently paired with reagent: ';

const tableHeaders = ['Pairing date', 'Decoupling date', 'Instrument ID', 'Distributor', 'Customer', 'Site', 'Volume'];

const getFullReagentId = (reagentId: string | undefined, instrumentId: string) => reagentId?.concat(instrumentId);

interface Filters {
  search: string;
}

function ReagentNoOfPairedInstruments() {
  const { reagentId } = useParams();
  const [filter, setFilter] = useState<Filters>({
    search: '',
  });
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [reagentPairedInstruments, setReagentPairedInstruments] = useState<ReagentWithPairedInstruments>();
  const [instruments, setInstruments] = useState<InstrumentPairing[]>([]);
  const [filteredInstruments, setFilteredInstruments] = useState<InstrumentPairing[]>([]);

  useEffect(() => {
    if (loading && reagentId) {
      getReagentPairedInstrument(
        reagentId,
        (result: ReagentWithPairedInstruments) => {
          setReagentPairedInstruments(result);
          setFilteredInstruments(result.instrumentPairing);
          setInstruments(result.instrumentPairing);
          setLoading(false);
        },
        () => {
          setLoading(false);
          displayMessage = 'Unable to fetch paired instruments for reagent with reagentId: ';
          provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch paired instruments');
        }
      );
    }
  }, [loading, reagentId]);

  const filterConditions = (instrument: InstrumentPairing) => {
    const { search } = filter;
    if (!search) return true;

    return (
      instrument.instrumentId.toString().toLowerCase().includes(search.toLowerCase()) ||
      instrument.distributor.name.toString().toLowerCase().includes(search.toLowerCase()) ||
      (instrument.customer && instrument.customer.name.toString().toLowerCase().includes(search.toLowerCase())) ||
      (instrument.site && instrument.site.name.toString().toLowerCase().includes(search.toLowerCase()))
    );
  };

  const applyFilters = () => {
    const filtered = instruments?.filter(filterConditions);
    setFilteredInstruments(filtered);
  };

  const mapInstruments = () => {
    if (!filteredInstruments) return [];
    return filteredInstruments?.map((instrument) => (
      <ReagentPairedInstrumentRow
        volume={reagentPairedInstruments?.volume}
        key={getFullReagentId(reagentId, instrument.instrumentId)}
        pairedInstrument={instrument}
      />
    ));
  };

  useMemo(() => {
    if (!loading) applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instruments, filter]);

  return (
    <ViewContainer flexdircolumn columns={0} rows={3}>
      <NavContainer>
        <NavigationLinkButton to={location.pathname.substring(0, location.pathname.lastIndexOf('/'))}>
          <ArrowBackIcon size="small" />
          <p>Lot overview</p>
        </NavigationLinkButton>
      </NavContainer>
      <ReagentNoOfPairedInstrumentsHeader reagent={reagentPairedInstruments} />
      <ToolbarContainer>
        <SearchBarContainer>
          <SearchField
            type="text"
            placeholder="Search"
            onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            iconUrl={magnifyingGlass}
          />
        </SearchBarContainer>
      </ToolbarContainer>
      {reagentPairedInstruments?.instrumentPairing && reagentPairedInstruments.instrumentPairing.length !== 0 ? (
        <HistoryTable>
          <Table>
            <TableHeader gridExpression="16% 17% 15% 14% 15% 14% 9%" textAlign="start">
              {tableHeaders.map((tableHeader: string) => (
                <p key={tableHeader}>{getTranslation(tableHeader)}</p>
              ))}
            </TableHeader>
            {loading ? <LoadingSpinner containerBased /> : <InfiniteScrollList items={mapInstruments()} />}
          </Table>
        </HistoryTable>
      ) : (
        !loading && (
          <TextWrapper>
            {displayMessage} {reagentId}.
          </TextWrapper>
        )
      )}
    </ViewContainer>
  );
}
export default ReagentNoOfPairedInstruments;
