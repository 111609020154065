import { bpTheme } from 'helpers/window/use-current-breakpoint';
import styled from 'styled-components';

export const TableRowItem = styled.div<{ icon?: boolean }>`
  white-space: nowrap;
  overflow: ${({ icon }) => (icon ? '' : 'hidden')};
  text-overflow: ellipsis;
  max-width: 15em;
  ${() => bpTheme.breakpoints.down('lg')} {
    max-width: 10em;
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    max-width: 8em;
  }
`;

export default TableRowItem;
