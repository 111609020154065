function ScanButtonIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 2.77778V8.33333H2.77778V2.77778H8.33333V0H2.77778C1.25 0 0 1.25 0 2.77778ZM2.77778 16.6667H0V22.2222C0 23.75 1.25 25 2.77778 25H8.33333V22.2222H2.77778V16.6667ZM22.2222 22.2222H16.6667V25H22.2222C23.75 25 25 23.75 25 22.2222V16.6667H22.2222V22.2222ZM22.2222 0H16.6667V2.77778H22.2222V8.33333H25V2.77778C25 1.25 23.75 0 22.2222 0Z"
        fill="#0167FF"
      />
    </svg>
  );
}

export default ScanButtonIcon;
