import { EqaGraphData } from '@dewire/models/definitions/api-response/eQA/eqa-graph';
import type {
  EqaReportDetails,
  EqaReportResponse,
} from '@dewire/models/definitions/api-response/eQA/eqa-report-details';
import { EqaSdiHistory } from '@dewire/models/definitions/api-response/eQA/eqa-sdi';
import type { EqaSelectionKeysResponse } from '@dewire/models/definitions/api-response/eQA/eqa-selectionkeys';
import { ApiResponse } from 'api/response';
import {
  getGraphData,
  getReport,
  getReportPdf,
  getSdiHistory as getSdiHistoryRedux,
  getSelectionKeys,
} from 'redux/slices/eqa.slice';
import { store } from 'redux/store';

export const getAllSelectionKeys = async (
  id: string,
  onFetchedKeys: (keys: EqaSelectionKeysResponse) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getSelectionKeys({ id, session: headersState }));
  const payload = response.payload as ApiResponse<EqaSelectionKeysResponse>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedKeys(payload.data.result);
};

export const getGeneratedReport = async (
  serialNumber: string,
  lotKitNumber: string,
  month: number,
  year: number,
  onFetchedReport: (reportDetails: EqaReportResponse) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getReport({ serialNumber, lotKitNumber, month, year, session: headersState }));
  const payload = response.payload as ApiResponse<EqaReportResponse>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedReport(payload.data.result);
};

export const getGraphDataForReport = async (
  reportSerialNumber: string,
  reportKey: string,
  reportLevel: string,
  year: number,
  month: number,
  onFetchedGraphData: (graphData: EqaGraphData) => void,
  onError: (message: string) => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(
    getGraphData({
      serialNumber: reportSerialNumber,
      key: reportKey,
      level: reportLevel,
      year,
      month,
      session: headersState,
    })
  );
  const payload = response.payload as ApiResponse<EqaGraphData>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result.measurements === undefined)
    onError('Unable to fetch chart data.');
  else onFetchedGraphData(payload.data.result);
};

export const getReportAsPdf = async (
  serialNumber: string,
  reportDetails: EqaReportDetails,
  onComplete: (pdfRepresentation: string) => void,
  onError: (message: string) => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(
    getReportPdf({
      serialNumber,
      lotKitNumber: reportDetails.lotKitNumber,
      month: reportDetails.date.month,
      year: reportDetails.date.year,
      session: headersState,
    })
  );

  const payload = response.payload as string;
  if (response.meta.requestStatus === 'rejected' || !payload) onError('Unable to fetch pdf.');
  else onComplete(payload);
};

export const getUniqueKey = (level: string, reportMeasurementKey: string): string => `${level}-${reportMeasurementKey}`;

export const getSdiHistory = async (
  serialNumber: string,
  month: number,
  year: number,
  onFetchedSdiHistory: (reportDetails: EqaSdiHistory) => void,
  onError: () => void
) => {
  const headersState = store.getState().headers;
  const response = await store.dispatch(getSdiHistoryRedux({ serialNumber, month, year, session: headersState }));
  const payload = response.payload as ApiResponse<EqaSdiHistory>;
  if (response.meta.requestStatus === 'rejected' ?? payload.data.result === undefined) onError();
  else onFetchedSdiHistory(payload.data.result);
};
