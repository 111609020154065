export enum AccessTypes {
  MonitorInstrument = 'Monitor instrument',
  UploadFiles = 'Upload files',
  OrganizationManagement = 'Organization management',
  UserManagement = 'User management',
  Admin = 'Admin',
  SuperAdmin = 'Super admin',
}

export enum Roles {
  Admin = 'Admin',
  ServiceTechnician = 'Service Technician',
  InstrumentCoordinator = 'Instrument Coordinator',
  Operator = 'Operator',
}

export enum InstrumentModels {
  SwelabAlfaPlus = 'Swelab Alfa Plus',
  MedonicM32 = 'Medonic M32',
}

export enum ReagentBrands {
  SwelabAlfaPlus = 'Swelab Alfa',
  MedonicM32 = 'Medonic M-Series',
}

export enum ReagentTypes {
  Diluent = 'Diluent',
  Lyse1 = 'Lyse 1',
  Lyse2 = 'Lyse 2',
  ReticulocyteStain = 'Reticulocyte Stain',
}

export enum FileTypes {
  AssayData = 'Assay',
  SampleExport = 'Sample Export',
  InstrumentLog = 'Instrument Log',
}

export enum AccessLimit {
  FullAccess = 'Full access',
  PartialAccess = 'Partial access',
  NoAccess = 'No access',
}

export enum Months {
  January = 'January',
  February = 'February',
  Mars = 'Mars',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
  Unknown = '',
}

export enum EQAValues {
  YourN = 'Your N',
  AssayMean = 'Assay Mean',
  YourMean = 'Your Mean',
  GroupMean = 'Group Mean',
  Your1Sd = 'Your 1 SD',
  Group1Sd = 'Group 1 SD',
  Your2Sd = 'Your 2 SD',
  Group2Sd = 'Group 2 SD',
  MeanDiff = 'Mean Diff',
  Sdi = 'SDI',
  YourCv = 'Your CV',
  GroupCv = 'Group CV',
  Note = 'Note',
}

export const EQAValuesAsArray = Object.keys(EQAValues) as Array<keyof typeof EQAValues>;

export enum Paths {
  InstrumentsUrl = '/instruments',
  SingleInstrumentUrl = '/instrument/:id',
  EQAUrl = '/eqa',
  OrganizationUrl = '/organization',
  FileUploadUrl = '/file-upload',
  UsersUrl = '/users',
  ProfileUrl = '/profile',
  AddUserUrl = '/add-user',
  PDFUrl = '/pdf',
  ReagentRegisterUrl = '/reagent/register',
  ReagentLockInstrumentUrl = '/reagent/connect',
  ReagentDashboardUrl = '/reagent/dashboard',
  ReagentDashboardLotUrl = 'reagent/dashboard/:lotNumber',
  ReagentDashboardPairedInstrumentUrl = 'reagent/dashboard/:lotNumber/:reagentId',
  ReagentOnInstrumentUrl = 'reagent/dashboard/instrument/:instrumentId',
}

export enum Views {
  Instruments = 'Instruments',
  EQA = 'EQA',
  Organization = 'Organization',
  FileUpload = 'File Upload',
  Users = 'Users',
  PDF = 'PDF',
  Reagent = 'Reagent',
}

export enum Environments {
  Dev = 'dev',
  Cont = 'cont',
  Test = 'test',
  Staging = 'staging',
  Prod = 'prod',
}

export enum OrganizationParents {
  Distributor = 'distributor',
  Customer = 'customer',
  Site = 'site',
  Unknown = '',
}

export enum LoadingState {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed',
  Deleting = 'deleting',
}

export enum Status {
  Success = 'success',
  Warning = 'warning',
  Failure = 'failure',
  Unknown = 'unknown',
  Error = 'error',
  Info = 'info',
  Ok = 'ok',
}

export enum TextColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  White = 'white',
  Blue = 'blue',
}

export enum HelperText {
  OrganizationNavigation = "For a clearer view of your Origin accesses, please visit the Organization Menu. It's optimized for those with full market access.",
}
