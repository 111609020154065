import styled from 'styled-components';

const QrFrameWrapper = styled.div`
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export default QrFrameWrapper;
