import { InstrumentReagent } from '@dewire/models/definitions/api-response/instrument-reagent';
import { Reagent } from '@dewire/models/definitions/api-response/reagent';
import { ReagentConnectionResponse } from '@dewire/models/definitions/api-response/reagent-connection-response';
import { ReagentOverviewForInstrument } from '@dewire/models/definitions/api-response/reagent-overview-for-instrument';
import { ReagentLot } from '@dewire/models/definitions/api-response/reagentlot';
import Session from '@dewire/models/definitions/api-response/session';
import { ReagentFormInput } from '@dewire/models/definitions/form-input/reagent-form-input';
import { FACTORY_BACKEND_API_BASE_URL } from 'config/config';
import FullGetResponse from 'interfaces/full-get-response';
import Response from 'interfaces/response';

import { registerReagentErrorMessages } from './error-messages/reagent';
import { getAuthHeaders, getJwtHeaders, getRequest } from './utils/api-utils';

export const getAllReagentLots = async (session: Session): Promise<FullGetResponse<ReagentLot[]>> =>
  getRequest<ReagentLot[]>(session, `${FACTORY_BACKEND_API_BASE_URL}/reagentlots`, []);

export const getInstrumentReagentOverview = async (session: Session): Promise<FullGetResponse<InstrumentReagent[]>> =>
  getRequest<InstrumentReagent[]>(session, `${FACTORY_BACKEND_API_BASE_URL}/reagents/instrument-reagent-overview`, []);

export const getReagentOverviewForInstrument = async (payload: {
  session: Session;
  instrumentId: string;
}): Promise<FullGetResponse<ReagentOverviewForInstrument[]>> =>
  getRequest<ReagentOverviewForInstrument[]>(
    payload.session,
    `${FACTORY_BACKEND_API_BASE_URL}/reagent-overview-for-instrument/${payload.instrumentId}`,
    []
  );

export const getAllReagentsForLot = async (
  session: Session,
  lotnumber: string
): Promise<FullGetResponse<ReagentLot[]>> =>
  getRequest<ReagentLot[]>(session, `${FACTORY_BACKEND_API_BASE_URL}/reagentlots/${lotnumber}`, []);

export const addReagent = async (
  session: Session,
  reagent: ReagentFormInput
): Promise<{ status: string; data: Response<Reagent> }> => {
  const body = JSON.stringify(reagent);
  const response = await fetch(`${FACTORY_BACKEND_API_BASE_URL}/reagents`, {
    method: 'POST',
    headers: getAuthHeaders(session),
    body,
  });

  if (response.status !== 200) {
    const errorMessage = registerReagentErrorMessages[response.status] || 'Unknown error, could not add reagent';
    throw new Error(errorMessage);
  }
  return response.json();
};

export const connectReagent = async (
  payload: string,
  jwt: string
): Promise<{ status: string; data: Response<ReagentConnectionResponse> }> => {
  const body = JSON.stringify(payload);
  const response = await fetch(`${FACTORY_BACKEND_API_BASE_URL}/reagents/connect/`, {
    headers: getJwtHeaders(jwt),
    method: 'POST',
    body,
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull add');
  }
  const res = await response.json();
  return res;
};

export const getReagentLotWithReagents = async (
  session: Session,
  lotNumber: string
): Promise<{ status: string; data: Response<any> }> => {
  const response = await fetch(`${FACTORY_BACKEND_API_BASE_URL}/reagentlots/${lotNumber}`, {
    headers: getAuthHeaders(session),
    method: 'GET',
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull get');
  }
  const res = await response.json();
  return res;
};

export const getReagentPairedInstruments = async (
  session: Session,
  reagentId: string
): Promise<{ status: string; data: Response<any> }> => {
  const response = await fetch(`${FACTORY_BACKEND_API_BASE_URL}/reagents/${reagentId}`, {
    headers: getAuthHeaders(session),
    method: 'GET',
  });
  if (response.status !== 200) {
    throw new Error('unsuccessfull get');
  }
  const res = await response.json();
  return res;
};
