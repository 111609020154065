import styled from 'styled-components';

const CycleInfoBox = styled.div<{ isError?: boolean }>`
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: ${({ theme, isError }) => (isError ? theme.colors.danger.iconBackground : theme.background.light)};
  background-clip: content-box;
  width: 100%;
`;

export default CycleInfoBox;
