import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import BackButton from 'components/styled-components/buttons/BackButton';
import styled from 'styled-components';

interface BackNavProps {
  navigate: () => void;
  text: string;
}

const NavContainer = styled.div`
  margin: 1em 0;
`;

function BackNav({ navigate, text }: BackNavProps) {
  return (
    <NavContainer>
      <nav>
        <BackButton onClick={() => navigate()}>
          <ArrowBackIcon size="small" />
          <p>{text}</p>
        </BackButton>
      </nav>
    </NavContainer>
  );
}

export default BackNav;
