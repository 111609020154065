import { DefaultTheme } from 'app/AppStyling';

function ResgisterReagentFailedIcon() {
  const theme = DefaultTheme;
  return (
    <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.5 34C68.5 52.4947 53.2846 67.5 34.5 67.5C15.7154 67.5 0.5 52.4947 0.5 34C0.5 15.5053 15.7154 0.5 34.5 0.5C53.2846 0.5 68.5 15.5053 68.5 34Z"
        fill={theme.icon.failedLightRed}
        stroke="#EE2A4A"
      />
      <path
        d="M44.5363 19L34.5 28.3888L24.4637 19L19 24.1112L29.0363 33.5L19 42.8888L24.4637 48L34.5 38.6112L44.5363 48L50 42.8888L39.9637 33.5L50 24.1112L44.5363 19Z"
        fill={theme.icon.failedDarkRed}
      />
    </svg>
  );
}

export default ResgisterReagentFailedIcon;
