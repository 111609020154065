import { useTheme } from 'app/AppStyling';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import { bpTheme } from 'helpers/window/use-current-breakpoint';
import { LoadingState } from 'interfaces/common';
import styled from 'styled-components';

import progressFile from '../../../assets/icons/progressFile.svg';

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 3em;
  justify-content: center;
`;
const FileContainer = styled.div<{ finishedLoading: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.lineColor};
  opacity: ${({ finishedLoading }) => (finishedLoading ? 0.5 : 1)};
  width: 100%;
`;
const ProgressBarContainer = styled.div`
  border-radius: 1em;
  width: 100%;
  height: 0.5em;
`;
const ProgressBar = styled.div<{ statusColor: string; finishedLoading: boolean }>`
  display: block;
  height: 100%;
  border-radius: 20px;
  background-color: ${({ statusColor }) => statusColor};
  background-image: linear-gradient(center bottom, #66a3ff 37%, rgb(84, 240, 84) 69%);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${({ finishedLoading }) =>
      !finishedLoading &&
      `background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );`}
    background-size: 50px 50px;
    animation: load 20s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  transform-origin: left;
  animation: load 2s infinite var(--ease-in-out-quartic) forwards;
  animation-iteration-count: infinite;
  @keyframes load {
    0% {
      left: -100%;
    }
    50% {
      left: 0;
    }
    100% {
      left: 200%;
    }
  }
`;
const ImgContainer = styled.div`
  margin: 1em 1em 0.5em 1em;
`;
const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33em;
  ${() => bpTheme.breakpoints.down('lg')} {
    width: 25em;
  }
  ${() => bpTheme.breakpoints.down('md')} {
    width: 20em;
  }
  ${() => bpTheme.breakpoints.down('sm')} {
    width: 8em;
  }
`;
interface ProgressRowProps {
  name: string;
  status: LoadingState;
  deleteCallback: () => void;
}

function ProgressRow({ name, status, deleteCallback }: ProgressRowProps) {
  const theme = useTheme();
  const fileFinished = status === LoadingState.Failed || status === LoadingState.Succeeded;

  function getColorStatus() {
    switch (status) {
      case LoadingState.Succeeded:
        return theme.colors.success.icon;
      case LoadingState.Failed:
        return theme.colors.danger.icon;
      default:
        return theme.colors.blue;
    }
  }

  return (
    <FileContainer finishedLoading={fileFinished}>
      <ImgContainer>
        <img src={progressFile} alt="file-icon" />
      </ImgContainer>
      <ProgressContainer>
        <FileNameContainer>
          <TextWrapper>{name}</TextWrapper>
          <CloseButton
            closeCallback={deleteCallback}
            buttonColor={fileFinished ? theme.font.color.black : theme.icon.dark}
          />
        </FileNameContainer>
        <ProgressBarContainer>
          <ProgressBar statusColor={getColorStatus()} finishedLoading={fileFinished} />
        </ProgressBarContainer>
      </ProgressContainer>
    </FileContainer>
  );
}

export default ProgressRow;
