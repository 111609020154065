/* eslint-disable jsx-a11y/media-has-caption */
import { ReagentFormInput } from '@dewire/models/definitions/form-input/reagent-form-input';
import QrFrameIcon from 'assets/icons/QrFrameIcon';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import ReagentModal from 'components/modals/reagent/ReagentModal';
import NumberInputField from 'components/selection/input/NumberInputField';
import TextInputField from 'components/selection/input/TextInputField';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import SecondaryButton from 'components/styled-components/buttons/SecondaryButton';
import QrFrameWrapper from 'components/styled-components/wrappers/QrFrameWrapper';
import VideoWrapper from 'components/styled-components/wrappers/VideoWrapper';
import addNewReagent from 'helpers/reagent/add';
import getTranslation from 'helpers/translation/get-translation';
import { LoadingState, ReagentTypes } from 'interfaces/common';
import QrScanner from 'qr-scanner';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 'none';
`;
const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 25em;
`;
const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.h3`
  margin: 1em 0;
`;

// reference: BM900 Application Specification - B001-14384 - Instrument Definition - Brands
const parseBrand = (id: string): string => {
  switch (id) {
    case '00':
      return 'Boule';
    case '01':
      return 'Swelab Alfa';
    case '02':
      return 'Medonic M-Series';
    default:
      return 'unknown';
  }
};

// reference: BM900 Application Specification - B001-14510 - Consumables Definition - Reagent Types
const parseReagentType = (id: string): string => {
  switch (id) {
    case '00':
      return ReagentTypes.Diluent;
    case '01':
      return ReagentTypes.Lyse1;
    case '02':
      return ReagentTypes.Lyse2;
    case '03':
      return ReagentTypes.ReticulocyteStain;
    default:
      return 'unknown';
  }
};

const mapToReagentFormInput = (data: string): ReagentFormInput => {
  // reference: BM900 Application Specification - B001-14513 - Reagent - QR code format
  const rawLotNumber = data.substring(8, 18);
  const rawInstrumentModel = data.substring(32, 34);
  const rawType = data.substring(36, 38);
  const rawVolume = data.substring(62, 67);
  const rawMargin = data.substring(69, 72);
  const rawContainerId = data.substring(20, 26);
  const rawExpiryDate = data.substring(48, 54);

  return {
    lotNumber: rawLotNumber,
    instrumentBaseModel: parseBrand(rawInstrumentModel),
    type: parseReagentType(rawType),
    volume: Number(rawVolume),
    margin: Number(rawMargin),
    numberOfReagents: Number(rawContainerId),
    expiryDate: new Date(
      // Javascript assumnes 19XX if not specifing century
      Number(`20${rawExpiryDate.substring(0, 2)}`),
      // Javascript uses 0 indexed months
      Number(rawExpiryDate.substring(2, 4)) - 1,
      Number(rawExpiryDate.substring(4, 6))
    ),
  };
};

const getDisabledTextField = (label: string, value: string): ReactElement => (
  <TableRow>
    <TextInputField disabled key={label} label={label} value={value ?? ''} filled={false} onChangeCallback={() => {}} />
  </TableRow>
);

const reagentDescriptionRegEx =
  /^(00)[0-9]{4}(01)[a-zA-Z0-9]{10}(02)[a-zA-Z0-9]{6}(03)[0-9]{2}(04)(01|02)(05)[0-9]{2}(06)[0-9]{6}(07)[0-9]{6}(08)[0-9]{4}(09)[0-9]{5}(10)[0-9]{3}(11)[a-zA-Z0-9]{4}$/;
interface ReagentScannerProps {
  onScanReultFaultyCallback: () => void;
}
function ReagentScanner({ onScanReultFaultyCallback }: ReagentScannerProps) {
  const scanner = useRef<QrScanner>();
  const videoElement = useRef<HTMLVideoElement>(null);
  const qrBoxElement = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  const reagentState = useAppSelector((state) => state.reagent);

  const [scannedResult, setScannedResult] = useState<string | undefined>('');
  const [addReagentActive, setAddReagentActive] = useState(false);

  const [reagentModalToggled, setReagentModalToggled] = useState(false);
  const [successfullySaved, setSuccessfullySaved] = useState(false);
  const [reagentInstance, setReagentInstance] = useState<Partial<ReagentFormInput>>();
  const [modalMessage, setModalMessage] = useState<string>('');

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    setScannedResult(result?.data);
    const data = result?.data;
    if (!data || !reagentDescriptionRegEx.test(data)) {
      setSuccessfullySaved(false);
      onScanReultFaultyCallback();
      setReagentModalToggled(true);
      setScannedResult(undefined);
    }
    setReagentInstance(mapToReagentFormInput(data));
    scanner.current?.stop();
  };

  const onAddReagent = () => {
    setAddReagentActive(true);
    addNewReagent(
      reagentInstance as ReagentFormInput,
      () => {
        if (reagentInstance?.numberOfReagents === 1) {
          setModalMessage(`${reagentInstance?.numberOfReagents} reagent was successfully saved.`);
        } else if (reagentInstance?.numberOfReagents && reagentInstance?.numberOfReagents > 1) {
          setModalMessage(`${reagentInstance?.numberOfReagents} reagents were successfully saved.`);
        } else {
          setModalMessage(`Reagent was successfully saved.`);
        }
        setReagentModalToggled(true);
        setSuccessfullySaved(true);
      },
      () => {
        if (reagentInstance?.numberOfReagents === 1) {
          setModalMessage(
            `Failed to save ${reagentInstance?.numberOfReagents} reagent. ${reagentState.reagents.error}`
          );
        } else if (reagentInstance?.numberOfReagents && reagentInstance?.numberOfReagents > 1) {
          setModalMessage(
            `Failed to save ${reagentInstance?.numberOfReagents} reagents. ${reagentState.reagents.error}`
          );
        } else {
          setModalMessage(`Failed to save reagent. ${reagentState.reagents.error}`);
        }
        setReagentModalToggled(true);
        setSuccessfullySaved(false);
      }
    );
    setAddReagentActive(false);
  };

  useEffect(() => {
    setAddReagentActive(reagentState.reagents.loadingStatus === LoadingState.Loading);
  }, [reagentState.reagents.loadingStatus]);

  useEffect(() => {
    if (videoElement?.current && !scanner.current) {
      scanner.current = new QrScanner(videoElement?.current, onScanSuccess, {
        maxScansPerSecond: 10,
        // In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: 'environment',
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (!videoElement?.current) {
        scanner?.current?.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!qrOn)
      // eslint-disable-next-line no-alert
      alert('Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.');
  }, [qrOn]);

  return (
    <>
      {!scannedResult && (
        <VideoWrapper>
          <video ref={videoElement} width="100%" height="100%" style={{ objectFit: 'cover' }} />
          <QrFrameWrapper ref={qrBoxElement} className="qr-box">
            <QrFrameIcon />
          </QrFrameWrapper>
        </VideoWrapper>
      )}
      {addReagentActive && !reagentModalToggled && <LoadingSpinner />}
      {reagentModalToggled && (
        <ReagentModal
          message={modalMessage}
          success={successfullySaved}
          onFailedCallback={() => setReagentModalToggled(false)}
        />
      )}
      {scannedResult && (
        <ResultContainer>
          <Title>Reagents</Title>
          <RowContainer>
            {getDisabledTextField('Lot Number', reagentInstance?.lotNumber ?? '')}
            {getDisabledTextField('Brand', reagentInstance?.instrumentBaseModel ?? '')}
            {getDisabledTextField('Type', reagentInstance?.type ?? '')}
            {getDisabledTextField('Volume', reagentInstance?.volume?.toString() ?? '')}
            {getDisabledTextField('Margin', reagentInstance?.margin?.toString() ?? '')}
            {getDisabledTextField('Expiry Date', reagentInstance?.expiryDate?.toDateString() ?? '')}
            <NumberInputField
              label="Number of reagents in lot *"
              value={String(reagentInstance?.numberOfReagents)}
              filled={false}
              onChangeCallback={(newNumberOfReagents: number) =>
                setReagentInstance({
                  ...reagentInstance,
                  numberOfReagents: newNumberOfReagents,
                })
              }
              error={!reagentInstance?.numberOfReagents}
            />
          </RowContainer>
          <ButtonContainer>
            <PrimaryButton
              onClick={() => {
                onAddReagent();
                setAddReagentActive(true);
              }}
              width={22}
              height={4}
              disabled={!reagentInstance?.numberOfReagents}
            >
              {addReagentActive ? (
                <LoadingSpinner containerBased inButton />
              ) : (
                getTranslation(`Save ${reagentInstance?.numberOfReagents} reagents`)
              )}
            </PrimaryButton>
            <SecondaryButton onClick={() => window.location.reload()} width={22} height={4}>
              {getTranslation(`Cancel`)}
            </SecondaryButton>
          </ButtonContainer>
        </ResultContainer>
      )}
    </>
  );
}

export default ReagentScanner;
