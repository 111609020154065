import { useTheme } from 'app/AppStyling';
import styled from 'styled-components';

import TableRowBase from './TableRowBase';

const TableHeader = styled(TableRowBase)`
  font-weight: bold;
  background-color: ${({ theme }) => theme.background.light};
  padding: 1em;
  border-bottom: 1px solid ${() => useTheme().font.color.tertiary};
`;

export default TableHeader;
