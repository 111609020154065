import { Distributor } from '@dewire/models/definitions/api-response/distributor';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoadingState } from 'interfaces/common';
import FullGetResponse from 'interfaces/full-get-response';

export const successfulDistributorAddCase = (state: any, action: PayloadAction<FullGetResponse<Distributor>>) => ({
  ...state,
  distributors: {
    ...state.distributors,
    content: state.distributors.content.concat(action.payload.data.result),
    updateStatus: LoadingState.Succeeded,
  },
});

export const successfulDistributorDeleteCase = (state: any, action: PayloadAction<FullGetResponse<number>>) => {
  const distributorId: number = action.payload.data.result;
  const updatedDistributors = [...state.distributors.content];
  const distributorIndex = updatedDistributors.findIndex((val: Distributor) => val.formData.id === distributorId);
  if (distributorIndex !== -1) {
    updatedDistributors.splice(distributorIndex, 1);
    return {
      ...state,
      distributors: {
        ...state.distributors,
        content: updatedDistributors,
        updateStatus: LoadingState.Succeeded,
      },
    };
  }
  return {
    ...state,
    distributors: {
      ...state.distributors,
      updateStatus: LoadingState.Succeeded,
    },
  };
};
