import { useTheme } from 'app/AppStyling';

function MachineryIcon() {
  return (
    <svg
      fill={useTheme().font.color.secondary}
      width="30"
      height="30"
      stroke={useTheme().font.color.secondary}
      strokeWidth="1.5"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 312 312"
      xmlSpace="preserve"
    >
      <g>
        <path d="M222.02,205c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-0.01c-4.142,0-7.495,3.357-7.495,7.5S217.878,205,222.02,205z" />
        <path d="M189.02,205c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-0.01c-4.142,0-7.495,3.357-7.495,7.5S184.878,205,189.02,205z" />
        <path
          d="M288,165h-25.5V24c0-4.143-3.357-7.5-7.5-7.5h-27.563v-9c0-4.143-3.357-7.5-7.5-7.5h-82.5c-4.143,0-7.5,3.357-7.5,7.5v9H57
          c-4.143,0-7.5,3.357-7.5,7.5v141H24c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h23.5v42c0,4.143,3.357,7.5,7.5,7.5h11V264h-9
          c-4.143,0-7.5,3.357-7.5,7.5v33c0,4.143,3.357,7.5,7.5,7.5h198c4.143,0,7.5-3.357,7.5-7.5v-33c0-4.143-3.357-7.5-7.5-7.5h-9v-34.5
          h7c4.143,0,7.5-3.357,7.5-7.5v-42H288c4.143,0,7.5-3.357,7.5-7.5S292.143,165,288,165z M144.938,15h67.5v34.5h-67.5V15z
          M169.688,64.5h18v5.797h-18V64.5z M129.938,31.5V57c0,4.143,3.357,7.5,7.5,7.5h17.25v13.297c0,4.143,3.357,7.5,7.5,7.5h33
          c4.143,0,7.5-3.357,7.5-7.5V64.5h17.25c4.143,0,7.5-3.357,7.5-7.5V31.5H247.5V165h-44.813v-46.916c0-9.644-7.198-17.62-16.5-18.888
          V96c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v3.196c-9.302,1.268-16.5,9.244-16.5,18.888V165H130.5v-46.916
          c0-9.644-7.198-17.62-16.5-18.888V96c0-4.143-3.357-7.5-7.5-7.5S99,91.857,99,96v3.196c-9.302,1.268-16.5,9.244-16.5,18.888V165
          h-18V31.5H129.938z M173.771,114h9.833c2.252,0,4.084,1.832,4.084,4.084V165h-18v-46.916C169.688,115.832,171.52,114,173.771,114z
          M101.583,114h9.833c2.252,0,4.084,1.832,4.084,4.084V165h-18v-46.916C97.5,115.832,99.332,114,101.583,114z M62.5,180h183v34.5
          h-183V180z M247.5,297h-183v-18h183V297z M231,264H81v-34.5h150V264z"
        />
        <rect x="106.5" y="189" width="16.5" height="16.5" />
        <rect x="73.5" y="189" width="16.5" height="16.5" />
      </g>
    </svg>
  );
}

export default MachineryIcon;
