function QrCodeIcon() {
  return (
    <svg width="315" height="303" viewBox="0 0 315 303" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.375 63.125V113.625H65.625V63.125H118.125V37.875H65.625C51.1875 37.875 39.375 49.2375 39.375 63.125ZM65.625 189.375H39.375V239.875C39.375 253.763 51.1875 265.125 65.625 265.125H118.125V239.875H65.625V189.375ZM249.375 239.875H196.875V265.125H249.375C263.813 265.125 275.625 253.763 275.625 239.875V189.375H249.375V239.875ZM249.375 37.875H196.875V63.125H249.375V113.625H275.625V63.125C275.625 49.2375 263.813 37.875 249.375 37.875Z"
        fill="#C2CDD6"
      />
      <path
        d="M83.2041 77.7727V143.962H149.245V77.7727H83.2041ZM132.735 127.415H99.7143V94.32H132.735V127.415ZM83.2041 160.509V226.699H149.245V160.509H83.2041ZM132.735 210.151H99.7143V177.057H132.735V210.151ZM165.755 77.7727V143.962H231.796V77.7727H165.755ZM215.286 127.415H182.265V94.32H215.286V127.415ZM165.755 160.509V226.699H231.796V160.509H165.755ZM215.286 210.151H182.265V177.057H215.286V210.151Z"
        fill="#C2CDD6"
      />
      <path
        d="M180.127 175.218V191.398H196.756V175.218H180.127ZM192.599 187.353H184.284V179.263H192.599V187.353ZM180.127 195.443V211.622H196.756V195.443H180.127ZM192.599 207.577H184.284V199.487H192.599V207.577ZM200.913 175.218V191.398H217.542V175.218H200.913ZM213.385 187.353H205.07V179.263H213.385V187.353ZM200.913 195.443V211.622H217.542V195.443H200.913ZM213.385 207.577H205.07V199.487H213.385V207.577Z"
        fill="#C2CDD6"
      />
    </svg>
  );
}

export default QrCodeIcon;
