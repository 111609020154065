import { Market } from '@dewire/models/definitions/api-response/market';
import { DistributorFormInput } from '@dewire/models/definitions/form-input/distributor-form-input';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import DropdownInput from 'components/selection/input/DropdownInput';
import TextInputField from 'components/selection/input/TextInputField';
import SearchInputField from 'components/selection/search/SearchInputField';
import CloseButton from 'components/styled-components/buttons/CloseButton';
import PrimaryButton from 'components/styled-components/buttons/PrimaryButton';
import AddCardContainer from 'components/styled-components/containers/AddCardContainer';
import DimmedContainer from 'components/styled-components/containers/DimmedContainer';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import TitleWrapper from 'components/styled-components/wrappers/TitleWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { findCountry, getCountryCodes } from 'helpers/formatters/country-codes';
import { addNewDistributor } from 'helpers/organization/add';
import getTranslation from 'helpers/translation/get-translation';
import validateInput from 'helpers/validation/validate-input';
import { LoadingState, Status } from 'interfaces/common';
import { FieldInput } from 'interfaces/field-input';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const NewDistributorTitleWrapper = styled(TitleWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 1em;
`;
const FlexContainer = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const DropdownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const DropdownWrapper = styled.div`
  margin: 1em;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RequiredTextWrapper = styled(TextWrapper)`
  margin: 1em;
`;

interface AddDistributorModalProps {
  onCloseCallback: () => void;
}

function AddDistributorModal({ onCloseCallback }: AddDistributorModalProps) {
  const countryCodes = getCountryCodes();
  const [adminState, distributorsState, marketsState] = useAppSelector((state) => [
    state.admin,
    state.admin.distributors.content,
    state.admin.markets.content,
  ]);

  const [error, setError] = useState(true);
  const [firstChange, setFirstChange] = useState(true);
  const [addDistributorActive, setAddDistributorActive] = useState(false);
  const [distributorInstance, setDistributorInstance] = useState<Partial<DistributorFormInput>>({});

  const listMarketsAsFieldInput = () => marketsState.map((market: Market) => ({ label: market.name, id: market.id }));

  const findMarketNameById = (id?: number) => marketsState.find((market: Market) => market.id === id)?.name ?? '';

  const onAddDistributor = () => {
    addNewDistributor(
      distributorInstance as DistributorFormInput,
      () => {
        provideSnackbar(
          Status.Success,
          lodash.capitalize(Status.Success),
          `Distributor "${distributorInstance.name}" was successfully added.`
        );
        onCloseCallback();
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to add distributor');
        onCloseCallback();
      }
    );
  };

  // Input validation
  useEffect(() => {
    if (
      lodash.isEqual(distributorInstance, {}) ||
      Object.values(distributorInstance).every((property) => property === '' || property === undefined)
    ) {
      setFirstChange(true);
    } else {
      setFirstChange(false);
    }
    if (
      !validateInput({ value: distributorInstance.name, params: ['required'], distributors: distributorsState })
        .error &&
      !validateInput({ value: distributorInstance.marketId?.toString(), params: ['requiredId'] }).error &&
      !validateInput({ value: distributorInstance.countryCode, params: ['required'] }).error &&
      !validateInput({ value: distributorInstance.email, params: ['email'] }).error &&
      !validateInput({ value: distributorInstance.phoneNumber, params: ['phone'] }).error
    ) {
      setError(false);
    } else {
      setError(true);
    }
  }, [distributorInstance, distributorsState]);

  useEffect(() => {
    setAddDistributorActive(adminState.distributors.updateStatus === LoadingState.Loading);
  }, [adminState.distributors.updateStatus]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseCallback();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCloseCallback]);

  return (
    <DimmedContainer>
      <AddCardContainer>
        <NewDistributorTitleWrapper>
          {getTranslation('New distributor')}
          <CloseButton closeCallback={onCloseCallback} />
        </NewDistributorTitleWrapper>
        <FlexContainer>
          <DropdownInputContainer>
            <TextInputField
              label={getTranslation('Name *')}
              filled
              value={distributorInstance.name ?? ''}
              onChangeCallback={(newName: string) => setDistributorInstance({ ...distributorInstance, name: newName })}
              error={
                !firstChange &&
                validateInput({
                  value: distributorInstance.name,
                  params: ['required'],
                  distributors: distributorsState,
                }).error
              }
              helper={
                validateInput({
                  value: distributorInstance.name,
                  params: ['required'],
                  distributors: distributorsState,
                }).errorMessage
              }
            />
            <DropdownWrapper>
              <DropdownInput
                label={getTranslation('Market *')}
                value={{ label: findMarketNameById(distributorInstance.marketId), id: distributorInstance.marketId }}
                valueArray={listMarketsAsFieldInput()}
                checkbox
                onChangeCallback={(newMarket: number | undefined) => {
                  setDistributorInstance({ ...distributorInstance, marketId: newMarket });
                }}
                error={
                  !firstChange &&
                  validateInput({ value: distributorInstance.marketId?.toString(), params: ['requiredId'] }).error
                }
                helper="Please select a market"
              />
            </DropdownWrapper>
          </DropdownInputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Address')}
              filled
              value={distributorInstance.address ?? ''}
              onChangeCallback={(newAddress: string) =>
                setDistributorInstance({ ...distributorInstance, address: newAddress })
              }
            />
            <TextInputField
              label={getTranslation('City')}
              filled
              value={distributorInstance.city ?? ''}
              onChangeCallback={(newCity: string) => setDistributorInstance({ ...distributorInstance, city: newCity })}
              containerHeight={116}
            />
            <SearchInputField
              label={getTranslation('Country *')}
              value={findCountry(distributorInstance.countryCode)}
              valueArray={countryCodes}
              onChangeCallback={(newCountry: FieldInput<string>) =>
                setDistributorInstance({ ...distributorInstance, countryCode: newCountry.id })
              }
              loading={false}
              error={
                !firstChange && validateInput({ value: distributorInstance.countryCode, params: ['required'] }).error
              }
              helper={getTranslation('Please select a country')}
            />
            <TextInputField
              label={getTranslation('Postal Code')}
              filled
              value={distributorInstance.postalCode ?? ''}
              onChangeCallback={(newPostal: string) =>
                setDistributorInstance({ ...distributorInstance, postalCode: newPostal })
              }
            />
          </InputContainer>
          <InputContainer>
            <TextInputField
              label={getTranslation('Email')}
              filled
              value={distributorInstance.email ?? ''}
              onChangeCallback={(newEmail: string) =>
                setDistributorInstance({ ...distributorInstance, email: newEmail })
              }
              error={
                !firstChange &&
                !!distributorInstance.email &&
                distributorInstance.email.length > 0 &&
                validateInput({ value: distributorInstance.email, params: ['email'] }).error
              }
              helper="Please enter a valid email address"
            />
            <TextInputField
              label={getTranslation('Phone')}
              filled
              value={distributorInstance.phoneNumber ?? ''}
              onChangeCallback={(newPhone: string) =>
                setDistributorInstance({ ...distributorInstance, phoneNumber: newPhone })
              }
              error={
                !firstChange &&
                !!distributorInstance.phoneNumber &&
                distributorInstance.phoneNumber.length > 0 &&
                validateInput({ value: distributorInstance.phoneNumber, params: ['phone'] }).error
              }
              helper="Please enter a valid phone number"
              containerHeight={116}
            />
            <TextInputField
              label={getTranslation('Comment')}
              filled
              multiline
              rows={6}
              value={distributorInstance.comment ?? ''}
              onChangeCallback={(newComment: string | undefined) =>
                setDistributorInstance({ ...distributorInstance, comment: newComment })
              }
              containerHeight={205}
            />
          </InputContainer>
        </FlexContainer>
        <ButtonContainer>
          <RequiredTextWrapper subtitle size="small">
            {getTranslation('* Required fields')}
          </RequiredTextWrapper>
          <PrimaryButton disabled={error || addDistributorActive} onClick={() => onAddDistributor()}>
            {addDistributorActive ? <LoadingSpinner containerBased inButton /> : getTranslation('Add')}
          </PrimaryButton>
        </ButtonContainer>
      </AddCardContainer>
    </DimmedContainer>
  );
}

export default AddDistributorModal;
