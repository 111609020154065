import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface ActivityState {
  lastActive: number;
}

const initialState: ActivityState = {
  lastActive: dayjs().unix(),
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    logActivity: () => ({ lastActive: dayjs().unix() }),
  },
});

export const { logActivity } = activitySlice.actions;

export default activitySlice.reducer;
