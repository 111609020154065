/* eslint-disable @typescript-eslint/no-unused-vars */
import { BouleUserDetails } from '@dewire/models/definitions/api-response/boule-user-details';
import { Origin } from '@dewire/models/definitions/api-response/user-admin/origin';
import { OriginTypes } from '@dewire/models/definitions/origin-types';
import { getPersonalUser } from 'api/Api';
import ManageListButtonCard from 'components/cards/origins/ManageListButtonCard';
import UserAccessCard from 'components/cards/origins/UserAccessCard';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import AccessOriginModal from 'components/modals/AccessOriginModal';
import ManageUserAccessModal from 'components/modals/manage/ManageUserAccessModal';
import NavigationModal from 'components/modals/NavigationModal';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { HelperText, Paths, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

const AccessList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`;

interface DropdownRolesSelectProps {
  onSaveCallback: (selectedOrigins: Origin[]) => void;
  required?: boolean;
}

function RoleSelection({ onSaveCallback, required = false }: DropdownRolesSelectProps) {
  const [headersState] = useAppSelector((state) => [state.headers, state.users]);
  const [userDetails, setUserDetails] = useState<BouleUserDetails>();
  const [userDetailsLoading, setUserDetailsLoading] = useState(true);
  const [accessOrigin, setAccessOrigin] = useState<{ unitId: string; originType: OriginTypes; unitName: string }>();
  const [isManageOriginActive, setIsManageOriginActive] = useState(false);
  const [apiError, setApiError] = useState('');
  const [selectedOrigins, setSelectedOrigins] = useState<Origin[]>([]);

  const onSaveOrigins = (units: Origin[]) => {
    setSelectedOrigins(units);
    setIsManageOriginActive(false);
    onSaveCallback(units);
  };

  useEffect(() => {
    const getUserId = async () => {
      const res = await getPersonalUser(headersState);
      if (res.status === Status.Ok) {
        setUserDetails(res.data.result);
        setUserDetailsLoading(false);
        setApiError('');
      } else {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), res.status);
        setUserDetailsLoading(false);
        setApiError(res.status);
      }
    };
    getUserId();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccessList>
      {isManageOriginActive && (
        <ManageUserAccessModal
          origins={selectedOrigins}
          onSaveCallback={(origins: Origin[]) => onSaveOrigins(origins)}
          onCloseCallback={() => setIsManageOriginActive(false)}
        />
      )}
      {accessOrigin &&
        userDetails &&
        (accessOrigin.originType === 'ADMIN' ? (
          <NavigationModal
            infoTitle="Full access"
            infoText={HelperText.OrganizationNavigation}
            navigationButtonText="Organization"
            navigationPath={Paths.OrganizationUrl}
            onCloseCallback={() => setAccessOrigin(undefined)}
          />
        ) : (
          <AccessOriginModal
            unit={{ ...accessOrigin, userId: String(userDetails.id) }}
            closeCallback={() => setAccessOrigin(undefined)}
          />
        ))}
      {apiError === '' && !userDetailsLoading && (
        <>
          <ManageListButtonCard
            title={`Manage access${required && ' *'}`}
            onClickCallback={() => setIsManageOriginActive(true)}
          />
          {selectedOrigins.map(({ id, name, type }) => (
            <UserAccessCard
              key={id}
              name={name}
              level={type}
              toggleModalCallback={() => setAccessOrigin({ unitId: id.toString(), originType: type, unitName: name })}
            />
          ))}
        </>
      )}
      {userDetailsLoading && <LoadingSpinner containerBased />} {apiError && <p> {apiError}</p>}
    </AccessList>
  );
}

export default RoleSelection;
