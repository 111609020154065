function CheckSnackbarIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.82 9.82L4.86 7.86C4.74 7.74 4.59333 7.68 4.42 7.68C4.24667 7.68 4.09333 7.74667 3.96 7.88C3.82667 8.01333 3.76 8.16667 3.76 8.34C3.76 8.51333 3.82667 8.66 3.96 8.78L6.4 11.22C6.52 11.34 6.66 11.4 6.82 11.4C6.98 11.4 7.12 11.34 7.24 11.22L12.04 6.42C12.16 6.3 12.2167 6.15333 12.21 5.98C12.2033 5.80667 12.14 5.66 12.02 5.54C11.9 5.40667 11.75 5.34 11.57 5.34C11.39 5.34 11.2333 5.40667 11.1 5.54L6.82 9.82ZM8 16C6.86667 16 5.81333 15.7967 4.84 15.39C3.86667 14.9833 3.02 14.42 2.3 13.7C1.58 12.98 1.01667 12.1333 0.61 11.16C0.203333 10.1867 0 9.13333 0 8C0 6.88 0.203333 5.83333 0.61 4.86C1.01667 3.88667 1.58 3.04 2.3 2.32C3.02 1.6 3.86667 1.03333 4.84 0.62C5.81333 0.206667 6.86667 0 8 0C9.12 0 10.1667 0.206667 11.14 0.62C12.1133 1.03333 12.96 1.6 13.68 2.32C14.4 3.04 14.9667 3.88667 15.38 4.86C15.7933 5.83333 16 6.88 16 8C16 9.13333 15.7933 10.1867 15.38 11.16C14.9667 12.1333 14.4 12.98 13.68 13.7C12.96 14.42 12.1133 14.9833 11.14 15.39C10.1667 15.7967 9.12 16 8 16ZM8 14.8C9.93333 14.8 11.55 14.15 12.85 12.85C14.15 11.55 14.8 9.93333 14.8 8C14.8 6.06667 14.15 4.45 12.85 3.15C11.55 1.85 9.93333 1.2 8 1.2C6.06667 1.2 4.45 1.85 3.15 3.15C1.85 4.45 1.2 6.06667 1.2 8C1.2 9.93333 1.85 11.55 3.15 12.85C4.45 14.15 6.06667 14.8 8 14.8Z"
        fill="#00BE8C"
      />
    </svg>
  );
}

export default CheckSnackbarIcon;
