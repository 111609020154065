import { AddUserInput } from '@dewire/models/definitions/api-response/user-admin/add-user-input';
import { addUser } from 'redux/slices/users.slice';
import { store } from 'redux/store';

export default async function addNewUser(
  user: AddUserInput,
  onAddedUser: () => void,
  onAddUserFailed: (error: unknown) => void
) {
  const headersState = store.getState().headers;
  try {
    const promise = store.dispatch(addUser({ session: headersState, user }));
    await promise.unwrap();
    onAddedUser();
  } catch (error) {
    onAddUserFailed(error);
  }
}
