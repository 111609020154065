/* eslint-disable react/jsx-props-no-spreading */
import { UploadHistoryEntry } from '@dewire/models/definitions/api-response/upload-history-entry';
import RefreshIcon from 'assets/icons/RefreshIcon';
import FilterModal from 'components/modals/FilterModal';
import Paginator from 'components/paginator/Paginator';
import FilterGroup from 'components/selection/filter/FilterGroup';
import RefreshContainer from 'components/styled-components/containers/RefreshContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import dayjs from 'dayjs';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { getAllFiles } from 'helpers/file-upload/getters';
import { FileTypes, LoadingState, Status } from 'interfaces/common';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';
import FileUploadContainer from '../../components/file-upload/FileUploadContainer';
import FileUploadHistory from '../../components/file-upload/FileUploadHistory';
import FileRow from '../../components/rows/file-upload/FileRow';

const Container = styled.div`
  padding: 3em;
`;
const ToolbarFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

function FileUploadView() {
  const filesState = useAppSelector((state) => state.files);

  const [history, setHistory] = useState<UploadHistoryEntry[]>([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const pageLimit = 8;
  const [historyIsLoading, setHistoryIsLoading] = useState(true);
  const filterActive = filter.length !== 0;
  const filterOptions = Object.values(FileTypes);

  const handleFilter = (selection: string) => {
    const newFilter: string[] = !filter.includes(selection)
      ? [...filter, selection]
      : filter.filter((f) => f !== selection);
    setFilter(newFilter);
  };

  const handleFilterClear = () => {
    setFilter([]);
  };

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const loadHistory = (): void => {
    getAllFiles(
      (response) => {
        setHistory(response);
      },
      () => {
        provideSnackbar(Status.Error, lodash.capitalize(Status.Error), 'Unable to fetch file history.');
      }
    );
  };

  useEffect(() => {
    loadHistory();
  }, []);

  useEffect(() => {
    const status = filesState.history.loadingStatus;
    setHistoryIsLoading(status === LoadingState.Loading);
  }, [filesState.history.loadingStatus]);

  useEffect(() => {
    handlePagination(1);
  }, [search, filter, history]);

  const fileUploadHistory = history
    .filter((file) => {
      const uploadDate = dayjs(file.uploadDate);
      const exportDate = dayjs(file.exportDate);
      return (
        (!search.length ||
          (search.length && file.fileName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (search.length && file.site.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (search.length && file.user.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (search.length &&
            uploadDate &&
            uploadDate.format('YYYY-MM-DD HH:mm:ss').toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (search.length &&
            exportDate &&
            exportDate.format('YYYY-MM-DD HH:mm:ss').toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (search.length && file.user.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (search.length && file.exportedFile.toLowerCase().indexOf(search.toLowerCase()) !== -1)) &&
        (!filter.length || filter.includes(file.exportedFile))
      );
    })
    .map((file, i) => <FileRow file={file} key={file.uploadstatuslog_id} color={i % 2 === 0} />);

  return (
    <Container>
      <FileUploadContainer loadHistoryCallback={loadHistory} />
      <ToolbarFlexContainer>
        <FlexContainer>
          <SearchBarContainer>
            <SearchField
              type="TextWrapper"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              iconUrl={magnyfyingGlass}
            />
          </SearchBarContainer>
          <FilterModal isFiltering={filterActive} clearCallback={() => handleFilterClear()}>
            <FilterGroup
              filterOptions={filterOptions}
              filterArray={filter}
              groupWidth={11}
              onFilterCallback={(filterValue) => handleFilter(filterValue)}
            />
          </FilterModal>
        </FlexContainer>
        <RefreshContainer onClick={() => loadHistory()}>
          <RefreshIcon />
        </RefreshContainer>
      </ToolbarFlexContainer>
      <FileUploadHistory
        fileUploadHistory={fileUploadHistory.slice(page * pageLimit - pageLimit, page * pageLimit)}
        historyIsLoading={historyIsLoading}
      />
      {fileUploadHistory.length > pageLimit && !historyIsLoading && (
        <Paginator
          currentPage={page}
          maxPages={Math.ceil(fileUploadHistory.length / pageLimit)}
          paginationCallback={(_page: number) => handlePagination(_page)}
        />
      )}
    </Container>
  );
}

export default FileUploadView;
