import { IQcDisplayRow } from './IQcDisplayRow';

const qcDisplayConfig: IQcDisplayRow[] = [
  {
    name: 'WBC',
    valueKey: 'whiteBloodCellConcentration',
    unit: '10^9/l',
    group: 1,
  },
  {
    name: 'LYM',
    valueKey: 'lymphocyteConcentration',
    shareKey: 'lymphocyteConcentrationRelative',
    unit: '10^9/l',
    group: 1,
  },
  {
    name: 'MID',
    valueKey: 'midConcentration',
    shareKey: 'midConcentrationRelative',
    unit: '10^9/l',
    group: 1,
  },
  {
    name: 'GRA',
    valueKey: 'granulocyteConcentration',
    shareKey: 'granulocyteConcentrationRelative',
    unit: '10^9/l',
    group: 1,
  },
  {
    name: 'HGB',
    valueKey: 'hemoglobinConcentration',
    unit: 'g/dl',
    group: 2,
  },
  {
    name: 'MCH',
    valueKey: 'meanCellHemoglobin',
    unit: 'pg',
    group: 2,
  },
  {
    name: 'MCHC',
    valueKey: 'meanCellHemoglobinConcentration',
    unit: 'g/dl',
    group: 2,
  },
  {
    name: 'RBC',
    valueKey: 'redBloodCellConcentration',
    unit: '10^12/l',
    group: 3,
  },
  {
    name: 'MCV',
    valueKey: 'meanCellVolume',
    unit: 'fl',
    group: 3,
  },
  {
    name: 'HCT',
    valueKey: 'hematocritValue',
    unit: '%',
    group: 3,
  },
  {
    name: 'RDW%',
    valueKey: 'redBloodCellDistributionWidthRelative',
    unit: '%',
    group: 3,
  },
  {
    name: 'PLT',
    valueKey: 'plateletConcentration',
    unit: '10^9/l',
    group: 4,
  },
  {
    name: 'MPV',
    valueKey: 'meanPlateletVolume',
    unit: 'fl',
    group: 4,
  },
];

export default qcDisplayConfig;
