import { bpTheme } from 'helpers/window/use-current-breakpoint';
import styled from 'styled-components';

const ViewContainer = styled.div<{ columns?: number; rows?: number; flexdircolumn?: boolean }>`
  display: flex;
  flex-direction: ${(flexdircolumn) => (flexdircolumn ? 'column' : 'none')};
  gap: 1em 0;
  padding: 3em;
  ${() => bpTheme.breakpoints.down('md')} {
    padding: 2em 1em;
  }
`;

export default ViewContainer;
