import { InstrumentOverviewCard } from '@dewire/models/definitions/api-response/qc-overview-card';
import { StatusType } from '@dewire/models/definitions/status-type';
import { getQcOverview } from 'api/Api';
import { useTheme } from 'app/AppStyling';
import QcCardAction from 'components/cards/instrument-dashboard/QcCardAction';
import LoadingSpinner from 'components/loading-spinner/LoadingSpinner';
import InstrumentMap from 'components/map/InstrumentMap';
import Paginator from 'components/paginator/Paginator';
import ViewButton from 'components/styled-components/buttons/ViewButton';
import ToolbarContainer from 'components/styled-components/containers/ToolbarContainer';
import { SearchBarContainer, SearchField } from 'components/styled-components/selection/SearchField';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import provideSnackbar from 'helpers/error-handling/provide-snackbar';
import { Status, TextColor } from 'interfaces/common';
import lodash from 'lodash';
import { SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import magnyfyingGlass from '../../assets/icons/magnifying-glas.svg';

const InstrumentsViewContainer = styled.div`
  padding: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ContentWrapper = styled.div<{ mapWidth: boolean }>`
  width: ${({ mapWidth }) => (mapWidth ? '100%' : '100%')};
`;
const NavigationContainer = styled.div`
  display: flex;
  gap: 3em;
`;
const CardContainer = styled.div`
  margin-bottom: 2em;
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, 15em);
  justify-content: space-between;
`;
const CardWrapperLink = styled(Link)`
  text-decoration: none;
  color: ${() => useTheme().font.color.black};
  transition: 50ms box-shadow ease-in-out;
  :hover {
    box-shadow: 3px 3px 10px ${() => useTheme().shadow.secondary};
  }
`;
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StatusBallContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1em;
  :last-child {
    margin: 0;
  }
`;
const StatusBall = styled.div<{ color?: StatusType; checked: boolean }>`
  display: flex;
  margin: 0 0.3em;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 2em;
  width: 2em;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.7);
  color: ${({ theme: { colors }, color, checked }) => {
    if (color === Status.Failure) {
      if (checked) return useTheme().background.light;
      return colors.danger.iconDetails;
    }
    if (color === Status.Warning) {
      if (checked) return useTheme().background.light;
      return colors.warning.iconDetails;
    }
    if (color === Status.Success) {
      if (checked) return useTheme().background.light;
      return colors.success.iconDetails;
    }
    if (color === Status.Unknown) {
      if (checked) return useTheme().background.light;
      return colors.unknown.iconDetails;
    }
    if (checked) return useTheme().background.light;
    return useTheme().font.color.primary;
  }};
  font-weight: 900;
  cursor: pointer;
  border: ${({ theme: { colors }, color, checked }) => {
    if (color === Status.Failure && !checked) {
      return `2px solid ${colors.danger.iconDetails}`;
    }
    if (color === Status.Warning && !checked) {
      return `2px solid ${colors.warning.iconDetails}`;
    }
    if (color === Status.Success && !checked) {
      return `2px solid ${colors.success.iconDetails}`;
    }
    if (color === Status.Unknown && !checked) {
      return `2px solid ${colors.unknown.iconDetails}`;
    }
    if (!checked) return `2px solid ${useTheme().font.color.primary}`;
    return 'none';
  }};
  background-color: ${({ theme: { colors }, color, checked }) => {
    if (color === Status.Failure) {
      if (checked) return colors.danger.iconDetails;
      return useTheme().colors.transparent;
    }
    if (color === Status.Warning) {
      if (checked) return colors.warning.iconDetails;
      return useTheme().colors.transparent;
    }
    if (color === Status.Success) {
      if (checked) return colors.success.iconDetails;
      return useTheme().colors.transparent;
    }
    if (color === Status.Unknown) {
      if (checked) return colors.unknown.iconDetails;
      return useTheme().colors.transparent;
    }
    if (checked) return useTheme().font.color.primary;
    return useTheme().colors.transparent;
  }};
  transition: background-color 100ms ease-in-out;
`;
const StatusBallDivider = styled.div`
  font-size: 1.9em;
  font-weight: 100;
`;

function InstrumentsStatusView() {
  const headersState = useAppSelector((state) => state.headers);

  const [originInstruments, setOriginInstruments] = useState<InstrumentOverviewCard[]>([]);
  const [searchedInstruments, setSearchedInstruments] = useState<InstrumentOverviewCard[]>([]);
  const [displayInstruments, setDisplayInstruments] = useState<InstrumentOverviewCard[]>([]);
  const [filters, setFilters] = useState<StatusType[]>([] as StatusType[]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [qcView, setQcView] = useState<'list' | 'map'>('map');
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const pageLimit = 15;

  const filterActive = filters.length !== 0 && filters.length < 4;
  const [filterCounter, setFilterCounter] = useState([0, 0, 0, 0, 0]);

  const handlePagination = (pageNumber: number) => {
    setPage(pageNumber);
  };

  const handleFilter = (selection: StatusType) => {
    const newFilter: StatusType[] = !filters.includes(selection)
      ? [...filters, selection]
      : filters.filter((f) => f !== selection);
    setFilters(newFilter);
  };

  const fetchInstruments = () => {
    getQcOverview(headersState).then(
      (res: { status: string; data: { result: SetStateAction<InstrumentOverviewCard[]> } }) => {
        if (res.status === Status.Ok) {
          setOriginInstruments(res.data.result);
        } else {
          setError(res.status);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (loading) {
      fetchInstruments();
    } else if (originInstruments.length === 0) {
      provideSnackbar(Status.Info, 'Status', 'No instruments currently exist.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (search === '') {
      setSearchedInstruments(originInstruments);
    } else {
      const searchedDistributors = originInstruments.filter(
        (qc) =>
          qc.siteName.toLowerCase().includes(search.toLowerCase()) ||
          qc.serialNumber.includes(search) ||
          qc.city.toLowerCase().includes(search.toLowerCase()) ||
          qc.countryName.toLowerCase().includes(search.toLowerCase()) ||
          qc.distributorName.toLowerCase().includes(search.toLowerCase()) ||
          qc.customerName.toLowerCase().includes(search.toLowerCase())
      );
      setSearchedInstruments(searchedDistributors);
    }
  }, [originInstruments, search]);

  useEffect(() => {
    const countSuccess = searchedInstruments.filter((qc) => qc.siteName && qc.status === Status.Success).length;
    const countFailed = searchedInstruments.filter((qc) => qc.siteName && qc.status === Status.Failure).length;
    const countWarning = searchedInstruments.filter((qc) => qc.siteName && qc.status === Status.Warning).length;
    const countUnknown = searchedInstruments.filter((qc) => qc.siteName && qc.status === Status.Unknown).length;
    const totalCount = countSuccess + countFailed + countWarning + countUnknown;
    setFilterCounter([countSuccess, countFailed, countWarning, countUnknown, totalCount]);

    const matchFilter = searchedInstruments.filter((qc) => filters.includes(qc.status));
    if (filters.length > 0) {
      setDisplayInstruments(matchFilter);
    } else {
      setDisplayInstruments(searchedInstruments);
    }
  }, [searchedInstruments, filters]);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const displayResults = displayInstruments
    .filter((qc) => qc.siteName)
    .map((qc) => (
      <CardWrapperLink to={`/instrument/${qc.serialNumber}`} key={qc.serialNumber}>
        <QcCardAction key={qc.serialNumber} qc={qc} />
      </CardWrapperLink>
    ));

  return (
    <InstrumentsViewContainer>
      <ContentWrapper mapWidth={qcView === 'map'}>
        <NavigationContainer>
          <ViewButton type="button" active={qcView === 'map'} onClick={() => setQcView('map')}>
            Map
          </ViewButton>
          <ViewButton type="button" active={qcView === 'list'} onClick={() => setQcView('list')}>
            List
          </ViewButton>
        </NavigationContainer>
        <StatusContainer>
          <ToolbarContainer>
            <SearchBarContainer>
              <SearchField
                type="text"
                placeholder="Search"
                className="searchField"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                iconUrl={magnyfyingGlass}
              />
            </SearchBarContainer>
          </ToolbarContainer>
          <ToolbarContainer>
            <StatusBallContainer>
              <StatusBall
                title={lodash.capitalize(Status.Success)}
                checked={!filterActive || filters.includes(Status.Success)}
                color={Status.Success}
                className="successCount"
                onClick={() => handleFilter(Status.Success)}
              >
                {filterCounter[0]}
              </StatusBall>
              <StatusBall
                title="Failed"
                checked={!filterActive || filters.includes(Status.Failure)}
                color={Status.Failure}
                className="failureCount"
                onClick={() => handleFilter(Status.Failure)}
              >
                {filterCounter[1]}
              </StatusBall>
              <StatusBall
                title={lodash.capitalize(Status.Warning)}
                checked={!filterActive || filters.includes(Status.Warning)}
                color={Status.Warning}
                className="warningCount"
                onClick={() => handleFilter(Status.Warning)}
              >
                {filterCounter[2]}
              </StatusBall>
              <StatusBall
                title={lodash.capitalize(Status.Unknown)}
                checked={!filterActive || filters.includes(Status.Unknown)}
                color={Status.Unknown}
                className="unknownCount"
                onClick={() => handleFilter(Status.Unknown)}
              >
                {filterCounter[3]}
              </StatusBall>
              <StatusBallDivider>|</StatusBallDivider>
              <StatusBall
                title="All"
                checked={!filterActive}
                className="totalStatusCount"
                onClick={() => setFilters([])}
              >
                {filterCounter[4]}
              </StatusBall>
            </StatusBallContainer>
          </ToolbarContainer>
        </StatusContainer>

        {qcView === 'list' &&
          (loading ? (
            <LoadingSpinner />
          ) : (
            <div>
              <CardContainer>
                {displayResults.slice(page * pageLimit - pageLimit, page * pageLimit)}
                {error && <TextWrapper color={TextColor.Danger}>{error}</TextWrapper>}
              </CardContainer>
              {displayResults.length > pageLimit && !loading && (
                <Paginator
                  currentPage={page}
                  maxPages={Math.ceil(displayResults.length / pageLimit)}
                  paginationCallback={(_page: number) => handlePagination(_page)}
                />
              )}
            </div>
          ))}
        {qcView === 'map' && (
          <div>
            <InstrumentMap qcs={displayInstruments} setTab={setQcView} setSearch={setSearch} />
          </div>
        )}
      </ContentWrapper>
    </InstrumentsViewContainer>
  );
}

export default InstrumentsStatusView;
