import { useTheme } from 'app/AppStyling';
import styled, { keyframes } from 'styled-components';

const SpinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div<{ button: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: ${({ button }) => (button ? '0em' : '1em')};
`;

const LoadingSpinnerElement = styled.div<{ button: boolean; modal?: boolean }>`
  border-radius: 100%;
  border-top: 1px solid ${({ theme: { colors } }) => colors.primary};
  border-bottom: 1px solid ${() => useTheme().font.color.white};
  border-left: 1px solid ${() => useTheme().font.color.white};
  border-right: 1px solid ${() => useTheme().font.color.white};
  height: ${({ button }) => (button ? '3em' : '5em')};
  width: ${({ button }) => (button ? '3em' : '5em')};
  animation: ${SpinAnimation} 1000ms infinite;
  position: absolute;
  top: ${({ modal }) => (modal ? '23%' : '50%')};
  left: 49%;
  transform: translate(-50%, -50%);
  background: transparent;
`;

const LoadingSpinnerContainerElement = styled.div<{ button: boolean; redColor: boolean }>`
  border-radius: 100%;
  border-top: 1px solid ${({ redColor }) => (redColor ? useTheme().colors.error.dark : useTheme().colors.primary)};
  border-bottom: 1px solid
    ${({ redColor }) => (redColor ? useTheme().colors.error.lighter : useTheme().font.color.white)};
  border-left: 1px solid ${({ redColor }) => (redColor ? useTheme().colors.error.lighter : useTheme().font.color.white)};
  border-right: 1px solid
    ${({ redColor }) => (redColor ? useTheme().colors.error.lighter : useTheme().font.color.white)};
  height: ${({ button }) => (button ? '2em' : '3em')};
  width: ${({ button }) => (button ? '2em' : '3em')};
  animation: ${SpinAnimation} 1000ms infinite;
  transform: translate(-50%, -50%);
  background: transparent;
`;

interface LoadingSpinnerProps {
  containerBased?: boolean;
  inButton?: boolean;
  inModal?: boolean;
  error?: boolean;
}

function LoadingSpinner({
  containerBased = false,
  inButton = false,
  inModal = false,
  error = false,
}: LoadingSpinnerProps) {
  return containerBased ? (
    <LoadingContainer button={inButton}>
      {' '}
      <LoadingSpinnerContainerElement button={inButton} redColor={error} />
    </LoadingContainer>
  ) : (
    <LoadingSpinnerElement button={inButton} modal={inModal} />
  );
}

export default LoadingSpinner;
