import { SnackbarType } from 'interfaces/snackbar-type';
import { setSnackbarWithPayload } from 'redux/slices/snackbar.slice';
import { store } from 'redux/store';

export default function provideSnackbar(snackbarType: SnackbarType, snackbarTitle: string, snackbarMessage: string) {
  store.dispatch(
    setSnackbarWithPayload({
      type: snackbarType,
      title: snackbarTitle,
      message: snackbarMessage,
      open: true,
    })
  );
}
