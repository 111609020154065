const loadStateFromStorage = (key: string) => {
  try {
    const serialState = localStorage.getItem(key);
    if (serialState === null) {
      return undefined;
    }
    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};

const saveStateToStorage = (key: string, state: any) => {
  try {
    const serialState = JSON.stringify(state);
    localStorage.setItem(key, serialState);
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export { loadStateFromStorage, saveStateToStorage };
