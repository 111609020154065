import styled from 'styled-components';

export const RootContainer = styled.div<{ margin?: boolean }>`
  display: flex;
  grid-gap: 2em;
  align-items: center;
  justify-content: center;
  width: 10em;
  margin: ${({ margin }) => (margin ? '2em 1em' : 'none')};
  height: 3em;
  border-radius: 2em;
  background-color: ${({ theme }) => theme.background.dark};
  cursor: pointer;
`;

export default RootContainer;
