import ArrowIcon from 'assets/icons/ArrowIcon';
import ScrollToTopButton from 'components/styled-components/buttons/ScrollToTopButton';
import React, { RefObject, useCallback, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

const maxListHeight = 600;
const listItemHeight = 50;
const scrollToTopIndexOffset = 2;

interface ListProps {
  items: any[];
}

function InfiniteScrollList({ items }: ListProps) {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const listRef: RefObject<List<any>> = React.createRef();

  const handleScrolling = useCallback((index: number) => {
    if (index === 0) setShowScrollToTop(false);
    if (index === maxListHeight / listItemHeight + scrollToTopIndexOffset) setShowScrollToTop(true);
  }, []);

  const scrollToTop = () => {
    listRef.current?.scrollToItem(0);
    setShowScrollToTop(false);
  };

  return (
    <>
      <List
        ref={listRef}
        height={items.length > maxListHeight / listItemHeight ? maxListHeight : items.length * listItemHeight}
        itemCount={items?.length ?? 0}
        itemSize={listItemHeight}
        width="100%"
      >
        {({ index, style }) => {
          handleScrolling(index);
          return <div style={style}>{items?.[index]}</div>;
        }}
      </List>
      {showScrollToTop && (
        <ScrollToTopButton
          onClick={() => {
            scrollToTop();
          }}
        >
          <ArrowIcon size="medium" direction="up" arrowColor="white" />
        </ScrollToTopButton>
      )}
    </>
  );
}

export default InfiniteScrollList;
