import styled from 'styled-components';

const TableRowBase = styled.div<{ gridExpression: string; textAlign: string }>`
  ${({ textAlign }) => ({ 'text-align': textAlign })};
  margin: auto;
  align-content: center;
  display: grid;
  padding: 1em 0;
  ${({ gridExpression }) => ({ 'grid-template-columns': gridExpression })};
`;

export default TableRowBase;
