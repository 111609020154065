import { StatusType } from '@dewire/models/definitions/status-type';
import { DefaultTheme, useTheme } from 'app/AppStyling';
import { Status } from 'interfaces/common';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ScaleState } from '../../interfaces/scale-state';

const ScaleBackgound = styled.div`
  grid-column: 1/1;
  grid-row: 1/1;
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${() => useTheme().background.medium}; // was #dae1e6
  border-radius: 50em;
  height: 1em;
  margin: auto;
`;

const AcceptedArea = styled(ScaleBackgound)`
  width: 50%;
  border-radius: 0;
  box-sizing: border-box;
  background: ${() => useTheme().hoverBackground};
`;

const CenterMark = styled.div`
  width: 2px;
  background-color: ${() => useTheme().icon.light};
  height: inherit;
`;

const ValueMark = styled.div<{ width: number; color: string }>`
  height: 1.2em;
  grid-column: 1/1;
  grid-row: 1/1;
  margin: auto 0;
  ${({ width, color }) => ({ width: `${width}%`, borderRight: `6px solid ${color}` })};
`;

const ScaleContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template: 1fr/1fr;
`;

function getWidth(value: number, limitHigh: number, limitLow: number): number {
  const raw = 25 * ((2 / (limitHigh - limitLow)) * value + 2 - (limitHigh + limitLow) / (limitHigh - limitLow));
  const result = Math.min(100, Math.max(0, raw));
  return result;
}

function getMarkerColor(
  value: number | undefined,
  limitHigh: number,
  limitLow: number,
  width: number | undefined,
  setState: (s: ScaleState) => void,
  status: StatusType
): string {
  if (width === undefined || value === undefined) {
    setState('valueMissing');
    return DefaultTheme.font.color.black;
  }
  if (width === 0) {
    setState('lowOutOfRange');
    return DefaultTheme.font.color.black;
  }
  if (width === 100) {
    setState('highOutOfRange');
    return DefaultTheme.font.color.black;
  }
  if (status === Status.Failure) {
    if (value < limitLow) {
      setState('lowRange');
    } else if (value > limitHigh) {
      setState('highRange');
    }
    return DefaultTheme.colors.danger.iconDetails;
  }

  setState('inRange');
  return DefaultTheme.colors.success.iconDetails;
}

function Scale({
  value,
  limitHigh,
  limitLow,
  setState,
  status,
}: {
  value: number | undefined;
  limitHigh: number;
  limitLow: number;
  setState: (s: ScaleState) => void;
  status: StatusType;
}) {
  const width = value !== undefined ? getWidth(value, limitHigh, limitLow) : undefined;
  const theme = useTheme();
  const markerColor = useRef(theme.colors.success.iconDetails);

  useEffect(() => {
    markerColor.current = getMarkerColor(value !== undefined ? value : 0, limitHigh, limitLow, width, setState, status);
  });

  return (
    <ScaleContainer>
      <ScaleBackgound>
        <AcceptedArea>
          <CenterMark />
        </AcceptedArea>
      </ScaleBackgound>
      {value !== undefined && width !== undefined ? <ValueMark width={width} color={markerColor.current} /> : null}
    </ScaleContainer>
  );
}

export default Scale;
