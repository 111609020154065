import { ReagentWithPairedInstruments } from '@dewire/models/definitions/api-response/reagent-with-paired-instruments';
import TextWrapper from 'components/styled-components/wrappers/TextWrapper';
import styled, { useTheme } from 'styled-components';

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${() => useTheme().font.color.white};
  padding: 2em;
  gap: 1em;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3em;
`;
const DetailTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;

interface ReagentNoOfPairedInstrumentsHeaderProps {
  reagent: ReagentWithPairedInstruments | undefined;
}

function ReagentNoOfPairedInstrumentsHeader({ reagent }: ReagentNoOfPairedInstrumentsHeaderProps) {
  return (
    <DetailsHeader>
      <h4>Lot overview</h4>
      <DetailsContainer>
        <DetailTextWrapper>
          <TextWrapper bold>Container ID:</TextWrapper>
          <TextWrapper>{reagent?.reagentId ?? ''}</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Lot:</TextWrapper>
          <TextWrapper>{reagent?.lotNumber ?? ''}</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Brand:</TextWrapper>
          <TextWrapper>{reagent?.brand ?? ''}</TextWrapper>
        </DetailTextWrapper>
        <DetailTextWrapper>
          <TextWrapper bold>Type:</TextWrapper>
          <TextWrapper>{reagent?.type ?? ''}</TextWrapper>
        </DetailTextWrapper>
      </DetailsContainer>
    </DetailsHeader>
  );
}

export default ReagentNoOfPairedInstrumentsHeader;
