import getTranslation from 'helpers/translation/get-translation';
import styled from 'styled-components';

const StyledClearButton = styled.div`
  padding: 0 1em;
  color: blue;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
`;

interface ClearButtonProps {
  onClearCallback: () => void;
}

function ClearButton({ onClearCallback }: ClearButtonProps) {
  return <StyledClearButton onClick={onClearCallback}>{getTranslation('Clear')}</StyledClearButton>;
}

export default ClearButton;
