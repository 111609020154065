import { makeStyles } from '@mui/styles';
import { DefaultTheme } from 'app/AppStyling';

export interface StyleProps {
  background: string;
}

const useSearchInputStyles = makeStyles({
  searchInputError: {
    color: DefaultTheme.colors.error.light,
    backgroundColor: DefaultTheme.background.light,
    [`& .MuiOutlinedInput-root`]: {
      '& fieldset': {
        borderColor: DefaultTheme.colors.error.light,
      },
      '&:hover fieldset': {
        borderColor: DefaultTheme.colors.error.light,
      },
      '&:Mui-focused fieldset': {
        borderColor: DefaultTheme.colors.error.light,
      },
    },
    [`& .MuiInputLabel-root`]: {
      color: DefaultTheme.colors.error.light,
    },
    [`& p`]: {
      color: DefaultTheme.colors.error.light,
      padding: '3px 0 0 14px',
      margin: '0',
      backgroundColor: DefaultTheme.font.color.white,
    },
  },
});

export default useSearchInputStyles;
