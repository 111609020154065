import InstrumentReagentOverview from 'components/reagent/dashboard/InstrumentReagentOverview';
import ViewButton from 'components/styled-components/buttons/ViewButton';
import ViewContainer from 'components/styled-components/containers/ViewContainer';
import getTranslation from 'helpers/translation/get-translation';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import styled from 'styled-components';

import ReagentLotsOverview from '../../components/reagent/dashboard/ReagentLotsOverview';

const TabContainer = styled.div`
  display: flex;
  gap: 5em;
`;

type Tabs = 'lot' | 'instruments';

function ReagentDashboardView() {
  const lotAccess = useAppSelector((state) => state.headers.accesses.reagentLotList);
  const [view, setView] = useState<Tabs>('lot');

  return (
    <ViewContainer flexdircolumn columns={0} rows={3}>
      <TabContainer>
        {lotAccess && (
          <ViewButton
            type="button"
            active={view === 'lot'}
            onClick={() => {
              setView('lot');
            }}
          >
            {getTranslation('Lot')}
          </ViewButton>
        )}
        {lotAccess && (
          <ViewButton
            type="button"
            active={view === 'instruments'}
            onClick={() => {
              setView('instruments');
            }}
          >
            {getTranslation('Instruments')}
          </ViewButton>
        )}
      </TabContainer>
      {view === 'instruments' && <InstrumentReagentOverview />}
      {view === 'lot' && <ReagentLotsOverview />}
    </ViewContainer>
  );
}

export default ReagentDashboardView;
